import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, HostListener, ViewChild, ElementRef, } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DeviceDetailsService } from '../device-details.service';
import { Subscription } from 'rxjs';
import { CommonUtils } from '../util/common-utils';
import { SearchService } from '../search.service';
import { TranslateService } from '@ngx-translate/core';
declare var setFocusOnInputField: any;
declare var openCardEdit1:any;
@Component({
  selector: 'app-airports',
  templateUrl: './airports.component.html',
  styleUrls: ['./airports.component.scss']
})
export class AirportsComponent implements OnInit, AfterViewInit {
  @Input() autoCompleteSourceItems = [];
  @Output() goBackSearchPage = new EventEmitter();
  @Output() goBackSearchPage1 = new EventEmitter();
  @Output() goBackSearchPage2 = new EventEmitter();
  @Output() autoCompleteDropDownClosed = new EventEmitter();
  @Input() searchString = '';
  @Input() selectFormControlname = '';
  isMobile: boolean;
  deviceSubscription: Subscription;
  scrollPosition: any;
  selectedInde=-1;
  airportselectedFromkeyboard=false;
  constructor(public ngxSmartModalService: NgxSmartModalService,
    public deviceDetailsService: DeviceDetailsService,
    public searchService: SearchService,
    public translateService: TranslateService,) { }
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent, index) {
    const focused = $(':focus');
    if(event.key === 'ArrowDown') {
      event.preventDefault();
    }
    if(this.searchString && this.searchString.trim().length === 3) {
      this.airportselectedFromkeyboard =true;
    } else {
      this.airportselectedFromkeyboard =false;
    }
    if (event.key === 'Tab') {
      if (this.autoCompleteSourceItems.length  >0 && index !== undefined) {
        this.selectAirport(this.autoCompleteSourceItems[index].airports[0]);
        // if (this.autoCompleteSourceItems[index].airports.length > 1) {
        //   this.childAirportSelction(focused,index);
        // } else {
        // }
      } else if (index === undefined ) {
        this.selectAirport(this.autoCompleteSourceItems[0].airports[0]);
      }
    } else if ((event.key === 'Enter' ) && index !== undefined) {
        if (this.autoCompleteSourceItems[index].airports.length > 1) {
          this.childAirportSelction(focused,index);
        } else {
          this.selectAirport(this.autoCompleteSourceItems[index].airports[0]);
        }
    } else if ((event.key === 'ArrowDown' || event.key === 'ArrowUp' )&& index !== undefined) {
      if (focused) {
        let direction = 1;
        if (event.key === 'ArrowUp') {
          direction = -1;
        }
        const focusedElemClassName = $(focused).attr('class').replace('ng-star-inserted', '');
        if (focusedElemClassName.indexOf('_') > -1) {
          // Child Area
          const itemCoord = focusedElemClassName.split('tabindex')[1].split('_');

          if (
            (direction === 1 && Number(itemCoord[1]) < (this.autoCompleteSourceItems[itemCoord[0]].airports.length -1))
            ||
            (direction === -1 && Number(itemCoord[1]) > 0)
            ) {
            $('#multipleAirports' + index + ' .airportItem')[Number(itemCoord[1]) + direction].focus();
          } else {
            this.handleFocusAcrossGroups(index, direction);
          }
        } else {
          this.handleFocusAcrossGroups(index, direction);
        }
      }
    }
  }

  handleFocusAcrossGroups(index, direction) {
    if ((direction ===1 && index < this.autoCompleteSourceItems.length -1)
    || (direction === -1 && index > 0)
    ) {
      $('.airportItem.tabindex' + (index + direction))[0].focus();
      const obj = document.getElementById('multipleAirports' + (index + direction));
      if (($(obj).closest('.card-div11').hasClass('active'))) {
        let childIndex = 0;
        if (direction === -1) {
          childIndex = this.autoCompleteSourceItems[index + direction].airports.length -1 ;
        }
          $('#multipleAirports' + (index + direction)+ ' .airportItem')[childIndex].focus();
      }
    }
  }

    childAirportSelction(focused,index){
      const obj = document.getElementById('multipleAirports' + index);
      if (($(obj).closest('.card-div11').hasClass('active'))) {
        if (focused && ($(focused).attr('class')).indexOf('_') > -1) {
          const itemCoord = ($(focused).attr('class')).split('tabindex')[1].split('_');
          this.selectAirport(this.autoCompleteSourceItems[parseInt(itemCoord[0])].airports[parseInt(itemCoord[1])]);
        } else {
          this.selectAirport(this.autoCompleteSourceItems[index].airports[0]);
        }
      } else {
        openCardEdit1(obj);
        $('#multipleAirports' + index + ' .airportItem')[0].focus();
      }
    }
  ngOnInit() {
    this.deviceSubscription = this.deviceDetailsService.isMobile1().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    if (this.selectFormControlname === 'source') {
      this.selectFormControlname = this.translateService.instant('search.Origin');
    } else {
      this.selectFormControlname =  this.translateService.instant('search.Destination');
    }
  }
  ngAfterViewInit() {
    this.openModal();
    if (this.isMobile) {
      setTimeout(() => {
        this.scrollPosition = $(window).scrollTop();
        window.scrollTo(0, 0);
      }, 100);
    }
  }
  handleModalEvents(eventName: string, currentModalId: string) {
    CommonUtils.handleModalEvents(this.ngxSmartModalService, eventName, currentModalId);
    if (eventName === 'onDismiss' || eventName === 'onClose') {

      if (this.autoCompleteSourceItems
        && this.autoCompleteSourceItems.length > 0 && this.searchString && this.searchString.trim().length === 3) {
        let matchedAirport;
        for (const groupedItems of this.autoCompleteSourceItems) {
          for (const singleItem of groupedItems.airports) {
            if (singleItem.code.toLowerCase() === this.searchString.toLowerCase()) {
              matchedAirport = singleItem;
            }
          }
        }
        if (matchedAirport && !this.airportselectedFromkeyboard) {
          this.autoCompleteDropDownClosed.emit(matchedAirport);
        }
      }else{
        if (!this.airportselectedFromkeyboard) {
          this.autoCompleteDropDownClosed.emit('');
        }
      }
    }
  }
 
  backToNormalSearch(modalName) {
    $('html').scrollTop(this.scrollPosition);
    this.ngxSmartModalService.getModal(modalName).close();
    setTimeout(() => {
      this.searchService.inputId = null;
    }, 100);
    this.goBackSearchPage1.emit(true);
  }
  openModal() {
    setTimeout(() => {
      this.ngxSmartModalService.getModal('searchInputModal').open()
    }, 100);
  }
  selectAirport(item) {
    if (this.isMobile) {
      $('html').scrollTop(this.scrollPosition);
    }
    this.ngxSmartModalService.getModal('searchInputModal').close();
    this.goBackSearchPage.emit(item);
    setTimeout(() => {
      this.searchService.inputId = null;
    }, 100);
  }
  searchAirport(item) {
    this.searchString = '';
    this.goBackSearchPage2.emit(item);
  }
}
