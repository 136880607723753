import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { throwError as _throw, Observable } from 'rxjs';
import { ErrorHandlingComponent } from '../error-handling/error-handling.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RouterModule, Router, Routes } from '@angular/router';
import { catchError } from 'rxjs/operators';


import { UserAccountService } from '../user-account.service';
import { PopupComponent } from '../popup/popup.component';
import { CommonUtils } from '../util/common-utils';
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;
@Injectable()
export class ErrorHandlingService
  implements HttpInterceptor {
  bsModalRefforSessionTimedOut: BsModalRef;
  bsModalRefforNetworkError: BsModalRef;

  constructor(private modalService: BsModalService,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(err => this.handleError(err)))
      ;
  }
  handleError(error: HttpErrorResponse) {
    // const userAccountService = this.injector.get(UserAccountService);
    //     // auth.clearLoggedInData();
    // //To know the version of RxJS npm list --depth=0 (I for this example im on version 5.5)
    // if (userAccountService && userAccountService.isLoggedIn()){
    //   userAccountService.clearLoggedInData();
    //   userAccountService.signOut();
    // }
    console.log("error coming in api",error);
    if(error.status === 503){

      if (!this.bsModalRefforNetworkError || !this.bsModalRefforNetworkError.content.bsModalRefforNetworkError) {
      //  this.closeAllModals();
        this.bsModalRefforNetworkError = this.modalService.show(PopupComponent, { initialState: { msgChange:true, message: error.message,}, backdrop: true, keyboard: false, ignoreBackdropClick: true }); 
      }
    }
    else if(error.status === 0){

      if (!this.bsModalRefforNetworkError || !this.bsModalRefforNetworkError.content.bsModalRefforNetworkError) {
      //  this.closeAllModals();
        this.bsModalRefforNetworkError = this.modalService.show(PopupComponent, { initialState: { msgChange:false,message: error.message,}, backdrop: true, keyboard: false, ignoreBackdropClick: true }); 
      }
    }else if (error.status === 403) {
      // A client-side or network error occurred. Handle it accordingly.
   
    //  setTimeout(() => {
      if (!this.bsModalRefforSessionTimedOut) {
        this.bsModalRefforSessionTimedOut =
        this.modalService.show(ErrorHandlingComponent, { initialState: {}, backdrop: true, keyboard: false, ignoreBackdropClick: true }); 
      }
     // }, 0);
     
    }
    // return an observable with a user-facing error message
    return _throw(error);
  }
  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
}