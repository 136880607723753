 import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { FlightSearchRequest } from 'src/app/entity/flight-search-request';
import { GallopLocalStorageService } from 'src/app/gallop-local-storage.service';
import { deserialize } from 'src/app/util/ta-json/src/methods/deserialize';
import { HotelQueryParam } from 'src/app/entity/hotel-query-param';
import { UserAccountService } from 'src/app/user-account.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { BookingResponseErrorType } from 'src/app/enum/booking-response-error.type';
import { TranslateService } from '@ngx-translate/core';
import { HotelBookingResponse } from 'src/app/entity/hotel-booking-response';
import { Location } from '@angular/common';
import { BookingResponse } from 'src/app/entity/booking-response';
import { SearchService } from 'src/app/search.service';
import { Title } from '@angular/platform-browser';
import { ClientConfiguration } from 'src/app/client-config.service';
import { BookingService } from 'src/app/booking.service';
import { HotelSearchService } from 'src/app/hotel-search.service';
@Component({
  selector: 'app-success-model',
  templateUrl: './success-model.component.html',
  styleUrls: ['./success-model.component.scss']
})
export class SuccessModelComponent implements OnInit {

  @Input() showHotelLink: boolean;
  @Input() responseType: BookingResponseErrorType;
  @Input() errorMessage: string;
  @Input() transactionMode: boolean = false;
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  flightBooking = false;
  multibooking =false;
  bookingResponse1: any;
  showMultipleItenaries : boolean;
 
  constructor(private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private bookingService: BookingService,
    private router: Router,
    private titleService: Title,
    private searchService: HotelSearchService,
    private clientConfig: ClientConfiguration,
    private translate: TranslateService,
    private searchservice: SearchService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private userAccountInfoService: UserAccountService,
    public translateService: TranslateService,
    private gallopLocalStorage: GallopLocalStorageService
    ) { }

  ngOnInit() {

    this.bookingResponse1 = JSON.parse(this.gallopLocalStorage.getItem("hotelBookingResponse"));
    if(!this.bookingResponse1)
     this.bookingResponse1 = JSON.parse(this.gallopLocalStorage.getItem("bookingResponse"));
    let flightSearchReq: FlightSearchRequest = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequestForBooking")));
    if (flightSearchReq) {
      this.flightBooking = true;
    } else {
      this.flightBooking = false;
    }
    if(this.bookingResponse1 && this.bookingResponse1.multiBookingResponse && this.bookingResponse1.multiBookingResponse.length >1){
      this.multibooking =true;
    }else{
      this.multibooking =false;
    }

    let distiinctTripSessionIds = [];
    if(this.bookingResponse1 && this.bookingResponse1.multiBookingResponse){
      for(let loopResponse of this.bookingResponse1.multiBookingResponse){
        if(!distiinctTripSessionIds.includes(loopResponse.tripSessionId)){
          distiinctTripSessionIds.push(loopResponse.tripSessionId);
        }
      }
    }

    if(distiinctTripSessionIds.length > 1){
      this.showMultipleItenaries = true;
    }
  }

  showMultipleItenerariesLink(){
    return this.showMultipleItenaries;
  }
  gotToHome() {
    this.bsModalRef.hide();
    this.bookingService.oldTripSessionId = undefined;
    this.searchService.EmptyHotelRequest();
    this.searchservice.bookingDone = true;
    if((this.userAccountInfoService.isUserCorporateAdmin() || this.userAccountInfoService.isUserIsTravelManager()) && this.bookingResponse1 && this.bookingResponse1.bookedHotels && this.bookingResponse1.bookedHotels.length > 0 && this.bookingResponse1.bookedHotels[0].reservationCode){
      this.searchservice.employeeEmail =[];
      this.searchservice.bookingAndApprovalDone =true;
    }
    this.searchservice.tripName='';
    this.bookingService.bookingResponse =undefined;
    if(this.searchservice.multiTripBooking){
      this.searchservice.multiTripBooking =false;
      this.searchservice.multiflightQuery =[];
      this.searchservice.multicarQuery =[];
      this.searchservice.multihotelQuery =[];
     // this.searchservice.tripName='';
      this.gallopLocalStorage.removeItem("selectedSeat");
      this.searchservice.previousSelectedCreditAirline=[];
    }
   // this.titleService.setTitle('Flight Search');
    this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
    this.router.navigate(["search"], { queryParams: {} });
  }

  showMeHotelLink() {
    this.bsModalRef.hide();
    let carSearchReq = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedCar")));
    let flightSearchReq: FlightSearchRequest = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequestForBooking")));
    let hotelQueryParam: HotelQueryParam = new HotelQueryParam();
    if (carSearchReq) {
      hotelQueryParam.hotelAddress = carSearchReq[0].pickUpLocation;
      hotelQueryParam.checkinDate = new Date(carSearchReq[0].pickUpDate);
      hotelQueryParam.checkoutDate = new Date(carSearchReq[0].dropOffDate)
    } else {
      hotelQueryParam.hotelAddress = flightSearchReq.requests[0].destination;
      hotelQueryParam.checkinDate = new Date(flightSearchReq.requests[0].journeyDate.intervalEnd);
      if (flightSearchReq.requests.length > 1) {
        hotelQueryParam.checkoutDate = new Date(flightSearchReq.requests[1].journeyDate.intervalStart);
      } else {
        hotelQueryParam.checkoutDate = hotelQueryParam.checkinDate;
      }
      hotelQueryParam.personCount = "0" + (flightSearchReq.travellers.adults);
      if (flightSearchReq.travellers.adults > 3) {
        hotelQueryParam.roomCount = "03";
      } else {
        hotelQueryParam.roomCount = "0" + (flightSearchReq.travellers.adults);
      }
    }

    this.goToHotelSearch(hotelQueryParam);

  }
  isAmnetRelease() {
    return environment.amnetSpecificRelease;
  }

  goToHotelSearch(hotelQueryParam: HotelQueryParam): void {
    let userid = this.userAccountInfoService.getUserEmail();
    let sToken = this.userAccountInfoService.getSToken();
    let queryAsJSON = JSON.parse(JSON.stringify(hotelQueryParam));
    //  this.searchservice.bookingDone= true;
    queryAsJSON.checkinDate = new DatePipe('en-US').transform(new Date(queryAsJSON.checkinDate), 'yyyy-MM-dd');
    queryAsJSON.checkoutDate = new DatePipe('en-US').transform(new Date(queryAsJSON.checkoutDate), 'yyyy-MM-dd');
    this.titleService.setTitle('Hotel Search');
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: 'hotel',
          query: encodeURIComponent(JSON.stringify(queryAsJSON)),
          step: 0,
          userid: userid,
          sToken: sToken
        },
      }
    );
  }

  getHotelBookingErrorMessage(responseType) {
    if (responseType === BookingResponseErrorType.ERROR_AVAILABILITY) {
      if (this.errorMessage) {
        return this.errorMessage;
      }
    }
    if (responseType === BookingResponseErrorType.ERROR_API) {
      return this.translateService.instant('successModel.hotelBookingPendingMessage');
    }
    if (responseType === BookingResponseErrorType.ERROR_AVAILABILITY) {
      return this.translateService.instant('successModel.hotelUnavailableMessage');
    }
    if (responseType === BookingResponseErrorType.ERROR_BOOKING_PENDING || responseType === BookingResponseErrorType.ERROR_TRIP_BOOKING_PENDING) {
      return this.translateService.instant('successModel.hotelBookingPendingMessage');
    }
    return this.translateService.instant('successModel.ThankyouforcompletingyourpassengerprofileYourbookingrequestisonitsway');
  }
  gotoSearchHotelAgain() {
    this.bsModalRef.hide();
    if(this.searchservice.multiTripBooking){
      this.searchservice.multiTripBooking =false;
      this.searchservice.multiflightQuery =[];
      this.searchservice.multicarQuery =[];
      this.searchservice.multihotelQuery =[];
      this.searchservice.tripName='';
      this.gallopLocalStorage.removeItem("selectedSeat")
      this.searchservice.previousSelectedCreditAirline=[];
    }
    this.searchservice.bookingDone = true;
    this.titleService.setTitle('Hotel Search');
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: 'hotel'
        },
      }
    );
  }
  gotoSearchFlightAgain() {
    this.bsModalRef.hide();
    if(this.searchservice.multiTripBooking){
      this.searchservice.multiTripBooking =false;
      this.searchservice.multiflightQuery =[];
      this.searchservice.multicarQuery =[];
      this.searchservice.multihotelQuery =[];
      this.gallopLocalStorage.removeItem("selectedSeat")
      this.searchservice.previousSelectedCreditAirline=[];
    }
    this.searchservice.bookingDone = true;
    //this.titleService.setTitle('Flight Search');
    this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: 'flight'
        },
      }
    );

  }
 cancelModel(){
  this.bsModalRef.hide();
 }
  goToItinerary() {
    let userid = this.userAccountInfoService.getUserEmail();
    let sToken = this.userAccountInfoService.getSToken();
    this.searchService.EmptyHotelRequest();
    this.bsModalRef.hide();
    this.bookingService.oldTripSessionId = undefined;
    if((this.userAccountInfoService.isUserCorporateAdmin() || this.userAccountInfoService.isUserIsTravelManager()) && this.bookingResponse1 && this.bookingResponse1.ticketId && this.bookingResponse1.tripId && this.bookingResponse1.transactionId){
      this.searchservice.employeeEmail=[];
      this.searchservice.bookingAndApprovalDone =true;
    }
     this.searchservice.tripName='';
    if(this.searchservice.multiTripBooking){
      this.searchservice.multiTripBooking =false;
      this.searchservice.multiflightQuery =[];
      this.searchservice.multicarQuery =[];
      this.searchservice.multihotelQuery =[];
     
      this.gallopLocalStorage.removeItem("selectedSeat")
      this.searchservice.previousSelectedCreditAirline=[];
    }
    let ticketid = this.bookingResponse1.ticketId;
    let tripid = this.bookingResponse1.tripId;
    let  transactionid = this.bookingResponse1.transactionId;
    let  tripSessionId = this.bookingResponse1.tripSessionId;
    this.bookingService.bookingResponse =undefined;
    this.titleService.setTitle('Bookings');
    if (userid && sToken) {
      this.router.navigate(["bookingHistory"],
        {
          queryParams:
          {
            type: 'detail',
            bookingType: 'upcoming',
            from: 'Itinenary',
            ticketid: ticketid,
            tripid: tripid,
            transactionid: transactionid,
            tripSessionId:tripSessionId
          },
        }
      );
      this.location.replaceState('/search');
      this.searchservice.bookingDone = false;
      this.searchservice.hotelbookingDone = true;
    } else {
      this.searchservice.bookingDone = true;
     // this.titleService.setTitle('Flight Search');
     this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
      this.router.navigate(['search']);
    }
  }
}
