export class EmailHops {


     airportname: string;
     duration: string;
     fareAttributes:any;
     baggageAllowance:any;
     airlineImageUrl: string;
     airportCode: string;
     flightHighlights:any;
     airlineName: string;
     airlineCode: string;
     flightNumber: string;
     fareClassName:string;
     oAirlineName:string;
     hopDuration:any;
     flightDate: string;
     flightEndDate: string;
     fromAirport: string;
     fromAirportName: string;
     departure: string;
     toAirport: string;
     toAirportName: string;
     arrival: string;
     flightDuration: string;
     type: string;
     typeName: string;
     brandClass: string;
}