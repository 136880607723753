<div #modal class=" show" id="">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <span class="icon-search-from modal-search-from"></span>

        <button type="button" class="close pull-right cross-button" aria-label="Close" (click)="hide()">
          <span class="icon-cross modal-cross"></span>
        </button>
      </div>
      <div class="modal-body">
        <!--for warning-->
        <!--<span class="logo-border checkmark"><span class="icon-checkmark modal-logo icon-warning"><span class="path1"><span class="path2"></span></span></span></span>-->
        <!--<span class="logo-border checkmark"><span class="icon-checkmark modal-logo "></span></span>-->

        <span *ngIf="iconSCSS && iconSCSS!=='no-icon'" class="logo-border" [ngClass]="iconSCSS">
          <span [ngClass]="'icon-'+ iconSCSS" class="modal-logo "></span>
        </span>
        <span *ngIf="!iconSCSS" class="logo-border checkmark">
          <span class="icon-checkmark modal-logo icon-warning">
            <span class="path1">
              <span class="path2"></span>
            </span>
          </span>
        </span>

        <h4 class="modal-title title-msg">{{title}}</h4>
        <div class="email-text-content" [innerHtml]="description">
        </div>

        <a *ngIf="nextStep && nextStep.length > 0" class="link" href="javascript:void(0)"
          (click)="nextStepAction()">{{nextStep}}</a>
          <div *ngIf="!showmultiitnenarylink() && this.bookingResponse && this.bookingResponse.tripId && this.bookingResponse.tripSessionId"
              class="modal-content-button">
              <button class="text-button link-primary" style="width: auto;cursor:pointer"
                  (click)="goToItinerary()"> {{'successModel.showmeitinerary' | translate}}</button>
          </div>
          <div *ngIf="showmultiitnenarylink()"
          class="modal-content-button" style="display: flex;display: flex;
          justify-content: center;">
          <div class="leftside">
          <span class="text-button" style="width: auto;"
             > {{'successModel.showmeitinerary' | translate}}</span>
              </div>
              <div class="rightside" style="display: block;">
              <div  *ngFor="let item of this.bookingResponse.multiBookingResponse">
                <a [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'upcoming',ticketid:item.ticketId,tripid:item.tripId,transactionid:item.transactionId,tripSessionId:item.tripSessionId}" routerLinkActive="active" (click)="cancelModel()">{{item.travellerDetails.travellers[0].firstName}} {{item.travellerDetails.travellers[0].lastName}}</a>
              </div>
              </div>
      </div>
        <div *ngIf="buttonName" class="modal-button-wrapper">
          <button type="button" name="buttonName" class="btn btn-default modal-button"
            (click)="acceptClick()">{{'bookingMsg.ACCEPT' | translate}}</button>
        </div>

      </div>


    </div>
  </div>
</div>