
import { FlightLegSearch } from './flight-leg-search';
import { Travellers } from './travellers';
import { FlightClassType } from '../enum/flight-class.type';
import { FlightTripType } from '..//enum/trip1.type'
import { AlgoTypes } from '../enum/algo-types';

export class FlightSearchRequest {

  public requests: FlightLegSearch[];

  public combo: string = "true";

  public travellers: Travellers = new Travellers();
  public filtered_airlines: Array<any>;
  public selected_legs: Array<any>;
  public src_filtered_airports: Array<any>;
  public travellerEmails: Array<any>;
  public previousSelectedCreditAirline:any;
  public dest_filtered_airports: Array<any>;
  public cabinClass: string = FlightClassType.ECONOMY;
  public algoType: string = AlgoTypes.PODUCTIVITY;
  public tripType: string = FlightTripType.ROUNDTRIP;
  public minPrices: any;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
