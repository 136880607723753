import { Component, Input, EventEmitter, ViewChild } from '@angular/core';
import { Options } from 'ng5-slider';
import { FilterService } from '../filter.service';
import { DateUtils } from '../util/date-utils';
import { toInt } from 'ngx-bootstrap/chronos/utils/type-checks';
import { CommonUtils } from '../util/common-utils';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html'
})
export class RangeSliderComponent {

  @Input() startAt: string;
  @Input() endAt: string;
  @Input() heading: string;
  @Input() name: string;
  @Input() defaultMinValue: any;
  @Input() defaultHighValue: any;
  @Input() filterForDuration: false;
  @Input() filterForPrice: false;
  @Input() minDurations:any
  @Input() maxDurations:any
  @Input() currency='USD';
  private readonly MIN_PER_STEP: number = 30;
  private valueChangedOrNot:Boolean;
  private rangeDataArray: Array<any>;
  minValue: number = 0;
  maxValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 5
  };
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();


  constructor(public filterService: FilterService,) {
    setTimeout(() => {
      this.manualRefresh.emit();
    }, 100);
  }

  private buildRangeDataArray() {

    this.rangeDataArray = new Array<string>();
    let date = new Date(this.startAt.split('.')[0]);
    let endDate = new Date(this.endAt.split('.')[0]);
    var toRoundMilis = 1000 * 60 * this.MIN_PER_STEP;
    // var date = new Date();
    var rounded = new Date(date.getTime() - (date.getTime() % toRoundMilis));
    while (rounded < endDate) {
      this.rangeDataArray.push(rounded.toISOString());
      rounded = new Date(rounded.getTime() + this.MIN_PER_STEP * 60000);
    }
    this.rangeDataArray.push(rounded.toISOString());
    rounded = new Date(rounded.getTime() + this.MIN_PER_STEP * 60000);
    this.rangeDataArray.push(rounded.toISOString());
    this.options = {
      floor: 0,
      ceil: this.rangeDataArray.length - 1,
      step: 1
    }
    this.maxValue = this.rangeDataArray.length - 1;
  }
  

  private buildRangeDataArrayForDuration(){
    this.rangeDataArray = new Array<string>();
    for(let i = this.startAt ; i < this.endAt; i += this.MIN_PER_STEP ){
      this.rangeDataArray.push(i);
    };
    if(this.startAt == this.endAt){
      this.rangeDataArray.push(this.startAt);
    }
    this.rangeDataArray.push(this.endAt);
    
    this.options = {floor: 0,ceil: this.rangeDataArray.length - 1,step: 1};
    this.maxValue = this.rangeDataArray.length - 1;
  }
  private buildRangeDataArrayForPrice(){
    this.rangeDataArray = new Array<string>();
    for(let i = this.startAt ; i < this.endAt; i += this.MIN_PER_STEP ){
      this.rangeDataArray.push(i);
    };
    if(this.startAt == this.endAt){
      this.rangeDataArray.push(this.startAt);
    }
    this.rangeDataArray.push(this.endAt);
    
    this.options = {floor: 0,ceil: this.rangeDataArray.length - 1,step: 1};
    this.maxValue = this.rangeDataArray.length - 1;
  }

  valueChanged() {
    if(this.name == "FLIGHT_PRICE"){
      let newTimeRange = { 'name': this.name, 'starts': this.rangeDataArray[this.minValue], 'ends': this.rangeDataArray[this.maxValue] };
      this.filterService.timeRangeChangeSubject.next(newTimeRange);
    }else if(this.name == "FLIGHT_DURATION"){
        this.valueChangedOrNot = true;
        let newTimeRange = { 'name': this.name, 'starts': this.rangeDataArray[this.minValue], 'ends': this.rangeDataArray[this.maxValue] };
        this.filterService.timeRangeChangeSubject.next(newTimeRange);
      }else{
        let newTimeRange = { 'name': this.name, 'starts': new Date(this.rangeDataArray[this.minValue]), 'ends': new Date(this.rangeDataArray[this.maxValue]) };
        this.filterService.timeRangeChangeSubject.next(newTimeRange);
      }
  }


  ngOnInit() {
    if(this.name == "FLIGHT_PRICE"){
      this.buildRangeDataArrayForPrice()
      if (this.defaultMinValue && this.rangeDataArray.indexOf(this.defaultMinValue) >= 0) this.minValue = this.rangeDataArray.indexOf(this.defaultMinValue);
      if (this.defaultHighValue && this.rangeDataArray.indexOf(this.defaultHighValue) >= 0) this.maxValue = this.rangeDataArray.indexOf(this.defaultHighValue);
    }else if(this.name == "FLIGHT_DURATION"){
      this.buildRangeDataArrayForDuration()
      if (this.defaultMinValue && this.rangeDataArray.indexOf(this.defaultMinValue) >= 0) this.minValue = this.rangeDataArray.indexOf(this.defaultMinValue);
      if (this.defaultHighValue && this.rangeDataArray.indexOf(this.defaultHighValue) >= 0) this.maxValue = this.rangeDataArray.indexOf(this.defaultHighValue);
    }else{
      this.buildRangeDataArray();
      if (this.defaultMinValue && this.rangeDataArray.indexOf(this.defaultMinValue.toISOString()) >= 0) this.minValue = this.rangeDataArray.indexOf(this.defaultMinValue.toISOString());
      if (this.defaultHighValue && this.rangeDataArray.indexOf(this.defaultHighValue.toISOString()) >= 0) this.maxValue = this.rangeDataArray.indexOf(this.defaultHighValue.toISOString());
    }
  }


  getHeadingText() {
    return this.heading;
  }

  getMinValueDate() {
    return new Date(this.rangeDataArray[this.minValue]);
  }

  getHighValueDate() {
    return new Date(this.rangeDataArray[this.maxValue]);
  }


  getMinDuration(){
    const d = DateUtils.getDurationAsHrsMinObj(this.rangeDataArray[this.minValue]);
    const flightDuration = d.hrs + ' hr ' + d.mins + ' min';
    return flightDuration;
  }
  getCurrencySymbol(currencyCode: string): string {
    return CommonUtils.getCurrencySymbol(currencyCode);
  }
getMinPrice(){
  return this.getCurrencySymbol(this.currency)+this.rangeDataArray[this.minValue]
}
getMaxPrice(){
  return this.getCurrencySymbol(this.currency)+this.rangeDataArray[this.maxValue]
}
  getMaxDuration(){
    const d = DateUtils.getDurationAsHrsMinObj(this.rangeDataArray[this.maxValue]);
    const flightDuration = d.hrs + ' hr ' + d.mins + ' min';
    return flightDuration;
  }

}