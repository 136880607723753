import { Injectable } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { Title } from '@angular/platform-browser';
import { UserAccountService } from './user-account.service';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { CommonUtils } from './util/common-utils';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  njoySpecificBuild: boolean;
  signInSubscription: Subscription;
  currentUrl: string;
  userid: string;
  sToken: string;
  isRequestInProgress = false;
  dataConsentAccepted =false;
  initResponse: any;
  private signInSubject = new BehaviorSubject<any>(null);
  signInSubject$ = this.signInSubject.asObservable();
  public loginPopupSubject = new BehaviorSubject<any>(null);
  constructor(private http: GallopHttpClient,
    private toastr: ToastrService,
    private gallopLocalStorage: GallopLocalStorageService,
    public userAccountService: UserAccountService,
    public translateService: TranslateService,
    public router: Router,
    private titleService: Title,
  ) {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
  }

  public  loginRequest(emailId: string, password: string, connectionType: string, accessToken: string): Observable<any> {
    let  body = 'login_email='+ encodeURIComponent(emailId)
    + '&login_password='+ encodeURIComponent(password)
    + '&googleAccessToken=' + accessToken
    + '&connectionType=' + connectionType ;
    const dataConsentAlreadyAccepted = localStorage.getItem('dataConsentAlreadyAccepted');
    const dataConsentRequired = localStorage.getItem('dataConsentRequired');
    if (dataConsentAlreadyAccepted !== 'true' && dataConsentRequired === 'true' && this.dataConsentAccepted) {
      body += '&dataConsentAccepted=true' ;
    } else {
      body += '&dataConsentAccepted=false' ;
    }
    return this.http.post(environment.apiLogin,body,
      {headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded')});
  }
public getDataCheckContraint(): Observable<any> {

  return this.http.get(environment.apiForCheckDataConstraint);
}
  private signUpRequest(emailId: string, password: string, connectionType: string, accessToken: string,
    firstName: string, lastName: string, profileImgUrl: string): Observable<any> {
    return this.http.get(environment.apiSignUp + '?email=' + encodeURIComponent(emailId)
      + '&password=' + encodeURIComponent(password)
      + '&googleAccessToken=' + accessToken
      + '&connectionType=' + connectionType
      + '&firstName=' + encodeURIComponent(firstName)
      + '&lastName=' + encodeURIComponent(lastName)
      + '&profileImageUrl=' + profileImgUrl
      + '&sourceApp=UserWebApp'
      + '&terms=true'
    );
    
  }
  pollingForBookingResponse(uuid): Observable<any> {
    return this.http.get(environment.apiForADSSOstatus + '?request-id=' + uuid);

  }
  pollingForGoogleSignInResponse(uuid): Observable<any> {
    return this.http.get(environment.apiForGoogleSignGetSSOStatus + '?request-id=' + uuid);

  }
  private resetPasswordLinkRequest(emailId: string): Observable<any> {
    return this.http.get(environment.apiPasswordResetlink + '?reset_request_email=' + encodeURIComponent(emailId) + '&sourceApp=UserWebApp'
    );
  }

getSSologinStatus(email): Observable<any> {
  return this.http.get(environment.apiForSSoLogin + '?email=' + encodeURIComponent(email)
  );
}
  private resetPasswordRequest(emailId: string, sToken: string, newPassword: string): Observable<any> {
    const body = 'userid=' + encodeURIComponent(emailId)
      + '&reset_password=' + encodeURIComponent(newPassword)
      + '&sToken=' + encodeURIComponent(sToken);
    return this.http.post(environment.apiResetPassword,body,
      {headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded')});
  }
  public resetPasswordFromProfileRequest(emailId: string,sToken: string, oldPassword: string, newPassword: string): Observable<any> {
    const body = 'userid=' + encodeURIComponent(emailId)
      + '&reset_password=' + encodeURIComponent(newPassword)
      + '&sToken=' + encodeURIComponent(sToken);
    return this.http.post(environment.apiResetPassword,body,
      {headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded')});
  }


  private verifyEmailRequest(emailId: string, sToken: string, userType: string): Observable<any> {
    if (userType && userType === 'corporate') {
      return this.http.get(environment.apiVerifyCompanyEmail + '?userid=' + encodeURIComponent(emailId)
      );
    } else {
      return this.http.get(environment.apiVerifyEmail + '?userid=' + encodeURIComponent(emailId)
      );
    }

  }

  private handleVerifyEmailResponse(verifyResponse: any, emailId: string, sToken: string) {
    // let signResponse = JSON.parse(response);
    if (verifyResponse.success) {
      // this.toastr.success(this.translateService.instant('Passwoard has been changed successfully!').toString());
      this.signInSubject.next("emailVerificationSuccess");
    } else {
      if (verifyResponse.error_message) {
        this.toastr.error(this.translateService.instant(verifyResponse.error_message).toString());
      } else {
        this.toastr.error(this.translateService.instant('reset.Pleasetryagain').toString());
      }
    }
  }

  private handleResetPasswordLinkResponse(resetResponse: any, emailId: string) {
    // let signResponse = JSON.parse(response);
    if (resetResponse.success) {
      this.signInSubject.next("resetPasswordLinkSuccess");
      this.toastr.success(this.translateService.instant('reset.IfUseraccountexistsfor').toString() + emailId + this.translateService.instant('reset.ane-mailwillbesentwithfurtherinstructions.').toString());
    } else {
      if (resetResponse.error_message) {
        this.toastr.error(this.translateService.instant(resetResponse.error_message).toString());
      } else {
        this.toastr.error(this.translateService.instant('reset.Pleasetryagain').toString());
      }
    }
  }

  private handleResetPasswordResponse(resetResponse: any, emailId: string, sToken: string) {
    // let signResponse = JSON.parse(response);
    if (resetResponse.success) {
      this.signInSubject.next("resetPasswordSuccess");
      sToken = resetResponse.data;
      this.toastr.success(this.translateService.instant('reset.Passwordhasbeenchangedsuccessfully!').toString());
      setTimeout(() => {
        this.router.navigate(['/profile'], { queryParams: { userid: emailId, sToken: sToken }, replaceUrl: true });
      }, 3000);
    } else {
      if (resetResponse.error_message) {
        this.toastr.error(this.translateService.instant(resetResponse.error_message).toString());
      } else {
        this.toastr.error(this.translateService.instant('reset.Pleasetryagain').toString());
      }
    }
  }

  private processResetPasswordLinkRequest(emailId: string) {
    this.resetPasswordLinkRequest(emailId).subscribe(res => {
      this.handleResetPasswordLinkResponse(res, emailId);
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          this.isRequestInProgress=false;
          this.toastr.error(this.translateService.instant('reset.Pleasecheckyourinternetandtryagain').toString());
        }, 100);
      }
    });
  }

  private processResetPasswordRequest(emailId: string, sToken: string, newPassword: string) {
    this.resetPasswordRequest(emailId, sToken, newPassword).subscribe(res => {
      this.handleResetPasswordResponse(res, emailId, sToken);
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          this.toastr.error(this.translateService.instant('reset.Pleasecheckyourinternetandtryagain').toString());
        }, 100);
      }
    });
  }

  private processVerifyEmailRequest(emailId: string, sToken: string, userType: string) {
    this.verifyEmailRequest(emailId, sToken, userType).subscribe(res => {
      this.handleVerifyEmailResponse(res, emailId, sToken);
    }, error => {
      console.log(JSON.stringify(error));
      if (error.status != 403) {
        setTimeout(() => {
          this.toastr.error(this.translateService.instant('reset.Pleasecheckyourinternetandtryagain').toString());
        }, 3000);
      }
    });
  }
  redirectToPath() {
    if ((CommonUtils.doesPathContain(this.currentUrl, 'admin'))) {
      if (this.currentUrl.search('userid') !== -1) {
        window.location.href = (this.initResponse.dashboardAppBaseUrl + this.currentUrl.slice(1) + "&sToken=" + this.sToken);
      } else {
        window.location.href = (this.initResponse.dashboardAppBaseUrl + this.currentUrl.slice(1) + "&userid=" + this.userid + "&sToken=" + this.sToken);
      }
      this.currentUrl = undefined;
    } else {
      if (this.currentUrl.search('userid') !== -1) {
        window.location.href = (this.initResponse.bookingAppBaseUrl + this.currentUrl.slice(1) + "sToken=" + this.sToken);
      } else if (this.currentUrl.search('userid') === -1 && this.currentUrl.indexOf('?') === -1) {
        window.location.href = (this.initResponse.bookingAppBaseUrl + this.currentUrl.slice(1) + "?userid=" + this.userid + "&sToken=" + this.sToken);
      }
      else {
        window.location.href = (this.initResponse.bookingAppBaseUrl + this.currentUrl.slice(1) + "&userid=" + this.userid + "&sToken=" + this.sToken);
      }
      this.currentUrl = undefined;
    }
  }
  private handleSignInResponse(signResponse: any, emailId: string, redirectRoute: string, redirectQueryParams: any) {
    // let signResponse = JSON.parse(response);
    if (signResponse.success && signResponse.data) {
      this.signInSubject.next("success");
      const dataConsentRequired = localStorage.getItem('dataConsentRequired');
      if (dataConsentRequired === 'true') {
        localStorage.setItem('dataConsentAlreadyAccepted', 'true');
      }
      if (redirectRoute && redirectRoute.length > 0) {
        if (signResponse.userid && signResponse.userid.trim().length > 0) {
          redirectQueryParams['userid'] = signResponse.userid;
        } else {
          redirectQueryParams['userid'] = emailId;
        }
        this.initResponse = signResponse.initResponse;
        redirectQueryParams['sToken'] = signResponse.data;
        redirectQueryParams['redirectedBackFromSignIn'] = 'true';
        this.userid = redirectQueryParams['userid'];
        this.sToken = redirectQueryParams['sToken']
        this.isRequestInProgress = false;
        // this.gallopLocalStorage.setItem("userid",userid);
        // this.gallopLocalStorage.setItem("sToken",sToken);
        if (this.currentUrl && this.currentUrl !== '/' && !this.userAccountService.njoySpecificBuild) {
          this.redirectToPath();
        } else {
          //this.titleService.setTitle('Flight Search');
          this.titleService.setTitle(this.translateService.instant('search.FlightSearch'));
          const navigateTo = [];
          navigateTo.push(this.getDefaultPath());
          this.router.navigate(navigateTo,
            {
              queryParams: redirectQueryParams,
              replaceUrl: true
            });
          this.currentUrl = undefined;
        }
      } else {
       // this.titleService.setTitle('Flight Search');
       this.titleService.setTitle(this.translateService.instant('search.FlightSearch'));
        this.router.navigate(['search'],
          {
            queryParams: redirectQueryParams,
            replaceUrl: true
          });
      }
    } else {
      if (signResponse.error_message) {
        this.toastr.error(this.translateService.instant(signResponse.error_message).toString());
        this.isRequestInProgress = false;
      } else {
        this.isRequestInProgress = false;
        this.toastr.error(this.translateService.instant('reset.Pleasetryagain').toString());
      }
    }
  }
  public getDefaultPath() {
    for (let i = 0; i < this.router.config.length; i++) {
      const routePath: string = this.router.config[i].path;
      if (routePath === '') {
        return this.router.config[i].redirectTo;
      }
    }
    return '/search';
  }


  private handleSignUpResponse(signUpResponse: any, emailId: string, redirectRoute: string, redirectQueryParams: any) {
    // let signResponse = JSON.parse(response);
    if (signUpResponse.success && signUpResponse.data) {
      this.signInSubject.next("success");
      if (redirectRoute && redirectRoute.length > 0) {
        redirectQueryParams['userid'] = emailId;
        redirectQueryParams['sToken'] = signUpResponse.data;
        this.router.navigate([redirectRoute.split('?')[0]],
          {
            queryParams: redirectQueryParams,
            replaceUrl: true
          });
      } else {
        this.router.navigate(['/login'],
          {
            queryParams: redirectQueryParams,
            replaceUrl: true
          });
      }
    } else {
      if (signUpResponse.error_message) {
        this.toastr.error(this.translateService.instant(signUpResponse.error_message).toString());
      } else {
        this.toastr.error(this.translateService.instant('reset.Pleasetryagain').toString());
      }
    }
  }

  private processSignInRequest(emailId: string, password: string, connectionType: string, accessToken: string,
    redirectRoute: string, redirectQueryParams: any) {
    let tempRedirectedQueryParams = {};
    if (redirectQueryParams) {
      tempRedirectedQueryParams = JSON.parse(JSON.stringify(redirectQueryParams));
    }
    this.signInSubscription = this.loginRequest(emailId, password, connectionType, accessToken).subscribe(res => {
      this.handleSignInResponse(res, emailId, redirectRoute, tempRedirectedQueryParams);
    }, error => {
      setTimeout(() => {
        this.isRequestInProgress = false;
        this.toastr.error(this.translateService.instant('reset.Pleasecheckyourinternetandtryagain').toString());
      }, 100);

    });
  }

  private processSignUpRequest(emailId: string, password: string, connectionType: string, accessToken: string,
    firstName: string, lastName: string, profileImgUrl: string,
    redirectRoute: string, redirectQueryParams: any) {
    let tempRedirectedQueryParams = {};
    if (redirectQueryParams) {
      tempRedirectedQueryParams = JSON.parse(JSON.stringify(redirectQueryParams));
    }
    this.signInSubscription = this.signUpRequest(emailId, password, connectionType, accessToken,
      firstName, lastName, profileImgUrl).subscribe(res => {
        this.handleSignUpResponse(res, emailId, redirectRoute, tempRedirectedQueryParams);
      }, error => {
        setTimeout(() => {
          this.toastr.error(this.translateService.instant('reset.Pleasecheckyourinternetandtryagain').toString());
        }, 100);

      });
  }
  public userEmailSignIn(emailId: string, password: string, redirectRoute: string, redirectQueryParams: any) {
    this.processSignInRequest(emailId, password, '', '', redirectRoute, redirectQueryParams);
  }

  public userGoogleSignIn(emailId: string, accessToken: string, redirectRoute: string, redirectQueryParams: any) {
    this.processSignInRequest(emailId, '', 'google', accessToken, redirectRoute, redirectQueryParams);
  }

  public userEmailSignUp(emailId: string, password: string, firstName: string, lastName: string,
    redirectRoute: string, redirectQueryParams: any) {
    this.processSignUpRequest(emailId, password, '', '', firstName, lastName, '', redirectRoute, redirectQueryParams);
  }

  public userGoogleSignUp(emailId: string, accessToken: string, firstName: string, lastName: string, profileImgURL: string,
    redirectRoute: string, redirectQueryParams: any) {
    this.processSignUpRequest(emailId, '', 'google', accessToken, firstName, lastName, profileImgURL, redirectRoute, redirectQueryParams);
  }


  public userResetPasswordLink(emailId: string) {
    this.processResetPasswordLinkRequest(emailId);
  }

  public userResetPasswordRequest(emailId: string, sToken: string, newPassword: string) {
    this.processResetPasswordRequest(emailId, sToken, newPassword);
  }

  public userVerifyEmail(emailId: string, sToken: string, userType: string) {
    this.processVerifyEmailRequest(emailId, sToken, userType);
  }
}
