<ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'calendarOptionModal')" [hideDelay]="0"
  (onClose)="handleModalEvents('onClose', 'calendarOptionModal')"
  (onDismiss)="handleModalEvents('onDismiss', 'calendarOptionModal')" [closable]="false" #calendarOptionModal
  identifier="calendarOptionModal">

  <div class="modal-container flight-modal-container filter-modal modalAirportFilterInfo" style="min-width:240px"
    (click)="$event.stopPropagation();">

    <div class="modal-body" >
      <ng-container *ngFor="let item of this.template">
        <div class="calendar" *ngIf="item.url">
          <a style="cursor:pointer;" (click)="this.value='apple';proceedtoCalendar(this.value,item.url)"> 
                <div class="icon_img_div">
                  <img  *ngIf='item.link_name == "apple"' class="image" style="height: 30px;" src="assets/images/apple.png" alt="" />
                  <img  *ngIf='item.link_name == "outlook"' class="image" style="height: 30px;" src="assets/images/outlook.png" alt="" />
                  <img  *ngIf='item.link_name == "outlook.com"' class="image" style="height: 30px;" src="assets/images/icon-outlookcom.svg" alt="" />
                  <img  *ngIf='item.link_name == "google"' class="image" style="height: 30px;" src="assets/images/google-icon.png" alt="" />
                  <img  *ngIf='item.link_name == "yahoo"' class="image" style="height: 30px;" src="assets/images/yahoo.png" alt="" />
                </div>
                <div class="icon_label_div">
                  <span class="trends">{{item.link_name}}</span>
                </div>
            </a> 
        </div>
        <div class="calendar calendar2" *ngIf="!item.url && item.details">
          <a class="calendar_link"  > 
                <div class="icon_img_div">
                  <img  *ngIf='item.link_name == "apple"' class="image" style="height: 30px;" src="assets/images/apple.png" alt="" />
                  <img  *ngIf='item.link_name == "outlook"' class="image" style="height: 30px;" src="assets/images/outlook.png" alt="" />
                  <img  *ngIf='item.link_name == "outlook.com"' class="image" style="height: 30px;" src="assets/images/icon-outlookcom.svg" alt="" />
                  <img  *ngIf='item.link_name == "google"' class="image" style="height: 30px;" src="assets/images/google-icon.png" alt="" />
                  <img  *ngIf='item.link_name == "yahoo"' class="image" style="height: 30px;" src="assets/images/yahoo.png" alt="" />
                </div>
                <div class="icon_label_div">
                  <span class="trends">{{item.link_name}}</span>
                </div>

                <div class="links">
                  <div class="details_link" *ngFor="let Newitem of item.details"> 
                    <a  style="cursor:pointer;" (click)="this.value=item.link_name;proceedtoCalendar(this.value,Newitem.url)">
                      <div  class="img-container"><img style="height: 20px;" src="assets/images/flight-circle.png" alt="" /></div>
                      <div>{{Newitem.detail.from}} - {{Newitem.detail.starts}}</div>
                      <div style="margin: 0px 10px;"> to </div>
                      <div>{{Newitem.detail.to}} - {{Newitem.detail.ends}}</div>
                    </a>
                  </div>
                </div>
          </a> 
        </div>
      </ng-container>
      

      <!-- <div class="calendar"> 
        <a style="cursor:pointer;" (click)="this.value='outlook';proceedtoCalendar(this.value)">
          <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/outlook.png" alt="" /> </div>
            <div class="icon_label_div">
             <span class="trends">Outlook</span>
          </div>
        </a> 
      </div>

      <div class="calendar"> 
        <a style="cursor:pointer;" (click)="this.value='outlook.com';proceedtoCalendar(this.value)">
          <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/icon-outlookcom.svg" alt="" /> </div>
          <div class="icon_label_div">
            <span class="trends">Outlook.com</span>
          </div>
        </a> 
      </div>

      <div class="calendar"> 
        <a style="cursor:pointer;" (click)="this.value='outlook.com';proceedtoCalendar(this.value)">
          <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/icon-outlookcom.svg" alt="" /> </div>
          <div class="icon_label_div">
            <span class="trends">Outlook.com</span>
          </div>
        </a> 
      </div>

      <div class="calendar">
        <a style="cursor:pointer;" (click)="this.value='google';proceedtoCalendar(this.value)">
          <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/google-icon.png" alt="" /> </div>
            <div class="icon_label_div">
              <span class="trends">Google</span>
            </div>
        </a>
      </div>

      <div class="calendar">
        <a style="cursor:pointer;" (click)="this.value='google';proceedtoCalendar(this.value)">
          <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/google-icon.png" alt="" /> </div>
            <div class="icon_label_div">
              <span class="trends">Google</span>
            </div>
        </a>
        <div class="links">
          <div> <a href="">this is first link</a></div>
          <div>
            <a href="">this is second link</a>
          </div>
        </div>
      </div>

      <div class="calendar">
         <a style="cursor:pointer;" (click)="this.value='yahoo';proceedtoCalendar(this.value)">
            <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/yahoo.png" alt="" /></div>
              <div class="icon_label_div">
                <span class="trends">Yahoo</span>
              </div>
          </a>
      </div> -->

      
    </div>
    <div class="modal-footer">

    </div>
  </div>
</ngx-smart-modal>