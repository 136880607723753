import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';

export const ALL_AIRLINES =

	[
		{
			"id": "",
			"value": _('ngOption.SelectAirline')
		},
		{
			"id": "AS",
			"value": "Alaska"
		},
		{
			"id": "AA",
			"value": "American"
		},
		{
			"id": "DL",
			"value": "Delta"
		},
		{
			"id": "F9",
			"value": "Frontier"
		},
		{
			"id": "B6",
			"value": "JetBlue"
		},
		{
			"id": "WN",
			"value": "SouthWest"
		},
		{
			"id": "NK",
			"value": "Spirit"
		},
		{
			"id": "UA",
			"value": "United"
		},
		{
			"id": "VX",
			"value": "Virgin America"
		},
		{
			"value": "Aegean Airlines",
			"id": "A3"
		},
		{
			"value": "Aer Lingus",
			"id": "EI"
		},
		{
			"value": "Aeroflot",
			"id": "SU"
		},
		{
			"value": "Aerolíneas Argentinas",
			"id": "AR"
		},
		{
			"value": "AeroMexico",
			"id": "AM"
		},
		{
			"value": "Aeroplan",
			"id": ""
		},
		{
			"value": "Aigle Azur",
			"id": "ZI"
		},
		{
			"value": "Air Algérie",
			"id": "AH"
		},
		{
			"value": "Air Antilles",
			"id": "3S"
		},
		{
			"value": "Air Arabia",
			"id": "G9"
		},
		{
			"value": "AirAsia",
			"id": "AK"
		},
		{
			"value": "Air Astana",
			"id": "4L"
		},
		{
			"value": "Air Austral",
			"id": "UU"
		},
		{
			"value": "Air Bagan",
			"id": "W9"
		},
		{
			"value": "airBaltic",
			"id": "BT"
		},
		{
			"value": "Airblue",
			"id": "PA"
		},
		{
			"value": "Air Botswana",
			"id": "BP"
		},
		{
			"value": "Air Burkina",
			"id": "2J"
		},
		{
			"value": "Air Busan",
			"id": "BX"
		},
		{
			"value": "Air Canada",
			"id": "AC"
		},
		{
			"value": "Air Caraïbes",
			"id": "TX"
		},
		{
			"value": "Air China",
			"id": "CA"
		},
		{
			"value": "Air Corsica",
			"id": "XK"
		},
		{
			"value": "Air Côte d'Ivoire",
			"id": "HF"
		},
		{
			"value": "Air Europa",
			"id": "UX"
		},
		{
			"value": "Air France KLM",
			"id": "AF"
		},
		{
			"value": "Air India",
			"id": "AI"
		},
		{
			"value": "Air Indus",
			"id": "I6"
		},
		{
			"value": "Air Inuit",
			"id": "3H"
		},
		{
			"value": "Air Italy",
			"id": "I9"
		},
		{
			"value": "Air KBZ",
			"id": "K7"
		},
		{
			"value": "Airkenya",
			"id": "P2"
		},
		{
			"value": "Air Madagascar",
			"id": "MD"
		},
		{
			"value": "Air Malta",
			"id": "KM"
		},
		{
			"value": "Air Mauritius",
			"id": "MK"
		},
		{
			"value": "Air Moldova",
			"id": "ZV"
		},
		{
			"value": "Air Namibia",
			"id": "SW"
		},
		{
			"value": "Air New Zealand",
			"id": "NZ"
		},
		{
			"value": "Air Niugini",
			"id": "PX"
		},
		{
			"value": "Air Peace",
			"id": "P4"
		},
		{
			"value": "Air Tahiti",
			"id": "VT"
		},
		{
			"value": "Air Tahiti Nui",
			"id": "TN"
		},
		{
			"value": "Air Zimbabwe",
			"id": "UM"
		},
		{
			"value": "Alitalia",
			"id": "AZ"
		},
		{
			"value": "Alrosa Airlines",
			"id": "6R"
		},
		{
			"value": "ANA - All Nippon Airways",
			"id": "NH"
		},
		{
			"value": "Ariana Afghan Airlines",
			"id": "FG"
		},
		{
			"value": "Arik Air",
			"id": "W3"
		},
		{
			"value": "Aserca Airlines",
			"id": "R7"
		},
		{
			"value": "Asian Wings Airways",
			"id": "YJ"
		},
		{
			"value": "Asiana Airlines",
			"id": "OZ"
		},
		{
			"value": "Asky Airlines",
			"id": "KP"
		},
		{
			"value": "Astra Airlines",
			"id": "A2"
		},
		{
			"value": "AtlasGlobal",
			"id": "KK"
		},
		{
			"value": "Aurigny",
			"id": "GR"
		},
		{
			"value": "Avianca",
			"id": "AV"
		},
		{
			"value": "Avianca Brasil",
			"id": "O6"
		},
		{
			"value": "Avior Airlines",
			"id": "9V"
		},
		{
			"value": "Azerbaijan Airlines",
			"id": "J2"
		},
		{
			"value": "Azul",
			"id": "AD"
		},
		{
			"value": "Bahamasair",
			"id": "UP"
		},
		{
			"value": "Bangkok Airways",
			"id": "PG"
		},
		{
			"value": "Batik Air",
			"id": "ID"
		},
		{
			"value": "Belavia",
			"id": "B2"
		},
		{
			"value": "Bhutan Airlines",
			"id": "B3"
		},
		{
			"value": "Biman Bangladesh Airlines",
			"id": "BG"
		},
		{
			"value": "Binter Canarias",
			"id": "NT"
		},
		{
			"value": "British Airways",
			"id": "BA"
		},
		{
			"value": "Brussels Airlines",
			"id": "SN"
		},
		{
			"value": "Buddha Air",
			"id": "U4"
		},
		{
			"value": "Bulgaria Air",
			"id": "FB"
		},
		{
			"value": "Canadian North",
			"id": "5T"
		},
		{
			"value": "Caribbean Airlines",
			"id": "BW"
		},
		{
			"value": "Cathay Pacific",
			"id": "CX"
		},
		{
			"value": "Cayman Airways",
			"id": "KX"
		},
		{
			"value": "Cebu Pacific Air",
			"id": "5J"
		},
		{
			"value": "CemAir",
			"id": "9M"
		},
		{
			"value": "China Airlines",
			"id": "CI"
		},
		{
			"value": "China Eastern",
			"id": "MU"
		},
		{
			"value": "China Southern",
			"id": "CZ"
		},
		{
			"value": "CM Airlines",
			"id": "CM"
		},
		{
			"value": "Copa",
			"id": "CM"
		},
		{
			"value": "Corsair",
			"id": "SS"
		},
		{
			"value": "Czech Airlines",
			"id": "OK"
		},
		{
			"value": "Dana Air",
			"id": "9J"
		},
		{
			"value": "Donghai Airlines",
			"id": "J5"
		},
		{
			"value": "Drukair",
			"id": "KB"
		},
		{
			"value": "Egyptair",
			"id": "MS"
		},
		{
			"value": "El Al",
			"id": "LY"
		},
		{
			"value": "Emirates",
			"id": "EK"
		},
		{
			"value": "Ethiopian Airlines",
			"id": "ET"
		},
		{
			"value": "Etihad Airways",
			"id": "EY"
		},
		{
			"value": "Eurowings",
			"id": "EW"
		},
		{
			"value": "EVA Air",
			"id": "BR"
		},
		{
			"value": "Finnair",
			"id": "AY"
		},
		{
			"value": "FirstNation",
			"id": ""
		},
		{
			"value": "flydubai",
			"id": "FZ"
		},
		{
			"value": "flynas",
			"id": "XY"
		},
		{
			"value": "Garuda",
			"id": "GA"
		},
		{
			"value": "Gol",
			"id": "G3"
		},
		{
			"value": "Grupo SATA",
			"id": "SP"
		},
		{
			"value": "Gulf Air",
			"id": "GF"
		},
		{
			"value": "Hainan Airlines",
			"id": "HU"
		},
		{
			"value": "Harbour Air",
			"id": "H3"
		},
		{
			"value": "Hawaiian Airlines",
			"id": "HA"
		},
		{
			"value": "Hebei Airlines",
			"id": "NS"
		},
		{
			"value": "HK Express",
			"id": "N8"
		},
		{
			"value": "Hunnu Air",
			"id": "MR"
		},
		{
			"value": "Iberia",
			"id": "IB"
		},
		{
			"value": "Icelandair",
			"id": "FI"
		},
		{
			"value": "Insel Air",
			"id": "7I"
		},
		{
			"value": "Interjet",
			"id": "D6"
		},
		{
			"value": "IranAir",
			"id": "IR"
		},
		{
			"value": "Japan Airlines",
			"id": "JL"
		},
		{
			"value": "Jazeera Airways",
			"id": "J9"
		},
		{
			"value": "Jeju Air",
			"id": "7C"
		},
		{
			"value": "Jet Airways",
			"id": "9W"
		},
		{
			"value": "Juneyao Airlines",
			"id": "HO"
		},
		{
			"value": "Korean Air",
			"id": "KE"
		},
		{
			"value": "Kuwait Airways",
			"id": "KU"
		},
		{
			"value": "La Compagnie",
			"id": "B0"
		},
		{
			"value": "LAM",
			"id": "TM"
		},
		{
			"value": "Lao Airlines",
			"id": "QV"
		},
		{
			"value": "Laser Airlines",
			"id": "QL"
		},
		{
			"value": "LATAM",
			"id": "LE"
		},
		{
			"value": "LATAM Brasil",
			"id": "JJ/LA"
		},
		{
			"value": "LC Perú",
			"id": "W4"
		},
		{
			"value": "Lion Air",
			"id": "JT"
		},
		{
			"value": "Lufthansa",
			"id": "LH"
		},
		{
			"value": "Mahan Air",
			"id": "W5"
		},
		{
			"value": "Malaysia Airlines",
			"id": "MH"
		},
		{
			"value": "Malindo Air",
			"id": "OD"
		},
		{
			"value": "Mann Yadanarpon Airlines",
			"id": "7Y"
		},
		{
			"value": "MEA",
			"id": "ME"
		},
		{
			"value": "Med-View Airline",
			"id": "VL"
		},
		{
			"value": "MIAT",
			"id": "OM"
		},
		{
			"value": "Mokulele Airlines",
			"id": "MW"
		},
		{
			"value": "Montenegro Airlines",
			"id": "YM"
		},
		{
			"value": "Myanmar Airways",
			"id": "8M"
		},
		{
			"value": "Nordavia",
			"id": "5N"
		},
		{
			"value": "NordStar Airlines",
			"id": "Y7"
		},
		{
			"value": "Norwegian",
			"id": "DY"
		},
		{
			"value": "Novoair",
			"id": "VQ"
		},
		{
			"value": "Oman Air",
			"id": "WY"
		},
		{
			"value": "Onur Air",
			"id": "8Q"
		},
		{
			"value": "Pegasus Airlines",
			"id": "PC"
		},
		{
			"value": "Perimeter Aviation",
			"id": "4B"
		},
		{
			"value": "Peruvian Airlines",
			"id": "P9 "
		},
		{
			"value": "Philippine Airlines",
			"id": "PR"
		},
		{
			"value": "PIA",
			"id": "PK"
		},
		{
			"value": "Porter Airlines",
			"id": "PD"
		},
		{
			"value": "Precision Air",
			"id": "PW"
		},
		{
			"value": "Proflight Zambia",
			"id": "P0"
		},
		{
			"value": "Qantas",
			"id": "QF"
		},
		{
			"value": "Qatar Airways",
			"id": "QR"
		},
		{
			"value": "Ravn Alaska",
			"id": "7H"
		},
		{
			"value": "Royal Air Maroc",
			"id": "AT"
		},
		{
			"value": "Royal Brunei Airlines",
			"id": "BI"
		},
		{
			"value": "Royal Jordanian",
			"id": "RJ"
		},
		{
			"value": "RwandAir",
			"id": "WB"
		},
		{
			"value": "S7 Airlines",
			"id": "S7"
		},
		{
			"value": "Saratov Airlines",
			"id": "6W"
		},
		{
			"value": "SAS",
			"id": "SK"
		},
		{
			"value": "Saudia",
			"id": "SV"
		},
		{
			"value": "SaudiGulf",
			"id": "6S"
		},
		{
			"value": "Seaborne Airlines",
			"id": "BB"
		},
		{
			"value": "Sichuan Airlines",
			"id": "3U"
		},
		{
			"value": "Singapore Airlines",
			"id": "SQ"
		},
		{
			"value": "Solaseed Air",
			"id": "6J"
		},
		{
			"value": "South African Airways",
			"id": "SA"
		},
		{
			"value": "SpiceJet",
			"id": "SG"
		},
		{
			"value": "Spring Airlines",
			"id": "9S"
		},
		{
			"value": "SriLankan Airlines",
			"id": "UL"
		},
		{
			"value": "Sun Country Airlines",
			"id": "SY"
		},
		{
			"value": "SunExpress",
			"id": "XQ"
		},
		{
			"value": "Surinam Airways",
			"id": "PY"
		},
		{
			"value": "TAAG",
			"id": "DT"
		},
		{
			"value": "TACV",
			"id": "TA"
		},
		{
			"value": "Tame",
			"id": "EQ"
		},
		{
			"value": "TAP Air Portugal",
			"id": "TP"
		},
		{
			"value": "Thai Airways",
			"id": "TG"
		},
		{
			"value": "THY - Turkish Airlines",
			"id": "TK"
		},
		{
			"value": "Trans Air Congo",
			"id": "P6"
		},
		{
			"value": "Air Transat",
			"id": "TS"
		},
		{
			"value": "Tunisair",
			"id": "TU"
		},
		{
			"value": "Ukraine International Airlines",
			"id": "3T"
		},
		{
			"value": "Ural Airlines",
			"id": "U6"
		},
		{
			"value": "US-Bangla Airlines",
			"id": "BS"
		},
		{
			"value": "UTair",
			"id": "UT"
		},
		{
			"value": "Uzbekistan Airways",
			"id": "HY"
		},
		{
			"value": "Vanilla Air",
			"id": "JW"
		},
		{
			"value": "Vietnam Airlines",
			"id": "VN"
		},
		{
			"value": "Virgin Atlantic",
			"id": "VS"
		},
		{
			"value": "Virgin Australia",
			"id": "VA"
		},
		{
			"value": "Vistara",
			"id": "UK"
		},
		{
			"value": "Vueling",
			"id": "VY"
		},
		{
			"value": "WestJet",
			"id": "WS"
		},
		{
			"value": "Sunwing",
			"id": "WG"
		},
		{
			"value": "Xiamen Airlines",
			"id": "MF"
		},
		{
			"value": "Yakutia",
			"id": "R3"
		},
		{
			"value": "Yangon Airways",
			"id": "YH"
		},
		{
			"value": "Yemenia",
			"id": "IY"
		},
		{
			"value": "Yeti Airlines",
			"id": "YT"
		},
		{
			"value": "ZagrosJet",
			"id": "Z4"
		}
	];


export const availableIcons = {
	"VA": true,
	"VS": true,
	"VX": true,
	"ZL": true,
	"YO": true,
	"XY": true,
	"WY": true,
	"WS": true,
	"WN": true,
	"WB": true,
	"US": true,
	"UA": true,
	"U2": true,
	"TN": true,
	"TL": true,
	"TG": true,
	"TB": true,
	"SX": true,
	"ST": true,
	"SS": true,
	"SN": true,
	"SK": true,
	"SA": true,
	"RO": true,
	"QR": true,
	"PR": true,
	"PG": true,
	"OV": true,
	"OU": true,
	"OA": true,
	"NK": true,
	"NF": true,
	"ML": true,
	"MI": true,
	"MH": true,
	"ME": true,
	"LY": true,
	"LO": true,
	"KM": true,
	"KL": true,
	"JU": true,
	"JQ": true,
	"JP": true,
	"JE": true,
	"J9": true,
	"IZ": true,
	"IY": true,
	"IR": true,
	"IG": true,
	"HV": true,
	"HR": true,
	"HA": true,
	"GL": true,
	"GF": true,
	"G4": true,
	"FJ": true,
	"F9": true,
	"EY": true,
	"ET": true,
	"DY": true,
	"DL": true,
	"DE": true,
	"DD": true,
	"CY": true,
	"BT": true,
	"BI": true,
	"BA": true,
	"B6": true,
	"AY": true,
	"AS": true,
	"AM": true,
	"AC": true,
	"AB": true,
	"AA": true,
	"6H": true,
	"4U": true,
	"WG": true,
	"IX": true,
	"TS": true
 };
