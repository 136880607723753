import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DateUtils } from '../util/date-utils';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtils } from '../util/common-utils';
import { EmailQuoteOptionsService } from '../email-quote-options.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FareAttributes } from '../entity/fare-attributes';
import { BaggageDetails } from '../entity/baggage-details';
@Component({
  selector: 'app-see-details',
  templateUrl: './see-details.component.html',
  styleUrls: ['./see-details.component.scss']
})
export class SeeDetailsComponent implements OnInit {
  @Input() comingFromSearchPage;
  @Input() IsMobile =false;;
  @Input() airlines;
  @Input() airports;
  @Input() airplaneData;
  @Input() selectedLegIndexForPopUp=-1;
  @Input() detailsList;
  bsModalRef: BsModalRef;
  constructor( public translateService: TranslateService,
    private bsModalRefForUpcomingTrip: BsModalRef,) { }
    
  @Input() tripSelectedToshowOnpopUp: any;
  fareAttributes:any;
  ngOnInit(): void {
    if(this.tripSelectedToshowOnpopUp.type == 'flight' && this.detailsList && this.comingFromSearchPage ==false){
      if(this.detailsList.flight_option.FareAttributes){
        this.fareAttributes = this.detailsList.flight_option.FareAttributes;
      } 
    }
    else if(this.comingFromSearchPage && this.tripSelectedToshowOnpopUp.type == 'flight'){
        if(this.tripSelectedToshowOnpopUp.option.flight_option.FareAttributes){
          this.fareAttributes = this.tripSelectedToshowOnpopUp.option.flight_option.FareAttributes;
          this.detailsList = this.tripSelectedToshowOnpopUp.option;
        }
    }
  }
  public onCancel(): void {
    this.bsModalRefForUpcomingTrip.hide();
  }
  getClassBytype(type) {
    if (type == "flight" && (this.fareAttributes == undefined || this.fareAttributes == null )) {
      return 'flight-row detailed flight-details'
    } else {
      return 'flight-row detailed'
    }

  }
  getDuration(flight, legIndex: number) {
    return this.getFlightLegDuration(flight, legIndex);
  }
  getFlightLegDuration(flight, legIndex: number): any {
    let durationInMins = this.getFlightLegDurationInMin(flight, legIndex);
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }
  getFlightLegDurationInMin(flight, legIndex: number): any {
    if (!flight && !flight.option.flight_option.legs[legIndex]) return 0;
    let startDate = new Date(flight.option.flight_option.legs[legIndex].hops[0].starts);
    let endDate = new Date(flight.option.flight_option.legs[legIndex].hops[flight.option.flight_option.legs[legIndex].hops.length - 1].ends);

    return DateUtils.getDateDiffInMinutes(endDate, startDate);
  }
  getStopsUIText(item, legIndex: number) {
    let stopsText = this.translateService.instant('flightChart.nonstop');
    if (item.length > 0) {
      if (item.length == 1) {
        stopsText = this.translateService.instant('flightSelected.1stop');
      } else {
        stopsText = item.length - 1 + this.translateService.instant('flightSelected.spacestops');
      }

      let stopAirports = undefined;
      for (let layover of item) {
        if (stopAirports) {
          stopAirports = stopAirports + ', ' + layover.to;
        } else {
          stopAirports = layover.to;
        }
      }
      stopsText = stopsText;
    }
    return ' ' + stopsText;
  }

  getHopDuration(durationInMins) {
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }
  getDisplayFlightDate(dateString: string, format: string): string {
    return DateUtils.getFormattedDateForGivenTimeZone(dateString, format);
  }

  getDurationOfhop(hop) {
    let d = DateUtils.getDurationAsHrsMinObj(hop.duration);
    return d.hrs + ' hr ' + d.mins + ' min';
  }
  getDisplayDateTimeForFlights(dateString: string, format: string): string {
    return DateUtils.getFormattedDateForGivenTimeZone(dateString, format);
  }
  carUrl(car) {
    if (car.images) {
      return car.images.S;
    }
  }
  getDisplayDate(dateString: string, format: string): string {
    return DateUtils.getFormattedDateWithoutTimeZoneFromFormat(new Date(dateString), format);
  }
  getFlightIconURL(flightCode: string) {
    return CommonUtils.getAirlineImageUrl(flightCode);
  }
 

  checkSeat(seat){
    let removedSpacesText = seat.split(" ").join("");
    if(removedSpacesText.length == 0){
      return false;
    }else{
      return true;
    }

  }

  getBrandText(brandClass: string) {
    if (brandClass && brandClass.trim().length > 0) {
      return '(' + brandClass + ')';
    }
    return '';
  }
  getLayOverDuration(hops,i){
    
    let endsDate = new Date(hops[i].ends);
    let startDate = new Date(hops[i + 1].starts)
    let durationInMins = DateUtils.getDateDiffInMinutes(startDate, endsDate);
    let durationInHrsAndMin = DateUtils.getDurationAsHrsMinObj(durationInMins);
    return durationInHrsAndMin.hrs + " hr " + durationInHrsAndMin.mins + " min ";
  }
  transformMinute(value: number): string {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    return hours + ' hrs ' + minutes + ' mins';
  }
  getPerDayPrice(car) {
    if (car.displayPrice) {
      var subTotal = (car.displayPrice / car.numberOfDay);
      return subTotal;
    } else {
      var subTotal = (car.price / car.numberOfDay);
      return subTotal;
    }
  }
  getRatingStarsMap(hotelStars) {
    return EmailQuoteOptionsService.getHotelRatingStarsMap(hotelStars);
  }
  isAmenityAvailable(type: string) {
    if (this.tripSelectedToshowOnpopUp && this.tripSelectedToshowOnpopUp.amenities) {
      return this.tripSelectedToshowOnpopUp.amenities[type];
    }
    return false;
  }
  getCurrencySymbol(item): string {
    if (item.displayCurrency) {
      return CommonUtils.getCurrencySymbol(item.displayCurrency);
    } else {
      return CommonUtils.getCurrencySymbol(item.currency);
    }
  }
  getCancellationPolicy(hotelOption) {
    var cancellationPolicy = '';
    if (hotelOption.cancellationPolicyInfo
      && hotelOption.cancellationPolicyInfo != null
      && hotelOption.cancellationPolicyInfo.trim().length > 0) {
      cancellationPolicy = hotelOption.cancellationPolicyInfo.toLowerCase();
    }
    if (hotelOption.hotelRateDetail
      && hotelOption.hotelRateDetail.cancelInfo
      && hotelOption.hotelRateDetail.cancelInfo.text
      && hotelOption.hotelRateDetail.cancelInfo.text.length > 0) {
      cancellationPolicy = hotelOption.hotelRateDetail.cancelInfo.text[0];
    }
    if (hotelOption.hotelRooms && hotelOption.hotelRooms != null
      && hotelOption.hotelRooms.length > 0
      && hotelOption.hotelRooms[0].hotelRates
      && hotelOption.hotelRooms[0].hotelRates != null
      && hotelOption.hotelRooms[0].hotelRates.length > 0
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies != null
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies.length > 0
      && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].cancellationDate) {
      let dateStr = new Date(DateUtils.getFormattedDateWithoutTimeZone(
        hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].cancellationDate
      )).toDateString().split(' ');
      cancellationPolicy = this.translateService.instant('optionSelection.CancellationPolicy').toString()
        + hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].amountAfterCancellation
        + this.translateService.instant('optionSelection.spacefeeafterspace').toString()
        + dateStr[1] + ' ' + dateStr[2];
    }
    return cancellationPolicy;
  }
  getDetailsStyleModal() {
    if (this.IsMobile) {
      return { 'justify-content': 'center', 'border-left': 'none' }
    } else {
      return { 'justify-content': 'start', 'border-left': '2px solid #e3e3e3' }
    }

  }

  getLocationType(item) {
    if (item && item !== '') {
      if (item === 'Terminal') {
        return 'At airport terminal'
      } else {
        return 'Outside airport - shuttle from airport terminal'
      }
    }
  }
  getCorporateFareAttributeMoreOptions(item) {
    if (item.corporateRate) {
      return item.corporateRate;
    }
  }
  getSeatFareAttributeMoreOptions(item) {
    return this.getCurrentFlightFareAttributesForMoreOptions(item).seat;
  }
  getCurrentFlightFareAttributesForMoreOptions(item): FareAttributes {
    return item.FareAttributes;
  }
  isMixed(item): boolean {
    if(item.flightHighlights){
    return item.flightHighlights.mixedClass;
    }
  }
  getCarryOnBagFareAttributeMoreOptions(item) {
    return this.getCurrentFlightFareAttributesForMoreOptions(item).carryBag;
  }
  getBaggageDetails(item) {
    let baggageDetails: BaggageDetails = item.baggageAllowance;
    if ( item.brandDetails && item.brandDetails.length > 0) {
      baggageDetails = item.brandDetails[0].baggageAllowance;
    }
    if (baggageDetails) {
      if (baggageDetails.numberOfPieceAllowed && baggageDetails.numberOfPieceAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.numberOfPieceAllowed) > 0) {
        return (Number.parseInt(baggageDetails.numberOfPieceAllowed) === 1) ? this.translateService.instant('flightChart.1checkedbagperadult')
          : baggageDetails.numberOfPieceAllowed + ' ' + this.translateService.instant('flightChart.checkedbagsperadult');
      }
      if ((baggageDetails.maxWeightAllowed && baggageDetails.maxWeightAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.maxWeightAllowed) === 0) || (!baggageDetails.maxWeightAllowed)) {
        return this.translateService.instant('flightChart.Nocheckedbags');
      } else {
        return baggageDetails.maxWeightAllowed + ' ' + baggageDetails.weightUnit.toLowerCase() + this.translateService.instant('flightChart.peradult');
      }
    }
    return null;
  }
  getBaggageNumber(item) {
    let baggageDetails: BaggageDetails = item.baggageAllowance;
    if (item.brandDetails && item.brandDetails.length > 0) {
      baggageDetails = item.baggageAllowance;
    }
    if (baggageDetails) {
      if (baggageDetails.numberOfPieceAllowed && baggageDetails.numberOfPieceAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.numberOfPieceAllowed) > 0) {
        return (Number.parseInt(baggageDetails.numberOfPieceAllowed))
      }
      if ((baggageDetails.maxWeightAllowed && baggageDetails.maxWeightAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.maxWeightAllowed) === 0) || (!baggageDetails.maxWeightAllowed)) {
        return this.translateService.instant('flightChart.Nocheckedbags');
      } else {
        return (Number.parseInt(baggageDetails.maxWeightAllowed));
      }
    }
    return null;
  }
  getBaggageDetailsColor1(item) {
    let baggageDetails: BaggageDetails = item.baggageAllowance;
    if (item.brandDetails && item.brandDetails.length > 0) {
      baggageDetails = item.baggageAllowance;
    }
    if (baggageDetails) {
      if (baggageDetails.numberOfPieceAllowed && baggageDetails.numberOfPieceAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.numberOfPieceAllowed) > 0) {
        return 'allowed';
      }
      if ((baggageDetails.maxWeightAllowed && baggageDetails.maxWeightAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.maxWeightAllowed) === 0) || (!baggageDetails.maxWeightAllowed)) {
        return 'notallowed';
      } else {
        return 'allowed';
      }
    }
    return null;
  }
  getRefundAttributeMoreOptions(item) {
    return this.getCurrentFlightFareAttributesForMoreOptions(item).refund;
  }
  getChangesFareAttributeMoreOptions(item) {
    return this.getCurrentFlightFareAttributesForMoreOptions(item).rebooking;
  }
  getCurrentFlightFareAttributes(): FareAttributes {

    return this.tripSelectedToshowOnpopUp.hops[0].fareAttributes;
  }
  getWifiAttributeMoreOptions(item) {
    return this.getCurrentFlightFareAttributesForMoreOptions(item).wifi;
  }
  getMealFareAttributeMoreOptions(item) {
    return this.getCurrentFlightFareAttributesForMoreOptions(item).meal;
  }
  getWifiFareAttribute() {
    return this.getCurrentFlightFareAttributes().wifi;
  }
  getCheckedBagFareAttribute() {
    return this.getCurrentFlightFareAttributes().checkedBag;
  }
  getCarryOnBagFareAttribute() {
    return this.getCurrentFlightFareAttributes().carryBag;
  }
  getRefundAttribute() {
    return this.getCurrentFlightFareAttributes().refund;
  }
  getChangesFareAttribute() {
    return this.getCurrentFlightFareAttributes().rebooking;
  }
  getSeatFareAttribute() {
    return this.getCurrentFlightFareAttributes().seat;
  }
  getWifiAttribute() {
    return this.getCurrentFlightFareAttributes().wifi;
  }
  getMealFareAttribute() {
    return this.getCurrentFlightFareAttributes().meal;
  }
  getLegroomFareAttributeMoreOptions(item) {
    return this.getCurrentFlightFareAttributesForMoreOptions(item).legRoom;
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.onCancel();
  }
}
