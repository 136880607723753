import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { UserAccountService } from './user-account.service';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BookingHistoryService {

  constructor(private httpClient: GallopHttpClient,
    private userAccountService: UserAccountService,) { }

  requestBookingHistoryList(): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken);
    return this.httpClient.get(environment.apiForBookingHistory + "?userid=" + encodeURIComponent(userId)
      );
  }


  public getAllBookings(startDate,endDate):Observable<any>{
    return this.httpClient.get(environment.apiForTravelManagerManagement 
      + '?startDate='+ encodeURIComponent(startDate)
      +'&endDate='+ encodeURIComponent(endDate));
  }
  getDetail(ticketid, tripid, transacId,tripSessionId): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    if(tripSessionId){
      const params = new HttpParams()
      .set('ticketid', ticketid)
      .set('tripid', tripid)
      .set('transactionid', transacId)
      .set('tripSessionId', tripSessionId);
     return this.httpClient.get(environment.apiForTripSessionBookingHistoryDetail, { params });
    }else{
    
      const params = new HttpParams()
      .set('ticketid', ticketid)
      .set('tripid', tripid)
      .set('transactionid', transacId)
       return this.httpClient.get(environment.apiForTripSessionBookingHistoryDetail, { params });    
    }
    
  }

  getReportDetail(userEmail, ticketid, tripid, transacId): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.httpClient.get(environment.apiForTripSessionBookingHistoryDetail 
      + "?ticketid=" + encodeURIComponent(ticketid)
      + "&tripid=" + encodeURIComponent(tripid)
      + "&userEmail=" + encodeURIComponent(userEmail)
      + "&transactionid=" + encodeURIComponent(transacId)
      +"&forReport=true"
    );
  }

  getPost(ticketid, tripid, transacId): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('ticketid', ticketid)
      .set('tripid', tripid)
      .set('transactionid', transacId);
    return this.httpClient.get(environment.apiForBookingHistoryCancel, { params });
  }

  bookingCancelApproval(tripSessionId): any{
    const userId = this.userAccountService.getUserEmail();
    return this.httpClient.get(environment.apiForBookingHistoryCancelApproval + "?userid=" + encodeURIComponent(userId) + "&tripSessionId=" + encodeURIComponent(tripSessionId) );
  }

  getPostOnChange(ticketid, tripid, transacId, message): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('ticketid', ticketid)
      .set('tripid', tripid)
      .set('transactionid', transacId)
      .set('message', message);
    return this.httpClient.post(environment.apiForBookingHistoryChanges, message, { params });
  }
  getPostExpensifyAdd(ticketid, tripid, transacId, type, expensifyId, expenseProvider): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('ticketid', ticketid)
      .set('tripid', tripid)
      .set('transactionid', transacId)
      .set('type', type)
      .set('expensifyid', expensifyId)
      .set('expenseProvider', expenseProvider);

    return this.httpClient.post(environment.apiForBookingHistoryExpensify, type, { params });
  }

  

}
