import { Injectable } from '@angular/core';
import { FlightResult } from './entity/flight-result';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SearchService } from './search.service';
import { environment } from '../environments/environment';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { HotelResult } from './entity/hotel-result';

// @Injectable({
//   providedIn: 'root'
// })

@Injectable()
export class SearchResultService {

  private noOfFlightLegs = 1;
  private selectedFlightSubject = new BehaviorSubject<any>(null);
  selectedFlight$ = this.selectedFlightSubject.asObservable();

  private selectedHotelSubject = new BehaviorSubject<any>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();

  selectedFlightsMap: any[] = [{ id: '', flight: '' }];

  noOfFlightLegsSubscription: Subscription;

  selectedFlightSearchIdx: number = 0;
  selectedHotelSearchIdx: number = 0;
  passengersForm: any;

  selectedHotelDetailObj: any;

  constructor(private searchService: SearchService,
    private gallopLocalStorage: GallopLocalStorageService) {
    this.subscribeSearchServiceEvents();
    let selectedFlight = this.gallopLocalStorage.getItem("selectedFlight");
    if (selectedFlight) {
      selectedFlight = JSON.parse(selectedFlight);
      this.selectedFlightSubject.next(selectedFlight);
    }

    let selectedHotel = this.gallopLocalStorage.getItem("selectedHotel");
    if (selectedHotel) {
      selectedHotel = JSON.parse(selectedHotel);
      this.selectedHotelSubject.next(selectedHotel)
    }
  }

  ngOnInit() {

  }

  broadcastSelectedFlight(flightResult: FlightResult, searchIndex: number, brandedFareCheckBox: boolean,noncombo:boolean) {
    if (!noncombo && brandedFareCheckBox) {
      let flightHasOutsidePolicy =0;
      for (let legIndex = 0; legIndex < flightResult.legs.length; legIndex++) {
        for (let hopIndex = 0; hopIndex < flightResult.legs[legIndex].flightHops.length; hopIndex++) {
          flightResult.legs[legIndex].flightHops[hopIndex].brandClass =
            (flightResult.legs[legIndex].brandDetails && flightResult.legs[legIndex].brandDetails[0].name) ? flightResult.legs[legIndex].brandDetails[0].name : '';
        }
        if (!flightResult.legs[legIndex].flightHighlights.withinPolicy) {
         flightHasOutsidePolicy =1
        } 
      }
      if(flightHasOutsidePolicy===1){
        this.searchService.outsidePolicyselected=true;
      }else{
        this.searchService.outsidePolicyselected=false;
      }
      flightResult.price += flightResult.legs[0].brandDetails[0].priceDiff;
      flightResult.fareBreakup.totalPrice += flightResult.legs[0].brandDetails[0].priceDiff;
      if (flightResult.legs[0].brandDetails && flightResult.legs[0].brandDetails[0].serviceFee
        && flightResult.legs[0].brandDetails[0].serviceFee > 0
      ) {
        flightResult.serviceFee = flightResult.legs[0].brandDetails[0].serviceFee;
      }
      if (flightResult.discountedPrice && flightResult.legs[0].brandDetails && flightResult.legs[0].brandDetails.length > 0) {
        flightResult.discountedPrice += flightResult.legs[0].brandDetails[0].priceDiff;
        flightResult.fareBreakup.discountedPrice += flightResult.legs[0].brandDetails[0].priceDiff;
      }
    }
    
   // if(!noncombo){
    let flightHasOutsidePolicy =0;
    for (let legIndex = 0; legIndex < flightResult.legs.length; legIndex++) {
      if (!flightResult.legs[legIndex].flightHighlights.withinPolicy) {
        flightHasOutsidePolicy=1;
      }
    }
    if(flightHasOutsidePolicy===1){
      for (let legIndex = 0; legIndex < flightResult.legs.length; legIndex++) {
        flightResult.legs[legIndex].flightHighlights.withinPolicy =false;

      }
      this.searchService.outsidePolicyselected=true;
    }else{
      this.searchService.outsidePolicyselected=false;
    }
  //  this.gallopLocalStorage.setItem("selectedFlight", JSON.stringify(flightResult));
    this.selectedFlightSubject.next(flightResult);
   // }else{
     // this.gallopLocalStorage.setItem("selectedNonComboFlight", JSON.stringify(this.searchService.nonComboSelectedFlight));
     // this.selectedFlightSubject.next(this.searchService.multipleOutgoingSelectedFlight);
   // }
   if(this.searchService.multiTripBooking){
    let selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"));
    if(selectedFlight){
      selectedFlight.push(flightResult);
    }else{
      selectedFlight =[];
      selectedFlight.push(flightResult)
    }
    this.gallopLocalStorage.setItem("selectedFlight", JSON.stringify(selectedFlight));

   let tripSessionListEntry =  {"type":"flight","index":selectedFlight.length-1};
    // this.selectedFlightSubject.next(flightResult);
    let selectedTripBookingList = JSON.parse(this.gallopLocalStorage.getItem("selectedTripBookingList"));
    if(selectedTripBookingList){
      selectedTripBookingList.push(tripSessionListEntry);
    }else{
      selectedTripBookingList =[];
      selectedTripBookingList.push(tripSessionListEntry)
    }
    this.gallopLocalStorage.setItem("selectedFlightTripSession", JSON.stringify(selectedTripBookingList));
  }else{
    this.gallopLocalStorage.removeItem("selectedFlight");
    let selectFlight =[];
      selectFlight.push(flightResult)
  this.gallopLocalStorage.setItem("selectedFlight", JSON.stringify(selectFlight));
  this.selectedFlightSubject.next(flightResult);
}
   if(!this.searchService.multiTripBooking){
    this.gallopLocalStorage.removeItem("selectedHotel");
    this.gallopLocalStorage.removeItem("selectedHotelDetailedObj");
    this.gallopLocalStorage.removeItem("selectedCar");
    this.gallopLocalStorage.removeItem("selectedCarForBooking");
   }
    
    this.selectedFlightSearchIndex = searchIndex;
  }

  broadcastSelectedHotel(hotelResult: HotelResult, searchIndex: number,item?) {
    if(this.searchService.multiTripBooking && !item){
      let selectHotel = JSON.parse(this.gallopLocalStorage.getItem("selectedHotel"));
      if(selectHotel){
        selectHotel.push(hotelResult);
      }else{
        selectHotel =[];
        selectHotel.push(hotelResult)
      }
      this.gallopLocalStorage.setItem("selectedHotel", JSON.stringify(selectHotel));
      let tripSessionListEntry =  {"type":"hotel","index":selectHotel.length-1};
    // this.selectedFlightSubject.next(flightResult);
    let selectedTripBookingList = JSON.parse(this.gallopLocalStorage.getItem("selectedTripBookingList"));
    if(selectedTripBookingList){
      selectedTripBookingList.push(tripSessionListEntry);
    }else{
      selectedTripBookingList =[];
      selectedTripBookingList.push(tripSessionListEntry)
    }
    this.gallopLocalStorage.setItem("selectedFlightTripSession", JSON.stringify(selectedTripBookingList));
    }else{
      if(!item){
      let  selectHotel =[];
      selectHotel.push(hotelResult)
      this.gallopLocalStorage.removeItem("selectedHotel");
    this.gallopLocalStorage.setItem("selectedHotel", JSON.stringify(selectHotel));
      }
    }
   // this.gallopLocalStorage.setItem("selectedHotel", JSON.stringify(hotelResult));
    if(!this.searchService.multiTripBooking){
    this.gallopLocalStorage.removeItem("selectedFlight");
    this.gallopLocalStorage.removeItem("selectedCar");
    this.gallopLocalStorage.removeItem("selectedCarForBooking");
    }
    this.selectedHotelSubject.next(hotelResult);
    this.selectedHotelSearchIndex = searchIndex;
  }

  subscribeSearchServiceEvents() {

    this.noOfFlightLegsSubscription = this.searchService.noOfFlightLegs$.subscribe((num) => {
      this.noOfFlightLegs = num;
    });
  }

  getSelectedFlightByIndex(index: number): FlightResult {

    if (this.selectedFlightsMap) {
      Object.entries(this.selectedFlightsMap).forEach((el) => {
        if (el[1]['id'] === index) {
          return el[1]['value'];
        }
      })
    }

    return null;
  }

  unsubscribeSearchServiceEvents() {
    this.noOfFlightLegsSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribeSearchServiceEvents();
  }

  getFlightIconURL(flightCode: string): string {
    flightCode += '.gif';
    return environment.flightIconUrl + flightCode;
  }

  get selectedFlightSearchIndex(): number {
    return this.selectedFlightSearchIdx;
  }

  set selectedFlightSearchIndex(val: number) {
    this.selectedFlightSearchIdx = val;
  }

  get selectedHotelSearchIndex(): number {
    return this.selectedHotelSearchIdx;
  }

  set selectedHotelSearchIndex(val: number) {
    this.selectedHotelSearchIdx = val;
  }

}
