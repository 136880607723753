import { Component, OnInit, ViewChild } from '@angular/core';
import { UserAccountService } from '../user-account.service';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.scss']
})
export class ErrorHandlingComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  constructor(
    public bsModalRefforSessionTimedOut: BsModalRef,
    public router: Router,
    public userAccountService: UserAccountService,) { }

  ngOnInit() {
  }

  isAmnetRelease() {
    return environment.amnetSpecificRelease;
  }

  public onConfirm(): void {
    this.userAccountService.clearLoggedInData();
    this.userAccountService.signOut();
    this.bsModalRefforSessionTimedOut.hide();
    window.location.href = '/login';

  }

}
