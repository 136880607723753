import { Injectable } from '@angular/core';
import { FlightSearchResponse } from './entity/flight-search-response';
import { Constants } from './util/constants';
import { BehaviorSubject } from 'rxjs';
import { ScoringLabel } from './entity/scoring-label';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { HotelSearchResponse } from './entity/hotel-search-response.';
import { FlightResult } from './entity/flight-result';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { TranslateService } from "@ngx-translate/core";
import { HotelFilterDTO } from './entity/hotel-filter-dto';
import { FilterType } from './enum/filter.type';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { start } from 'repl';
import { DateUtils } from './util/date-utils';
import { Data } from './util/bookingdata';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class FilterService {
  public currLegIndex = 0;
  public hotelFilterDefs: HotelFilterDTO = new HotelFilterDTO();
  optimizeOn: String[] = [];
  filter_airlines: Array<string> = new Array<string>();
  filter_airlineAlliances: Array<string> = new Array<string>();
  filter_airports: Array<string> = new Array<string>();
  filter_destination_airports: Array<string> = new Array<string>();
  temp_filter_airlineAlliances=[];
  departureFlightTimeOption = { 'starts': undefined, 'ends': undefined };
  arrivalFlightTimeOption = { 'starts': undefined, 'ends': undefined };
  flightDurationOption = { 'starts': undefined, 'ends': undefined }
  flightPriceOption = { 'starts': undefined, 'ends': undefined }
  temp_filter_policy :string ='NONE';
  temp_filter_stops = Constants.MAX_STOPS;
  temp_filter_departure_range:any=  this.departureFlightTimeOption;
  temp_filter_arrival_range:any =this.arrivalFlightTimeOption;
  temp_filter_duration_range:any = this.flightDurationOption;
  temp_filter_price_range:any = this.flightPriceOption;
  appliedFilterList: Array<FilterType> = new Array();
  originalFlightSearchResponse: FlightSearchResponse;
  originalHotelSearchResponse: HotelSearchResponse;
  flightTimeRangeOptions: any;
  flightPriceRangeOptions: any;
  noOfStops: number = Constants.MAX_STOPS;
  preferredAirline: string = Constants.ANY;
  public showLessFlag: boolean = true;
  public loadingForShowMoreFlag: boolean = false;
  noOfStopsChangeSubject = new BehaviorSubject<any>(null);
  noOfStopsChange$ = this.noOfStopsChangeSubject.asObservable();

  airlinesChangeSubject = new BehaviorSubject<any>(null);
  airlinesChange$ = this.airlinesChangeSubject.asObservable();

  airportChangeSubject = new BehaviorSubject<any>(null);
  airportChange$ = this.airlinesChangeSubject.asObservable();

  policyChangeSubject = new BehaviorSubject<any>(null);
  policyChange$ = this.airlinesChangeSubject.asObservable();

  timeRangeChangeSubject = new BehaviorSubject<any>(null);
  timeRangeChangeObserver$ = this.timeRangeChangeSubject.asObservable();

  resetFiltersSubject = new BehaviorSubject<any>(null);
  resetFiltersObservable$ = this.resetFiltersSubject.asObservable();


  setOriginalHotelSearchResults(hotelSearchResponse: HotelSearchResponse): any {
    this.originalHotelSearchResponse = hotelSearchResponse;
  }

  constructor(public translateService: TranslateService,
    public activatedRoute:ActivatedRoute,) { }

  public setOriginalFlightSearchResults(originalFlightSearchResult: FlightSearchResponse) {
    this.originalFlightSearchResponse = originalFlightSearchResult;
    this.updateTimeFilterRange();
    this.broadcastResetFilters();
  }

  public updateOriginalFlightSearchResults(originalFlightSearchResult: FlightSearchResponse) {
    this.originalFlightSearchResponse = originalFlightSearchResult;
    this.updateTimeFilterRange();
    this.broadcastUpdateFilters();
  }

  public updateTimeFilterRange() {
    try {
      if (this.originalFlightSearchResponse && this.originalFlightSearchResponse.flightsLists[0].flights.length > 0) {
        // Flight starts filter 
        var newArray = this.originalFlightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
          if (a.legs[0].flightHops[0].starts < b.legs[0].flightHops[0].starts) return -1;
          else if (a.legs[0].flightHops[0].starts > b.legs[0].flightHops[0].starts) return 1;
          else return 0;
        });
        let departureStartRange = newArray[0].legs[0].flightHops[0].starts;
        let departureEndRange = newArray[newArray.length - 1].legs[0].flightHops[0].starts;

        // Flight ends filter 
        var newArray = this.originalFlightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
          let destinationEndsA = a.legs[0].flightHops[a.legs[0].flightHops.length - 1].ends;
          let destinationEndsB = b.legs[0].flightHops[b.legs[0].flightHops.length - 1].ends;
          if (destinationEndsA < destinationEndsB) return -1;
          else if (destinationEndsA > destinationEndsB) return 1;
          else return 0;
        });
        
        let destinationStartRange = newArray[0].legs[0].flightHops[newArray[0].legs[0].flightHops.length - 1].ends;
        let destintionEndRange = newArray[newArray.length - 1].legs[0].flightHops[newArray[newArray.length - 1].legs[0].flightHops.length - 1].ends;
//flight price filter
var newArrayFotPrice = this.originalFlightSearchResponse.flightsLists[0].flights.sort(function (a, b) {
  if (a.price < b.price) return -1;
  else if (a.price > b.price) return 1;
  else return 0;
});
let minprice=newArrayFotPrice[0].price;
let maxPrice =  newArrayFotPrice[newArrayFotPrice.length-1].price;
let currency = 'USD';
if(this.originalFlightSearchResponse.flightsLists[0].flights && this.originalFlightSearchResponse.flightsLists[0].flights.length >0){
  if(this.originalFlightSearchResponse.flightsLists[0].flights[0].displayCurrency){
    currency = this.originalFlightSearchResponse.flightsLists[0].flights[0].displayCurrency;
  }else{
    currency = this.originalFlightSearchResponse.flightsLists[0].flights[0].currency;
  }
}
      //  Flight Duration filter 
        let allduration = [];
        this.originalFlightSearchResponse.flightsLists[0].flights.forEach((e)=>{
          const flightHops = e.legs[0].flightHops;
          const startTime = new Date(flightHops[0].starts);
          const endsTime = new Date(flightHops[flightHops.length -1 ].ends);
          const durationInMin =   DateUtils.getDateDiffInMinutes(endsTime,startTime);
          const obj = {start: startTime.toISOString(),ends:endsTime.toISOString(),durationInMin:durationInMin};
            if(allduration.length == 0){allduration.push(obj);}
            else{let durationFind = allduration.find(a => a.durationInMin !== durationInMin);if(durationFind){allduration.push(obj);};};
          });

          var newArray1 = allduration.sort(function (a, b) {
            let minflightDurationA = a.durationInMin;
            let maxflightDurationB = b.durationInMin;
            if (minflightDurationA < maxflightDurationB) return -1;
            else if (minflightDurationA > maxflightDurationB) return 1;
            else return 0;
          });
          let minflightDuration = newArray1[0].durationInMin;
          let maxflightDuration = newArray1[newArray1.length - 1].durationInMin;
        let departureRange = { 'id': 'DEPART_TIME_RANGE', 'heading': this.translateService.instant('filterService.Flightdeparturetime'), 'starts': departureStartRange, 'ends': departureEndRange ,forDuration:false,forPrice:false,'currency':currency};
        let destintionRange = { 'id': 'ARIVAL_TIME_RANGE', 'heading': this.translateService.instant('filterService.Flightarrivaltime'), 'starts': destinationStartRange, 'ends': destintionEndRange ,forDuration:false,forPrice:false,'currency':currency};
        let flightDuration = { 'id': 'FLIGHT_DURATION', 'heading': this.translateService.instant('filterService.flightDuration'), 'starts': minflightDuration, 'ends': maxflightDuration ,forDuration:true,forPrice:false,'currency':currency};
        let flightPrice = { 'id': 'FLIGHT_PRICE', 'heading': this.translateService.instant('filterService.flightPrice'), 'starts': minprice, 'ends': maxPrice ,forPrice:true,forDuration:false,'currency':currency};
        this.flightTimeRangeOptions = [];
        this.flightTimeRangeOptions.push(departureRange);
        this.flightTimeRangeOptions.push(destintionRange);
       this.flightTimeRangeOptions.push(flightDuration);
       this.flightPriceRangeOptions=[];
       this.flightPriceRangeOptions.push(flightPrice);
      }
    } catch (error) {
      console.log(error);
    }


  }
  public set optimizeFilterArray(optimizeFilterArray: String[]) {
    this.optimizeOn = JSON.parse(JSON.stringify(optimizeFilterArray));
  }

  public get optimizeFilterArray(): String[] {
    return this.optimizeOn;
  }

  public set numOfStops(value: number) {
    this.noOfStops = value;
  }

  public get numOfStops(): number {
    return this.noOfStops;
  }

  public set airline(value: string) {
    this.preferredAirline = value;
  }

  public get airline(): string {
    return this.preferredAirline;
  }

  broadcastNoOfStops(value: number) {
    this.noOfStopsChangeSubject.next(value);
  }

  broadcastResetFilters() {
    this.resetFiltersSubject.next('true');
  }
  broadcastGetFiltersResults() {
    this.resetFiltersSubject.next('filterResults');
  }
  broadcastResetFiltersResults() {
    this.resetFiltersSubject.next('resetResults');
  }
  broadcastUpdateFilters() {
    this.resetFiltersSubject.next('update');
  }
  broadcastgetFilters() {
    this.resetFiltersSubject.next('reset');
  }

  broadcastPreferredAirline(value: string) {
    this.airlinesChangeSubject.next(value);
  }

  // currentSortOptionId =   'recommended';

  public filterByWithinPolicyHotel(hotelSearchResponse: HotelSearchResponse): HotelSearchResponse {
    let hotelSearchRes = hotelSearchResponse;

    if (hotelSearchRes && hotelSearchRes.hotelsList[0]) {

      hotelSearchRes = JSON.parse(JSON.stringify(hotelSearchResponse));
      hotelSearchRes.hotelsList = hotelSearchRes.hotelsList.filter(hotel => {
        return hotel.withinPolicy;
      });
    }
    return hotelSearchRes;
  }
  public filterByWithinPolicy(flightSearchResponse: FlightSearchResponse): FlightSearchResponse {
    let flightSearchRes = flightSearchResponse;

    if (flightSearchRes && flightSearchRes.flightsLists[0]) {

      flightSearchRes = JSON.parse(JSON.stringify(flightSearchResponse));
      flightSearchRes.flightsLists[0].flights = flightSearchRes.flightsLists[0].flights.filter(flight => {
        if (flight.otherFarePriceAttributes && flight.otherFarePriceAttributes.length > 0){
          for(let counter=0; counter < flight.otherFarePriceAttributes.length; counter++){
            if (flight.otherFarePriceAttributes[counter].legs[0].flightHighlights.withinPolicy){
              return true;
            }
          }
          return false;

        }else{
          return flight.legs[0].flightHighlights.withinPolicy;
        }
      });
    }
    return flightSearchRes;
  }
  public filterByNoOfStops(noOfStops: number, flightSearchResponse: FlightSearchResponse): FlightSearchResponse {
    let flightSearchRes = flightSearchResponse;

    if (flightSearchRes && flightSearchRes.flightsLists[0]) {

      flightSearchRes = JSON.parse(JSON.stringify(flightSearchResponse));
      flightSearchRes.flightsLists[0].flights = flightSearchRes.flightsLists[0].flights.filter(flight => {

        let leg = flight.legs[0];
        let flight_NoOfStops = (leg.flightHops.length - 1);
        return (flight_NoOfStops <= noOfStops) ? true : false;
      });
    }
    return flightSearchRes;
  }

  public optimizeFlightResponse(optimizeOn: String[], flightSearchResponse: FlightSearchResponse): FlightSearchResponse {

    let flightSearchRes: FlightSearchResponse = deserialize(JSON.parse(JSON.stringify(flightSearchResponse)), FlightSearchResponse);

    if (flightSearchRes && flightSearchResponse.flightsLists && flightSearchRes.flightsLists[0]) {

      if (optimizeOn.length == 0) {
        this.setDefaultOptimization(flightSearchRes.scoringLabels.labels);
      }
      let scoreIdList: string[] = this.getScoreIdList(flightSearchRes.scoringLabels.labels);

      //TODO: this is assuming we always will be getting combo results
      flightSearchRes.flightsLists[0].flights = flightSearchRes.flightsLists[0].flights.sort(function (a, b) {

        let a_score: number = 0;
        let b_score: number = 0;


        scoreIdList.forEach((scoreLabel) => {
          a_score += a.scores[scoreLabel];
          b_score += b.scores[scoreLabel];
        });

        return a_score - b_score;
      });
    }
    return flightSearchRes;
  }

  private getScoreIdList(scoringLabels: ScoringLabel[]): string[] {

    let scoreIdList: string[] = [];

    scoringLabels.forEach((scoringLabel: ScoringLabel) => {

      if (this.isPresentFilter(scoringLabel.label)) {
        scoreIdList.push(scoringLabel.id);
      }
    });
    return scoreIdList;
  }

  public filterByDepartureTimeRange(flightSearchResponse, minTime, maxTime) {
    flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.filter(flight => {
      let start = new Date(flight.legs[0].flightHops[0].starts.split('.')[0]).getTime();

      if (start >= new Date(minTime).getTime() && start <= new Date(maxTime).getTime()) {
        return flight;
      }
    });

    return flightSearchResponse;
  }


  public filterByArrivalTimeRange(flightSearchResponse, minTime, maxTime) {
    flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.filter(flight => {
      let end = new Date(flight.legs[0].flightHops[flight.legs[0].flightHops.length - 1].ends.split('.')[0]).getTime();
      if (end >= new Date(minTime).getTime() && end <= new Date(maxTime).getTime()) {
        return flight;
      }
    });

    return flightSearchResponse;
  }

  public filterByDuration(flightSearchResponse, minTime, maxTime) {    
    flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.filter(flight => {
          let start = new Date(flight.legs[0].flightHops[0].starts);
          let end = new Date(flight.legs[0].flightHops[flight.legs[0].flightHops.length - 1].ends);
          const checkDurationInMin =   DateUtils.getDateDiffInMinutes(end,start);
          if (minTime <= checkDurationInMin && maxTime >= checkDurationInMin) {return flight;}
      });       
    return flightSearchResponse;
  }
  public filterByPrice(flightSearchResponse, minTime, maxTime) {    
    flightSearchResponse.flightsLists[0].flights = flightSearchResponse.flightsLists[0].flights.filter(flight => {
      let priceRangeMAtched=false;
      if(flight.otherFarePriceAttributes
        && flight.otherFarePriceAttributes.length > 0) {
     
          for(let counter = 0; counter < flight.otherFarePriceAttributes.length; counter++) {
            let    effectivePriceObject = flight.otherFarePriceAttributes[counter];
            if (minTime <= effectivePriceObject.price && maxTime >=effectivePriceObject.price) {
              priceRangeMAtched=true;
             break
             
            }
          }
      }
          if (priceRangeMAtched) {return flight;}
      });       
    return flightSearchResponse;
  }
  filterByPreferredNoncomboAirlines(preferredAirlines: Array<string>, flightSearchResponse: FlightSearchResponse) {

    let flightSearchRes = flightSearchResponse;

    if (flightSearchRes && flightSearchRes.flightsLists[0]) {

      flightSearchRes = JSON.parse(JSON.stringify(flightSearchResponse));
      flightSearchRes.flightsLists[0].flights = flightSearchRes.flightsLists[0].flights.filter(flight => {

        let flightHops = flight.legs[0].flightHops[0];
        //for (let flightHop of flightHops) {
          for (let airlineChoice of preferredAirlines) {
            if (airlineChoice.toUpperCase() == Constants.ANY.toUpperCase()) {
              return true;
            } else {
              if (airlineChoice.toUpperCase() == flightHops.carrier.toUpperCase() || airlineChoice.toUpperCase() == flightHops.operatingCarrier.toUpperCase()) {
                return true;
              }
            }

          }
       // }
        return false;
      });
    }
    return flightSearchRes;
  }
  filterByPreferredAirlines(preferredAirlines: Array<string>, flightSearchResponse: FlightSearchResponse) {

    let flightSearchRes = flightSearchResponse;

    if (flightSearchRes && flightSearchRes.flightsLists[0]) {

      flightSearchRes = JSON.parse(JSON.stringify(flightSearchResponse));
      flightSearchRes.flightsLists[0].flights = flightSearchRes.flightsLists[0].flights.filter(flight => {

        let flightHops = flight.legs[0].flightHops;
        for (let flightHop of flightHops) {
          for (let airlineChoice of preferredAirlines) {
            if (airlineChoice.toUpperCase() === Constants.ANY.toUpperCase()) {
              return true;
            } else {
              if (airlineChoice.toUpperCase() === flightHop.carrier.toUpperCase() 
                || airlineChoice.toUpperCase() === flightHop.operatingCarrier.toUpperCase()) {
                return true;
              }
            }

          }
        }
        return false;
      });
    }

    return flightSearchRes;
  }

  filterByAllianceAirlines(allianceAirlines: Array<string>, flightSearchResponse: FlightSearchResponse) {

    let flightSearchRes = flightSearchResponse;

    if (flightSearchRes && flightSearchRes.flightsLists[0]) {

      flightSearchRes = JSON.parse(JSON.stringify(flightSearchResponse));
      flightSearchRes.flightsLists[0].flights = flightSearchRes.flightsLists[0].flights.filter(flight => {

        let flightHops = flight.legs[0].flightHops;
        for (let flightHop of flightHops) {
          for (let airlineChoice of allianceAirlines) {
            if (airlineChoice.toUpperCase() == flightHop.allianceName.replace(' ', '').toUpperCase()) {
              return true;
            }
          }
        }
        return false;
      });
    }
    return flightSearchRes;
  }
  public filterFlightArrayByAirports(preferredAirports: Array<string>, flights: FlightResult[]) {
    if (!flights || flights.length == 0) {
      return flights;
    }
    let outputFlights: FlightResult[] = flights.filter(flight => {

      let flightHops = flight.legs[0].flightHops;
      // for(let flightHop of flightHops){
      let from = flightHops[0].from;
      let to = flightHops[flightHops.length - 1].to;
      for (let airportChoice of preferredAirports) {
        if (airportChoice.toUpperCase() == Constants.ANY.toUpperCase()) {
          return true;
        } else {
          if (airportChoice.toUpperCase() == from.toUpperCase()
            || airportChoice.toUpperCase() == to.toUpperCase()) {
            return true;
          }
        }
      }
      // }
      return false;
    });
    return outputFlights;
  }

  filterByPreferredAirport(preferredAirports: Array<string>, flightSearchResponse: FlightSearchResponse) {

    let flightSearchRes = flightSearchResponse;

    if (flightSearchRes && flightSearchRes.flightsLists[0]) {

      flightSearchRes = JSON.parse(JSON.stringify(flightSearchResponse));
      flightSearchRes.flightsLists[0].flights
        = this.filterFlightArrayByAirports(preferredAirports, flightSearchRes.flightsLists[0].flights);
    }
    return flightSearchRes;
  }

  filterAssociatedReturnFlights(parentFlightId: string, flightSearchResponse: FlightSearchResponse) {
    let flightSearchRes: FlightSearchResponse = flightSearchResponse;

    if (flightSearchRes && flightSearchRes.flightsLists[0]) {

      flightSearchRes = JSON.parse(JSON.stringify(flightSearchResponse));
      flightSearchRes.flightsLists[0].flights = flightSearchRes.flightsLists[0].flights.filter(flight => {
        if (flight.parentFlightId === parentFlightId
          /*|| flight.parentIdsOfSameFlight.indexOf(parentFlightId) !== -1 */) {
          return true;
        } else {
          return false;
        }
      });
    }
    return flightSearchRes;
  }

  resetStopsAndAirlines() {
    this.broadcastNoOfStops(Constants.MAX_STOPS);
    this.broadcastPreferredAirline(Constants.ANY);
  }

  resetAllFilters() {
    this.resetFiltersSubject.next(null);
  }
  private setDefaultOptimization(scoringLabels: ScoringLabel[]) {
    this.optimizeOn = [];
    this.optimizeOn.push(scoringLabels[0].label);
  }

  private isPresentFilter(filterName: string): boolean {
    return this.optimizeOn.indexOf(filterName) == -1 ? false : true;
  }


  public getTraflaHotelChainCode(inputChain) {
    switch (inputChain) {
      case "SW":
      case "AK":
      case "BG":
      case "EB":
      case "RZ":
      case "XR":
      case "MC":
      case "BR":
      case "TX":
      case "WH":
      case "WI":
      case "CY":
      case "GE":
      case "MD":
      case "RC":
      case "SI":
      case "AR":
      case "AL":
      case "EL":
      case "FN":
      case "XV":
      case "TO":
      case "OX":
      case "PR":
        return "SW";
      case "HL":
      case "ES":
      case "DT":
      case "HX":
      case "GI":
      case "HG":
      case "HT":
      case "CN":
      case "RU":
        return "HL";
      case "IC":
      case "HI":
      case "CP":
      case "YZ":
      case "YO":
        return "IC";
      case "WY":
      case "DX":
      case "RA":
      case "BU":
      case "HJ":
      case "OZ":
      case "DI":
      case "BH":
      case "KG":
      case "MT":
      case "TL":
      case "WG":
      case 'LQ':
        return "WY";
      case "ACC":
      case "BY":
      case "FA":
      case "SB":
      case "PU":
        return "ACC";
      case "UB":
      case "CC":
      case "CI":
      case "EO":
      case "QI":
      case "RI":
      case "SZ":
        return "UB";
      case "BW":
        return "BW";
      case "HY":
        return "HY";
      case "CW":
        return "CW";
      case "BOUH":
        return "BOUH";
    }
    return inputChain;
  }
  otherBrand(code, brand) {
    let otherBrand = true;
    for (let chainCode of brand) {
      if (code === chainCode) {
        otherBrand = false;
      }
    }
    return otherBrand;
  }
  filterByHotelChains(hotelChainCodes: Array<string>, hotelSearchResponse: HotelSearchResponse) {

    let hotelSearchRes = hotelSearchResponse;
    let index = 0;
    if (hotelChainCodes && hotelChainCodes.length > 0
      && hotelSearchRes && hotelSearchRes.hotelsList && hotelSearchRes.hotelsList.length > 0) {

      hotelSearchRes = JSON.parse(JSON.stringify(hotelSearchResponse));
      hotelSearchRes.hotelsList = hotelSearchRes.hotelsList.filter(hotel => {

        for (let chainCode of hotelChainCodes) {
          if (chainCode.toUpperCase() == Constants.ANY.toUpperCase()) {
            return true;
          } else if (chainCode.toUpperCase() == 'OTHERS') {
            var ALL_BRANDS = Constants.Hotel_Brand;
            return this.otherBrand(hotel.hotelChainCode, ALL_BRANDS);
          } else {
            if (hotel.hotelChainCode && this.getTraflaHotelChainCode(hotel.hotelChainCode.toUpperCase()) == chainCode) {
              return true;
            }
          }

        }
        return false;
      });
    }
    return hotelSearchRes;
  }

  filterByAmenities(hotelAmenities: Array<string>, hotelSearchResponse: HotelSearchResponse) {

    let hotelSearchRes = hotelSearchResponse;

    if (hotelSearchRes && hotelSearchRes.hotelsList && hotelSearchRes.hotelsList.length > 0) {

      hotelSearchRes = JSON.parse(JSON.stringify(hotelSearchResponse));
      hotelSearchRes.hotelsList = hotelSearchRes.hotelsList.filter(hotel => {
        let allAmenitiesFound = true;
        if (hotelAmenities.indexOf('ANY') > -1) {
          return true;
        }
        for (let amenity of hotelAmenities) {
          if (allAmenitiesFound && (amenity === 'ANY' || (hotel.amenities && hotel.amenities[amenity]))) {
            allAmenitiesFound = true;
          } else {
            allAmenitiesFound = false;
          }
        }
        return allAmenitiesFound;
      });
    }
    return hotelSearchRes;
  }

  filterHotelByName(searchText: string, hotelSearchResponse: HotelSearchResponse) {

    let hotelSearchRes = hotelSearchResponse;

    if (hotelSearchRes && hotelSearchRes.hotelsList && hotelSearchRes.hotelsList.length > 0) {

      hotelSearchRes = JSON.parse(JSON.stringify(hotelSearchResponse));
      hotelSearchRes.hotelsList = hotelSearchRes.hotelsList.filter(hotel => {
        if (hotel.hotelName.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 || hotel.address.toUpperCase().indexOf(searchText.toUpperCase()) >= 0) return true;
        else return false;
      });
    }
    return hotelSearchRes;
  }

  filterByStarRating(starRating: number, hotelSearchResponse: HotelSearchResponse) {
    let hotelSearchRes = hotelSearchResponse;

    if (starRating > 0 && hotelSearchRes && hotelSearchRes.hotelsList && hotelSearchRes.hotelsList.length > 0) {

      hotelSearchRes = JSON.parse(JSON.stringify(hotelSearchResponse));
      hotelSearchRes.hotelsList = hotelSearchRes.hotelsList.filter(hotel => {
        if (Number.parseInt(hotel.starRating) === starRating)
          return true;
        else
          return false;
      });
    }
    return hotelSearchRes;
  }
}
