<div *ngIf="!luggageDropDown" [class]="this.changeViewForRepots()">
    <div *ngFor="let tag of tagset,let i=index" class="{{getChangeClass()}}" >
        <label class="labelClass"> 
            <div class="tagSetName {{getTagSetNameClass()}}" >{{this.tagset[i]}}:<span style="color:red" *ngIf="checkTagIsMendotaryOrNot(i)"> *</span></div>
        </label>

        <div id="chartDepartment{{i}}" style="height:32px;">
            <div class="input" (click)="chartDepartment.toggle()" style="margin-top: 5px;position: relative;" >
                <ng-select #chartDepartment appendTo="#chartDepartment{{i}}" [disabled]="savingTag" style="position: absolute;
            z-index: -1;
            top: 0;
            width: 100%;
            padding-bottom: 0;
            height: 32px;" (click)="isdropDownOpen(i)" dropdownPosition="bottom" [searchable]="false" [clearable]="true" [items]="tagShow[i]" (close)="closeDropdown(i)" (change)="showTagChanged($event.tag_id,i)" bindLabel="tag_name" [(ngModel)]="selectedTag[i]"
                    bindValue="tag_id">
                    <ng-template ng-header-tmp>
                        <div class="selectox-header">
                            <span >  {{'bookingHistory.SelectTag' | translate}}</span>
                        </div>
                    </ng-template>

                </ng-select>
                <div class="select-overlay"></div>
                <div style="margin-top: 6px;float: right;" *ngIf="isTripDetails == false ">
                        <svg class="down-arrow" (click)="chartDepartment.toggle()" style="" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                                fill="#8936F3" />
                        </svg>
                    </div>
                <div class="labelDiv" style="height:32px;">
                    {{getSelectedData(this.dropDownopen[i],i) }}
                </div>
                
            </div>

        </div>
      
        <div *ngIf="this.selectedTag[i] && this.selectedTag[i]!=='' && !checkTagIsMendotaryOrNot(i) && isTripDetails == false" class="crossTag" (click)="removeSeelctedTag(i)">x</div>
        <div *ngIf="checkTagIsMendotaryOrNot(i)"  class="trip-tag-alert"><span *ngIf="showSelectTags[i]">{{'bookingHistory.RequestToSelectTag' | translate}}</span></div>
    </div>
</div>
<div *ngIf="luggageDropDown">
    <div class="col-sm-12 col-md-4" style="height:32px;margin-top: 0px;display:inline-block;position: relative;">
        <label class="labelClass">{{'result.Pleaseselectluggageoption' | translate}}: {{tagShow[1].leg}}</label>

        <div id="chartDepartment" style="height:32px;">
            <div class="input" (click)="chartDepartment.toggle()" style="margin-top: 5px;">
                <ng-select #chartDepartment appendTo="#chartDepartment" [disabled]="savingTag" style="position: absolute;
                z-index: -1;
                top: 0;
                width: 100%;
                padding-bottom: 0;
                height: 32px;" dropdownPosition="bottom" [searchable]="false" [clearable]="true" [items]="tagShow" (close)="closeDropdown(i)" (change)="showLuggageChanged($event)" [(ngModel)]="selectLuggage" bindLabel="originalText">
                    <ng-template ng-header-tmp>
                        <div class="selectox-header">
                            <span>{{'result.Selectlugagge' | translate}}</span>
                        </div>
                    </ng-template>

                </ng-select>
                <div class="select-overlay"></div>
                <div class="labelDiv" style="height:32px;">
                    {{selectLuggage}}</div>
            </div>

        </div>
        <svg class="down-arrow" (click)="chartDepartment.toggle()" style="" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                fill="#8936F3" />
        </svg>

    </div>
</div>