import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingResponseErrorType } from '../enum/booking-response-error.type';
import { Constants } from '../util/constants';
import { of, Observable, Subject } from 'rxjs';
import { UserAccountService } from '../user-account.service';
import { Location } from '@angular/common';
import { FlightSearchQueryParam } from '../entity/flight-search-query-param';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { SearchService } from '../search.service';
import { BookingService } from '../booking.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '../filter.service';
import { BookingResponse } from '../entity/booking-response';
import { HotelSearchService } from '../hotel-search.service';

@Component({
  selector: 'booking-message-modal',
  templateUrl: './booking-message-modal.component.html',
  styleUrls: ['./booking-message-modal.component.scss']
})
export class BookingMessageModalComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalDirective;

  @Input() title: string;
  @Input() description: string;
  @Input() nextStep: string;
  @Input() buttonName: string;
  @Input() iconSCSS: string;
  @Input() errorCode: string;
  @Input() bookingType: '';
  @Input() bookingResponse: BookingResponse;;
  acceptClickSubject = new Subject();
  userid: string;
  sToken: string;
  multibooking =false;
  type: string;
  showMultipleItenaries : boolean;
  constructor(private modalService: BsModalService,
    private gallopLocalStorage: GallopLocalStorageService,
    private searchService: SearchService,
    private searchService1: HotelSearchService,
    public bsModalRef: BsModalRef,
    private filterService: FilterService,
    private translate: TranslateService,
    private bookingService: BookingService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private userAccountService: UserAccountService
  ) {
    this.userid = this.userAccountService.getUserEmail();
    this.sToken = this.userAccountService.getSToken();
  }

  ngOnInit() {
    if(this.bookingResponse && this.bookingResponse.multiBookingResponse && this.bookingResponse.multiBookingResponse.length >1){
      this.multibooking =true;
    }else{
      this.multibooking =false;
    }

    let distiinctTripSessionIds = [];
    if(this.bookingResponse && this.bookingResponse.multiBookingResponse){
      for(let loopResponse of this.bookingResponse.multiBookingResponse){
        if(!distiinctTripSessionIds.includes(loopResponse.tripSessionId)){
          distiinctTripSessionIds.push(loopResponse.tripSessionId);
        }
      }
    }

    if(distiinctTripSessionIds.length > 1){
      this.showMultipleItenaries = true;
    }
  }
  cancelModel(){
    this.bsModalRef.hide();
   }
  acceptClick() {
    if (this.errorCode === BookingResponseErrorType.ERROR_PRICE_CHANGE) {
      this.acceptClickSubject.next(true);
    } else if (this.errorCode === BookingResponseErrorType.REVALIDATE_PRICE_CHANGE) {
      this.acceptClickSubject.next('ACCEPT');
    }
    else {
      this.acceptClickSubject.next(false);
    }

    this.bsModalRef.hide();
  }
showmultiitnenarylink(){
  return this.showMultipleItenaries;
}
  nextStepAction() {

    if (this.errorCode) {

      switch (this.errorCode) {

        case 'SUCCESS': {
          this.goToHomePage();
          this.bookingService.oldTripSessionId = undefined;
          break;
        }

        case BookingResponseErrorType.ERROR_AVAILABILITY: {
          if (this.searchService.processedFlightResults && this.searchService.processedFlightResults.length > 1) {
            this.searchService.flight = [];
            this.searchService.outgoingSelectedFlight = undefined;
            this.searchService.resetOutgoingSelectedFlight(0);
          }
          if (this.bookingService.pageMode === 'emailTicketMode') {
            this.goToHomePage();
          } else {
            this.goToSearchResults();
          }
          break;
        }
        case BookingResponseErrorType.ERROR_FAILED_BY_VENDOR:
          case BookingResponseErrorType.ERROR_ROOM_UNAVAILABLE: {
            if (this.bookingService.pageMode === 'emailTicketMode') {
              this.goToHomePage();
            } else {
            this.acceptClickSubject.next('showRoomList');
            }
            break;
          }

        case BookingResponseErrorType.ERROR_PRICE_CHANGE: {
          if (this.searchService.processedFlightResults && this.searchService.processedFlightResults.length > 1) {
            this.searchService.flight = [];
            this.searchService.outgoingSelectedFlight = undefined;
            this.searchService.resetOutgoingSelectedFlight(0);
          }
          if (this.bookingService.pageMode === 'emailTicketMode') {
            this.goToHomePage();
          } else {
            this.goToSearchResults();
          }
          //this.goToSearchResults();
          break;
        }

        case BookingResponseErrorType.ERROR_PAYMENT: {
          break;
        }

        case BookingResponseErrorType.ERROR_TICKETING: {
          this.goToHomePage();
          break;
        }

        case BookingResponseErrorType.ERROR_INVALID_INPUT: {
          // this.goBack();
          break;
        }

        default: {
          this.acceptClickSubject.next('nextStepCallBack');
        }
      }

    }
    this.bsModalRef.hide();
  }

  goToItinerary() {
    let userid = this.userAccountService.getUserEmail();
    let sToken = this.userAccountService.getSToken();
    this.bsModalRef.hide();
    this.searchService1.EmptyHotelRequest();
    if((this.userAccountService.isUserCorporateAdmin() || this.userAccountService.isUserIsTravelManager()) && this.bookingResponse && this.bookingResponse.ticketId && this.bookingResponse.tripId && this.bookingResponse.transactionId){
      this.searchService.employeeEmail=[];
      this.searchService.bookingAndApprovalDone =true;
    }
    this.searchService.tripName='';
    this.bookingService.oldTripSessionId = undefined;
    if(this.searchService.multiTripBooking){
      this.searchService.multiTripBooking =false;
      this.searchService.multiflightQuery =[];
      this.searchService.multicarQuery =[];
      this.gallopLocalStorage.removeItem("selectedSeat");
   //   this.searchService.tripName='';
      this.searchService.multihotelQuery =[];
      this.searchService.previousSelectedCreditAirline=[];
    }
    let ticketid = this.bookingService.bookingResponse.ticketId;
    let tripid = this.bookingService.bookingResponse.tripId;
    let transactionid = this.bookingService.bookingResponse.transactionId;
    let tripSessionId = this.bookingService.bookingResponse.tripSessionId;
    this.titleService.setTitle('Bookings');
    if (userid && sToken) {
      this.router.navigate(["bookingHistory"],
        {
          queryParams:
          {
            type: 'detail',
            bookingType: 'upcoming',
            from: 'Itinenary',
            ticketid: ticketid,
            tripid: tripid,
            transactionid: transactionid,
            tripSessionId:tripSessionId
          },
        }
      );
      this.location.replaceState('/search');
    } else {
     // this.titleService.setTitle('Flight Search');
     this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
      this.router.navigate(['search']);
    }
  }

  goToHomePage() {
    this.searchService.bookingDone = true;
    //this.titleService.setTitle('Flight Search');
    if((this.userAccountService.isUserCorporateAdmin() || this.userAccountService.isUserIsTravelManager()) && this.bookingResponse && this.bookingResponse.ticketId && this.bookingResponse.tripId && this.bookingResponse.transactionId){
      this.searchService.employeeEmail=[];
      this.searchService.bookingAndApprovalDone =true;
    }
    this.searchService.tripName='';
    this.searchService1.EmptyHotelRequest();
    if(this.searchService.multiTripBooking){
      this.searchService.multiTripBooking =false;
      this.searchService.multiflightQuery =[];
      this.searchService.multicarQuery =[];
   //   this.searchService.tripName='';
      this.gallopLocalStorage.removeItem("selectedSeat");
      this.bookingService.bookingResponse =undefined;
      this.searchService.multihotelQuery =[];
      this.searchService.previousSelectedCreditAirline=[];
    }
    this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
    this.router.navigate(["search"], { queryParams: { userid: this.userid, sToken: this.sToken }, replaceUrl: true });
  }

  goToSearchResults() {
    let flightSearchQueryParam: FlightSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequest")));
    var params = this.searchService.getSearchRequest(flightSearchQueryParam);
    this.searchService.seletedIndex = 0;
    this.searchService.multipleOutgoingSelectedFlight = [];
    this.searchService.tempFlight = [];
    this.searchService.nonComboSelectedFlight =[];
    this.searchService.backClick = false;
    this.bookingService.bookingResponse =undefined;
    this.searchService.flightId = [];
    this.searchService.selectedFilter1 = [];
    this.filterService.broadcastgetFilters();  
    this.titleService.setTitle('Flight Results');
    this.router.navigate(["flights"],
      {
        queryParams:
        {
          query: encodeURIComponent(JSON.stringify(flightSearchQueryParam)),
          step: 0,
          index: this.searchService.seletedIndex,
          resultFound: 1
        },
        replaceUrl: true
      });
  }

  goBack() {
    window.history.back();
  }

  hide() {

    this.bsModalRef.hide();
    this.bookingService.oldTripSessionId = undefined;
    if (this.errorCode) {

      switch (this.errorCode) {

        case 'SUCCESS': {
          this.goToHomePage();
          break;
        }

        case BookingResponseErrorType.ERROR_AVAILABILITY: {
          this.goToHomePage();
          break;
        }

        case BookingResponseErrorType.ERROR_PRICE_CHANGE: {
          if (this.searchService.processedFlightResults && this.searchService.processedFlightResults.length > 1) {
            this.searchService.flight = [];
            this.searchService.outgoingSelectedFlight = undefined;
            this.searchService.resetOutgoingSelectedFlight(0);
          }
          if (this.bookingService.pageMode === 'emailTicketMode') {
            this.goToHomePage();
          } else {
            this.goToSearchResults();
          }
          break;
        }

        case BookingResponseErrorType.ERROR_PAYMENT: {
          break;
        }

        case BookingResponseErrorType.ERROR_API: {
          this.goBack();
          break;
        }

        case BookingResponseErrorType.ERROR_TICKETING: {
          this.goToHomePage();
          break;
        }

        case Constants.STRIPE: {
          break;
        }

        case BookingResponseErrorType.ERROR_INVALID_INPUT: {
          // this.goBack();
          break;
        }
        case BookingResponseErrorType.ERROR_FAILED_BY_VENDOR:
        case BookingResponseErrorType.ERROR_ROOM_UNAVAILABLE: {
          if (this.bookingService.pageMode === 'emailTicketMode') {
            this.goToHomePage();
          } else {
          this.acceptClickSubject.next('showRoomList');
          }
          break;
        }
      }

    } else {
      //for server errors
      this.goToHomePage();
    }
  }

}
