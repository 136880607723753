export class AddressDTO {
  public address: string;
  public lattitude: string;
  public longitude: string;
  public type: string;
  public city: string;
  public country_code: string;
  public stateCode: string;
  public postal_code: string;
  state_code: string;
}