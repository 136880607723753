import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FlightClassType } from '../enum/flight-class.type';
import { NoOfStops } from '../enum/no-of-stops.type';
import { FlightSearchResponse } from '../entity/flight-search-response';
import { SearchService } from '../search.service';
import { FilterService } from '../filter.service';
import { Constants } from '../util/constants';
import { DeviceDetailsService } from '../device-details.service';
import { Subscription, Subject } from 'rxjs';
import { ScoringLabel } from '../entity/scoring-label';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { FilterType } from '../enum/filter.type';
import { FlightSearchQueryParam } from '../entity/flight-search-query-param';
import { AddressType } from '../enum/address.type';
import { SearchActionType } from '../enum/search-action.type';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { FlightResult } from '../entity/flight-result';
import { CommonUtils } from '../util/common-utils';
import { AlgoTypes } from '../enum/algo-types';
import { DateUtils } from '../util/date-utils';
import { NavigationUtil } from '../util/navigation-util';
import { Router } from '@angular/router';
declare var setNgxSmartModalOpen: any;
declare var setNgxSmartModalOpenStateClosed: any;

@Component({
  selector: 'flight-search-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})


export class  FilterComponent implements OnInit {

  @Input() searchForm: FormGroup;
  @Output() selectedFilter = new EventEmitter();
  quickSearchSubscription: Subscription;
  passengerOptions = Constants.PASSENGER_OPTIONS;
  classOptions = Constants.CLASS_OPTIONS;
  stopOptions = Constants.STOP_OPTIONS;
  policyOptions = Constants.POLICY_OPTIONS;
  demoOptions = [{ id: 'all', value: 'Show All Airlines' },
  { id: 'Star Alliance', value: 'Star Alliance', Type: 'Alliance' },
  { id: 'NA', value: 'NA', Type: 'Alliance' },
  { id: 'Alliance Air (9I)', value: 'Alliance Air (9I)', Type: 'Airlines' },
  { id: 'SpiceJet Ltd (SG)', value: 'SpiceJet Ltd (SG)', Type: 'Airlines' },
  { id: 'Air India (AI)', value: 'Air India (AI)', Type: 'Airlines' },
  { id: 'Hahn Air Systems (H1)', value: 'Hahn Air Systems (H1)', Type: 'Airlines' },
  { id: 'JET AIRWAYS INDIA (9W)', value: 'JET AIRWAYS INDIA (9W)', Type: 'Airlines' }];

  // flightTimeOptions = new Array<any>();
  // allainceOptions = Constants.ALLIANCE_OPTIONS
  // airportOptions = Constants.AIRPORT_OPTIONS;
  departureFlightTimeOption = { 'starts': undefined, 'ends': undefined };
  arrivalFlightTimeOption = { 'starts': undefined, 'ends': undefined };
  flightDurationOption = { 'starts': undefined, 'ends': undefined };
  flightPriceOption = { 'starts': undefined, 'ends': undefined };
  selectedClass: string = FlightClassType.ECONOMY;
  optimizeOn: String[] = [];
  stops = Constants.MAX_STOPS;
  stopsLabel = 'Any';
  airlineVal = Constants.ANY;
  shownFilter = 'SERVER';
  isMobile: boolean;
  airportFilterClicked: boolean = false;
  airportsChange = false;
  airportsChange1 = false;
  noOfStopsChangeSubscription: Subscription;
  airlinesChangeSubscription: Subscription;
  resetFiltersSubscription: Subscription;

  filter_noOfStops: number = Constants.MAX_STOPS;
  filter_airlines: Array<string> = new Array<string>();
  filter_airlineAlliances: Array<string> = new Array<string>();
  filter_airports: Array<string> = new Array<string>();
  filter_destination_airports: Array<string> = new Array<string>();
  filter_policy: string = 'NONE';
  filter_departure_range: any = this.departureFlightTimeOption;
  filter_arrival_range: any = this.arrivalFlightTimeOption;
  filter_duration_range:any = this.flightDurationOption;
  filter_price_range:any=this.flightPriceOption;
  temp_filter_noOfStops: number;
  temp_filter_airlines: Array<string>;
  temp_filter_airlineAlliances: Array<string>;
  temp_filter_airports: Array<string>;
  temp_filter_destination_airports: Array<string>;
  temp_filter_policy: string;
  temp_filter_departure_range: any = this.filter_departure_range;
  temp_filter_arrival_range: any = this.filter_arrival_range;
  temp_filter_duration_range: any = this.filter_duration_range;
  temp_filter_price_range: any = this.filter_price_range;
  filterOffset  = -1;
  private appliedFilterList: Array<FilterType> = new Array();
  timeRangeChangeSubscription: Subscription;
  isSticky = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 238;
  }
 
  constructor(public searchService: SearchService,
    public filterService: FilterService,
    public router: Router,
    public deviceDetailsService: DeviceDetailsService,
    private modalService: BsModalService,
    public ngxSmartModalService: NgxSmartModalService) {

    this.noOfStopsChangeSubscription = this.filterService.noOfStopsChange$.subscribe((val) => {
      // this.noOfStops = Number(val);
    });

    this.airlinesChangeSubscription = this.filterService.airlinesChange$.subscribe((val) => {
      // this.preferredAirline = val;
    });

    this.resetFiltersSubscription = this.filterService.resetFiltersObservable$.subscribe((val) => {
      if (val && val === 'update') {
        this.updateAirlineFilter();
      }else if (val && val === 'reset') {
        this.resetDefault();
      }else if (val && val === 'filterResults') {
        this.getAllFilterresults();
      }else if (val && val === 'resetResults') {
        this.getResetFilterresults();
      }
       else {
        this.resetFiltersToDefault();
      }

    });
    this.timeRangeChangeSubscription = this.filterService.timeRangeChangeObserver$.subscribe((val) => {

      if (!val) return;
      if (val.name === 'DEPART_TIME_RANGE') {this.temp_filter_departure_range = val;}
      if(val.name === 'ARIVAL_TIME_RANGE'){this.temp_filter_arrival_range = val;} 
      if(val.name === 'FLIGHT_DURATION'){this.temp_filter_duration_range = val;};
      if(val.name === 'FLIGHT_PRICE'){this.temp_filter_price_range = val;};
    });
    // this.filter_noOfStops = this.noOfStops;
    this.initTempFiltersValues();
  }
  
initTempUrlFiltersValues() {
  this.temp_filter_noOfStops =  this.filterService.temp_filter_stops;
  this.temp_filter_airlines = this.filter_airlines;
  if(this.filterService.filter_airports && this.filterService.filter_airports.length > 0){
  this.filter_airports = this.filterService.filter_airports;
  }
  if(this.filterService.filter_destination_airports && this.filterService.filter_destination_airports.length > 0){
  this.filter_destination_airports = this.filterService.filter_destination_airports;
  }
  this.temp_filter_airports = this.filter_airports;
  this.temp_filter_destination_airports = this.filter_destination_airports;
  //this.searchService.priceWithTravelCreditsZero
  if (this.searchService.selectedFilter1.length > 0) {
    this.temp_filter_airlines = [];
    this.filter_airlines = [];
    this.temp_filter_airlines = this.searchService.selectedFilter1;
    this.filter_airlines = this.searchService.selectedFilter1;
  } 

  if (this.searchService.selectedSourceAirports.length > 0) {
    this.temp_filter_airports = [];
    this.filter_airports = [];
    this.temp_filter_airports = this.searchService.selectedSourceAirports;
    this.filter_airports = this.searchService.selectedSourceAirports;
  } 
  if (this.searchService.selectedDestAirports.length > 0) {
    this.temp_filter_destination_airports = [];
    this.filter_destination_airports = [];
    this.temp_filter_destination_airports = this.searchService.selectedDestAirports;
    this.filter_destination_airports = this.searchService.selectedDestAirports;
  }
  
  this.filter_noOfStops = this.filterService.temp_filter_stops;
  if(this.filterService.temp_filter_airlineAlliances && this.filterService.temp_filter_airlineAlliances.length > 0){
    this.filter_airlineAlliances = this.filterService.temp_filter_airlineAlliances;
    }
   
  this.filter_policy = this.filterService.temp_filter_policy;
  this.filter_departure_range = this.filterService.temp_filter_departure_range;
  this.filter_arrival_range = this.filterService.temp_filter_arrival_range;
  this.filter_duration_range = this.filterService.temp_filter_duration_range;
  this.filter_price_range = this.filterService.temp_filter_price_range;
  this.temp_filter_airlineAlliances = this.filter_airlineAlliances;
  this.temp_filter_policy = this.filter_policy;
  //this.appliedFilterList = this.filterService.appliedFilterList;
  this.temp_filter_departure_range = this.filter_departure_range;
  this.temp_filter_arrival_range = this.filter_arrival_range;
  this.temp_filter_duration_range = this.filter_duration_range;
  this.temp_filter_price_range = this.filter_price_range;
  if(this.filterService.appliedFilterList){
    this.appliedFilterList =  this.filterService.appliedFilterList;
  }
  if(this.appliedFilterList && this.appliedFilterList.length > 0){
      if(this.temp_filter_noOfStops!==10){
        this.applyStopsFilter(true);
       
      }
      if(this.temp_filter_departure_range || this.temp_filter_arrival_range || this.temp_filter_duration_range ||  this.temp_filter_duration_range || this.temp_filter_price_range){
      this.applyTimeFilter(true);
      }
      this.applyPolicyFilter(true);
    }
      

   // if(this.searchService.backClick){
    // if(this.filter_departure_range.starts || this.filter_departure_range.ends)
    // this.getSliderDefaultMinValue(this.filter_departure_range.id)
   
   // }
}
  getSliderDefaultMinValue(SliderID: string): any {
    if (SliderID === 'FLIGHT_PRICE' && this.temp_filter_price_range) {
      return this.temp_filter_price_range.starts;
    } else  if (SliderID === 'DEPART_TIME_RANGE' && this.temp_filter_departure_range) {
      return this.temp_filter_departure_range.starts;
    } else if (SliderID === 'ARIVAL_TIME_RANGE' && this.temp_filter_arrival_range) {
      return this.temp_filter_arrival_range.starts;
    }else if (SliderID === 'FLIGHT_DURATION' && this.temp_filter_duration_range){
      return this.temp_filter_duration_range.starts;
    }
  }

  getSliderDefaultHighValue(SliderID: string): any {
    if (SliderID === 'FLIGHT_PRICE' && this.temp_filter_price_range) {
      return this.temp_filter_price_range.ends;
    }else if (SliderID === 'DEPART_TIME_RANGE' && this.temp_filter_departure_range) {
      return this.temp_filter_departure_range.ends;
    } else if (SliderID === 'ARIVAL_TIME_RANGE' && this.temp_filter_arrival_range) {
      return this.temp_filter_arrival_range.ends;
    }else if (SliderID === 'FLIGHT_DURATION' && this.temp_filter_duration_range){
      return this.temp_filter_duration_range.ends;
    }
  }
  resetDefault(){
    this.stopOptions = JSON.parse(JSON.stringify(Constants.STOP_OPTIONS));
    this.policyOptions = JSON.parse(JSON.stringify(Constants.POLICY_OPTIONS));
    this.filter_policy = 'NONE';
    this.searchService.selectedFilter1 = [];
    this.initAirlineFilter();
    this.filter_airlineAlliances = new Array<string>();
      this.filter_noOfStops = Constants.MAX_STOPS;
     this.filter_airports = new Array<string>();
     this.filter_destination_airports = new Array<string>();
    this.initAirportFilter();
    
  
    this.filter_departure_range = null;
    this.filter_arrival_range = null;
    this.filter_departure_range = null;
    this.temp_filter_noOfStops = this.filter_noOfStops;
    this.temp_filter_airlines = this.filter_airlines;
    this.temp_filter_airports = this.filter_airports;
    this.filterService.filter_airports = [];
   // this.appliedFilterList=[];
    this.filterService.appliedFilterList = this.appliedFilterList;
    this.temp_filter_destination_airports = this.filter_destination_airports;
    this.filterService.filter_destination_airports = [] ;
      this.filterService.temp_filter_policy = this.filter_policy;
      this.filterService.temp_filter_departure_range = this.filter_departure_range ;
      this.filterService.temp_filter_arrival_range = this.filter_arrival_range;
      this.filterService.temp_filter_duration_range = this.filter_duration_range;
      this.filterService.temp_filter_price_range = this.filter_price_range;
        this.filterService.temp_filter_airlineAlliances = this.filter_airlineAlliances;
        this.searchService.currentSortOptionId = 'recommended';
        this.filter_noOfStops = Constants.MAX_STOPS; 
        this.filterService.temp_filter_stops = this.filter_noOfStops;
        this.initTempUrlFiltersValues();
        setTimeout(() => {
          this.filterResultsFrom() 
        }, 100);
  }
  resetFiltersToDefault() {
   // 
    this.stopOptions = JSON.parse(JSON.stringify(Constants.STOP_OPTIONS));
    this.policyOptions = JSON.parse(JSON.stringify(Constants.POLICY_OPTIONS));
    this.initAirlineFilter();
    this.filter_airlineAlliances = new Array<string>();
      this.filter_noOfStops = Constants.MAX_STOPS;
     this.filter_airports = new Array<string>();
     this.filter_destination_airports = new Array<string>();
    this.initAirportFilter();
    if(!this.searchService.backClick){
    this.filter_policy = 'NONE';
    this.filter_departure_range = null;
    this.filter_arrival_range = null;
    this.filter_departure_range = null;
    }
    this.initTempFiltersValues();
   //if(this.searchService.backClick){
    //this.filterResults();
   // this.searchService.backClick =false;
  // }
   
  }

  initTempFiltersValues() {
   
    this.temp_filter_noOfStops =  this.filterService.temp_filter_stops;
    this.temp_filter_airlines = this.filter_airlines;
    if(this.filterService.filter_airports && this.filterService.filter_airports.length > 0){
      this.filter_airports = this.filterService.filter_airports;
      }
      if(this.filterService.filter_destination_airports && this.filterService.filter_destination_airports.length > 0){
      this.filter_destination_airports = this.filterService.filter_destination_airports;
      }
    this.temp_filter_airports = this.filter_airports;
    this.temp_filter_destination_airports = this.filter_destination_airports;
    if (this.searchService.selectedFilter1.length > 0 && !this.searchService.backClick && (!this.searchService.apiCallafterFlightSelection || this.searchService.priceWithTravelCreditsZero)) {
      this.temp_filter_airlines = [];
      this.filter_airlines = [];
      this.temp_filter_airlines = this.searchService.selectedFilter1;
      this.filter_airlines = this.searchService.selectedFilter1;
    } 
    if (this.searchService.selectedFilter1.length > 0 && this.searchService.backClick){
      this.temp_filter_airlines = [];
      this.filter_airlines = [];
      this.temp_filter_airlines = this.searchService.selectedFilter1;
      this.filter_airlines = this.searchService.selectedFilter1;
    }
    if (this.searchService.selectedSourceAirports.length > 0 && !this.searchService.backClick && (!this.searchService.apiCallafterFlightSelection || this.searchService.priceWithTravelCreditsZero)) {
      this.temp_filter_airports = [];
      this.filter_airports = [];
      this.temp_filter_airports = this.searchService.selectedSourceAirports;
      this.filter_airports = this.searchService.selectedSourceAirports;
    } 
    if (this.searchService.selectedDestAirports.length > 0 && !this.searchService.backClick && (!this.searchService.apiCallafterFlightSelection || this.searchService.priceWithTravelCreditsZero)) {
      this.temp_filter_destination_airports = [];
      this.filter_destination_airports = [];
      this.temp_filter_destination_airports = this.searchService.selectedDestAirports;
      this.filter_destination_airports = this.searchService.selectedDestAirports;
    }
    if (this.searchService.selectedDestAirports.length > 0 && this.searchService.backClick){
      this.temp_filter_airports = [];
      this.filter_airports = [];
      this.temp_filter_airports = this.searchService.selectedSourceAirports;
      this.filter_airports = this.searchService.selectedSourceAirports;

    }
    if (this.searchService.selectedDestAirports.length > 0 && this.searchService.backClick){
      this.temp_filter_destination_airports = [];
      this.filter_destination_airports = [];
      this.temp_filter_destination_airports = this.searchService.selectedDestAirports;
      this.filter_destination_airports = this.searchService.selectedDestAirports;
    }
    this.filter_noOfStops = this.filterService.temp_filter_stops;
    if(this.filterService.temp_filter_airlineAlliances && this.filterService.temp_filter_airlineAlliances.length > 0){
      this.filter_airlineAlliances = this.filterService.temp_filter_airlineAlliances;
      }
     
    this.filter_policy = this.filterService.temp_filter_policy;
    this.filter_departure_range = this.filterService.temp_filter_departure_range;
    this.filter_arrival_range = this.filterService.temp_filter_arrival_range;
    this.filter_duration_range = this.filterService.temp_filter_duration_range;
    this.filter_price_range = this.filterService.temp_filter_price_range;
    this.temp_filter_airlineAlliances = this.filter_airlineAlliances;
    this.temp_filter_policy = this.filter_policy;
    //this.appliedFilterList = this.filterService.appliedFilterList;
    this.temp_filter_departure_range = this.filter_departure_range;
    this.temp_filter_arrival_range = this.filter_arrival_range;
    this.temp_filter_duration_range = this.filter_duration_range;
   this.temp_filter_price_range = this.filter_price_range;

    if(this.appliedFilterList && this.appliedFilterList.length > 0){
      if(this.temp_filter_noOfStops!==10){
        this.applyStopsFilter(true);
       
      }
      if(this.temp_filter_departure_range || this.temp_filter_arrival_range || this.temp_filter_duration_range || this.temp_filter_price_range){
      this.applyTimeFilter(true);
      }
      this.applyPolicyFilter(true);
    }
     // if(this.searchService.backClick){
      // if(this.filter_departure_range.starts || this.filter_departure_range.ends)
      // this.getSliderDefaultMinValue(this.filter_departure_range.id)
     
     // }
  }

  onModelCancel() {
    this.initTempFiltersValues();
  }
public getResetFilterresults(){
    this.searchService.selectedFilter1 = [];
    this.searchService.selectedDestAirports=[];
    this.searchService.selectedSourceAirports=[];
    this.stopOptions = JSON.parse(JSON.stringify(Constants.STOP_OPTIONS));
    this.policyOptions = JSON.parse(JSON.stringify(Constants.POLICY_OPTIONS));
    this.filter_policy = 'NONE';
    this.filter_airlineAlliances = new Array<string>();
      this.filter_noOfStops = Constants.MAX_STOPS;
     this.filter_airports = new Array<string>();
     this.filter_destination_airports = new Array<string>();
  //  this.resetDefault();
    this.initAirlineFilter();
    this.initAirportFilter();
    this.filter_departure_range = null;
    this.filter_arrival_range = null;
    this.filter_duration_range = null;
    this.filter_price_range = null;
    this.temp_filter_noOfStops = this.filter_noOfStops;
    this.temp_filter_airlines = this.filter_airlines;
    this.temp_filter_airports = this.filter_airports;
    this.filterService.filter_airports = [];
   // this.appliedFilterList=[];
    this.filterService.appliedFilterList = this.appliedFilterList;
    this.temp_filter_destination_airports = this.filter_destination_airports;
    this.filterService.filter_destination_airports = [] ;
      this.filterService.temp_filter_policy = this.filter_policy;
    this.filterService.temp_filter_departure_range = this.filter_departure_range ;
      this.filterService.temp_filter_arrival_range = this.filter_arrival_range;
      this.filterService.temp_filter_duration_range = this.filter_duration_range;
      this.filterService.temp_filter_price_range = this.filter_price_range;
        this.filterService.temp_filter_airlineAlliances = this.filter_airlineAlliances;
        this.searchService.currentSortOptionId = 'recommended';
        this.filter_noOfStops = Constants.MAX_STOPS; 
        this.filterService.temp_filter_stops = this.filter_noOfStops;
    this.temp_filter_airlines = this.filter_airlines;
    this.temp_filter_airports = this.filter_airports;
    this.temp_filter_destination_airports = this.filter_destination_airports;
    setTimeout(() => {
    //  this.filterResultsFrom() 
    }, 100);
  }
public getAllFilterresults(){
  this.stopOptions = JSON.parse(JSON.stringify(Constants.STOP_OPTIONS));
  this.policyOptions = JSON.parse(JSON.stringify(Constants.POLICY_OPTIONS));
  this.initAirlineFilter();
  this.filter_airlineAlliances = new Array<string>();
    this.filter_noOfStops = Constants.MAX_STOPS;
   this.filter_airports = new Array<string>();
   this.filter_destination_airports = new Array<string>();
  this.initAirportFilter();
  
  this.initTempUrlFiltersValues();
  setTimeout(() => {
    this.filterResultsFrom() 
  }, 100);
}
  onSmartModelCancel(modelName: string) {
    this.ngxSmartModalService.getModal(modelName).close();
    this.initTempFiltersValues();
  }
  onStopSelectionChange(value: any) {
    this.temp_filter_noOfStops = Number.parseInt(value);
  }

  onPolicySelectionChange(value: any) {
    this.temp_filter_policy = value;
  }

  onShowAllAirlinesChange(event) {
    this.temp_filter_airlines = [];
    if (event.target.checked) {
      const airlineFilterIndex = this.filterService.appliedFilterList.indexOf(FilterType.AIRLINE);
      if (airlineFilterIndex > -1) {
        this.filterService.appliedFilterList.splice(airlineFilterIndex, 1);
      }
      for (const airline of this.airlineOptions) {
       // this.temp_filter_airlines.push(airline.id);
      }
      this.temp_filter_airlineAlliances = new Array<string>();
    }
  }

  isShowAllAirlineChecked() {
    return (
      (this.temp_filter_airlines.length > 0 && this.temp_filter_airlines.length === this.airlineOptions.length)
      ||
      (this.temp_filter_airlineAlliances.length === 0 && this.temp_filter_airlines.length === 0)
    );
  }
  onAirlineCheckClicked(option, event) {
    this.temp_filter_airlineAlliances = new Array<string>();



    if (event.target.checked) {
      this.temp_filter_airlines.push(option);
    } else {
      this.temp_filter_airlines = this.temp_filter_airlines.filter(airline => {
        if (airline !== option) return true;
      });
    }

  }

  isAirlineChecked(airline) {
    return this.temp_filter_airlines.indexOf(airline) > -1;
  }

  onAllianceAirlineCheckClicked(option, event) {
    this.temp_filter_airlines = new Array<string>();



    if (event.target.checked) {
      this.temp_filter_airlineAlliances.push(option);
    } else {
      this.temp_filter_airlineAlliances = this.temp_filter_airlineAlliances.filter(airline => {
        if (airline !== option) return true;
      });
    }

  }

  isAllianceAirlineChecked(airline) {
    return this.temp_filter_airlineAlliances.indexOf(airline) > -1;
  }

  applyStopsFilter(event?) {
    if (this.temp_filter_noOfStops === Number.parseInt(NoOfStops.ANY)) {
      this.appliedFilterList = this.appliedFilterList.filter(filter => {
        if (filter.toString() !== FilterType.STOPS.toString()) return true;
      });
    } else if (this.appliedFilterList.indexOf(FilterType.STOPS) == -1) {
      this.appliedFilterList.push(FilterType.STOPS);
    }
    let params;
    this.filter_noOfStops = this.temp_filter_noOfStops;
    if (this.filter_noOfStops == Number.parseInt(NoOfStops.ANY)) {
      this.stopOptions[0].selected = true;
      this.stopOptions[1].selected = false;
      this.stopOptions[2].selected = false;
      params = "?ua_action=FlightResultFilterItem&ua_filter=stops&ua_stops=Any";
    }
    if (this.filter_noOfStops == Number.parseInt(NoOfStops.NONE)) {
      this.stopOptions[0].selected = false;
      this.stopOptions[1].selected = true;
      this.stopOptions[2].selected = false;
      params = "?ua_action=FlightResultFilterItem&ua_filter=stops&ua_stops=none";
    }
    if (this.filter_noOfStops == Number.parseInt(NoOfStops.ONE)) {
      params = "?ua_action=FlightResultFilterItem&ua_filter=stops&ua_stops=one" + " " + 'or' + " " + 'fewer';
      this.stopOptions[0].selected = false;
      this.stopOptions[1].selected = false;
      this.stopOptions[2].selected = true;
    }
    this.searchService.letsTrack(params);
if(!event){
    this.filterResults();
}
  }

  applyAirlineFilter() {
    // if(this.temp_filter_airlines){
      this.searchService.priceWithTravelCreditsZero =false;
    if (this.temp_filter_airlines.length == 0 && this.temp_filter_airlineAlliances.length == 0) {
      this.appliedFilterList = this.appliedFilterList.filter(filter => {
        if (filter.toString() !== FilterType.AIRLINE.toString()) return true;
      });
    } else if (this.appliedFilterList.indexOf(FilterType.AIRLINE) == -1) {
      this.appliedFilterList.push(FilterType.AIRLINE);
    }
    if (this.temp_filter_airlines.length > 0) {
      let params;
      if (this.temp_filter_airlines.length === this.airlineOptions.length) {
        params = "?ua_action=FlightResultFilterItem&ua_filter=Airline&ua_airline=all";
      } else {
        params = "?ua_action=FlightResultFilterItem&ua_filter=Airline&ua_airline=" + this.temp_filter_airlines;
      }
      this.searchService.letsTrack(params);
    } else if (this.temp_filter_airlineAlliances.length > 0) {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=Airline&ua_alliance=" + this.temp_filter_airlineAlliances;
      this.searchService.letsTrack(params);
    } else {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=Airline&ua_airline=all";
      this.searchService.letsTrack(params);
    }
    this.filter_airlines = this.temp_filter_airlines;
    this.filter_airlineAlliances = this.temp_filter_airlineAlliances;
    this.searchService.filterReset =false;
    if (this.temp_filter_airlines && this.temp_filter_airlines.length > 0 && (this.searchService.airlineOptions.length !== this.temp_filter_airlines.length) && this.searchService.seletedIndex === 0) {
      if (this.searchService.filtered_airlines.length > 0) {
        if(this.searchService.filtered_airlines.length === this.searchService.airlineOptions.length ){
          this.searchService.filtered_airlines=[];
        }
        let selectedflight = [];
        for (let item1 of this.temp_filter_airlines) {
          if (this.searchService.filtered_airlines.indexOf(item1) === -1) {
            selectedflight.push(item1);
          }

        }
        if (selectedflight.length > 0) {
          this.selectedFilter.emit({ 'airlineFilter': selectedflight, 'sourceAirports': this.searchService.selectedSourceAirports, 'destAirports': this.searchService.selectedDestAirports });
          // this.selectedFilter.emit(selectedflight);
        }
        this.searchService.selectedFilter1 = this.temp_filter_airlines;
      } else {
        // this.selectedFilter.emit(this.temp_filter_airlines);
        this.selectedFilter.emit({ 'airlineFilter': this.temp_filter_airlines, 'sourceAirports': this.searchService.selectedSourceAirports, 'destAirports': this.searchService.selectedDestAirports });
        this.searchService.selectedFilter1 = this.temp_filter_airlines;
      }
    }
    // }
    this.filterResults();
    this.ngxSmartModalService.getModal('airlineTopModal').close();
  }


  onAirportCheckClicked(option, event) {



    if (event.target.checked) {
      this.temp_filter_airports.push(option);
    } else {
      this.temp_filter_airports = this.temp_filter_airports.filter(airport => {
        if (airport !== option) return true;
      });
    }

  }

  isAirportChecked(airport) {
    return this.temp_filter_airports.indexOf(airport) > -1;
  }

  onDestinationAirportCheckClicked(option, event) {



    if (event.target.checked) {
      this.temp_filter_destination_airports.push(option);
    } else {
      this.temp_filter_destination_airports = this.temp_filter_destination_airports.filter(airport => {
        if (airport !== option) return true;
      });
    }

  }

  isDestinationAirportChecked(airport) {
    return this.temp_filter_destination_airports.indexOf(airport) > -1;
  }
  applyAirportFilter() {
    this.airportFilterClicked = true;
    if (this.temp_filter_airports.length == 0 && this.temp_filter_destination_airports.length == 0) {
      this.appliedFilterList = this.appliedFilterList.filter(filter => {
        if (filter !== FilterType.AIRPORT) return true;
      });
    } else if (this.appliedFilterList.indexOf(FilterType.AIRPORT) == -1) {
      this.appliedFilterList.push(FilterType.AIRPORT);
    }
    if (this.temp_filter_airports.length > 0) {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=Airport&ua_sourceAirports=" + this.temp_filter_airports;
      this.searchService.letsTrack(params);
    }
    if (this.temp_filter_destination_airports.length > 0) {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=Airports&ua_destAirports=" + this.temp_filter_destination_airports;
      this.searchService.letsTrack(params);
    }
    this.filter_airports = this.temp_filter_airports;
    this.filter_destination_airports = this.temp_filter_destination_airports;
    this.airportsChange = false;
    this.airportsChange1 = false;
    if (this.airportOptions.length > 1 || this.destinationAirportOptions.length > 1) {
      if (this.temp_filter_airports && this.temp_filter_airports.length > 0 && this.searchService.seletedIndex === 0) {
        if (this.searchService.filtereSourceAirports.length > 0) {
          let selectedflight = [];
          for (let item1 of this.temp_filter_airports) {
            if (this.searchService.filtereSourceAirports.indexOf(item1) === -1) {
              selectedflight.push(item1);
            }

          }
          if (selectedflight.length > 0) {
            this.airportsChange = true;
          }
          this.searchService.selectedSourceAirports = this.temp_filter_airports;
        } else {
          this.airportsChange = true;
          this.searchService.selectedSourceAirports = this.temp_filter_airports;
        }
      }
      if (this.temp_filter_destination_airports && this.temp_filter_destination_airports.length > 0 && this.searchService.seletedIndex === 0) {
        if (this.searchService.filteredDestAirports.length > 0) {
          let selectedflight = [];
          for (let item1 of this.temp_filter_destination_airports) {
            if (this.searchService.filteredDestAirports.indexOf(item1) === -1) {
              selectedflight.push(item1);
            }

          }
          if (selectedflight.length > 0) {
            this.airportsChange1 = true;
          }
          this.searchService.selectedDestAirports = this.temp_filter_destination_airports;
        } else {
          this.airportsChange1 = true;
          this.searchService.selectedDestAirports = this.temp_filter_destination_airports;
        }
      }
      if (this.airportsChange || this.airportsChange1) {
        this.selectedFilter.emit({ 'airlineFilter': this.searchService.selectedFilter1, 'sourceAirports': this.searchService.selectedSourceAirports, 'destAirports': this.searchService.selectedDestAirports });
      }
    }
    this.filterResults();
    this.ngxSmartModalService.getModal('airportTopModal').close();
  }

  applyTimeFilter(event) {
    console.log(event);
    if (!this.temp_filter_departure_range && !this.temp_filter_arrival_range && !this.temp_filter_duration_range && !this.temp_filter_price_range) {
      this.appliedFilterList = this.appliedFilterList.filter(filter => {
        if (filter !== FilterType.TIME) return true;
      });
    } else if (this.appliedFilterList.indexOf(FilterType.TIME) == -1) {
      this.appliedFilterList.push(FilterType.TIME);
    }
    if (this.temp_filter_arrival_range) {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_arrivalTimes=Changed";
      this.searchService.letsTrack(params);
    } else {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_arrivalTimes=NotChanged";
      this.searchService.letsTrack(params);
    }
    if (this.temp_filter_departure_range) {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_departureTimes=Changed";
      this.searchService.letsTrack(params);
    } else {
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_departureTimes=NotChanged";
      this.searchService.letsTrack(params);
    }

    if(this.temp_filter_duration_range){
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_durationTime=Changed";
      this.searchService.letsTrack(params);
    }else{
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_durationTime=NotChanged";
      this.searchService.letsTrack(params);
    }  
    if(this.temp_filter_price_range){
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_priceFilter=Changed";
      this.searchService.letsTrack(params);
    }else{
      let params = "?ua_action=FlightResultFilterItem&ua_filter=time&ua_priceFilter=NotChanged";
      this.searchService.letsTrack(params);
    }
    this.filter_departure_range = this.temp_filter_departure_range;
    this.filter_arrival_range = this.temp_filter_arrival_range;
    this.filter_duration_range = this.temp_filter_duration_range;
    this.filter_price_range = this.temp_filter_price_range;
    if(!event){
    this.filterResults();
    }

    // this.policy.toggle();
  }

  applyPolicyFilter(event) {

    this.appliedFilterList = this.appliedFilterList.filter(filter => {
      if (filter !== FilterType.POLICY) return true;
    });
    if (this.temp_filter_policy && this.temp_filter_policy != null && this.temp_filter_policy === 'WITHINPOLICY') {
      this.appliedFilterList.push(FilterType.POLICY);
    }
    this.filter_policy = this.temp_filter_policy;
    if (this.filter_policy == 'WITHINPOLICY') {
      this.policyOptions[0].selected = true;
      this.policyOptions[1].selected = false;
      let params = "?ua_action=FlightResultFilterItem&ua_filter=policy&ua_policyType=withInPolicy";
      this.searchService.letsTrack(params);
    } else {
      this.policyOptions[0].selected = false;
      this.policyOptions[1].selected = true;
      let params = "?ua_action=FlightResultFilterItem&ua_filter=policy&ua_policyType=all";
      this.searchService.letsTrack(params);
    }
    if(!event){
    this.filterResults();
    }
  }

  get noOfStops(): number {
    return this.stops
  }


  set noOfStops(value: number) {

    let val: any = value;
    //we are getting object in case of mobile
    if (val instanceof Object) {
      val = Number(value['id'])
    }

    this.stops = Number(val);
    this.updateNoOfStopsLabel();

    this.filterService.numOfStops = this.stops;
    this.filterByNoOfStops();
  }

  get preferredAirline(): string {
    return this.airlineVal;
  }

  updateNoOfStopsLabel() {
    switch (Number(this.stops)) {
      case 0: {
        this.stopsLabel = 'Non Stop';
        break;
      };
      case 1: {
        this.stopsLabel = '1 Stop or fewer';
        break;
      };
      case 2: {
        this.stopsLabel = '2 Stops or fewer';
        break;
      };
      case 3: {
        this.stopsLabel = '3 Stops or fewer';
        break;
      };
      case 4: {
        this.stopsLabel = '4 Stops or fewer';
        break;
      };
      case 5: {
        this.stopsLabel = '5 Stops or fewer';
        break;
      };
      default: {
        this.stopsLabel = 'Any';
        break;
      };

    }
  }

  set preferredAirline(value: string) {
    this.airlineVal = value;
    this.filterService.preferredAirline = this.airlineVal;
    this.filterByPreferredAirline();
  }


  get flightSearchResponse(): FlightSearchResponse {
    return this.searchService.flightSearchResponse;
  }

  get airlineOptions(): any {
    return this.searchService.airlineOptions;
  }

  get allainceOptions(): any {
    return this.searchService.allianceOptions;
  }
  get airportOptions(): any {
    return this.searchService.airportOptions;
  }

  get destinationAirportOptions(): any {
    return this.searchService.airportDestinationOptions;
  }

  get flightTimeOptions(): any {
    return this.filterService.flightTimeRangeOptions;
  }
  get flightPriceOptions(): any {
    return this.filterService.flightPriceRangeOptions;
  }
  get scoringOptions(): ScoringLabel[] {
    return this.searchService.flightSearchResponse.scoringLabels.labels;
  }

  ngOnInit() {
    this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.resetFiltersToDefault();
    
    // this.setDefaultOptimization(this.flightSearchResponse);
    
  }

  initAirlineFilter() {
    this.filter_airlines = [];
    for (let airline of this.airlineOptions) {
     // this.filter_airlines.push(airline.id);
    }
    if(this.searchService.selectedFilter1 && this.searchService.selectedFilter1.length === 0){
    this.filterService.filter_airlines = this.filter_airlines;
    }
  }
  updateAirlineFilter() {
    if (this.showAllAirlineWasChecked) {
      this.filter_airlines = [];
      for (let airline of this.airlineOptions) {
        this.filter_airlines.push(airline.id);
      }
      this.temp_filter_airlines = this.filter_airlines;
      

    }


  }

  initAirportFilter() {
    this.filter_airports = [];
    for (let airport of this.airportOptions) {
      this.filter_airports.push(airport.id);
    }

    this.filter_destination_airports = [];
    for (let airport of this.destinationAirportOptions) {
      this.filter_destination_airports.push(airport.id);
    }

  }
  updateAirportFilter() {
    for (let airport of this.airportOptions) {
      if (!this.filter_airports[airport.id]) {
        this.filter_airports.push(airport.id);
      }
    }
    for (let airport of this.destinationAirportOptions) {
      if (!this.filter_destination_airports[airport.id]) {
        this.filter_destination_airports.push(airport.id);
      }
    }

  }

  setDefaultOptimization(flightSearchResponse: FlightSearchResponse) {
    this.optimizeOn = [];
    this.optimizeOn.push(flightSearchResponse.scoringLabels.labels[0].label);
  }


  showAirportModal(airportModal) {
    this.modalService.show(airportModal, { class: 'modalAirportFilterInfo' });
  }

  //client side filter
  filterByNoOfStops() {
    this.filterResults();
  }

  isActiveFilter(filterName: string): boolean {
    return this.optimizeOn.indexOf(filterName) == -1 ? false : true;
  }

  //client side filter
  optimize(filterName: string) {
    this.updateOptimizeFilterArray(filterName);
    this.filterResults();
  }

  //client side filter
  filterByPreferredAirline() {
    this.filterResults();
  }

  filterResults() {
    // setTimeout(()=>{

    // }),200);
    setTimeout(() => {
      this.filterResultsAsync();
    }, 200);
  }
  filterResultsFrom(){
    if (!this.filterService.originalFlightSearchResponse) return;
    //this.searchService.priceWithTravelCreditsZero =false;
      let flightSearchRes = JSON.parse(JSON.stringify(this.filterService.originalFlightSearchResponse));
      var totalOptions = 0;
      if (flightSearchRes && flightSearchRes.flightsLists
        && flightSearchRes.flightsLists.length > 0
        && flightSearchRes.flightsLists[0].flights
      ) {
        totalOptions = flightSearchRes.flightsLists[0].flights.length;
      }
      for (let filter of this.appliedFilterList) {
        if (filter === FilterType.AIRPORT) {
          if (this.filter_airports.length > 0)
            flightSearchRes = this.filterService.filterByPreferredAirport(this.filter_airports, flightSearchRes);
          if (this.filter_destination_airports.length > 0)
            flightSearchRes = this.filterService.filterByPreferredAirport(this.filter_destination_airports, flightSearchRes);
          //Check result count
          if (flightSearchRes && flightSearchRes.flightsLists
            && flightSearchRes.flightsLists.length > 0
            && flightSearchRes.flightsLists[0].flights
          ) {
            let currTotalOptions = flightSearchRes.flightsLists[0].flights.length;
            if (currTotalOptions <= Constants.OPTION_COUNT_THREADHOLD_AFTER_FILTER_FOR_BG_SEARCH
              && (
                (this.filter_airports.length > 0 && this.filter_airports.length !== this.airportOptions.length)
                ||
                (this.filter_destination_airports.length > 0 && this.filter_destination_airports.length !== this.destinationAirportOptions.length)
              )
              && (this.airportFilterClicked)
              && (this.filterService.currLegIndex == 0)
            ) {
              this.airportFilterClicked = false;
              let oldSearchQueryParam: FlightSearchQueryParam = JSON.parse(JSON.stringify(this.searchService.flightSearchQueryParam));
              let searchQueryParam: FlightSearchQueryParam = this.searchService.flightSearchQueryParam;
              if (this.filter_destination_airports.length > 0 && this.filter_destination_airports.length !== this.destinationAirportOptions.length) {
                searchQueryParam.destinationType = AddressType.GIVEN_AIRPORT_ONLY;
                searchQueryParam.destination = this.filter_destination_airports[0];
              }
              if (this.filter_airports.length > 0 && this.filter_airports.length !== this.airportOptions.length) {
                searchQueryParam.originType = AddressType.GIVEN_AIRPORT_ONLY;
                searchQueryParam.source = this.filter_airports[0];
              }
              this.searchQuick(searchQueryParam, currTotalOptions, oldSearchQueryParam);
            }
          }
        } else if (filter === FilterType.AIRLINE) {
          if (this.filter_airlines.length > 0) {
            flightSearchRes = this.filterService.filterByPreferredAirlines(this.filter_airlines, flightSearchRes);
          }
          if (this.filter_airlineAlliances.length > 0)
            flightSearchRes = this.filterService.filterByAllianceAirlines(this.filter_airlineAlliances, flightSearchRes);
        } else if (filter === FilterType.STOPS) {
          flightSearchRes = this.filterService.filterByNoOfStops(this.filter_noOfStops, flightSearchRes);
        } else if (filter === FilterType.TIME) {
          if (this.filter_departure_range && this.filter_departure_range.starts && this.filter_departure_range.ends) {
            flightSearchRes = this.filterService.filterByDepartureTimeRange(
              flightSearchRes, this.filter_departure_range.starts, this.filter_departure_range.ends);
          }
          if (this.filter_arrival_range && this.filter_arrival_range.starts && this.filter_arrival_range.ends) {
            flightSearchRes = this.filterService.filterByArrivalTimeRange(
              flightSearchRes, this.filter_arrival_range.starts, this.filter_arrival_range.ends);
          }
          if (this.filter_duration_range && this.filter_duration_range.starts && this.filter_duration_range.ends) {
            flightSearchRes = this.filterService.filterByDuration(flightSearchRes, this.filter_duration_range.starts, this.filter_duration_range.ends);
          }
          if (this.filter_price_range && this.filter_price_range.starts && this.filter_price_range.ends) {
            flightSearchRes = this.filterService.filterByPrice(flightSearchRes, this.filter_price_range.starts, this.filter_price_range.ends);
          }
        } else if (filter === FilterType.POLICY) {
          flightSearchRes = this.filterService.filterByWithinPolicy(flightSearchRes);
        }
       
      }
      this.searchService.applySorting(flightSearchRes);
      this.filterService.showLessFlag = true;
     // this.filterService.resetSubscriptio();
  }
  filterResultsAsync() {
    if(this.searchService.backClick) {
      this.searchService.backClick = false;
    }
    this.filter_airlineAlliances=  this.temp_filter_airlineAlliances;
    this.filterService.temp_filter_airlineAlliances = this.filter_airlineAlliances ;
    this.filterService.temp_filter_stops = this.filter_noOfStops;
    this.filterService.appliedFilterList= this.appliedFilterList;
    this.filterService.temp_filter_departure_range = this.filter_departure_range;
    this.filterService.temp_filter_arrival_range = this.filter_arrival_range;
    this.filterService.temp_filter_duration_range = this.filter_duration_range;
    this.filterService.temp_filter_price_range = this.filter_price_range;
    //this.searchService.changeClick =false;
    if(this.searchService.selectedFilter1 && this.searchService.selectedFilter1.length > 0){

    this.filterService.filter_airlines = this.searchService.selectedFilter1;
    }else{
    this.filterService.filter_airlines = this.filter_airlines;
    }
    this.filterService.filter_airports = this.filter_airports;
    this.filterService.filter_destination_airports = this.filter_destination_airports;
  //  this.filterService.filter_airlines = this.filter_airlines;
    this.filterService.temp_filter_policy =  this.filter_policy
    let queryAsJSON = JSON.parse(JSON.stringify(this.searchService.flightSearchQueryParam));
    queryAsJSON.departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.departureDate)) + "T00:00:00.000Z";
    queryAsJSON.arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(queryAsJSON.arrivalDate)) + "T00:00:00.000Z";
    if(queryAsJSON.durationDate){
      queryAsJSON.durationDate = queryAsJSON.durationDate;
    }
    if (queryAsJSON.flights && queryAsJSON.flights.length > 0) {
      for (let multiFlightObj of queryAsJSON.flights) {
        multiFlightObj.departureDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(multiFlightObj.departureDate)) + "T00:00:00.000Z";
        multiFlightObj.arrivalDate = DateUtils.getFormattedDateWithoutTimeZone(new Date(multiFlightObj.arrivalDate)) + "T00:00:00.000Z";
        if(multiFlightObj.durationDate){
          multiFlightObj.durationDate = multiFlightObj.durationDate;
        }
      }
    }
    const queryParams = {
      query: encodeURIComponent(JSON.stringify(queryAsJSON)),
      step: 0,
      index: this.searchService.seletedIndex,
      noofstops:this.filterService.temp_filter_stops,
      sortid :this.searchService.currentSortOptionId,
      departureRange:JSON.stringify(this.filterService.temp_filter_departure_range),
      arrivalRange:JSON.stringify(this.filterService.temp_filter_arrival_range),
      durationRange:JSON.stringify(this.filterService.temp_filter_duration_range),
      priceRange:JSON.stringify(this.filterService.temp_filter_price_range),
      policy: this.filterService.temp_filter_policy,
      appliedFilter:this.filterService.appliedFilterList,
      resultFound: 1
    };
    if (this.searchService.flightId.length !== 0) {
      queryParams['flight'] = JSON.parse(JSON.stringify(this.searchService.flightId));
    }
    if (this.filter_airlines && this.filter_airlines.length > 0) {
      queryParams['airline'] = this.filter_airlines.toString();
    } else {
      queryParams['airline'] ='';
    }
    if(this.filter_airlineAlliances &&  this.filter_airlineAlliances.length > 0) {
      queryParams['alliance'] = this.filter_airlineAlliances.toString();
    } else {
      queryParams['alliance'] ='';
    }
    if (this.filter_airports && this.filter_airports.length > 0) {
      queryParams['sourceAirport'] = this.filter_airports.toString();
    } else {
      queryParams['sourceAirport'] ='';
    }
    if (this.filter_destination_airports && this.filter_destination_airports.length > 0) {
      queryParams['destAirport'] = this.filter_destination_airports.toString();
    } else {
      queryParams['destAirport'] ='';
    }
    this.router.navigate(['flights'], {queryParams:queryParams, replaceUrl: true});
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_UTIL_STORAGE_KEY);
    this.filterService.broadcastGetFiltersResults();
  }

  showAllAirlineWasChecked = true;
  searchQuick(flightSearchQueryParam: FlightSearchQueryParam, oldResultCount: number, oldQueryParams: FlightSearchQueryParam) {
    this.filterService.loadingForShowMoreFlag = true;
    this.quickSearchSubscription = this.searchService.search(flightSearchQueryParam, SearchActionType.QUICK).subscribe(res => {
      flightSearchQueryParam.destination = oldQueryParams.destination;
      flightSearchQueryParam.destinationType = oldQueryParams.destinationType;
      flightSearchQueryParam.source = oldQueryParams.source;
      flightSearchQueryParam.originType = oldQueryParams.originType;
      this.searchService.setPreviousSearch(flightSearchQueryParam);
      this.searchService.broadCastFlightRequest(flightSearchQueryParam);
      this.filterService.loadingForShowMoreFlag = false;
      if (res.status === 'success') {
        let flightSearchRes: FlightSearchResponse = deserialize(res.data, FlightSearchResponse);
        let merged: FlightResult[] = flightSearchRes.flightsLists[0].flights;
        if (merged && merged.length > 0
          &&
          this.filterService.originalFlightSearchResponse.flightsLists[0].flights.length > 0
        ) {
          for (let bestOptionIndex = 0; bestOptionIndex < 5 && bestOptionIndex < merged.length; bestOptionIndex++) {
            for (let legIndex = 0; legIndex < merged[bestOptionIndex].legs.length; legIndex++) {
              merged[bestOptionIndex].legs[legIndex].flightHighlights.topFlightReason = "";
            }
          }
          merged = this.filterService.originalFlightSearchResponse.flightsLists[0].flights.concat(merged);
          merged = this.searchService.removeDuplicates(merged);
          let filteredAndMerged: FlightResult[] = this.filterService.filterFlightArrayByAirports(this.filter_airports, merged);
          filteredAndMerged = this.filterService.filterFlightArrayByAirports(this.filter_destination_airports, filteredAndMerged);

          if (filteredAndMerged.length > oldResultCount) {
            flightSearchRes.flightsLists[0].flights = merged;
            let oldAirlineNames = this.filterService.originalFlightSearchResponse.airlineNames;
            for (let airLineName in oldAirlineNames) {
              flightSearchRes.airlineNames[airLineName] = this.filterService.originalFlightSearchResponse.airlineNames[airLineName];
            }
            let oldAirports = this.filterService.originalFlightSearchResponse.airports;
            for (let airport in oldAirports) {
              flightSearchRes.airports[airport] = this.filterService.originalFlightSearchResponse.airports[airport];
            }
            this.showAllAirlineWasChecked = this.isShowAllAirlineChecked();
            this.searchService.updateFlightSearchResponse(flightSearchRes);
            // this.updateAirlineFilter();
            this.filterResults();
          }
        }
      }
    }
    );
  }


  canShowPolicyFilter() {
    return this.searchForm.controls['classTypeSwitch'].value.toString() === AlgoTypes.PODUCTIVITY.toString() && this.filterService.originalFlightSearchResponse.policySet;
  }
  private updateOptimizeFilterArray(optimizeType: string) {

    var existingFilterIndex = this.optimizeOn.indexOf(optimizeType);

    if (existingFilterIndex !== -1) {
      this.optimizeOn.splice(existingFilterIndex, 1);
    } else {
      this.optimizeOn.push(optimizeType);
    }

    if (this.optimizeOn.length == 0) {
      this.setDefaultOptimization(this.flightSearchResponse);
    }

    this.filterService.optimizeFilterArray = this.optimizeOn;
  }

  showFilter(filter: string) {
    this.shownFilter = filter;
  }

  isShownFilter(filter: string) {
    return filter === this.shownFilter;
  }

  onSelectClass(val: any) {
    this.selectedClass = val.value;
  }

  getClassName(id) {
    let className;

    this.classOptions.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        className = item.value;
        return;
      }
    });

    return className;
  }

  ngOnDestroy() {
    this.noOfStopsChangeSubscription.unsubscribe();
    this.airlinesChangeSubscription.unsubscribe();
    if(this.resetFiltersSubscription){
      this.resetFiltersSubscription.unsubscribe();
    }
    if(this.timeRangeChangeSubscription){
      this.timeRangeChangeSubscription.unsubscribe();
    }

  }
  handleModalEvents(eventName: string, currentModalId: string) {
    CommonUtils.handleModalEvents(this.ngxSmartModalService, eventName, currentModalId);
  }
  log(val){
    console.log('consolelogOffilter->>>>>>>>>>',val)
  }
}
