<div style="display: grid;">
        <div class="modal-header" style="padding: 0px !important;padding-right: 5px !important;background-color: #315EF6;">
            <h5 class="modal-title" id="myModalLabel" style="width: 100% !important;text-align: left;padding-left: 10px;">
                <span *ngIf="tripSelectedToshowOnpopUp.type==='flight'">{{'dashboard.flight' | translate}} </span>
                <span *ngIf="tripSelectedToshowOnpopUp.type==='hotel'">{{'dashboard.hotel' | translate}} </span>
                <span *ngIf="tripSelectedToshowOnpopUp.type==='car'">{{'bookingHistory.car' | translate}} </span>
                 <!-- {{'flightChart.Details' | translate}} -->
            </h5>
            <button type="button" class="close" data-dismiss="modal" (click)="onCancel()">
                <i class="material-icons">close</i>
            </button>
        </div>
        <div class="modal-body" style="background-color: #FFF !important;">
            <div [class]="this.getClassBytype(tripSelectedToshowOnpopUp.type)"
                style="background-color: #FFF !important;box-shadow: none !important;">
                <div class="flight-row-left">
                    <ng-container *ngIf="tripSelectedToshowOnpopUp.type==='flight' && !comingFromSearchPage">
                        <div class="date-duration" style="justify-content: unset;padding-left: 0px !important;">
                            <label class="date" style="margin-right: 4px;">{{'option.Traveltime' |translate}}
                            </label>
                            <span class="date"> {{getDuration(this.airplaneData,this.selectedLegIndexForPopUp)?.hrs + ' hr ' + getDuration(this.airplaneData,this.selectedLegIndexForPopUp)?.mins + ' min'}}</span>
                            <span class="dotBlack"></span>
                            <span class="block flight-airport" style="margin-left: 5px;" *ngIf="tripSelectedToshowOnpopUp.hops.length == 1"> {{ 'flightChart.nonstop'| translate}}</span>
                            <span class="block flight-airport" *ngIf="tripSelectedToshowOnpopUp.hops.length > 1 && tripSelectedToshowOnpopUp.hops.length > 1 "> 
                                {{ getStopsUIText(tripSelectedToshowOnpopUp.hops,this.selectedLegIndexForPopUp)| translate}}
                            </span>
    
    
    
                        </div>
                        <div class="flight-schedule">
                            <ng-container *ngFor="let hop of this.tripSelectedToshowOnpopUp.hops; let hopIndex = index">
                                <div class="flight-box">
                                    <div class="flight-box-left" style="max-width: 63px;">
                                        <img onerror="this.onerror = null; this.src = 'https://s3.amazonaws.com/images.biztravel.ai/template/default.gif';"
                                            [src]="getFlightIconURL(hop.carrier)" />
                                    </div>
                                    <div class="flight-box-right">
                                        <div class="row" style="margin-top: 10px;">
                                            <div class="col-6" style="text-align: left;">
                                                <div *ngIf="hop.seatNo && checkSeat(hop.seatNo)" class="seat-no">{{'bookingHistory.Seat' |
                                                    translate}} {{hop.seatNo}}</div>
                                                <div class="date">{{hop.carrier}} {{hop.flightNumber}}</div>
                                            </div>
                                            <div class="col-6" style="color:#817E7B;text-align: left;">
                                                <p> {{hop.fareClassName}}
                                                    {{getBrandText(hop.brandClass)}}</p>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 10px;">
                                            <div class="col-6" style="text-align: left;"> <div class="date">{{this.airlines[hop.carrier]}}</div></div>
                                            <div class="col-6" style="text-align: left;"> <div class="date">{{getDurationOfhop(hop)}}</div></div>
                                        </div>
                                        <div class="row" style="margin-top: 10px;">
                                            <div class="col-6" style="text-align: left;">
                                                <div style="margin-top: 10px;" class="block flight-time">
                                                    {{getDisplayFlightDate(hop.starts,'h:mm a')}} <span class="extra-time1"></span></div>
                                                <div class="flight-timings-lineDetail"></div>
                                                <div class="date" style="font-size: 14px;margin-top: 10px;">Departure</div>
                                                <div class="detailcity" style="text-align: left;line-height: 18px">
                                                    {{hop.from}} - {{this.airports[hop.from].name}}</div>
                                                <div class="dateDetails" style="text-align: left;">
                                                    {{getDisplayFlightDate(hop.starts,
                                                    'EEEE, MMMM d, y')}}
                                                </div>
                                            </div>
    
                                            <div class="col-6" style="text-align: left;">
                                                <div style="margin-top: 10px;" class="block flight-time">
                                                    {{getDisplayFlightDate(hop.ends,
                                                    'h:mm a')}} <span class="extra-time"></span></div>
                                                <div class="date" style="font-size: 14px;margin-top: 10px;">Arrival</div>
                                                <div class="detailcity" style="line-height: 18px">{{hop.to}} -
                                                    {{this.airports[hop.to].name}}</div>
                                                <div class="dateDetails">{{getDisplayFlightDate(hop.ends,
                                                    'EEEE, MMMM d, y')}}
                                                </div>
                                            </div>
    
                                        </div>
    
    
    
                                    </div>
                                </div>
                                <div class="flight-layover"
                                    *ngIf="tripSelectedToshowOnpopUp.hops[hopIndex+1] && tripSelectedToshowOnpopUp.hops[hopIndex+1].duration"
                                    style="justify-content: center;">
                                    <div>
                                       
                                        <span class="flight-layover-right date">
                                            {{getLayOverDuration(tripSelectedToshowOnpopUp.hops,hopIndex)}}
                                            </span> 
                                        <span class="dotBlack" style="margin-right: 4px;margin-left: 4px;;"></span>
                                        <span class="flight-layover-left">{{'flightChart.Layoverin' | translate}}
                                            {{hop.to}}</span>
                                    </div>
    
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="tripSelectedToshowOnpopUp.type==='flight' && comingFromSearchPage">
                        <div *ngFor="let item of this.tripSelectedToshowOnpopUp.option.flight_option.legs;let i=index;">
                            <div class="date-duration" style="justify-content: unset;padding-left: 0px !important;">
                                <label class="date" style="margin-right: 4px;">{{'option.Traveltime' |translate}}
                                </label>
                                <span class="date"> {{getDuration(this.airplaneData,i)?.hrs + ' hr ' + getDuration(this.airplaneData,i)?.mins + ' min'}}</span>
                                <span class="dotBlack"></span>
                                <span class="block flight-airport" style="margin-left: 5px;" *ngIf="item.hops.length == 1"> {{ 'flightChart.nonstop'| translate}}</span>
                                <span class="block flight-airport" *ngIf="item.hops.length > 1 && item.hops.length > 1 "> 
                                    {{ getStopsUIText(item.hops,i)| translate}}
                                </span>
        
        
        
                            </div>
                            <div class="flight-schedule">
                                <ng-container *ngFor="let hop of item.hops; let hopIndex = index">
                                    <div class="flight-box">
                                        <div class="flight-box-left" style="max-width: 63px;">
                                            <img onerror="this.onerror = null; this.src = 'https://s3.amazonaws.com/images.biztravel.ai/template/default.gif';"
                                                [src]="getFlightIconURL(hop.carrier)" />
                                        </div>
                                        <div class="flight-box-right">
                                            <div class="row" style="margin-top: 10px;">
                                                <div class="col-6" style="text-align: left;">
                                                    <div *ngIf="hop.seatNo && checkSeat(hop.seatNo)" class="seat-no">{{'bookingHistory.Seat' |
                                                        translate}} {{hop.seatNo}}</div>
                                                    <div class="date">{{hop.carrier}} {{hop.flightNumber}}</div>
                                                </div>
                                                <div class="col-6" style="color:#817E7B;text-align: left;">
                                                    <p> {{hop.fareClassName}}
                                                        {{getBrandText(hop.brandClass)}}</p>
                                                </div>
                                            </div>
        
                                            <div class="row" style="margin-top: 10px;">
                                                <div class="col-6" style="text-align: left;">
                                                    <div class="date">{{this.airlines[hop.carrier]}}</div>
                                                    <div style="margin-top: 10px;" class="block flight-time">
                                                        {{getDisplayFlightDate(hop.starts,
                                                        'h:mm a')}} <span class="extra-time1"></span></div>
                                                    <div style="top: 43px !important;" class="flight-timings-lineDetail"></div>
                                                    <div class="date" style="font-size: 14px;margin-top: 10px;">Departure</div>
                                                    <div class="detailcity" style="text-align: left;line-height: 18px">
                                                        {{hop.from}} - {{this.airports[hop.from].name}}</div>
                                                    <div class="dateDetails" style="text-align: left;">
                                                        {{getDisplayFlightDate(hop.starts,
                                                        'EEEE, MMMM d, y')}}
                                                    </div>
                                                </div>
        
                                                <div class="col-6" style="text-align: left;">
                                                    <div class="date">{{getDurationOfhop(hop)}}</div>
                                                    <div style="margin-top: 10px;" class="block flight-time">
                                                        {{getDisplayFlightDate(hop.ends,
                                                        'h:mm a')}} <span class="extra-time"></span></div>
                                                    <div class="date" style="font-size: 14px;margin-top: 10px;">Arrival</div>
                                                    <div class="detailcity" style="line-height: 18px">{{hop.to}} -
                                                        {{this.airports[hop.to].name}}</div>
                                                    <div class="dateDetails">{{getDisplayFlightDate(hop.ends,
                                                        'EEEE, MMMM d, y')}}
                                                    </div>
                                                </div>
        
                                            </div>
        
        
        
                                        </div>
                                    </div>
                                    <div class="flight-layover"
                                        *ngIf="item.hops[hopIndex+1] && item.hops[hopIndex+1].duration"
                                        style="justify-content: center;">
                                        <div>
                                           
                                            <span class="flight-layover-right date">
                                                {{getLayOverDuration(item.hops,hopIndex)}}
                                                </span> 
                                            <span class="dotBlack" style="margin-right: 4px;margin-left: 4px;;"></span>
                                            <span class="flight-layover-left">{{'flightChart.Layoverin' | translate}}
                                                {{hop.to}}</span>
                                        </div>
        
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        </ng-container>
                    <ng-container *ngIf="tripSelectedToshowOnpopUp.type==='car'">
                        <div class="date-duration" style="justify-content: unset;">
                            <label class="date" style="margin-right: 4px;">{{tripSelectedToshowOnpopUp.description}}
                            </label>
    
    
                        </div>
                        <div class="flight-schedule">
                            <ng-container>
                                <div class="flight-box">
                                    <div class="flight-box-left" style="max-width: 63px;">
                                        <div>
                                            <img style="width:130px !important;" *ngIf="carUrl(tripSelectedToshowOnpopUp)"
                                                src="{{carUrl(tripSelectedToshowOnpopUp)}}"
                                                onerror="this.src='https://s3.amazonaws.com/images.biztravel.ai/ota/carrentals/vehicle_notavailable_S.jpg' " />
                                        </div>
                                        <div> <img
                                                style="margin-top:5px;height:auto!important;width:100px !important;border-radius: 6px"
                                                *ngIf="tripSelectedToshowOnpopUp.partnerLogo"
                                                src="{{ tripSelectedToshowOnpopUp.partnerLogo }}" />
                                        </div>
                                    </div>
                                    <div class="flight-box-right">
                                        <div class="date" style="font-size: 14px;white-space: break-spaces;text-align: start;">{{tripSelectedToshowOnpopUp.carMake}}</div>
                                        <div class="row" >
                                            
                                            <div class="col-6" style="text-align: left;">
                                                
    
                                                <div class="date" style="font-size: 14px;">
                                                    {{tripSelectedToshowOnpopUp.pickUpLocationType}} {{'carresult.Pick-up' |
                                                    translate }}</div>
                                                    <div class="date" style="font-size: 14px;">
                                                            {{getDisplayDateTimeForFlights(tripSelectedToshowOnpopUp.pickUpDate,
                                                                'EEEE, MMM d, yyyy')}}  </div>
                                                <div class="detailcity" style="text-align: left;line-height: 18px">
                                                    {{tripSelectedToshowOnpopUp.pickUpLocation}}</div>
                                                <div class="dateDetails" style="text-align: left;">{{
                                                    getLocationType(tripSelectedToshowOnpopUp.locationInformation) }}
                                                </div>
                                                <div style="color:#9C4AF6;margin-top: 10px;">
                                                    {{getPerDayPrice(tripSelectedToshowOnpopUp) | currency : getCurrencySymbol(tripSelectedToshowOnpopUp): 'code' : "1.0-0"}} {{'carresult.avgday' | translate }}
                                                </div>
                                                <div style="margin-top: 10px;">
                                                    <li>
                                                        <img *ngIf="tripSelectedToshowOnpopUp.policy"
                                                            src="../../../assets/images/hotel/policy-active.svg">
    
                                                        <img *ngIf="!tripSelectedToshowOnpopUp.policy"
                                                            src="../../../assets/images/hotel/policy.svg">
    
    
                                                        <span style="position: relative;top:
                                                                             2px;left:3px;">{{'flightChart.Policy' |
                                                            translate}}</span>
    
                                                    </li>
                                                </div>
                                            </div>
    
                                            <div class="col-6" style="text-align: left;">
    
                                                <div class="date" style="font-size: 14px;">
                                                    {{tripSelectedToshowOnpopUp.dropLocationType}} {{'carresult.Drop-off' |
                                                    translate }}</div>
                                                    <div class="date" style="font-size: 14px;">
                                                            {{getDisplayDateTimeForFlights(tripSelectedToshowOnpopUp.dropOffDate,
                                                                'EEEE, MMM d, yyyy')}}    </div>
                                                <div class="detailcity" style="line-height: 18px"><span
                                                        *ngIf="tripSelectedToshowOnpopUp.pickUpLocation !== tripSelectedToshowOnpopUp.dropOffLocation">
                                                        {{tripSelectedToshowOnpopUp.dropOffLocation}}</span><span
                                                        *ngIf="tripSelectedToshowOnpopUp.pickUpLocation === tripSelectedToshowOnpopUp.dropOffLocation">
                                                        {{'option.Sameaspick-up' | translate}}</span></div>
    
                                            </div>
    
                                        </div>
                                        
                                        <hr  *ngIf="tripSelectedToshowOnpopUp.type==='car' && this.IsMobile">
                                        <div  *ngIf="tripSelectedToshowOnpopUp.type==='car' && this.IsMobile" style="text-align: left;">
                                            <div *ngIf="tripSelectedToshowOnpopUp.milage" class="hotel-distance-address">
                                                <i class="fa fa-tachometer" aria-hidden="true"></i><span
                                                    style="margin-left:4px;">{{'carresult.Unlimitedmileage' |
                                                    translate}}</span>
                                            </div>
                                            <div *ngIf="tripSelectedToshowOnpopUp.automaticTransmission"
                                                class="hotel-distance-address">
                                                <span class="inlineblock icon-margin "><img class="door"
                                                        src="assets/images/transmission_icon.png"></span><span
                                                    style="margin-left:4px;">{{'carresult.Automatictransmission' |
                                                    translate}}</span>
                                            </div>
                                            <div *ngIf="tripSelectedToshowOnpopUp.doors"
                                                class="duration-section car-duration" style="display: block !important;">
                                                <span class="inlineblock icon-margin "><img class="door"
                                                        src="assets/images/car_gate.svg"></span>
                                                <span class="inlineblock"
                                                    style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.doors}}</span>
                                            </div>
    
                                            <div *ngIf="tripSelectedToshowOnpopUp.passengers"
                                                class="duration-section car-duration" style="display: block !important;">
                                                <span class="inlineblock icon-margin "><i class="fa fa-user"
                                                        aria-hidden="true"></i></span>
                                                <span class="inlineblock"
                                                    style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.passengers}}</span>
                                            </div>
    
                                            <div *ngIf="tripSelectedToshowOnpopUp.bags"
                                                class="duration-section walk-duration" style="display: block !important;">
                                                <span class="inlineblock icon-margin"><i class="fa fa-suitcase"
                                                        aria-hidden="true"></i></span>
                                                <span class="inlineblock "
                                                    style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.bags}}</span>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
    
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="tripSelectedToshowOnpopUp.type==='hotel'">
                        <div class="date-duration" style="justify-content: unset;padding-left: 1px !important;">
                            <label class="date"
                                style="margin-right: 4px;text-align: left;">{{tripSelectedToshowOnpopUp.hotelRooms[0].hotelRoomName}}
                            </label>
    
    
                        </div>
                        <div class="flight-schedule">
                            <ng-container>
                                <div class="flight-box">
                                    <div class="flight-box-left" style="min-width: 63px;max-width: 63px;">
                                        <img onerror="this.onerror=null;this.src='assets/images/hotel/hotel.png'"
                                            *ngIf="tripSelectedToshowOnpopUp.thumbnailImage"
                                            src="{{tripSelectedToshowOnpopUp.thumbnailImage.url}}" />
                                        <img *ngIf="!tripSelectedToshowOnpopUp.thumbnailImage"
                                            src="assets/images/hotel/hotel.png" />
                                    </div>
                                    <div class="flight-box-right">
    
                                        <div class="row" style="margin-top: -4px;">
                                            <div class="col-12" style="text-align: left;">
                                                <div class="date" style="">{{tripSelectedToshowOnpopUp.hotelName}}</div>
                                                <div class="date">{{tripSelectedToshowOnpopUp.distanceInMilesFromMeeting | number :'.2-2'}}{{'hotelSelect.milesfromyourdestination' | translate}}</div>
                                                <div class="date" style="font-size: 14px;margin-top: 10px;">
                                                </div>
                                                <div class="detailcity" style="text-align: left;line-height: 18px">
                                                    {{tripSelectedToshowOnpopUp.address}}</div>
                                                <div class="dateDetails" style="text-align: left;margin-top: 10px;">
                                                    <div>
                                                        <span class="distance-img-text"
                                                            style="margin-left: -3px !important;">
                                                            <span class="inlineblock_m" style="margin-right: 10px;" *ngIf="tripSelectedToshowOnpopUp.distance > 0">
                                                                <img class="distance-time-img"
                                                                    src="assets/images/emailflow/car-distance.png" alt="" />
                                                                <span
                                                                    class="distance-time-text">{{tripSelectedToshowOnpopUp.distance}}
                                                                    {{'option.min' | translate}}</span>
                                                            </span>
                                                            <span *ngIf="tripSelectedToshowOnpopUp.walkingTime < 30"
                                                                class="inlineblock_m">
                                                                <img class="distance-time-img"
                                                                    src="assets/images/emailflow/walk-distance.png"
                                                                    alt="" />
                                                                <span
                                                                    class="distance-time-text">{{tripSelectedToshowOnpopUp.walkingTime}}
                                                                    {{'option.min' | translate}}</span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-6">
                                                   <div class="date">{{'bookingHistory.CheckIn' | translate}}  </div>
                                                   <div class="date">{{getDisplayDate(tripSelectedToshowOnpopUp.checkInDate,'MMM d, yyyy')}}</div>
                                                 </div>
                                                 <div class="col-6">
                                                 <div class="date">{{'bookingHistory.CheckOut' | translate}} </div>
                                                 <div class="date">
                                                        {{getDisplayDate(tripSelectedToshowOnpopUp.checkOutDate,'MMM d, yyyy')}}
                                                 </div>
                                                 </div>
                                                </div>
                                                <div *ngIf="!tripSelectedToshowOnpopUp.loyaltyPointsSupported"
                                                    style="color:#f93d30; width:100%;text-align: left;margin-top:15px;">
                                                    <img src="assets/images/flight-list/gen-negative.svg">
                                                    {{'option.Noloyaltyrewards' | translate}}
                                                </div>
                                                <div class="dateDetails"
                                                    style="margin-top: 15px;color: #817E7B !important;">
                                                    {{'option.Cancellationpolicy' | translate}}:
                                                    {{getCancellationPolicy(tripSelectedToshowOnpopUp)}}
                                                </div>
    
                                                <div style="margin-top: 10px;">
                                                    <li>
    
                                                        <img
                                                            src="assets/images/hotel/policy{{tripSelectedToshowOnpopUp.inPolicy ? '-active' : ''}}.svg">
    
    
                                                        <span style="position: relative;top:
                                                                         2px;left:3px;">{{'flightChart.Policy' |
                                                            translate}}</span>
    
                                                    </li>
                                                </div>
                                            </div>
    
    
                                        </div>
    
    
    
                                    </div>
                                </div>
    
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="( this.tripSelectedToshowOnpopUp.type==='flight' && this.fareAttributes) || this.tripSelectedToshowOnpopUp.type==='car' || this.tripSelectedToshowOnpopUp.type === 'hotel'" [hidden]="tripSelectedToshowOnpopUp.type==='car' && this.IsMobile " class="flight-row-right" [ngStyle]="getDetailsStyleModal()" style="padding-right:6px !important;background-color: #FFF !important;box-shadow: none!important;align-items:  baseline !important;">
                    <div class="flight-box" style="padding-top: 0px !important;padding-right: 0px !important;">
                        <div *ngIf="false" class="flight-box-left" style="width: 0px;"></div>
                        <div class="flight-box-right">
                            <div *ngIf="tripSelectedToshowOnpopUp.type==='flight' && this.fareAttributes"class="flight-feature-list">
                                <ul>
                                    <li *ngIf="getCorporateFareAttributeMoreOptions(this.detailsList.flight_option)" style="white-space: nowrap;">
                                          <i class="fa fa-star" style="margin-right: 4px;color: #3CBF9A"></i> 
                                         
                                          <span style="white-space: break-spaces;">{{'hotelResult.Corporaterate' | translate}}</span>
                                       </li>
                                   
                                 <li *ngIf="getSeatFareAttributeMoreOptions(this.detailsList.flight_option) !== 'NOTFOUND'" style="white-space: nowrap;">
                                       <img
                                       *ngIf="getSeatFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'" 
                                       src="../../../assets/images/seat-green.svg"
                                       >
                                      
                                       <img
                                       *ngIf="getSeatFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getSeatFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" 
                                       src="../../../assets/images/seat-red.svg"
                                       >
                                       <span  *ngIf="getSeatFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'"  style="white-space:break-spaces;">{{'flightChart.Seatchoice' | translate}}</span>
                                       <span  *ngIf="getSeatFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getSeatFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'"  style="white-space:break-spaces;">{{'flightChart.NoSeatchoice' | translate}}</span>
                                 </li>
                                
                                 <li *ngIf="isMixed(this.fareAttributes)" class="not-active  "
                                    style="flex: 0 0 auto; min-width:50px;">
                                    <i class="fa fa-exclamation-triangle  fare-attr-red" 
                                       aria-hidden="true"></i>
                                    <span style=" color: rgb(249, 61, 48);">{{'flightChart.Mixedclass' | translate}}</span>
                                 </li>
                                 <li *ngIf="getCarryOnBagFareAttributeMoreOptions(this.detailsList.flight_option) !== 'NOTFOUND'">
                                       <img
                                       *ngIf="getCarryOnBagFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'" 
                                       src="../../../assets/images/carry-on-green.svg"
                                       >
                                     
                                       <img
                                       *ngIf="getCarryOnBagFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getCarryOnBagFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" 
                                       src="../../../assets/images/carry-on-red.svg"
                                       >
                                       <span *ngIf="getCarryOnBagFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED' ">{{'flightChart.Carryonbag' | translate}}</span>
                                       <span *ngIf="getCarryOnBagFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getCarryOnBagFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'">{{'flightChart.NoCarryonbag' | translate}}</span>
                                 </li>
                                 <li *ngIf="getChangesFareAttributeMoreOptions(this.detailsList.flight_option) !== 'NOTFOUND'">
                                      <img style="transform: rotate(90deg);" 
                                      *ngIf="getChangesFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'" 
                                      src="../../../assets/images/changes-green.svg"
                                      >
                                     
                                      <img
                                      *ngIf="getChangesFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getChangesFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" 
                                      src="../../../assets/images/changes-red.svg"
                                      >
                                      <span *ngIf="getChangesFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED' ">{{'flightChart.Changes' | translate}}</span>
                                      <span *ngIf="getChangesFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getChangesFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'">{{'flightChart.NoChanges' | translate}}</span>
                                </li>
                                <li *ngIf="getBaggageDetails(this.detailsList.flight_option)" class="not-active" style="flex: 0 0 auto; min-width:50px;white-space: nowrap;">
                                      <img *ngIf="getBaggageNumber(this.detailsList.flight_option) ===1 || getBaggageNumber(this.detailsList.flight_option) >3" src="../../../assets/images/checked-bag-green.svg">
                                      <img *ngIf="getBaggageNumber(this.detailsList.flight_option) ===2" src="../../../assets/images/checked-bag-green2.svg">
                                      <img *ngIf="getBaggageNumber(this.detailsList.flight_option) ===3" src="../../../assets/images/checked-bag-green3.svg">

                                      <img *ngIf="getBaggageDetailsColor1(this.detailsList.flight_option)==='notallowed'" src="../../../assets/images/checked-bag-red.svg">
                                  <!--<i class="fa fa-suitcase  fare-attr-red" aria-hidden="true" [ngStyle]="{'color': isBaggageAvailable(item) ? '#27c198':'#9B9B9B'}"></i>-->
                                    <span style="white-space: break-spaces;">{{getBaggageDetails(this.detailsList.flight_option)}}</span>
                                 </li>
                                 
                                 <li  >
                                      <img
                                      *ngIf="this.detailsList.flight_option.withinPricePolicy == true"
                                      src="../../../assets/images/policy-green.svg"
                                      >
                                      <img
                                      *ngIf="this.detailsList.flight_option.withinPricePolicy == false"
                                         src="../../../assets/images/policy-red.svg"
                                         >
                                        
                                   


                                   <span *ngIf="this.detailsList.flight_option.withinPricePolicy == true">{{'flightChart.InPolicy' | translate}}</span>
                                   <span *ngIf="this.detailsList.flight_option.withinPricePolicy == false">{{'flightChart.NotinPolicy' | translate}}</span>

                                </li>
                                 <li *ngIf="getRefundAttributeMoreOptions(this.detailsList.flight_option) !== 'NOTFOUND'">
                                       <img
                                       *ngIf="getRefundAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'" 
                                       src="../../../assets/images/refund-green.svg"
                                       >
                                     
                                       <img
                                       *ngIf="getRefundAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getRefundAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" 
                                       src="../../../assets/images/refund-red.svg"
                                       >
                                       <span *ngIf="getRefundAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED' ">{{'flightChart.Refundable' | translate}}</span>
                                       <span *ngIf="getRefundAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getRefundAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE' ">{{'flightChart.NotRefundable' | translate}}</span>
                                 </li>
                                
                                
                                 <li *ngIf="getWifiAttributeMoreOptions(this.detailsList.flight_option) !== 'NOTFOUND'"
                                 style="flex: 0 0 auto; min-width:80px;">
                                 <img
                                 *ngIf="getWifiAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'" 
                                 src="../../../assets/images/wifi-green.svg"
                                 >
                                
                                 <img
                                 *ngIf="getWifiAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getWifiAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" 
                                 src="../../../assets/images/wifi-red.svg"
                                 >
                  
                                 <span *ngIf="getWifiAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'">{{'flightChart.WiFi' | translate}}<label  style="margin-left: 4px;">{{'flightChart.atcost' | translate}}</label></span>
                                 <span *ngIf="getWifiAttributeMoreOptions(this.detailsList.flight_option) == 'NOTOFFERED'">{{'flightChart.NOWiFi' | translate}}</span>
                                 <span *ngIf="getWifiAttributeMoreOptions(this.detailsList.flight_option) == 'INCLUDED'" >{{'flightChart.FreeWiFi' | translate}}</span>
                              </li>
                                 <li *ngIf="getMealFareAttributeMoreOptions(this.detailsList.flight_option) !== 'NOTFOUND'">
                                       <img
                                       *ngIf="getMealFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'" 
                                       src="../../../assets/images/meal-green.svg"
                                       >
                                      
                                       <img
                                       *ngIf="getMealFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getMealFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" 
                                       src="../../../assets/images/meal-red.svg"
                                       >
                                       <span *ngIf="getMealFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'">{{'flightChart.freeMeal' | translate}}</span>
                                       <span *ngIf="getMealFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'">{{'flightChart.Meal' | translate}}</span>
                                                           <span *ngIf="getMealFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED'">{{'flightChart.NOMeal' | translate}}</span>
                                 </li>
                                 <li *ngIf="getLegroomFareAttributeMoreOptions(this.detailsList.flight_option) !== 'NOTFOUND'"  style="flex: 0 0 auto; min-width:50px;white-space: nowrap;">
                                      <img
                                      *ngIf="getLegroomFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED'" 
                                      src="../../../assets/images/leg-space-green.svg"
                                      >
                                      
                                      <img
                                      *ngIf="getLegroomFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getLegroomFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" 
                                      src="../../../assets/images/leg-space-red.svg"
                                      >
                                      <span *ngIf="getLegroomFareAttributeMoreOptions(this.detailsList.flight_option) === 'INCLUDED' "style="white-space: break-spaces;">{{'flightChart.legRoom' | translate}}</span>
                                                  <span *ngIf="getLegroomFareAttributeMoreOptions(this.detailsList.flight_option) === 'NOTOFFERED' || getLegroomFareAttributeMoreOptions(this.detailsList.flight_option) === 'AVAILABLEFORCHARGE'" style="white-space: break-spaces;">{{'flightChart.NolegRoom' | translate}}</span>
                                   </li>
                                
                              </ul>
                            </div>
                            <div  *ngIf="tripSelectedToshowOnpopUp.type==='car' && !this.isMobile1" [ngStyle]="{'margin-left': this.isMobile1 ?  '30px' : '0px;'}" style="text-align: left;">
                                <div *ngIf="tripSelectedToshowOnpopUp.milage" class="hotel-distance-address">
                                    <i class="fa fa-tachometer" aria-hidden="true"></i><span
                                        style="margin-left:4px;">{{'carresult.Unlimitedmileage' | translate}}</span>
                                </div>
                                <div *ngIf="tripSelectedToshowOnpopUp.automaticTransmission" class="hotel-distance-address">
                                    <span class="inlineblock icon-margin "><img class="door"
                                            src="assets/images/transmission_icon.png"></span><span
                                        style="margin-left:4px;">{{'carresult.Automatictransmission' | translate}}</span>
                                </div>
                                <div *ngIf="tripSelectedToshowOnpopUp.doors" class="duration-section car-duration"
                                    style="display: block !important;">
                                    <span class="inlineblock icon-margin "><img class="door"
                                            src="assets/images/car_gate.svg"></span>
                                    <span class="inlineblock"
                                        style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.doors}}</span>
                                </div>
    
                                <div *ngIf="tripSelectedToshowOnpopUp.passengers" class="duration-section car-duration"
                                    style="display: block !important;">
                                    <span class="inlineblock icon-margin "><i class="fa fa-user"
                                            aria-hidden="true"></i></span>
                                    <span class="inlineblock"
                                        style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.passengers}}</span>
                                </div>
    
                                <div *ngIf="tripSelectedToshowOnpopUp.bags" class="duration-section walk-duration"
                                    style="display: block !important;">
                                    <span class="inlineblock icon-margin"><i class="fa fa-suitcase"
                                            aria-hidden="true"></i></span>
                                    <span class="inlineblock "
                                        style="margin-left:4px !important;">{{tripSelectedToshowOnpopUp.bags}}</span>
                                </div>
                            </div>
                            <div *ngIf="tripSelectedToshowOnpopUp.type==='hotel'">
                                <div class="hotel-amenities">
    
                                    <ul>
                                        <li class="inlineblock_m hotel-rating-stars">
                                            <span
                                                *ngFor="let starType of getRatingStarsMap(tripSelectedToshowOnpopUp.rating); let i = index;"
                                                class="star">
                                                <img *ngIf="starType == 'full'" src="assets/images/hotel/star-filled.svg" />
                                                <img *ngIf="starType == 'half'" src="assets/images/hotel/halfstar.png" />
                                                <img *ngIf="starType == 'none'" src="assets/images/hotel/star.svg" />
                                            </span>
                                            <!-- <span><img src="assets/images/hotel/star-filled.svg" alt="star-filled" /></span>
                                                                    <span><img src="assets/images/hotel/star-filled.svg" alt="star-filled" /></span>
                                                                    <span><img src="assets/images/hotel/star-filled.svg" alt="star-filled" /></span>
                                                                    <span><img src="assets/images/hotel/star.svg" alt="star" /></span>
                                                                    <span><img src="assets/images/hotel/star.svg" alt="star" /></span> -->
                                        </li>
                                        <li>
                                            <img class="inlineblock_m"
                                                src="assets/images/hotel/local_cafe{{isAmenityAvailable('AMENITY_TYPE_BKFAST') ? '-active' : ''}}.svg"
                                                alt="" />
                                            <span class="inlineblock_m">{{'hotelSelect.Breakfast' | translate}}</span>
                                        </li>
    
                                        <li>
                                            <img class="inlineblock_m"
                                                src="assets/images/hotel/wifi{{isAmenityAvailable('AMENITY_TYPE_WIFI') ? '-active' : ''}}.svg"
                                                alt="" />
                                            <span class="inlineblock_m">{{'hotelSelect.FreeWiFi' | translate}}</span>
                                        </li>
    
                                        <li>
                                            <img class="inlineblock_m"
                                                src="assets/images/hotel/fitness-dumbbell{{isAmenityAvailable('AMENITY_TYPE_GYM') ? '-active' : ''}}.svg"
                                                alt="" />
                                            <span class="inlineblock_m">{{'hotelSelect.Gym' | translate}}</span>
                                        </li>
    
                                        <li>
                                            <img class="inlineblock_m"
                                                src="assets/images/hotel/local_restaurant{{isAmenityAvailable('AMENITY_TYPE_RESTAURANT') ? '-active' : ''}}.svg"
                                                alt="" />
                                            <span class="inlineblock_m">{{'hotelSelect.Restaurant' | translate}}</span>
                                        </li>
    
                                        <li>
                                            <img class="inlineblock_m"
                                                src="assets/images/hotel/bell-call{{isAmenityAvailable('AMENITY_TYPE_ROOMSERVICE') ? '-active' : ''}}.svg"
                                                alt="" />
                                            <span class="inlineblock_m">{{'hotelSelect.Concierge' | translate}}</span>
                                        </li>
    
                                        <li>
                                            <img class="inlineblock_m"
                                                src="assets/images/hotel/swimming-pool-person{{isAmenityAvailable('AMENITY_TYPE_POOL') ? '-active' : ''}}.svg"
                                                alt="" />
                                            <span class="inlineblock_m">{{'hotelSelect.Pool' | translate}}</span>
                                        </li>
    
                                        <li>
                                            <img class="inlineblock_m"
                                                src="assets/images/hotel/bar{{isAmenityAvailable('AMENITY_TYPE_BAR') ? '-active' : ''}}.svg"
                                                alt="" />
                                            <span class="inlineblock_m">{{'hotelSelect.Bar' | translate}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</div>