export enum BookingResponseErrorType {
  ERROR_PAYMENT = "ERROR_PAYMENT",
  ERROR_PRICE_CHANGE = "ERROR_PRICE_CHANGE",
  REVALIDATE_PRICE_CHANGE = "REVALIDATE_PRICE_CHANGE",
  ERROR_INTERNAL = "ERROR_INTERNAL",
  ERROR_API = "ERROR_API",
  ERROR_AVAILABILITY = "ERROR_AVAILABILITY",
  ERROR_AVAILABILITY_WITHIN_TWOHRS = "ERROR_AVAILABILITY_WITHIN_TWOHRS",
  ERROR_ROOM_UNAVAILABLE = "ERROR_ROOM_UNAVAILABLE",
  ERROR_FAILED_BY_VENDOR = "ERROR_FAILED_BY_VENDOR",
  ERROR_BOOKING_PENDING = "ERROR_BOOKING_PENDING",
  ERROR_TICKETING = "ERROR_TICKETING",
  ERROR_INVALID_INPUT = "ERROR_INVALID_INPUT",
  ERROR_TRIP_BOOKING_PENDING = "ERROR_TRIP_BOOKING_PENDING",
}
