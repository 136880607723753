import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Constants } from "../util/constants";
import { CookieService } from "ngx-cookie-service";
import { UserCredentialsService } from "../user-credentials.service";

@Injectable()
export class GallopHttpClient {
  constructor(private httpclient: HttpClient,
    private userCredentials: UserCredentialsService,
    private cookieService: CookieService) {
  }
  public post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: any;
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    if (url.indexOf('?') === -1) {
      url = url + '?';
    }
    url += '&' + Constants.PARAM_APP_VERSION + '=' + environment.appVersion;
    url += '&' + Constants.PARAM_APPSTORE_VERSION + '=' + environment.appStoreVersionName;
    url += '&' + Constants.PARAM_APP_BUILD_NUMBER + '=' + environment.appStoreBuildNumber;
    url += '&' + Constants.PARAM_APP_PLATFORM + '=' + environment.platform;
    url += '&' + Constants.PARAM_APP_LANGUAGE + '=' + this.userCredentials.getLang();
  //  url += '&' + Constants.PARAM_RS_CLIENT_COOKIE + '=' + this.cookieService.get('RS-CLIENT');
    url += '&' + Constants.PARAM_OTA_BUILD_NUMBER + '=' + environment.otaBuildNumber;
    if ((!options || JSON.stringify(options).indexOf('sToken') === -1) && (url.indexOf('sToken') === -1)
      && this.userCredentials.getSToken()) {
    //  url += '&' + Constants.PARAM_USER_ID + '=' + this.userCredentials.getUserId();
    //  url += '&' + Constants.PARAM_STOKEN + '=' + this.userCredentials.getSToken();
    }
    if(!options  && this.userCredentials.getSToken()){
      options ={};
      options['headers'] = new HttpHeaders().append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));

    }else if(options && this.userCredentials.getSToken()){
      if(options.headers){
        options.headers =   options.headers.append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));
    
      }else{
        options.headers = new HttpHeaders().append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));
      }
    }
    return this.httpclient.post(url, body, options);
  }

  public postBlob(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]:any;
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<Blob> {
    if (url.indexOf('?') === -1) {
      url = url + '?';
    }
    url += '&' + Constants.PARAM_APP_VERSION + '=' + environment.appVersion;
    url += '&' + Constants.PARAM_APPSTORE_VERSION + '=' + environment.appStoreVersionName;
    url += '&' + Constants.PARAM_APP_BUILD_NUMBER + '=' + environment.appStoreBuildNumber;
    url += '&' + Constants.PARAM_APP_PLATFORM + '=' + environment.platform;
    url += '&' + Constants.PARAM_APP_LANGUAGE + '=' + this.userCredentials.getLang();
  //  url += '&' + Constants.PARAM_RS_CLIENT_COOKIE + '=' + this.cookieService.get('RS-CLIENT');
    url += '&' + Constants.PARAM_OTA_BUILD_NUMBER + '=' + environment.otaBuildNumber;
    if ((!options || JSON.stringify(options).indexOf('sToken') === -1) && (url.indexOf('sToken') === -1)
      && this.userCredentials.getSToken()) {
     // url += '&' + Constants.PARAM_USER_ID + '=' + this.userCredentials.getUserId();
     // url += '&' + Constants.PARAM_STOKEN + '=' + this.userCredentials.getSToken();
    }
    if(!options  && this.userCredentials.getSToken()){
      options['headers'] = new HttpHeaders().append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));

    }else if(options && this.userCredentials.getSToken()){
      if(options.headers){
        options.headers = options.headers.append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));
    
      }else{
        options.headers = new HttpHeaders().append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));
      }
    }
   
    return this.httpclient.post(url, body, options);
  }

  public get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: any;
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    if (url.indexOf('?') === -1) {
      url = url + '?';
    }
    url += '&' + Constants.PARAM_APP_VERSION + '=' + environment.appVersion;
    url += '&' + Constants.PARAM_APPSTORE_VERSION + '=' + environment.appStoreVersionName;
    url += '&' + Constants.PARAM_APP_BUILD_NUMBER + '=' + environment.appStoreBuildNumber;
    url += '&' + Constants.PARAM_APP_PLATFORM + '=' + environment.platform;
    url += '&' + Constants.PARAM_APP_LANGUAGE + '=' + this.userCredentials.getLang();
   // url += '&' + Constants.PARAM_RS_CLIENT_COOKIE + '=' + this.cookieService.get('RS-CLIENT');
    url += '&' + Constants.PARAM_OTA_BUILD_NUMBER + '=' + environment.otaBuildNumber;
    if ((!options || JSON.stringify(options).indexOf('sToken') === -1) && (url.indexOf('sToken') === -1)
      && this.userCredentials.getSToken()) {
     // url += '&' + Constants.PARAM_USER_ID + '=' + this.userCredentials.getUserId();
     // url += '&' + Constants.PARAM_STOKEN + '=' + this.userCredentials.getSToken();
    }
    if(!options  && this.userCredentials.getSToken()){
      options ={};
      options['headers'] = new HttpHeaders().append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));

    }else if(options && this.userCredentials.getSToken()){
      if(options.headers){
        options.headers = options.headers.append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));
    
      }else{
        options.headers = new HttpHeaders().append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));
      }
     // options.headers = new HttpHeaders().append('Authorization','Bearer'+' '+this.userCredentials.getUserId()+':'+this.userCredentials.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'));
    }
    return this.httpclient.get(url, options);
  }


}
