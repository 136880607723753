import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { UserAccountService } from './user-account.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddEmployeeDTO } from './entity/add-employee-dto';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from './entity/user-info';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { CompanyReportResponse, UserInfoBasic } from './entity/company-report-response';
import { CardListDTO } from './entity/card-list-dto';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { DateUtils } from './util/date-utils';
import { UserDetails } from './entity/userDetails';
import { CommonUtils } from './util/common-utils';
import { JsonConstructor } from './util/ta-json/src';
import { Constants } from './util/constants';
import { CookieService } from 'ngx-cookie-service';
import { headersToString } from 'selenium-webdriver/http';

@Injectable()
export class AdminPanelService {
  departmentSelect: string;
  originalApproverList: any;
  private employeeListResponseSubject = new BehaviorSubject<Array<UserInfo>>(null);
  employeeListResponseObservable$ = this.employeeListResponseSubject.asObservable();

  private managerListResponseSubject = new BehaviorSubject<Array<UserInfo>>(null);
  managerListResponseObservable$ = this.managerListResponseSubject.asObservable();

  private employeeAddResponseSubject = new BehaviorSubject<any>(null);
  employeeAddResponseObservable$ = this.employeeAddResponseSubject.asObservable();

  private employeeCheckDetailResponseSubject = new BehaviorSubject<any>(null);
  employeeCheckDetailResponseObservable$ = this.employeeCheckDetailResponseSubject.asObservable();

  private companySettingsResponseSubject = new BehaviorSubject<any>(null);
  companySettingsResponseObservable$ = this.companySettingsResponseSubject.asObservable();

  private companyReportsResponseSubject = new BehaviorSubject<CompanyReportResponse>(null);
  companyReportsResponseObservable$ = this.companyReportsResponseSubject.asObservable();

  private activeTravellerResponseSubject = new BehaviorSubject<any>(null);
  activeTravellerResponseObservable$ = this.activeTravellerResponseSubject.asObservable();

  private dashBoardResponseSubject = new BehaviorSubject<any>(null);
  dashBoardResponseObservable$ = this.dashBoardResponseSubject.asObservable();

  private companyApprovalResponseSubject = new BehaviorSubject<CompanyReportResponse>(null);
  companyApprovalResponseObservable$ = this.companyApprovalResponseSubject.asObservable();

  private responseErrorSubject = new BehaviorSubject<any>(null);
  responseErrorSubject$ = this.responseErrorSubject.asObservable();

  giftCardsEnabled = false;
  selectedqboCardvalue=''
  applyButton1 = true;
  gotoAppApproval = false;
  waitingForCompanySettings = false;
  from = '';
  originalTravellerResponse: any;
  public companySettings: CompanySettings;
  companySaveReport: CompanyReportResponse;
  public toAddEmployeeUserInfo: UserInfo;
  private origReportResponse: CompanyReportResponse;
  public origApprovalResponse: any;
  employeeList = [];
  managerList = [];
  all_tags = [];
  originalalltags = [];
  addEmployee = false;
  uatpAirline='';
  uatpDept:string;
  uatpPPaccount='';
  userChangedApprover = false;
  routeToQuickBooks=false
  showPenndingApprovals = false;
  addNewCard = false;
  bookingType = 'ALL';
  gotoDetail = false;
  employeePopOpen = false;
  subTabForBookingDetail = '';
  wdrdata = [];
  selectDateRange = 'today';
  filterDateType = 'all';
  navigateFrom = '';
  filterAppliedEmployeeName: string = '';
  filterTravellerName: string = '';
  filterEmployeeListBySearchName: string = '';
  filterManagerListBySearchName: string = '';
  filterAppliedEmployeeId: string = '';
  filterAppliedApprovalEmployeeId: string = '';
  filterAppliedDepartmentId: string = '';
  deptValue = '';
  empValue = '';
  dateValue = '';
  selectedCard = '';
  approvalFinished = false;
  refValueExist = '';
  previousReportDate: string = '';
  usersInApproval = [{ id: '', value: 'All Employees',name:"approval.AllEmployees" }];
  firstReportDate: Date;
  lastReportDate: Date;
  firstApprovalDate1: Date;
  lastApprovalDate1: Date;
  firstReportDate1: Date;
  lastReportDate1: Date;
  constructor(
    private http: GallopHttpClient,
    private http1: HttpClient,
    private userAccountService: UserAccountService,
    private cookieService: CookieService,
    public translateService: TranslateService,
    private toastr: ToastrService,
  ) {
    // this.processCompanySettingsRequest(userAccountService.getUserCompanyId());
  }

  public getComanyPolicies() {
    if (this.companySettings && this.companySettings.company) {
      return this.companySettings.company.policy;
    }
  }

  public getDepartments() {
    if (this.companySettings && this.companySettings.departments) {
      return this.companySettings.departments;
    }
  }

  public getDomains() {
    if (this.companySettings && this.companySettings.domainList) {
      return this.companySettings.domainList;
    }
  }
  emptyEmployeeList() {
    this.employeeListResponseSubject.next(null);
  }
  private companySettingsRequest(companyId: number): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
    .set('companyId', "" + companyId);
    return this.http.get(environment.apiCompanySettings , { params });
  }
  origDashBoardResponse: any;
  previousDate = '';
  previousDept = '';
  previousBookingType = '';
  public getChartData(endDate, startDate, companyId, department, bookingType, adminDateType, dateType, userid): Observable<any> {
    if (dateType === 'Currentyear' || dateType === 'Currentquarter') {
      dateType = 'CUSTOMDATE';
    }
    let startDateISO = DateUtils.getDayAsLocalISODateString(startDate);
    let endDateISO = DateUtils.getDayAsLocalISODateString(endDate);
    endDate = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(endDate, 'dd/MM/yyyy');
    startDate = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(startDate, 'dd/MM/yyyy');

    return this.http.get(environment.apiForGraphData + 'companyid=' + "" + companyId
      + '&startDate=' + encodeURIComponent(startDateISO)
      + '&endDate=' + encodeURIComponent(endDateISO)
      + '&userid=' + encodeURIComponent(userid)
    );
  }

  processGraphData(endDate, startDate, companyId, department, bookingType, adminDateType, dateType): any {
    let searchDates = startDate + 'T' + endDate;
    if ((this.previousDate === searchDates && this.previousDept === department) && (this.previousBookingType === bookingType)) {
      //   this.fetchDashBoardData(bookingType,adminDateType);
    } else {
      let userid = '';
      this.getChartData(endDate, startDate, companyId, department, bookingType, adminDateType, dateType, userid).subscribe(res => {
        if (res && res.success) {
          this.origDashBoardResponse = res;
          this.previousDate = startDate + 'T' + endDate;
          this.previousDept = department;
          this.previousBookingType = bookingType;
          // this.fetchDashBoardData(bookingType,adminDateType);
        } else {
          this.origDashBoardResponse = undefined;
          this.previousDate = '';
          this.previousDept = '';
          this.previousBookingType = '';
          // this.fetchDashBoardData(bookingType,adminDateType);
        }
      });
    }
  }
  saveCompanySettingsRequest(companyId: number, settings: any): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
    .set('companyId', "" + companyId)
      ;
    return this.http.post(environment.apiSaveCompanySettings, settings, { params });
  }

  addDepartmentRequest(companyId: number, departmentName: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('companyId', "" + companyId)
      .set('departmentName', "" + departmentName)
      ;
    return this.http.get(environment.apiAddCompanyDepartment, { params });
  }

  saveAdminSettingsRequest(companyId: number, settings: any): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userid)
      .set('sToken', sToken)
      .set('companyId', "" + companyId)
      ;
    return this.http.post(environment.apiSaveAdminSettings, settings, { params });
  }
  getRoutespringList():Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForUpdateRoutespring + '?userid=' + userid);
  }
  getRoutespringUpdateCreditCard(oldids,newids):Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForUpdateCreditCard + '?originalCardId=' + oldids+'&newCardId='+newids,{});
  }
  getQboCardUpload(oldids,newids):Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.post(environment.apiForUpdateQbocardDetails + '?card_id=' + newids+'&qbo_account_id='+oldids+ '&userid=' + userid,{});
  }
  getRoutespringUpdateCreditCardDropdown(body):Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.post(environment.apiForUpdateCreditCardDropdown,body);
  }
  updateTnplDetails(body):Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.post(environment.apiForUpdateTnplDetails,body);
  }
  getTnplDetails():Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForGetTnplDetails);
  }
  getRoutespringUpdateList(ids):Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForUpdateItemRoutespring + '?userid=' + userid+'&updateItemsIds='+ids,{});
  }
  getRoutespringUpdateItemList(id):Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForUpdateItemClickedRoutespring + '?userid=' + userid+'&updateItemId='+id,{});
  }
  getAcceptTermsAndCondition():Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForAcceptTerms,{});
  }
  getUatpList():Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForUatpList);
  }
  deleteUaptCard(airlinecode):Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForDeleteUatpCard  +'?airline='+airlinecode,{});
  }
  editUatpCard(body):Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForEditUatpCard ,body);
  }
  addUatpCard(body):Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForAddUatpCard,body);
  }
  getAirlineTourList():Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForAirlineTourList);
  }
  deleteAirlineTourCard(body):Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForDeleteAirlineTourCode,body);
  }
  editAirlineTourCard(body):Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForEditAirlineTourCode ,body);
  }
  addAirlineTourCard(body):Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForAddAirlineTourCode ,body);
  }
getOnboardingList():Observable<any>{
  const userid = this.userAccountService.getUserEmail();
  return this.http.get(environment.apiForOnboardingList + '?userid=' + userid);
}
updateOnboardingList(popupid):Observable<any>{
  const userid = this.userAccountService.getUserEmail();
  return this.http.get(environment.apiForUpdateOnboarding + '?userid=' + userid +"&popupId=" + popupid);
}
  getAllTags(companyid): Observable<any> {
    return this.http.get(environment.apiForGetAllTags + '?companyId=' + companyid);
  }
  getQuickBookdetails(uuid): Observable<any> {
    return this.http.get(environment.apiForQuickBooks + '?request-id=' + uuid);
  }
  getDisconnnectQuickBooks(): Observable<any> {
    return this.http.get(environment.apiForDisconnectQuickBooks);
  }
  getTagAllList(): Observable<any>{
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForTagsetsList)
  }
  getTagSet(companyid):Observable<any> {
    const sToken = this.userAccountService.getSToken();
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForTagsets + '?companyId=' + companyid)
  }
  getOktaDataRendering():Observable<any>{
    return this.http.get(environment.apiForOktaDataRendering);
  }
  getOktaDataSaving(obj):Observable<any>{
    return this.http.post(environment.apiForSAMLDataSaving+'?metadata_url='+obj.metadataUrl +'&provider='+obj.provider,{} )
  }
  getSamlDataDeactivate(obj):Observable<any>{
    return this.http.post(environment.apiForSAMLDataDeactivate +'?provider='+obj.provider,{} )
  }
  uploadSamlMetaData(file):Observable<any>{
    const uploadData = new FormData();
    uploadData.append('myFile', file, file.name);
    return this.http.post(environment.apiForSAMLMetaDataSaving ,uploadData,{} )
  }
  createTagSet(tagSetId,tagname): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.post(environment.apiForTagsetsSave + '?tagsetId=' + tagSetId+'&tagName='+tagname,{})

  }
  getFutureTripsOfTravellers(emails): Observable<any>{
   return this.http.get(environment.apiForFindingDuplicateBooking+"?travellers=" + emails);
  }
  updateCreateTagSetNAme(tagSetId,tagname, is_mandatory): Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.post(environment.apiForTagsetsUpdate + '?tagsetId=' + tagSetId+'&tagsetName='+tagname +'&mandatory='+is_mandatory,{})
  }
  updateISMandatoryOrNot(tagsetId,tagname,is_mandatory): Observable<any>{
    return this.updateCreateTagSetNAme(tagsetId,tagname,is_mandatory);
  }
  createTag(companyid, tagname): Observable<any> {
    return this.http.get(environment.apiForSaveTags + '?companyId=' + companyid + '&tagName=' + tagname);
  }
  UpdateTag(companyid, taglist): Observable<any> {
    return this.http.post(environment.apiForUpdateTags + '?companyId=' + companyid, taglist);
  }
  UpdateAllTag(companyid, taglist): Observable<any> {
    return this.http.post(environment.apiForUpdateAllTags + '?companyId=' + companyid, taglist);
  }
  UpdateTagFromTrip(companyid, taglist): Observable<any> {
    return this.http.post(environment.apiForSaveTagsAtTrip + '?companyId=' + companyid, taglist);
  }
  getVerficationCode(bankId): Observable<any> {
    return this.http.get(environment.apiForFundVerficationCodeCard + '?bank_account_id=' + bankId);
  }
  getTransferFunds(bankDetails): Observable<any> {
    return this.http.post(environment.apiForTransferFundCard, bankDetails);
  }
  updateCompanyDepartmentRequest(department: Department): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      
      .set('companyId', "" + department.companyId)
      ;
    return this.http.post(environment.apiUpdateCompanyDepartment, department, { params });

  }

  deleteCompanyDepartmentRequest(department: Department): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
     
      .set('companyId', "" + department.companyId)
      ;
    return this.http.post(environment.apiDeleteCompanyDepartment, department, { params });

  }
  saveCompanyPolicyRequest(policyName: string, policyObject: any, departmentId: string, policyId: string, isDefaultPolicy: boolean): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const companyId = this.userAccountService.getUserCompanyId();
    const params = new HttpParams()
      
      .set('companyId', "" + companyId)
      .set('departmentId', "" + departmentId)
      .set('policyId', "" + policyId)
      .set('policyName', policyName)
      .set('isDefault', "" + isDefaultPolicy)
      ;
    return this.http.post(environment.apiForAdminPolicyForUpdate, policyObject, { params });
  }

  addCompanyPolicyRequest(policyObject: any, departmentId: string, policyName: string, isDefaultPolicy): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const companyId = this.userAccountService.getUserCompanyId();
    const params = new HttpParams()
     
      .set('companyId', "" + companyId)
      .set('departmentId', "" + departmentId)
      .set('policyName', "" + policyName)
      .set('isDefault', "" + isDefaultPolicy)
      ;
    return this.http.post(environment.apiForAdminPolicyForAdd, policyObject, { params });
  }

  deleteCompanyPolicyRequest(departmentId: string, policyId: string, replacingPolicyId: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const companyId = this.userAccountService.getUserCompanyId();
    const params = new HttpParams()
      
      .set('companyId', "" + companyId)
      .set('departmentId', "" + departmentId)
      .set('policyId', policyId)
      .set('replacingPolicyId', replacingPolicyId)
      ;
    return this.http.get(environment.apiForAdminPolicyForDelete, { params });
  }
  resetApprovers(item): Observable<any> {
    return this.http.post(environment.apiForresetApprovers, item);
  }
  uploadCompanyProfileImageRequest(companyId: number, image: any): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
     
      .set('companyId', "" + companyId)
      ;
    const uploadData = new FormData();
    uploadData.append('myFile', image, image.name);
    return this.http.post(environment.apiUploadCompanyImage, uploadData, { params });
  }
  uploadFileRequest(file: any): Observable<any> {
    const uploadData = new FormData();
    uploadData.append('myFile', file, file.name);
    return this.http.post(environment.apiUploadReconcileFile, uploadData);
  }

  private checkEmployeeDetailRequest(employeeEmailId: string, companyId: number, flowType: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
     
      .set('employeeEmailId', employeeEmailId)
      .set('addManager', "" + (flowType == 'addManager' || flowType == 'addAdmin' || flowType == 'changeApprover'))
      .set('companyId', "" + companyId);
    return this.http.get(environment.apiCompanyEmployeeCheckDetail, { params });
  }

  private listEmployeesRequest(companyId: number): Observable<any> {
    this.applyButton1 = true;
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.get(environment.apiCompanyEmployeeList + '?companyId=' + companyId);
  }
  public getCardStatus(): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForCardStatus + '?userid=' + userid);

  }
  public getTransactioReport(startDate, endDate): Observable<any> {
    let url = environment.apiForCardTransaction
      + '?companyid=' + this.userAccountService.getUserCompanyId()
      + '&startDate=' + encodeURIComponent(startDate)
      + '&endDate=' + encodeURIComponent(endDate)
    url = this.addingSomeGallopVariables(url);
    return this.http1.get(url, { responseType: 'text',headers: new HttpHeaders().append("Content-Type", "application/json").append('Authorization','Bearer'+' '+this.userAccountService.getUserEmail()+':'+this.userAccountService.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'))});

  }
  private addingSomeGallopVariables(url) {
    if (url.indexOf('?') === -1) {
      url = url + '?';
    }
    url += '&' + Constants.PARAM_APP_VERSION + '=' + environment.appVersion;
    url += '&' + Constants.PARAM_APPSTORE_VERSION + '=' + environment.appStoreVersionName;
    url += '&' + Constants.PARAM_APP_BUILD_NUMBER + '=' + environment.appStoreBuildNumber;
    url += '&' + Constants.PARAM_APP_PLATFORM + '=' + environment.platform;
  //  url += '&' + Constants.PARAM_RS_CLIENT_COOKIE + '=' + this.cookieService.get('RS-CLIENT');
    url += '&' + Constants.PARAM_OTA_BUILD_NUMBER + '=' + environment.otaBuildNumber;
    return url;
  }
  public getDeleteReceipt(authid): Observable<any> {
    const params = new HttpParams()
      .set('auth_id', authid);
    return this.http.post(environment.apiForDeleteTransactionReceipt, params);
  }
  public openTransactiondetailPopup(referenceId): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    let url = environment.apiForTransactionCardDetails  + '?referenceid=' + referenceId;
    url = this.addingSomeGallopVariables(url)
    return this.http1.get(url, { responseType: 'text',headers: new HttpHeaders().append("Content-Type", "application/json").append('Authorization','Bearer'+' '+this.userAccountService.getUserEmail()+':'+this.userAccountService.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'))});

  }
  public getUpdateQboExpense(expenseid, type): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    let expesneCatParamName = 'qbo_exp_category_id_for_hotels';
    if (type === 'flight') {
      expesneCatParamName = 'qbo_exp_category_id_for_flights';
    }
    return this.http.post(environment.apiForUpdateQbocardExpenseSave+'?' + expesneCatParamName + '=' + expenseid  ,{});
  }
  public getUpdateExpense(authid, expenseid): Observable<any> {
    let params = new HttpParams();
    if (expenseid !== "") {
      params = new HttpParams()
        .set('auth_id', authid)
        .set('expense_category_id', expenseid)
        ;
    } else {
      params = new HttpParams()
        .set('auth_id', authid);
    }
    return this.http.post(environment.apiForUpdateTransactionExpense, params);
  }
  public getQboSync(authids): Observable<any> {

    return this.http.post(environment.apiForTransactionSync, authids);
  }
  public getQboStatus(statusid): Observable<any> {

    return this.http.get(environment.apiForTransactionStatusSync + '?bookingStatusId=' + statusid);
  }
  public getUserUpdateTransactionList(): Observable<any> {
    return this.http1.get(environment.apiForUserTransactionSave, { responseType: 'text',headers: new HttpHeaders().append("Content-Type", "application/json").append('Authorization','Bearer'+' '+this.userAccountService.getUserEmail()+':'+this.userAccountService.getSToken()).append(Constants.PARAM_RS_CLIENT_COOKIE,this.cookieService.get('RS-CLIENT'))})
  }
  public uploadReceipt(authid, file): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const params = new HttpParams()
      .set('auth_id', authid)
      .set('userid', userid)
      ;
    const uploadData = new FormData();
    uploadData.append('myFile', file, file.name);
    return this.http.post(environment.apiForUpdateTransactionReceipt, uploadData, { params });
  }
  public getUpdateMemo(authid, memo): Observable<any> {
    const params = new HttpParams()
      .set('auth_id', authid)
      .set('memo', memo)
      ;
    return this.http.post(environment.apiForUpdateTransactionMemo, params);
  }
  public getRequestCard(): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForRequestCard + '?userid=' + userid);

  }
  public getUploadedDocumentsForPAylater(): Observable<any> {
    return this.http.get(environment.apiForGetUploadDocumentsForPayLater);

  }

  public uploadedDocumentsForPaylater(file): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const stoken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('fileName', file.name)
      ;
  const uploadData = new FormData();
  uploadData.append('myFile', file, file.name);
    return this.http.post(environment.apiForUploadDocumentsForPayLater,uploadData,{ params});

  }
  public getUserAssignedCards(): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.getUseraAsignedCards + '?userid=' + userid);
  }
  public getrevealCard(cardId): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForRevealCard + '?userid=' + userid + '&' + 'issuing_card_id=' + cardId);

  }
  public getLiveBalance(): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    return this.http.get(environment.apiForLiveBalance + '?userid=' + userid);

  }
  public addNewCardTransaction(cardDetails): Observable<any> {

    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForAddCard + '?userid=' + userid, cardDetails);
  }
  public updateCardStatus(cardId, status, reason?): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    let msg = '';
    if (reason && reason !== '') {
      return this.http.post(environment.apiForUpdateCard + '?userid=' + userid + '&' + 'issuing_card_id=' + cardId + '&status=' + status + '&cancellation_reason=' + reason, { msg });
    } else {
      return this.http.post(environment.apiForUpdateCard + '?userid=' + userid + '&' + 'issuing_card_id=' + cardId + '&status=' + status, { msg });
    }
  }
  public updateCardDetails(cardDetails): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    return this.http.post(environment.apiForUpdateCardDetails + '?userid=' + userid, cardDetails);
  }
  public getPaymentOptions(): Observable<any>{
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
    return this.http.get(environment.apiForPaymentOptions , { params });
  }
  private addEmployeeRequest(companyId: number, employeeInfo: AddEmployeeDTO, mode: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
     
      .set('companyId', '' + companyId)
      .set('mode', mode)
      ;
    return this.http.post(environment.apiCompanyAddEmployee, employeeInfo, { params });
  }

  private inviteEmployeeRequest(companyId: number, employeeId: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('companyId', '' + companyId)
      .set('employeeId', employeeId)
      ;
    return this.http.get(environment.apiCompanyInviteEmployee, { params });
  }
  private deleteEmployeeRequest(employeeEmailId): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const deleteUrl = environment.apiCompanyDeleteEmployee
      
      + '?employeeEmailId=' + encodeURIComponent(employeeEmailId)
      ;
    return this.http.get(deleteUrl);
  }


  private companyReportRequest(companyId: number, startDate: string, endDate: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.get(environment.apiCompanyReports +
      + "?companyid=" + companyId
      + "&startDate=" + encodeURIComponent(startDate)
      + "&endDate=" + encodeURIComponent(endDate)
    );
  }
  public companyActiveTravellerRequest(companyId: number, startDate: string, endDate: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.get(environment.apiForActiveTravellers 
      + "?companyid=" + companyId
      + "&startDate=" + encodeURIComponent(startDate)
      + "&endDate=" + encodeURIComponent(endDate)
    );
  }
  public fetchLcc(): Observable<any> {
    return this.http.get(environment.apiForLcc);
  }
  public companyApprovalRequest(companyId: number, startDate: string, endDate: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.get(environment.apiCompanyApprovalsList 
      + "?companyid=" + companyId
      + "&userid=" + userid
      + "&startDate=" + encodeURIComponent(startDate)
      + "&endDate=" + encodeURIComponent(endDate)
    );
  }

  getApprovalDetail(userEmail, ticketid, tripid, transacId): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    return this.http.get(environment.apiCompanyApprovalDetail 
      + "?ticketid=" + encodeURIComponent(ticketid)
      + "&tripid=" + encodeURIComponent(tripid)
      + "&userEmail=" + encodeURIComponent(userEmail)
      + "&transactionid=" + encodeURIComponent(transacId)
    );
  }

  getApprovalDetailByTripSessionId(userEmail, ticketid, tripid, transacId,tripSessionId): any {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    if(tripSessionId){
      return this.http.get(environment.apiCompanyTripSessionApprovalDetail 
      
        +"?ticketid=" + encodeURIComponent(ticketid)
        + "&tripid=" + encodeURIComponent(tripid)
        + "&userEmail=" + encodeURIComponent(userEmail)
        + "&transactionid=" + encodeURIComponent(transacId)
        + "&tripSessionId=" + encodeURIComponent(tripSessionId)
      );
    }else{
      return this.http.get(environment.apiCompanyTripSessionApprovalDetail 
       
        +"?ticketid=" + encodeURIComponent(ticketid)
        + "&tripid=" + encodeURIComponent(tripid)
        + "&userEmail=" + encodeURIComponent(userEmail)
        + "&transactionid=" + encodeURIComponent(transacId)
      );
    }
  }
  requestApprovalReviewAction(userEmail, ticketid, tripid, transacId,tripSessionId,
     reviewAction, note, bookingStatusId, noteForTraveler,selectedTag?): Observable<any> {
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    let adminEmail = userId;
    var revalidateTestCase = "";
    var bookingTestCase = "";
    var reviewActionRequestURL = environment.apiCompanyApprovalReviewAction;
    if(tripSessionId && (""+tripSessionId).trim().length >= 9){
      reviewActionRequestURL = environment.apiCompanyTripSessionApprovalReviewAction;
    }
    if (selectedTag && selectedTag.length > 0) {
      if (this.refValueExist !== '') {
        return this.http.post(reviewActionRequestURL 
          + "?ticketid=" + encodeURIComponent(ticketid)
          + "&tripid=" + encodeURIComponent(tripid)
          + "&adminEmail=" + encodeURIComponent(adminEmail)
          + "&userEmail=" + encodeURIComponent(userEmail)
          + "&reviewAction=" + encodeURIComponent(reviewAction)
          + "&transactionid=" + encodeURIComponent(transacId)
          + "&tripSessionId=" + encodeURIComponent(tripSessionId)
          + "&revalidateTestCase=" + encodeURIComponent(revalidateTestCase)
          + "&bookingTestCase=" + encodeURIComponent(bookingTestCase)
          + "&bookingStatusId=" + encodeURIComponent(bookingStatusId)
          + "&ref=" + encodeURIComponent(this.refValueExist)
          + "&noteToTraveler=" + encodeURIComponent(noteForTraveler)
          + "&projectTagId=" + encodeURIComponent(selectedTag.toString())

          , note);
      } else {
        return this.http.post(reviewActionRequestURL 
          + "?ticketid=" + encodeURIComponent(ticketid)
          + "&tripid=" + encodeURIComponent(tripid)
          + "&adminEmail=" + encodeURIComponent(adminEmail)
          + "&userEmail=" + encodeURIComponent(userEmail)
          + "&reviewAction=" + encodeURIComponent(reviewAction)
          + "&transactionid=" + encodeURIComponent(transacId)
          + "&tripSessionId=" + encodeURIComponent(tripSessionId)
          + "&revalidateTestCase=" + encodeURIComponent(revalidateTestCase)
          + "&bookingTestCase=" + encodeURIComponent(bookingTestCase)
          + "&bookingStatusId=" + encodeURIComponent(bookingStatusId)
          + "&noteToTraveler=" + encodeURIComponent(noteForTraveler)
          + "&projectTagId=" + encodeURIComponent(selectedTag.toString())
          , note);
      }
    } else {
      if (this.refValueExist !== '') {
        return this.http.post(reviewActionRequestURL 
          + "?ticketid=" + encodeURIComponent(ticketid)
          + "&tripid=" + encodeURIComponent(tripid)
          + "&adminEmail=" + encodeURIComponent(adminEmail)
          + "&userEmail=" + encodeURIComponent(userEmail)
          + "&reviewAction=" + encodeURIComponent(reviewAction)
          + "&transactionid=" + encodeURIComponent(transacId)
          + "&tripSessionId=" + encodeURIComponent(tripSessionId)
          + "&revalidateTestCase=" + encodeURIComponent(revalidateTestCase)
          + "&bookingTestCase=" + encodeURIComponent(bookingTestCase)
          + "&bookingStatusId=" + encodeURIComponent(bookingStatusId)
          + "&noteToTraveler=" + encodeURIComponent(noteForTraveler)
          + "&ref=" + encodeURIComponent(this.refValueExist)

          , note);
      } else {
        return this.http.post(reviewActionRequestURL 
          + "?ticketid=" + encodeURIComponent(ticketid)
          + "&tripid=" + encodeURIComponent(tripid)
          + "&adminEmail=" + encodeURIComponent(adminEmail)
          + "&userEmail=" + encodeURIComponent(userEmail)
          + "&reviewAction=" + encodeURIComponent(reviewAction)
          + "&transactionid=" + encodeURIComponent(transacId)
          + "&tripSessionId=" + encodeURIComponent(tripSessionId)
          + "&revalidateTestCase=" + encodeURIComponent(revalidateTestCase)
          + "&bookingTestCase=" + encodeURIComponent(bookingTestCase)
          + "&noteToTraveler=" + encodeURIComponent(noteForTraveler)
          + "&bookingStatusId=" + encodeURIComponent(bookingStatusId)
          , note);
      }
    }
  }

  public downloadReport(reportdata: any, file: string): Observable<any> {
    let body = {
      filename: file,
      data: reportdata
    };
    return this.http.postBlob(environment.apiDownloadCompanyReport, body, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  requestSaveCardInfo(token: string, gToken: string): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const companyEmailId = this.companySettings.company.emailId;
    const sToken = this.userAccountService.getSToken();
    const body = 'companyEmailId=' + encodeURIComponent(companyEmailId)
      + '&token=' + token
      + '&gToken=' + gToken;
    return this.http.post(environment.apiUserAccountUrlforSaveCard 
      ,body, {headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded')} );
  }

  requestDeleteCard(cardId: string, defaultCardId: string, map?): Observable<any> {
    const userid = this.userAccountService.getUserEmail();
    const companyEmailId = this.companySettings.company.emailId;
    let map2;
    if (!map && Object.keys(map).length === 0) {
      map = {}
    } else {
      map2 = this.returnobject(map);
      map2 = JSON.stringify(map2);
    }
    console.log("map is here ", map2)

    const sToken = this.userAccountService.getSToken();
    return this.http.post(environment.apiDeleteCompanyCard + '?'
      + '&defaultCardId=' + defaultCardId
      + '&cardToDelete=' + cardId, map2);
  }
  returnobject(inputMap) {
    let obj = {};

    inputMap.forEach(function (value, key) {
      obj[key] = value
    });

    return obj;
  }
  getMessages(userEmailID): Observable<any> {
    let emailIds = userEmailID[0].email;
    if (userEmailID.length > 1) {
      for (let i = 1; i < userEmailID.length; i++) {
        emailIds = emailIds + ',' + userEmailID[i].email;
      }
    }
    return this.http.get(environment.apiForGetMessage + '?to=' + encodeURIComponent(emailIds));
  }
  getSentMessages(userEmailID, message): Observable<any> {
    let emailIds = userEmailID[0].email;
    if (userEmailID.length > 1) {
      for (let i = 1; i < userEmailID.length; i++) {
        emailIds = emailIds + ',' + userEmailID[i].email;
      }
    }
    return this.http.post(environment.apiForSentMessage, { 'to': emailIds, 'message': message });
  }

  private previousReportColumns: Array<string> = [];
  fetchActiveTraveler(companyId: number, startDate: string, endDate: string) {
    let searchDates = startDate + 'T' + endDate;
    if (this.previousReportDate === searchDates) {
      this.filterActiveTraveler1();
      return;
    }

    this.companyActiveTravellerRequest(companyId, startDate, endDate).subscribe(res => {
      this.originalTravellerResponse = undefined;
      if (res && res.success) {
        this.originalTravellerResponse = deserialize(res.data, CompanyReportResponse);
        this.filterActiveTraveler1();
      } else if (res && res.error_message) {
        this.originalTravellerResponse = undefined;
        this.filterActiveTraveler1();
        //this.responseErrorSubject.next(res.error_message);
      } else {
        //this.responseErrorSubject.next("Apologies! something went wrong, we couldn't report data. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    });

  }
  fetchCompanyReports(companyId: number, startDate: string, endDate: string, selectedColumns: Array<string>) {
    if (!this.previousReportColumns || this.previousReportColumns.toString() !== selectedColumns.toString()) {
      let settings = { report_columns: selectedColumns };
      this.saveAdminSettingsRequest(companyId, settings).subscribe(res => {
        if (res && res.success) {
          this.companySettings.columnList = [...res.data];
        }
      });
      if (this.origReportResponse) this.origReportResponse.columns = selectedColumns;
      this.previousReportColumns = selectedColumns;
    }
    let searchDates = startDate + 'T' + endDate;
    if (this.previousReportDate === searchDates) {
      this.filterCompanyReport();
      return;
    }
    this.previousReportDate = startDate + 'T' + endDate;
    this.companyReportRequest(companyId, startDate, endDate).subscribe(res => {
      if (res && res.success) {
        this.origReportResponse = deserialize(res.data, CompanyReportResponse);
        // this.companyReportsResponseSubject.next(this.origReportResponse);
        this.filterCompanyReport();
      } else if (res && res.error_message) {
        this.origReportResponse = undefined;
        this.filterCompanyReport();
        //this.responseErrorSubject.next(res.error_message);
      } else {
        //this.responseErrorSubject.next("Apologies! something went wrong, we couldn't report data. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    });
  }

  private previousApprovalsDates = '';
  fetchCompanyApprovals(companyId: number, startDate: string, endDate: string, item) {
    this.companyApprovalRequest(companyId, startDate, endDate).subscribe(res => {
      if (res) {
        if (res.success) {
          this.origApprovalResponse = deserialize(res.data, CompanyReportResponse);
        } else {
          this.origApprovalResponse = new CompanyReportResponse();
        }
        this.filterApprovalByEmployee(item);
      } else {
        this.responseErrorSubject.next("Apologies! something went wrong, we could'nt report data. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    });
  }

  filterApprovalByEmployee(type) {
    if (!this.origApprovalResponse) {
      this.companyApprovalResponseSubject.next(this.origApprovalResponse);
    } else {
      let filteredReport = JSON.parse(JSON.stringify(this.origApprovalResponse));
      let employeeEmail = this.filterAppliedApprovalEmployeeId;
      if (type === 'all') {
        // if(employeeEmail && employeeEmail.trim().length > 0){
        //filteredReport.pendingApprovals = filteredReport.pendingApprovals.filter(booking => {
        //   return booking.userid ===  employeeEmail;
        //});
        if (employeeEmail && employeeEmail.trim().length > 0) {
          filteredReport.approvalHistory = filteredReport.approvalHistory.filter(booking => {
            return booking.userid === employeeEmail;
          });
        }
      } else {
        if (employeeEmail && employeeEmail.trim().length > 0) {
          filteredReport.approvalHistory = filteredReport.approvalHistory.filter(booking => {
            return booking.userid === employeeEmail;
          });

        }
      }
      this.companyApprovalResponseSubject.next(filteredReport);
    }
  }
  removeDataAfterLogging() {
    this.companyApprovalResponseSubject.next(null);
    this.companyReportsResponseSubject.next(null);
    this.activeTravellerResponseSubject.next(null);
  }
  filterCompanyReport() {
    if (!this.origReportResponse) {
      this.companyReportsResponseSubject.next(this.origReportResponse);
    } else {
      let filteredReport = JSON.parse(JSON.stringify(this.origReportResponse));
      filteredReport = this.filterCompanyReportByDepartment(filteredReport, this.filterAppliedDepartmentId);
      filteredReport = this.filterCompanyReportByEmployeeEmail(filteredReport, this.filterAppliedEmployeeId);
      filteredReport = this.filterCompanyReportByEmployeeName(filteredReport, this.filterAppliedEmployeeName);
      filteredReport = this.filterCompanyReportByBookingType(filteredReport, this.bookingType);
      this.calculateTotal(filteredReport);
      this.companyReportsResponseSubject.next(filteredReport);
    }
  }

  filterActiveTraveler1() {
    if (!this.originalTravellerResponse) {
      this.activeTravellerResponseSubject.next(this.originalTravellerResponse);
    } else {
      let filteredReport = JSON.parse(JSON.stringify(this.originalTravellerResponse));
      if (this.filterTravellerName !== '' && this.filterTravellerName.toLowerCase().search('@') !== -1) {
        filteredReport = this.filterCompanyReportByEmployeeEmail(filteredReport, this.filterTravellerName);
      } else if (this.filterTravellerName !== '') {
        filteredReport = this.filterCompanyReportByEmployeeName(filteredReport, this.filterTravellerName);
      }
      filteredReport = this.filterTravellerByFilterSelected(filteredReport, this.filterDateType);
      this.activeTravellerResponseSubject.next(filteredReport);
    }
  }
  filterTRavellersByDate(response, startDate, endDate) {
    let filteredReport = JSON.parse(JSON.stringify(response));
    filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
      let travelStartDate = new Date(CommonUtils.getTravelDate(booking));
      let travelEndDate = null;
      if (booking.type === 'flight') {
        if (booking.option.flight_option.legs.length > 1) {
          travelEndDate = new Date(booking.option.flight_option.legs[booking.option.flight_option.legs.length - 1].hops[0].starts);
        }
      }
      if (booking.type === 'hotel') {
        travelEndDate = new Date(booking.option.hotel_option.checkOutDate);
      }
      if (booking.type === 'cars') {
        travelEndDate = new Date(booking.option.car_option.dropOffDate);
      }
      if (travelStartDate >= startDate && travelStartDate <= endDate) {
        return true;
      }
      if (travelEndDate >= startDate && travelEndDate <= endDate) {
        return true;
      }
      if (booking.type === 'hotel') {
        if (travelStartDate <= startDate && travelEndDate >= endDate) {
          return true;
        }
      }
      return false;
    });
    return filteredReport;
  }

  filterTravellerByFilterSelected(response, dateOption) {
    let startDate = new Date();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date();
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(0);
    switch (dateOption) {
      case '7Days':
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 7);
        break;
      case '30Days':
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 30);
        break;
      case 'all':
        startDate.setDate(startDate.getDate());
        endDate.setDate(endDate.getDate() + 180);
        break;
      case '':
        let firstDate = new Date(this.firstReportDate);
        firstDate.setHours(23);
        firstDate.setMinutes(59);
        firstDate.setSeconds(59);
        firstDate.setMilliseconds(0);
        let lastDate = new Date(this.lastReportDate);
        lastDate.setHours(23);
        lastDate.setMinutes(59);
        lastDate.setSeconds(59);
        lastDate.setMilliseconds(0);
        startDate = firstDate;
        endDate = lastDate;
        break;
      case 'today':

        break;
    }
    return this.filterTRavellersByDate(response, startDate, endDate);
  }


  //   filterCompanyReportByTraveelDate(response,date){
  //     let filteredReport = JSON.parse(JSON.stringify(response));
  //   if(date==='today'){
  //      let date1= new Date();
  //       filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
  //         let date2 = CommonUtils.getTraveleingDate(booking);
  //         date2 = new Date(date2);
  //         return (date2.getDate() ===  date1.getDate());
  //      });
  //      return filteredReport;
  //   }else  if(date==='7Days'){
  //     let date1= new Date();
  //     date1.setDate(date1.getDate() +6);
  //      filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
  //        let date2 = CommonUtils.getTraveleingDate(booking);
  //        date2 = new Date(date2);
  //  return (date2 <= date1);
  //     });
  //     return filteredReport;
  //  }else if(date==='30Days'){
  //   let date1= new Date();
  //   date1.setDate(date1.getDate() +29);
  //    filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
  //     let date2 = CommonUtils.getTraveleingDate(booking);
  //     date2 = new Date(date2);
  // return (date2 <= date1);
  //   });
  //   return filteredReport;
  // }
  //   return filteredReport;
  //   }



  private calculateTotal(reportResponseObj: CompanyReportResponse) {
    let totalAmount = 0;
    for (let bookedOption of reportResponseObj.bookingList) {
      if (bookedOption.type === 'flight') {
        totalAmount = totalAmount + Number.parseFloat(bookedOption.option.flight_option.price);
      } else if (bookedOption.type === 'hotel') {
        totalAmount = totalAmount + bookedOption.option.hotel_option.minPrice;
      }

    }
    reportResponseObj.totalAmount = totalAmount;
  }
  filterCompanyReportByEmployeeEmail(reportResponseObj: CompanyReportResponse, employeeEmail: string): CompanyReportResponse {
    // this.filterAppliedEmployeeId = employeeEmail;
    let filteredReport = JSON.parse(JSON.stringify(reportResponseObj));
    if (employeeEmail && (employeeEmail.trim().length > 0 && this.navigateFrom !== 'message')) {
      let val;
      val = filteredReport.bookingList.find(booking => booking.userid === employeeEmail);
      if (!val) {
        employeeEmail = "";
      }
    }
    if (employeeEmail && employeeEmail.trim().length > 0) {
      filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
        let userid;
        if (booking.primaryTraveller && booking.primaryTraveller.userName) {
          userid = booking.primaryTraveller.userId;
        } else {
          userid = booking.userid;
        }
        return userid === employeeEmail;
      });
    }
    return filteredReport;
    // if(this.filterAppliedEmployeeName.trim().length > 0){
    //   this.filterCompanyReportByEmployeeName(filteredReport,this.filterAppliedEmployeeName);
    // }else{
    //   this.companyReportsResponseSubject.next(filteredReport);
    // }

  }

  filterCompanyReportByEmployeeName(reportResponseObj: CompanyReportResponse, nameString: string) {
    // this.filterAppliedEmployeeName = nameString;

    if (nameString && nameString.trim().length > 0) {
      let filteredReport = JSON.parse(JSON.stringify(reportResponseObj));
      filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
        let userNames;
        if (booking.primaryTraveller && booking.primaryTraveller.userName) {
          userNames = booking.primaryTraveller.userName.toLowerCase();
        } else {
          let userInfo: UserInfoBasic = filteredReport.users[booking.userid];
          userNames = userInfo.userName.toLowerCase();
        }
        let returnValue = false;
        nameString = nameString.trim().toLowerCase().replace(/[ ]+/g, ' ');
        let userNameParts = nameString.split(' ');
        for (let namePart of userNameParts) {
          returnValue = returnValue || (userNames.indexOf(namePart) > -1);
        }
        return returnValue;
      });
      return filteredReport;
    }
    // this.companyReportsResponseSubject.next(filteredReport);
    return reportResponseObj;
  }
  filterCompanyReportByBookingType(reportResponseObj: CompanyReportResponse, nameString: string) {
    // this.filterAppliedEmployeeName = nameString;

    if (nameString && nameString.trim().length > 0) {
      if (nameString !== 'ALL') {
        let filteredReport = JSON.parse(JSON.stringify(reportResponseObj));
        filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
          return booking.type === nameString
        });
        return filteredReport;
      } else {
        return reportResponseObj;
      }
    }
    // this.companyReportsResponseSubject.next(filteredReport);
    return reportResponseObj;
  }

  filterCompanyReportByDepartment(reportResponseObj: CompanyReportResponse, departmentId: string) {
    // this.filterAppliedDepartmentId = departmentId;

    if (departmentId && departmentId.trim().length > 0 && departmentId !== '0') {
      let filteredReport: CompanyReportResponse = JSON.parse(JSON.stringify(reportResponseObj));
      let departmentUserIds = [];
      for (let userObj in filteredReport.users) {
        if (filteredReport.users[userObj].departmentId == departmentId) {
          departmentUserIds.push(filteredReport.users[userObj].userId);
        }
      }
      filteredReport.bookingList = filteredReport.bookingList.filter(booking => {
        return departmentUserIds.some(x => x === booking.userid)
        // return booking.userid in departmentUserIds;
      });
      return filteredReport;
    } else {
      return reportResponseObj;
    }
    // if(this.filterAppliedEmployeeName.trim().length > 0){
    //   this.filterCompanyReportByEmployeeName(filteredReport,this.filterAppliedEmployeeName);
    // }else{
    //   this.companyReportsResponseSubject.next(filteredReport);
    // }
  }
  filterByEmployeeName(nameString: string) {
    this.filterEmployeeListBySearchName = nameString;
    let filteredReport = [...this.employeeList];
    if (nameString && nameString.trim().length > 0) {
      filteredReport = filteredReport.filter(item => {
        let userInfo = item;
        return (userInfo.firstName && userInfo.firstName.toLowerCase().startsWith(nameString.toLowerCase()))
          || (userInfo.lastName && userInfo.lastName.toLowerCase().startsWith(nameString.toLowerCase()));
      });
    }
    this.employeeListResponseSubject.next(filteredReport);
  }
  filterByEmployeeNameAndEmail(nameString: string) {
    this.filterEmployeeListBySearchName = nameString;
    let filteredReport = [...this.employeeList];
    if (nameString && nameString.trim().length > 0 && (nameString.search('@') !== -1)) {
      filteredReport = filteredReport.filter(item => {
        let userInfo = item;
        return ((userInfo.email.toLowerCase().startsWith(nameString.toLowerCase())));
      });
    } else if (nameString && nameString.trim().length > 0) {
      filteredReport = filteredReport.filter(item => {
        let userInfo = item;
        return (userInfo.firstName && userInfo.firstName.toLowerCase().startsWith(nameString.toLowerCase()))
          || (userInfo.lastName && userInfo.lastName.toLowerCase().startsWith(nameString.toLowerCase()));
      });
    }
    return filteredReport;
  }

  filterByManagerName(nameString: string) {
    this.filterManagerListBySearchName = nameString;
    let filteredReport = [...this.managerList];
    if (nameString && nameString.trim().length > 0) {
      filteredReport = filteredReport.filter(item => {
        let userInfo = item;
        return userInfo.firstName.toLowerCase().startsWith(nameString.toLowerCase())
          || userInfo.lastName.toLowerCase().startsWith(nameString.toLowerCase());
      });
    }
    this.managerListResponseSubject.next(filteredReport);
  }
  fetchEmployeesList(companyId: number) {
    this.listEmployeesRequest(companyId).subscribe(res => {
      if (res && res.success) {
        this.applyButton1 = false;
        this.employeeList = deserialize(res.data).employees;
        this.managerList = deserialize(res.data).managers;
        if (this.filterEmployeeListBySearchName) {
          this.filterByEmployeeName(this.filterEmployeeListBySearchName);
        } else {
          this.employeeListResponseSubject.next(this.employeeList);
        }

        if (this.filterManagerListBySearchName) {
          this.filterByManagerName(this.filterManagerListBySearchName);
        } else {
          this.managerListResponseSubject.next(this.managerList);
        }
      } else if (res && res.error_message) {
        this.toastr.error(res.error_message);
      } else {
        this.toastr.error("Apologies! something went wrong, we could'nt retrive employees list. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    })
  }

  getManagersList() {
    if (this.managerList)
      return this.managerList;
    else
      return [];
  }

  getNumberOfEmployeesWithPolicy(policyId) {
    let selectedPolicy: DepartmentPolicy = this.companySettings.departmentPolicies.find(policy => policy.policyId == policyId);
    let numberOfAffectedEmployees = 0;
    let deptsWithThisPolicyAsDefault = this.companySettings.departments.filter(dept => dept.defaultPolicyId == policyId);

    let deptEmployeeCountMap = {};
    let deptsIdsWithThisPolicyAsDefault = [];
    for (let deptartment of deptsWithThisPolicyAsDefault) {
      deptsIdsWithThisPolicyAsDefault.push(deptartment.departmentId);
      for (let userInfo of this.employeeList) {
        if (userInfo.employeeInfo.departmentId == deptartment.departmentId) {
          let prevCount = 0;
          if (deptEmployeeCountMap[deptartment.departmentId]) {
            prevCount = deptEmployeeCountMap[deptartment.departmentId];
          }
          prevCount++;
          deptEmployeeCountMap[deptartment.departmentId] = prevCount;
        }
      }
    }
    if (selectedPolicy) {
      for (let userInfo of this.employeeList) {
        if (userInfo.employeeInfo && userInfo.employeeInfo.policyId && userInfo.employeeInfo.policyId == selectedPolicy.policyId) {
          numberOfAffectedEmployees++;
        } else if (userInfo.employeeInfo.departmentId && deptEmployeeCountMap[userInfo.employeeInfo.departmentId]) {
          numberOfAffectedEmployees++;
        } else if (selectedPolicy.default) {
          numberOfAffectedEmployees++;
        }
      }
    }
    return numberOfAffectedEmployees;
  }
  processAddEmployee(companyId: number, employeeInfo: AddEmployeeDTO, mode: string) {
    this.addEmployeeRequest(companyId, employeeInfo, mode).subscribe(res => {
      if (res && res.success) {
        let employee: UserInfo = deserialize(res.data, UserInfo);
        this.employeeAddResponseSubject.next(employee);
        this.employeeAddResponseSubject.next(null);
        if (mode === 'edit') {
          this.processCompanySettingsRequest(this.userAccountService.getUserCompanyId());
        }
        if (mode === 'add') {
          this.toastr.success(this.translateService.instant("employee.EmployeeAddedSuccessfully"))
        } else if (mode === 'edit') {
          this.toastr.success(this.translateService.instant("employee.EmployeeUpdatedSuccessfully"))
        }

      } else if (res && res.error_message) {
        // this.responseErrorSubject.next(res.error_message);
        this.toastr.error(res.error_message);
        this.employeeAddResponseSubject.next(null);
      } else {
        this.toastr.error(this.translateService.instant('setting.Apologiessomethingwentwrongwecouldntprocessrequest.Pleasetryagainlaterorcontactsupport'));
        this.employeeAddResponseSubject.next(null);
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
          this.employeeAddResponseSubject.next(null);
        }, 100);
      }
    })

  }

  processInviteEmployee(companyId: number, employeeId: string) {
    this.inviteEmployeeRequest(companyId, employeeId).subscribe(res => {
      if (res && res.success) {
        // let employee: UserInfo = deserialize(res.data, UserInfo);
        // this.employeeAddResponseSubject.next(employee);
        // window.alert("Invite sent Successfully");
        this.toastr.success("Invite sent Successfully");
      } else if (res && res.error_message) {
        this.responseErrorSubject.next(res.error_message);
      } else {
        this.responseErrorSubject.next("Apologies! something went wrong, we could'nt retrive employees list. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    })
  }

  processDeleteEmployee(employeeEmail) {
    this.deleteEmployeeRequest(employeeEmail).subscribe(res => {
      if (res && res.success) {
        // let employee: UserInfo = deserialize(res.data, UserInfo);
        // this.employeeAddResponseSubject.next(employee);
        this.toastr.success(this.translateService.instant("employee.EmployeeDeletedSuccessfully"));
        this.fetchEmployeesList(this.userAccountService.getUserCompanyId());
        this.processCompanySettingsRequest(this.userAccountService.getUserCompanyId());
        for (let departmentObj of this.companySettings.departments) {
          if (departmentObj.adminList && departmentObj.adminList.length > 0) {
            let newAdminList = [];
            for (let email of employeeEmail) {
            for (let departmentAdmin of departmentObj.adminList) {
              if (email !== departmentAdmin.emailId) {
                newAdminList.push(departmentAdmin);
              }
            }
            departmentObj.adminList = newAdminList;
          }
        }
        }
      } else if (res && res.error_message) {
        this.responseErrorSubject.next(res.error_message);
      } else {
        this.responseErrorSubject.next("Apologies! something went wrong, we could'nt retrive employees list. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    })
  }


  public getAddEmployeeUserInfo(): UserInfo {
    return this.toAddEmployeeUserInfo;
  }

  public getDepartmentName(departmentId: number) {
    if (!this.companySettings || !this.companySettings.departments) return '';
    let departmentObj = this.companySettings.departments.find(obj => obj.departmentId == departmentId);
    if (departmentObj) { return departmentObj.name; } else {
      return 'N/A';
    }
  }

  public getDepartmentPolicyName(policyId: number) {
    if (!this.companySettings || !this.companySettings.departmentPolicies) return '';
    let departmentObj = this.companySettings.departmentPolicies.find(obj => obj.policyId == policyId);
    if (departmentObj) { return departmentObj.policyName; } else {
      return 'N/A';
    }
  }

  public getDefaultPolicyName() {
    if (!this.companySettings || !this.companySettings.departmentPolicies) return 'Select';
    let departmentObj = this.companySettings.departmentPolicies.find(obj => obj.default);
    if (departmentObj) { return departmentObj.policyName; } else {
      return 'N/A';
    }
  }
  public getDefaultPolicyID() {
    const departmentObj = this.companySettings.departmentPolicies.find(obj => obj.default);
    if (departmentObj) {
      return departmentObj.policyId;
    } else {
      return -1;
    }
  }

  public getDefaultDepartmentPolicyName(departmentId: number) {
    if (!this.companySettings || !this.companySettings.departments) return 'N/A';
    let departmentObj = this.companySettings.departments.find(obj => obj.departmentId == departmentId);
    if (departmentObj && departmentObj.defaultPolicyId && departmentObj.defaultPolicyId > 0) {
      return this.getDepartmentPolicyName(departmentObj.defaultPolicyId);
    } else {
      return this.getDefaultPolicyName();
    }
  }
  public errorResponseEmpty() {
    this.responseErrorSubject.next(null);
  }
  public getDefaultAdminDetail(departmentId: number): any {
    if (!this.companySettings || !this.companySettings.departments) return null;
    let departmentObj = this.companySettings.departments.find(obj => obj.departmentId == departmentId);
    if (departmentObj && departmentObj.approvers && departmentObj.approvers.length > 0) {
      return departmentObj.approvers;
    } else {
      return [];
    }
  }

  public doesDepartmentExists(departmentName: string) {
    if (!this.companySettings || !this.companySettings.departments) return null;
    return this.companySettings.departments.find(obj => obj.name.toLowerCase() === departmentName.toLowerCase());
  }
  public getDepartmentPolicyList(departmentId: number) {
    if (!this.companySettings || !this.companySettings.departmentPolicies) return [];
    return this.companySettings.departmentPolicies.filter(obj => (!obj.departmentId || obj.departmentId == -1 || obj.departmentId == departmentId));

  }
  public setEditEmployeeUserInfo(userInfo: UserInfo): void {
    this.toAddEmployeeUserInfo = userInfo;
  }

  processCheckEmployeeDetail(employeeEmailId: string, companyId: number, flowType: string) {
    this.checkEmployeeDetailRequest(employeeEmailId, companyId, flowType).subscribe(res => {
      if (res && res.success) {
        if (res.data) {
          if (flowType != "changeApprover") {
            this.toAddEmployeeUserInfo = deserialize(res.data, UserInfo);
            let data = { flowType: flowType, userInfo: this.toAddEmployeeUserInfo };
            this.employeeCheckDetailResponseSubject.next(data);
          } else {
            let tempToAddEmployeeUserInfo = deserialize(res.data, UserInfo);
            let data = { flowType: flowType, userInfo: tempToAddEmployeeUserInfo };
            this.employeeCheckDetailResponseSubject.next(data);
          }
          this.employeeCheckDetailResponseSubject.next(null);
        } else {
          if (flowType != "changeApprover") {
            this.toAddEmployeeUserInfo = new UserInfo();
            this.toAddEmployeeUserInfo.email = employeeEmailId;
            let data = { flowType: flowType, userInfo: this.toAddEmployeeUserInfo };
            this.employeeCheckDetailResponseSubject.next(data);
          } else {
            let tempToAddEmployeeUserInfo = new UserInfo();
            tempToAddEmployeeUserInfo.email = employeeEmailId;
            let data = { flowType: flowType, userInfo: tempToAddEmployeeUserInfo };
            this.employeeCheckDetailResponseSubject.next(data);
          }

          this.employeeCheckDetailResponseSubject.next(null);
        }

      } else if (res && res.error_message) {
        this.responseErrorSubject.next(res.error_message);
        this.employeeCheckDetailResponseSubject.next(null);
        this.responseErrorSubject.next(null);
      } else {
        this.responseErrorSubject.next("Apologies! something went wrong, we could'nt retrive employee detail. Please try again later or contact support");
        this.employeeCheckDetailResponseSubject.next(null);
        this.responseErrorSubject.next(null);
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    })
  }


  processCompanySettingsRequest(companyId: number) {
    this.companySettingsRequest(companyId).subscribe(res => {
      if (res && res.success) {
        this.fetchEmployeesList(this.userAccountService.getUserCompanyId());
        this.companySettings = deserialize(res.data, CompanySettings);
        this.previousReportColumns = this.companySettings.columnList;
        this.companySettingsResponseSubject.next(this.companySettings);
        //          this.companySettingsResponseSubject.next(null);
      } else if (res && res.error_message) {
        this.responseErrorSubject.next(res.error_message);
      } else {
        this.responseErrorSubject.next("Apologies! something went wrong, we could'nt retrive company settings. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    })
  }
  updateDepartment =false;
  processUpdateCompanyDepartmentRequest(department: Department) {
    this.updateCompanyDepartmentRequest(department).subscribe(res => {
      if (res && res.success) {
        this.processCompanySettingsRequest(department.companyId);
        this.toastr.success('Settings saved successfully !!');
      } else if (res && res.error_message) {
        this.responseErrorSubject.next(res.error_message);
      } else {
        this.responseErrorSubject.next("Apologies! something went wrong, we could'nt retrive company settings. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
        //  this.updateDepartment =false;
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    })
  }

  processDeleteCompanyDepartmentRequest(department: Department) {
    this.deleteCompanyDepartmentRequest(department).subscribe(res => {
      if (res && res.success) {
        this.processCompanySettingsRequest(department.companyId);
      } else if (res && res.error_message) {
        this.responseErrorSubject.next(res.error_message);
      } else {
        this.responseErrorSubject.next("Apologies! something went wrong, we could'nt retrive company settings. Please try again later or contact support");
      }
    }, error => {
      if (error.status != 403) {
        setTimeout(() => {
          let resultErrorMessage = this.translateService.instant('searchResult.Pleasecheckyourinternet').toString();
          this.toastr.error(resultErrorMessage);
        }, 100);
      }
    })
  }

  public getCompanySettingObject() {
    return JSON.parse(JSON.stringify(this.companySettings));
  }
  getAdminPolicy(): any {
    const userid = this.userAccountService.getUserEmail();
    const companyid = this.userAccountService.getUserCompanyId();
    return this.http.get(environment.apiForAdminPolicyForGet  + '?userid=' + userid + '&companyid=' + companyid);
  }

  getDepartmentPolicies(departmentId): any {
    const userid = this.userAccountService.getUserEmail();
    const companyid = this.userAccountService.getUserCompanyId();
    return this.http.get(environment.apiForAdminPolicyForList  + '?userid=' + userid + '&companyid=' + companyid + '&departmentid=-1');
  }


  getColumnSwitchMap() {
    let switchInitValues = new Map<string, boolean>();
    if (this.companySettings && this.companySettings.columnList) {
      for (let colomn of this.companySettings.columnList) {
        switchInitValues[colomn] = true;
      }
    } else {
      switchInitValues['Refrence'] = true;
      switchInitValues['NAME'] = true;
      switchInitValues['BK_DATE'] = true;
      switchInitValues['BK_TYPE'] = true;
      switchInitValues['TRV_DATE'] = true;
      switchInitValues['STATUS'] = false;
      switchInitValues['DEST'] = false;
      switchInitValues['POLICY'] = true;
      switchInitValues['PRICE'] = true;
      switchInitValues['ADV_BK'] = false;
      switchInitValues['BILL_INF'] = false;
      switchInitValues['Vendor'] = true;
    }
    return switchInitValues;
  }

  loginSessionChanged() {
    this.companySettings = undefined;
    this.origReportResponse = undefined;
    this.previousReportColumns = [];
    this.previousReportDate = '';
  }
}

export class CompanySettings {
  public company: any;
  public logoURL: string;
  public expensifySupported: boolean;
  public cardList: CardListDTO;
  public departments: Array<Department>;
  public departmentPolicies: Array<DepartmentPolicy>;
  public domainList: Array<CompanyDomain>;
  public columnList: Array<string>;
  public expenseProviders: Array<string>;
  public defaultAdmin: UserDetails;
  public discount_code: any;
}

export class Department {
  public departmentId: number;
  public defaultAgentRole: string;
  public card_id: string;
  public ssoPolicy: string;
  public discount_code: Array<any>;
  public allowOnlyForSpecificCarCorporatedId: boolean;
  public defaultPolicyId: number;
  public companyId: number;
  public approvers: Array<any>;
  public name: string;
  public adminList: Array<UserDetails>;
}

export class DepartmentPolicy {
  public departmentId: number;
  public policyId: number;
  public policyName: string;
  public default: boolean;
}

export class CompanyDomain {
  public domainId: number;
  public companyId: number;
  public domain: string;
}

