import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators, FormControl, Form, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Constants } from '../util/constants';
import { SearchService } from '../search.service';
import { FlightTimeType } from '../enum/time.type';
import { IntervalType } from '../enum/interval.type';
import { DateUtils } from '../util/date-utils';
import { CommonUtils } from '../util/common-utils';
import { PopupComponent } from '../popup/popup.component';
import { UserAccountService } from '../user-account.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { sourceDestinationValidator } from '../shared/address-validator.directive';
import { CarBookingService } from '../car-booking.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { google } from '@agm/core/services/google-maps-types'
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { AutocompleteSearchDTO } from '../entity/autocomplete-search-dto';
import { CarSearchQueryParam } from '../entity/car-query-param';
import { environment } from 'src/environments/environment';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from '../booking.service';
import { ToastrService } from 'ngx-toastr';

declare var setFocusOnInputField: any;

@Component({
  selector: 'app-car-search',
  templateUrl: './car-search.component.html',
  styleUrls: ['./car-search.component.scss']
})
export class CarSearchComponent implements OnInit {
  searchCarForm: FormGroup;
  lastAirportSearchTime: Date = new Date();
  lastLocationSearchTime: Date = new Date();
  carType: any;
  @Output() comeBackEmitter = new EventEmitter();
  bsModalRef: BsModalRef;
  previousDate = false;
  timeDifference = false;
  preDefinedCarBrands: any;
  timeOptions = Constants.TIME_FOR_CAR;
  showTransparentOverlay: boolean = false;
  minimumDate: Date = new Date();
  maximumDate: Date = new Date();
  minimumDateLeg2: Date = new Date();
  minimumDate3: Date = new Date();
  sameAddressCheckedStatus = false;
  timeTypeOptions = Constants.TIME_FOR_CAR;
  departureTimeTypeOptions = [Constants.TIME_TYPE[0], Constants.TIME_TYPE[1]];
  returnTimeTypeOptions = [Constants.TIME_TYPE[1], Constants.TIME_TYPE[0]];

  autoCompleteSourceItems: AutocompleteSearchDTO[] = [];
  autoCompleteSourceAirportItems: any[] = [];
  autoComplateSourceLocationItems: any[] = [];
  autoCompleteDestItems: any[] = [];
  autoCompleteDestAirportItems: any[] = [];
  autoComplateDestLocationItems: any[] = [];
  configOrigin: any = {
    'class': 'autocompleteAirports', 'max': 10,
    'placeholder': 'Pick-up',
    'sourceField': ['airport']
  };
  configDestination: any = {
    'class': 'autocompleteAirports', 'max': 10,
    'placeholder': 'Drop-off',
    'sourceField': ['airport']
  };
  pickUpModal: string;
  dropOffModal: string;




  constructor(private fb: FormBuilder,
    private searchService: SearchService,
    public translateService: TranslateService,
   
    private gallopLocalStorage: GallopLocalStorageService,
    private userAccountInfoService: UserAccountService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private carBookingService: CarBookingService,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private router: Router,
   ) {
    this.maximumDate.setDate(this.maximumDate.getDate() + 329);
    this.minimumDate.setDate(this.minimumDate.getDate());
    this.minimumDateLeg2.setDate(this.minimumDate.getDate());
    this.minimumDate3.setDate(this.minimumDate.getDate() );
  }

  ngOnInit() {
    this.carType = Constants.CARS_OPTIONS;
    this.preDefinedCarBrands = Constants.CARS_BRANDS;
    this.searchCarForm = this.fb.group({
      dropOffType: 'ADDRESS',
      pickupType: 'ADDRESS',
      source: ['', Validators.compose([Validators.required, this.spaceValidator])],
      sourceAirportCode: ['', Validators.compose([Validators.required, this.spaceValidator])],
      destination: [''],
      destinationAirportCode: [''],
      pickUpLat: [0],
      pickUpLng: [0],
      dropOffLat: [0],
      dropOffLng: [0],
      currentLocation: [''],
      sameAddress: true,
      carType: 'Select Car',
      carRentalCompany: 'Select Car Brand',
      pickTime: ['10:00 AM', Validators.compose([Validators.required])],
      dropTime: ['10:00 AM', Validators.compose([Validators.required])],
      pickUpDate: [null, Validators.compose([Validators.required])], //[new Date(currentDate)],
      dropOffDate: [null, Validators.compose([Validators.required])], //[new Date(returnDateHotel)]
    });
    if (this.carBookingService.carSearchDetail) {
      this.setPreviousFormValue(this.carBookingService.carSearchDetail)
    }
  }
  ngOnDestroy(){
    if(this.latlongSubscription){
      this.latlongSubscription.unsubscribe();
    }
  }
  public spaceValidator(control: AbstractControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true }
  }
  setPreviousFormValue(previousValue) {
    if(this.searchService.bookingAndApprovalDone && !this.searchService.searchHeaderCliked){
      return;
    }
    if (previousValue && previousValue.pickUp) {
      previousValue.source = previousValue.pickUp;
      this.searchCarForm.controls['pickUpDate'].enable()
      previousValue.sourceAirportCode = previousValue.pickUp;
      previousValue.destination = previousValue.dropOff;
      previousValue.destinationAirportCode = previousValue.dropOff;
      previousValue.sameAddress = previousValue.sameAddress;
      if (previousValue.sameAddress) {
        this.searchCarForm.controls['destinationAirportCode'].setValue('');
        this.searchCarForm.controls['destination'].setValue('');
        this.searchCarForm.controls['destinationAirportCode'].clearValidators();
        this.searchCarForm.controls['destinationAirportCode'].updateValueAndValidity();
        this.searchCarForm.controls['destination'].clearValidators();
        this.searchCarForm.controls['destination'].updateValueAndValidity();
      } else {
        this.searchCarForm.controls['destinationAirportCode'].setValidators([Validators.required]);
        this.searchCarForm.controls['destinationAirportCode'].updateValueAndValidity();
        this.searchCarForm.controls['destination'].setValidators([Validators.required]);
        this.searchCarForm.controls['destination'].updateValueAndValidity();
      }
      if (previousValue.travellerEmail && previousValue.travellerEmail.length > 0 && !this.searchService.bookingAndApprovalDone &&  !this.searchService.multiTripBooking || this.searchService.searchHeaderCliked) {
        this.searchService.employeeEmail = [];
        if (this.userAccountInfoService.showRadio) {
          if (previousValue.travellerEmail !== 'GUEST') {
            let employee = this.searchService.employeeList.filter(item1 => item1.email === previousValue.travellerEmail);
            let employee1 = { Name: '', Id: -1, Type: '' };
            console.log("employee", employee);
            if (employee && employee[0].employeeInfo) {
              employee1['Name'] = employee[0].firstName + " " + employee[0].lastName;
              employee1['Id'] = employee[0].employeeInfo.employeeId;
              employee1['email'] = employee[0].email;
            }else{
              if(this.bookingService.rebookingTravlleremail && this.bookingService.rebookingTravlleremail.length > 0){
              let guestTraveler = this.bookingService.rebookingTravlleremail.find(item1 => item1.email===previousValue.travellerEmail)
              if(guestTraveler){
              employee1['Name'] =guestTraveler.firstName +" "+guestTraveler.lastName;
              employee1['Id'] =-1;
              employee1['email'] = guestTraveler.email;
              }
              }
            }
            let userid = this.userAccountInfoService.getUserEmail();
            if (userid === previousValue.travellerEmail) {
              employee1['Id'] = 0;
              employee1['Name'] = this.translateService.instant('search.Myself');
              employee1['email'] = previousValue.travellerEmail;
              this.searchService.employeeEmail.push(employee1)
            } else {
              this.searchService.employeeEmail.push(employee1)
            }
          } else {
            let employee1 = { Name: '', Id: -1, Type: '' };
            employee1['Name'] = this.translateService.instant('search.Guest');
            employee1['Id'] = 1;
            employee1['email'] = 'GUEST';
            this.searchService.employeeEmail.push(employee1)
          }
        } else {
          let employee1 = { Name: '', Id: -1, Type: '' };
          let userid = this.userAccountInfoService.getUserEmail();
          employee1['Id'] = 0;
          employee1['Name'] = this.translateService.instant('search.Myself');
          employee1['email'] = userid;
          if(!this.route.snapshot.queryParams['query']){
          this.searchService.employeeEmail.push(employee1)
          }
        }
      }
      if(this.searchService.employeeEmail.length > 0){
        for(let item of this.searchService.employeeEmail){
          if(item.email!=='GUEST'){
          this.searchService.employeeOptions[0] = this.searchService.employeeOptions[0].filter(item1 => item1.email!==item.email);
        }
      }
      }
      this.setPreviousCarSearch(previousValue);
      this.previousDate = true;
      this.searchCarForm.patchValue(previousValue);
      this.setSearchForm();
    }
  }
  setPreviousCarSearch(searchObj: any) {
    let pickUpDate: Date = null;
    let dropOffDate: Date = null;
    if (searchObj.pickUpDate && !(searchObj.pickUpDate instanceof Date)) {
      pickUpDate = new Date(searchObj.pickUpDate.split('T')[0]);
      dropOffDate = new Date(searchObj.dropOffDate.split('T')[0]);
      searchObj.pickUpDate = new Date(new Date(pickUpDate).getTime() + new Date(pickUpDate).getTimezoneOffset() * 60 * 1000);
      searchObj.dropOffDate = new Date(new Date(dropOffDate).getTime() + new Date(dropOffDate).getTimezoneOffset() * 60 * 1000);
    }
  }
  onHidePicker() {
    this.searchService.calendarOpenEventListsner.next(null);
  }
  onShowPicker(event, picker) {
    this.searchService.calendarOpenEventListsner.next(picker);
    this.previousDate = false;
    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = (hoverEvent) => {
      const { cell, isHovered } = hoverEvent;

      if ((isHovered &&
        !!navigator.platform &&
        /iPad|iPhone|iPod/.test(navigator.platform)) &&
        'ontouchstart' in window
      ) {
        (picker as any)._datepickerRef.instance.daySelectHandler(cell);
      }

      return dayHoverHandler(hoverEvent);
    };
    event.dayHoverHandler = hoverWrapper;
  }
  setLeg1Date(date) {
    if(date){
    this.minimumDateLeg2 = new Date(date);
    this.minimumDate3 = new Date(date);
    this.minimumDate3.setDate(this.minimumDateLeg2.getDate());
    this.minimumDateLeg2.setDate(this.minimumDateLeg2.getDate() + 1);
    if (date && this.searchCarForm) {
      let leg1DateControl = this.searchCarForm.controls['pickUpDate'];
      leg1DateControl.setValue(date);

      let leg2DateControl = this.searchCarForm.controls['dropOffDate'];
      if (!this.previousDate) {
        if (leg2DateControl.value < this.minimumDateLeg2) {
          this.searchCarForm.get('dropOffDate').setValue(this.minimumDateLeg2);
        }
      }
    }
    var test1 = this.getTimeDifference();
    if(test1){
      this.timeDifference = false;
    } else{
      this.timeDifference = true;
    }
  }
  }
  setLeg2Date(date) {
    if (date && this.searchCarForm) {
      let leg2DateControl = this.searchCarForm.controls['dropOffDate'];
      leg2DateControl.setValue(date);
    }
    var test1 = this.getTimeDifference();
    if(test1){
      this.timeDifference = false;
    } else{
      this.timeDifference = true;
    }
  }
  getTimeOptions() {
    this.timeOptions = Constants.TIME_FOR_CAR;
  }

  getTimeTypeName(id) {
    let timeTypeName;

    this.timeTypeOptions.map(item => {
    //  if (item.id == id) {
        // timeTypeName = item.value;
      //   return;
     // }
    });

    return timeTypeName;
  }

  getTimeOptionName(id) {
    let timeOptionName;
    if (!this.timeOptions) {
      return 'ngOption.Anytime';
    }
    this.timeOptions.map(item => {
      if (item.name.toLowerCase() == id.toLowerCase()) {
        timeOptionName = item.title;
        // return;
      }
    });

    return timeOptionName;



  }
  get pickUpDate() {
    return this.searchCarForm.get('pickUpDate').value;
  }
  get dropOffDate() {
    return this.searchCarForm.get('dropOffDate').value;
  }
  get formIfInvalid() {
    if (this.searchService.employeeEmail.length === 0) {
      return true;
    } else {
      return this.searchCarForm.invalid;
    }
  }
  goToCarSearchResults(): void {
    let userid = this.userAccountInfoService.getUserEmail();
    let sToken = this.userAccountInfoService.getSToken();
    this.gallopLocalStorage.removeItem("emergencyContactDetails");
    let carQueryObject: CarSearchQueryParam = new CarSearchQueryParam();
    let queryAsJSON = JSON.parse(JSON.stringify(this.searchCarForm.value));
    carQueryObject.pickUpDate = new DatePipe('en-US').transform(new Date(queryAsJSON.pickUpDate), 'yyyy-MM-dd');
    carQueryObject.dropOffDate = new DatePipe('en-US').transform(new Date(queryAsJSON.dropOffDate), 'yyyy-MM-dd');
   this.comeBackEmitter.emit(queryAsJSON);
    carQueryObject.pickUp = queryAsJSON.source;
    carQueryObject.dropOff = queryAsJSON.destination;
    carQueryObject.pickUpLat = queryAsJSON.pickUpLat;
    carQueryObject.pickUpLng = queryAsJSON.pickUpLng;
    carQueryObject.dropOffLat = queryAsJSON.dropOffLat;
    carQueryObject.dropOffLng = queryAsJSON.dropOffLng;
    carQueryObject.sameAddress = queryAsJSON.sameAddress;
    carQueryObject.carType = queryAsJSON.carType;
    carQueryObject.carRentalCompany = queryAsJSON.carRentalCompany;
    carQueryObject.pickTime = queryAsJSON.pickTime;
    carQueryObject.dropTime = queryAsJSON.dropTime;
    carQueryObject.dropOffType = queryAsJSON.dropOffType;
    carQueryObject.pickupType = queryAsJSON.pickupType;
    carQueryObject.travellerEmail = this.searchService.employeeEmail[0].email
    // this.carBookingService.pickUpType = queryAsJSON.pickupType ;



    this.router.navigate(['/carResult'],
      {
        queryParams:
        {
          query: encodeURIComponent(JSON.stringify(carQueryObject)),
          step: 0
        },
      }
    );
  }
  getTimeDifference() {
    var pickUpTime = this.searchCarForm.controls['pickTime'].value;
    var dropOffTime = this.searchCarForm.controls['dropTime'].value;
    if(this.searchCarForm.controls['dropOffDate'].value){
    var dropOffDate = this.searchCarForm.controls['dropOffDate'].value;
    }
    if(this.searchCarForm.controls['pickUpDate'].value){
    var pickUpDate = this.searchCarForm.controls['pickUpDate'].value;
    }
    const oneDay = 24 * 60 * 60 * 1000;
  //  var diff2 = Math.round(Math.abs((dropOffDate.getTime() - pickUpDate.getTime()) / oneDay));
    const time1 = this.getMinutes(pickUpTime);
    const time2 = this.getMinutes(dropOffTime);
    const diff = time2 - time1;
    if (dropOffDate && pickUpDate && dropOffDate.getTime() === pickUpDate.getTime()) {
      if (diff >= 60) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  }
  getMinutes(input) {
   
    let MINUTES_IN_12_HOURS = 12 * 60;
    let totalMinutes;
    const [time, amOrPm] = input.split(" ");
    const [hours, minutes] = time.split(":");
    
     totalMinutes = (hours * 60 + Number(minutes));
     if(amOrPm === "PM" && Number(hours)!==12){
      totalMinutes =((hours+12) * 60) + Number(minutes)
    }

    totalMinutes = totalMinutes + Number((amOrPm === "AM" ? 0 : MINUTES_IN_12_HOURS));

    return totalMinutes;
  }
  isSearchCarFormNonEmpty() {
    return (this.searchCarForm.controls.pickUp
      && this.searchCarForm.controls.pickUp.value
      && this.searchCarForm.controls.pickUp.value.trim().length > 0);
  }
  isSearchCarFormNonEmpty1() {
    return (this.searchCarForm.controls.dropOff
      && this.searchCarForm.controls.dropOff.value
      && this.searchCarForm.controls.dropOff.value.trim().length > 0);
  }
  clearCarTextField() {
    let form = { ...this.searchCarForm };
    form['pickUp'] = '';
    this.searchCarForm.patchValue({ ...form });
  }
  clearCarTextField1() {
    let form = { ...this.searchCarForm };
    form['dropOff'] = '';
    this.searchCarForm.patchValue({ ...form });
  }
  errorhandled(){
    var test1 = this.getTimeDifference();
    if(test1){
      this.timeDifference = false;
    } else{
      this.timeDifference = true;
    }
  }
  calculateAge(dateOfBirth: Date, currentDate: Date): number {
    const dob = new Date(dateOfBirth);
    const now = new Date(currentDate);
  
    let age = now.getFullYear() - dob.getFullYear();
    const monthDiff = now.getMonth() - dob.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
      age--;
    }else if(monthDiff < 0 || (monthDiff === 0 && now.getDate() > dob.getDate())){
      age++;
    }
  
    return age;
  }
  isAgeEighteenPlus(){
    let userid =this.userAccountInfoService.getUserEmail();
    if(this.searchService.employeeEmail[0] && this.searchService.employeeEmail[0].email !=='GUEST'){
      let employee:any = this.searchService.travellerList.filter(item => item.userId ===this.searchService.employeeEmail[0].email)
      if(employee && employee.length > 0 &&  employee[0].dob){
        employee = employee[0];
     let dob = new Date(employee.dob);
    let todayDate;
    if(this.searchCarForm.controls['pickUpDate'].value){
       todayDate = this.searchCarForm.controls['pickUpDate'].value;
      }else{
        todayDate = new Date();
      }
      const oneDay = 24 * 60 * 60 * 1000;
      let twelveYearsAgo: Date = new Date(todayDate.getFullYear() - 18, todayDate.getMonth(), todayDate.getDate()); 
      let differenceInMs: number =todayDate.getTime() - twelveYearsAgo.getTime();
    let  days: number = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
      let diff2 = Math.floor((todayDate.getTime() - dob.getTime()) / oneDay);
  if(diff2<=days){
    return true;
  }
  }else if(userid === this.searchService.employeeEmail[0].email && this.userAccountInfoService.getAccountInfo()){
    let userInfoDTO = this.userAccountInfoService.getAccountInfo().userInfo;
    let dob;
    if(userInfoDTO && userInfoDTO.dob){
     dob = new Date(userInfoDTO.dob);
    
    let todayDate;
    if(this.searchCarForm.controls['pickUpDate'].value){
      todayDate = this.searchCarForm.controls['pickUpDate'].value;
     }else{
       todayDate = new Date();
     }
     const oneDay = 24 * 60 * 60 * 1000;
     let twelveYearsAgo: Date = new Date(todayDate.getFullYear() - 18, todayDate.getMonth(), todayDate.getDate()); 
     let differenceInMs: number =todayDate.getTime() - twelveYearsAgo.getTime();
   let  days: number = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
     let diff2 = Math.floor((todayDate.getTime() - dob.getTime()) / oneDay);
 if(diff2<=days){
   return true;
 }else{
         return false;
       }
      }
   }
  return false;
}
return false;
}
 
 
  submitForm(): void {
   
      if (this.searchCarForm) {
        var test1 = this.getTimeDifference();
        if (test1) {
          this.timeDifference = false;
          this.userAccountInfoService.doNotSaveProfile = false;
          this.searchService.searchHeaderCliked = false;
          if((this.userAccountInfoService.isUserCorporateAdmin() || this.userAccountInfoService.isUserIsTravelManager()) && !this.bookingService.previousTransactionId){
            this.searchService.bookingAndApprovalDone =true;
          }
          if(this.isAgeEighteenPlus()){
            this.toastr.error("Car bookings are allowed only for travelers with age of 18+ years");
            return
          }
          this.userAccountInfoService.paymentPageSave = false;
          this.searchService.bookingDone = false;
          this.searchService.comingFromSelectionPAge =false;
          this.bookingService.bookingData = undefined;
          if(this.userAccountInfoService.isUserIsTravelManager()){
    this.searchService.bookingAndApprovalDone =true;
    }
          this.searchService.hotelbookingDone = false;
          if(!this.searchService.multiTripBooking){
            this.searchService.tripName='';
          }else{
            let seatArray = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
      if(seatArray && seatArray.length > 0){
        //  seatArray.pop();
          this.gallopLocalStorage.removeItem("selectedSeat");
        }
          }
          this.goToCarSearchResults();
        } else {
          this.timeDifference = true;
        }
      }
      // this.searchService.outgoingSelectedFlight = undefined;
      //this.searchInProgress = true;
      //this.goToSearchResults();
  
  }
  setDepartureConstraint(value: string) {
    this.searchCarForm.controls['constraint'].setValue(value);
  }
  setArrivalConstraint(value: string) {
    this.searchCarForm.controls['constraint1'].setValue(value);
  }

  // For custom Autocomplete field
  valueSelectedFromDropDown: boolean = false;
latlongSubscription:Subscription;
  onAutoCompleteSelect(item: any, formcontrolname: string, parentDivId) {
    this.valueSelectedFromDropDown = true;
    let form = { ...this.searchCarForm };
    if (item.data
    ) {
      form[formcontrolname] = item.data;
      form[formcontrolname + 'AirportCode'] = item.data;
      if (formcontrolname === 'source') {
        this.pickUpModal = item.data;
        this.autoCompleteSourceItems = [];
        form['pickupType'] = item.type;
        if(item.type!=='ADDRESS'){
        let source = this.autoCompleteSourceAirportItems.filter(item1 => item1.code===item.code)
        if(source && source.length > 0){
         this.setDateWithTimeZone(source[0]);
        }
      }else{
       this.latlongSubscription=  this.userAccountInfoService.getLatlng(item.data).subscribe(resp=>{
          if(resp && resp.results && resp.results.length > 0 && resp.results[0].geometry && resp.results[0].geometry.location){
            console.log("car address",resp)
            let latlngObject = {location:{lat:-1,lon:-1}};
            latlngObject.location.lat= resp.results[0].geometry.location.lat;
            latlngObject.location.lon= resp.results[0].geometry.location.lng;
            this.setDateWithTimeZone(latlngObject);
          }else{
            if(resp){
            this.setSearchForm();
            }
          }
        })
       // this.searchCarForm.controls['pickUpDate'].enable()
      
      }
      } else {
        this.dropOffModal = item.data;
        this.autoCompleteDestItems = [];
        form['dropOffType'] = item.type;
      }
      this.searchCarForm.patchValue({ ...form });
      setTimeout(() => {
        $('#' + parentDivId).find('input[type=text]').val(item.data);
      }, 1);
    }
  }
  onAutoCompleteClose(eventObject, formcontrolname: string, parentDivId) {
    if (!this.valueSelectedFromDropDown) {
      let form = { ...this.searchCarForm };
      let inputText: any = $('#' + parentDivId).find('input[type=text]').val();
      let itemList;
      if (formcontrolname === 'source') {
        itemList = this.autoCompleteSourceItems;
      } else {
        itemList = this.autoCompleteDestItems;
      }
      if (itemList && itemList.length > 0 && inputText && inputText.length > 0) {
        let item = itemList.find(
          x => x.code === inputText.toUpperCase()
        );
        if (item) {
          this.onAutoCompleteSelect(item, formcontrolname, parentDivId);
          return;
        }
      }
      this.clearAirportField(formcontrolname);
    }
  }
  clearAirportField(formcontrolname: string) {
    let form = { ...this.searchCarForm };
    form[formcontrolname] = '';
    form[formcontrolname + 'AirportCode'] = '';
    this.searchCarForm.patchValue({ ...form });
    let inputFieldElementId = '';
    if (formcontrolname === 'source') {
      inputFieldElementId = 'inputFieldIDSourceAirport';
    } else {
      //destination
      inputFieldElementId = 'inputFieldIDDestAirport';
    }
    this.setSearchForm();
    this.autoCompleteSourceItems = [];
    this.autoCompleteDestItems = [];
    setFocusOnInputField(inputFieldElementId);

  }
  searchAutoComplete(term: string, formcontrolname: string) {
    this.valueSelectedFromDropDown = false;
    let curQueryTime = new Date();
    if (formcontrolname === 'source') {
      this.pickUpModal = term;
      this.searchCarForm.controls['sourceAirportCode'].setValue('');
    } else {
      this.dropOffModal = term;
      this.searchCarForm.controls['destinationAirportCode'].setValue('');
    }
  
    this.searchService.search1(term, 'cars')
      .subscribe(response => {
        if (curQueryTime > this.lastAirportSearchTime) {
          this.lastAirportSearchTime = curQueryTime;
          let respObj = JSON.parse(JSON.stringify(response.data));
          if (formcontrolname === 'source') {
            this.autoCompleteSourceAirportItems = respObj;
          } else {
            this.autoCompleteDestAirportItems = respObj;
          }
          this.updateAutoCompleteList(formcontrolname);
        }
      });
    if (term && term.trim().length > 0 && environment.locationSupportedInCarSearch) {
      this.searchService.searchGoogleAutoComplete(term).subscribe(response2 => {
        if (curQueryTime > this.lastLocationSearchTime) {
          this.lastLocationSearchTime = curQueryTime;
          if (response2 && response2['status'] === 'OK' && response2['predictions'] && response2['predictions'].length > 0) {
            let respObjArr = JSON.parse(JSON.stringify(response2['predictions']));
            console.log("car address",response2);
            if (formcontrolname === 'source') {
              this.autoComplateSourceLocationItems = respObjArr;
            } else {
              this.autoComplateDestLocationItems = respObjArr;
            }
          }
          this.updateAutoCompleteList(formcontrolname);
        }
      });
    }
  }
  openPickupCalender(checkinDatePicker){
    if(this.searchCarForm.controls['source'].value && this.searchCarForm.controls['source'].value!==''){
      checkinDatePicker.toggle();
        }else{
          this.toastr.error(this.translateService.instant('setting.Pleaseenterapickuplocation'));
        }
  }
  setSearchForm(){
    if(this.searchService.currentTimeZone){
    this.minimumDate = this.searchService.currentTimeZone;
    }
        let selectedDate = (this.searchCarForm.controls['pickUpDate'] && this.searchCarForm.controls['pickUpDate'].value) ? this.searchCarForm.controls['pickUpDate'].value.setHours(0, 0, 0, 0):null;
        let rangeDate= this.minimumDate ? this.minimumDate.setHours(0, 0, 0, 0):null;
    if(this.searchCarForm.controls['source'].value && this.searchCarForm.controls['source'].value!==''&& selectedDate && selectedDate < rangeDate){
      this.searchCarForm.controls['pickUpDate'].setValue(null);
     }
  }
  timezoneDate:any;
  setDateWithTimeZone(item){
    this.userAccountInfoService.getTimeZone(item.location).subscribe(resp =>{
      if(resp && resp.timeZoneId){
     let d = new Date(new Date().toLocaleString("en-US", {timeZone: resp.timeZoneId}));
    
      this.searchService.currentTimeZone = d;
    
     this.setSearchForm()
        console.log("response timezone",resp,d);
      }else{
        this.setSearchForm();
      }
    })
  }
  updateAutoCompleteList(formcontrolname) {
    if (formcontrolname === 'source') {
      this.autoCompleteSourceItems = [];
      var maxAirports = 10;
      if (environment.locationSupportedInCarSearch) {
        maxAirports = 3;
      }
      for (let counter = 0; counter < maxAirports && counter < this.autoCompleteSourceAirportItems.length; counter++) {
        let item: AutocompleteSearchDTO = {
          type: 'AIRPORT',
          code: this.autoCompleteSourceAirportItems[counter].code,
          label: this.autoCompleteSourceAirportItems[counter].city,
          data: this.autoCompleteSourceAirportItems[counter].code
        };
        this.autoCompleteSourceItems.push(item);
      }
      if (this.autoComplateSourceLocationItems && this.autoComplateSourceLocationItems.length > 0) {
        for (let counter = 0; counter < this.autoComplateSourceLocationItems.length; counter++) {
          let item: AutocompleteSearchDTO = {
            type: 'ADDRESS',
            code: '',
            label: this.autoComplateSourceLocationItems[counter].description,
            data: this.autoComplateSourceLocationItems[counter].description
          };
          this.autoCompleteSourceItems.push(item);
        }
      }
    } else {
      this.autoCompleteDestItems = [];
      for (let counter = 0; counter < 3 && counter < this.autoCompleteDestAirportItems.length; counter++) {
        let item: AutocompleteSearchDTO = {
          type: 'AIRPORT',
          code: this.autoCompleteDestAirportItems[counter].code,
          label: this.autoCompleteDestAirportItems[counter].city,
          data: this.autoCompleteDestAirportItems[counter].code
        };
        this.autoCompleteDestItems.push(item);
      }
      if (this.autoComplateDestLocationItems && this.autoComplateDestLocationItems.length > 0) {
        for (let counter = 0; counter < this.autoComplateDestLocationItems.length; counter++) {
          let item: AutocompleteSearchDTO = {
            type: 'ADDRESS',
            code: '',
            label: this.autoComplateDestLocationItems[counter].description,
            data: this.autoComplateDestLocationItems[counter].description
          };
          this.autoCompleteDestItems.push(item);
        }
      }
    }
  }
  onFocused(e, formcontrolname: string) {
    this.searchCarForm.controls[formcontrolname].markAsTouched();
    this.searchCarForm.controls[formcontrolname + 'AirportCode'].updateValueAndValidity();
    //this.clearAirportField(formcontrolname);
  }
  getAirportCodeFromSearchResponse(input: string) {
    return input.substring(0, 3);
  }
  getAirportLocationFromSearchRersponse(input: string) {
    if (input.length > 4) {
      return input.substring(4).trim();
    }
    return this.getAirportCodeFromSearchResponse(input);
  }

  boxChecked() {
    if (this.searchCarForm.controls['sameAddress'].value) {
      this.searchCarForm.controls['sameAddress'].setValue(false);
      this.searchCarForm.controls['destinationAirportCode'].setValidators([Validators.required]);
      this.searchCarForm.controls['destinationAirportCode'].updateValueAndValidity();
      this.searchCarForm.controls['destination'].setValidators([Validators.required]);
      this.searchCarForm.controls['destination'].updateValueAndValidity();
    } else {
      this.searchCarForm.controls['sameAddress'].setValue(true);
      this.searchCarForm.controls['destinationAirportCode'].setValue('');
      this.searchCarForm.controls['destination'].setValue('');
      this.searchCarForm.controls['destinationAirportCode'].clearValidators();
      this.searchCarForm.controls['destinationAirportCode'].updateValueAndValidity();
      this.searchCarForm.controls['destination'].clearValidators();
      this.searchCarForm.controls['destination'].updateValueAndValidity();
    }
  }
  getPlaceHolder() {
    if (this.searchCarForm.controls['sameAddress'].value) {
      return   this.translateService.instant('search.Pick-upandDrop-offlocation');
    } else {
      return  this.translateService.instant('search.Pickup');
    }
  }
  getPlaceHolder1() {
    return  this.translateService.instant('search.Drop-off');
  }
}
