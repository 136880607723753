import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { UserInfo } from './entity/user-info';
import { UserAccountInfo } from './entity/user-account-info';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { ActivatedRoute, Router } from '@angular/router';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { UserPreferences } from './entity/user-prefs';
import { TranslateService } from '@ngx-translate/core';
import { Role } from './entity/employee-info';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { UserCredentialsService } from './user-credentials.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Constants } from './util/constants';
import { CommonUtils } from './util/common-utils';

declare var setCDValues: any;

@Injectable()
export class UserAccountService {
  njoySpecificBuild: boolean = false;
  private fetchAccountInfoSubscription: Subscription;
  private userAccountInfoObj: UserAccountInfo;
  appVisibleToUser = true;
  isConnected: boolean = true;
  deletingCard = false;
  showRadio = false;
  showLanguageOptions=false;
  userFormOpen=-1;
  confermaStatus = false;
  approvalRequiredFor = '';
  userhascard = false;
  comingFromLoginPage = false;
  completeOnboardTask = false;
  hotelReason = [];
  onBoardingTask = [];
  showOnBoardingTask = false;
  paymentPageSave = false;
  historyFeedBack = false;
  initResponseReceived = false;
  initResponseReceivedFoorSearchPage = false;
  onboardingUser = false;
  public showAllBookings = false;
  public showUpSellOnAllLegs = true;
  slackTab = "";
  connectingToSlack = false;
  hideSuccesMsg = false;
  onBoardTaskCompleted = false;
  onParticularTaskCompleted = false;
  userIsAdmin = false;
  savingProfileData = false;
  notToRefreshForm = false;
  userIsDeptAdmin = false;
  promptUserTosaveProfile = true;
  doNotSaveProfile = false;
  feedbackMsg = 'Rate your experience';
  typeOfBooking = 'BOOK_FOR_SELF';
  feedbackSubmitSuccessfully = false;
  historyThumb = '';
  public userAccountInfoObjObserver$ = new BehaviorSubject<any>(null);
  public userAccountInitObjObserver$ = new BehaviorSubject<any>(null);

  private fetchPreferenceSubscription: Subscription;
  public userPreferencesObj: UserPreferences;
  public userPreferencesObjObserver$ = new BehaviorSubject<any>(null);

  public userSessionChangePrompt$ = new BehaviorSubject<any>(null);
  private routerPath: string;
  private userEmail: string;
  private sToken: string;
  public isLoginInProgress: boolean;
  public isCarsEnabled = true;
  public isDutyOfCareEnabled = false;
  constructor(private http: GallopHttpClient,
    private userCredentials: UserCredentialsService,
    private route: ActivatedRoute,
    private http1: HttpClient,
    public router: Router,
    private gallopLocalStorage: GallopLocalStorageService,) { }


  public isShowAllBookings() {
    return this.showAllBookings;
  }
  public getAccountInfo(): UserAccountInfo {
    return this.userAccountInfoObj;
  }

  public saveAccountInfo(userInfo: UserInfo): Observable<any> {
    return this.http.post(environment.apiUserAccountUrlforSave
      , userInfo);
  }
  public saveAccountInfoForOthers(userInfo: UserInfo): Observable<any> {
    return this.http.post(environment.apiUserAccountUrlForOtherTraveller +  '?travellerEmail=' + encodeURIComponent(userInfo.email)
      , userInfo);
  }

  private requestAccountInfo(userEmail: string, stoken: string): Observable<any> {
    return this.http.get(environment.apiUserAccountUrlForFetch );
  }
 public savePersonalInformation(others,userInfo): Observable<any>{
   if(others){
     return this.saveAccountInfoForOthers(userInfo)
   }else{
     return this.saveAccountInfo(userInfo)
   }
 }
 public savePersonalPrefernece(others,userPref ,email: Observable<any>){
  if(others){
    return this.saveUserPreferenceForOtherTraveller(userPref,email)
  }else{
   return  this.saveUserPreference(userPref)
  }
}
  private requestUserPreferences(userEmail: string, stoken: string): Observable<any> {
    return this.http.get(environment.apiUserPreferenceUrlForFetch)
  }

  public saveUserPreference(userPref: UserPreferences): Observable<any> {
    return this.http.post(environment.apiUserPreferenceUrlForSave 
      , userPref);
  }
  public saveUserPreferenceForOtherTraveller(userPref: UserPreferences,email): Observable<any> {
    return this.http.post(environment.apiUserPreferenceUrlForOtherTraveller + '?travellerEmail=' + encodeURIComponent(email)
      , {'seatPreference':userPref.seatPreference});
  }
  public initializeApiClient() {
    this.initializeClient().subscribe(resp => {
      if (resp && resp.status === 'success') {
        this.userAccountInitObjObserver$.next(resp);
      }
    });
  }
  public initializeClient(): Observable<any> {
    const currentParams = this.route.snapshot.queryParams;
    let initUrl = environment.apiInitializeClient;
    if (currentParams['userid']) {
      if (initUrl.indexOf('?') > -1) {
        initUrl += '&userid=' + encodeURIComponent(currentParams['userid']);
      } else {
        initUrl += '?userid=' + encodeURIComponent(currentParams['userid']);
      }
    }
    return this.http.get(initUrl);
  }
  public updateCurrentRouterPath(newPath: string) {
    this.routerPath = newPath;
  }
  uploadProfileImageRequest(image: any): Observable<any> {
    const userid = this.getUserEmail();
    const sToken = this.getSToken();
    const params = new HttpParams()
      .set('userid', userid)
      .set('sToken', sToken)
      ;
    const uploadData = new FormData();
    uploadData.append('myFile', image, image.name);
    return this.http.post(environment.apiUploadProfileImage, uploadData, { params });
  }
  public getCurrentRouterPath() {
    return this.routerPath;
  }
  public requestLogout(): Observable<any> {
    return this.http.get(environment.apiLogout);
  }

  // handleError(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     // client-side error
  //     errorMessage = `Error: ${error.error.message}`;
  //   } else {
  //     // server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   window.alert(errorMessage);
  //   return throwError(errorMessage);
  // }
  requestSaveCardInfo(token: string, gToken: string): Observable<any> {
    const body = 'token=' + token
      + '&gToken=' + gToken;
    return this.http.post(environment.apiUserAccountUrlforSaveCard,
      body, {headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded')});
  }

  requestDeleteCard(cardId: string): Observable<any> {
    const body = 'cardToDelete=' + cardId
    return this.http.post(environment.apiUserAccountUrlforDeleteCard,
        body, {headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded')});
  }

  requestCardInfoFromToken(gToken: string, token: string, userEmailID: string): Observable<any> {
    const body = 'token=' + token
      + '&gToken=' + gToken;
    return this.http.post(environment.apiUserAccountUrlforSaveCard,
      body, {headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded')});
  }
  isItNjoyBuild() {
    return this.njoySpecificBuild;
  }
  public initUserAccountService(email: string, token: string, redirect403: boolean) {
    this.userEmail = email;
    this.sToken = token;
    this.userCredentials.setUserId(this.userEmail);
    this.userCredentials.setSToken(this.sToken);
    this.fetchUserAccountInfo(redirect403);
  }

  public updateUserSession(userEmail: string, sToken: string, userInfoObj: UserAccountInfo) {
    if (userInfoObj) {
      let loginInputDetails = { 'userid': userEmail, 'sToken': sToken };
      this.userEmail = userEmail;
      this.sToken = sToken;
      localStorage.setItem("loginSession", JSON.stringify(loginInputDetails));
    } else if (localStorage.getItem('loginSession') !== 'null' && localStorage.getItem('loginSession')) {
      let prevLoginSession = JSON.parse(localStorage.getItem('loginSession'));
      if (userEmail === prevLoginSession['userid']) {
        localStorage.setItem("loginSession", null);
      }
    }
    this.userCredentials.setUserId(userEmail);
    this.userCredentials.setSToken(sToken);
    this.setAccountInfo(userInfoObj);
  }
  public hotelFeedBack(tripid, ticketid, transactionid, eventid, feedback, selected): Observable<any> {
    let comment = '';
    return this.http.post(environment.apiForFeedBack, { 'tripid': tripid, 'ticketid': ticketid, 'transactionid': transactionid, 'eventid': eventid, 'feedback': feedback, 'negativeReason': selected, 'comments': comment });
  }
  public fetchUserAccountInfo(redirect403: boolean) {
    if (!this.userEmail || !this.sToken) return;

    this.isLoginInProgress = true;
    let sToken = this.getSToken();
    this.fetchAccountInfoSubscription = this.requestAccountInfo(this.userEmail, sToken).subscribe(res => {
      this.isLoginInProgress = false;
      if (!res && res == null) {
        if (redirect403) this.router.navigate(['/errors'], { queryParams: { errorCode: 'unknown' } });
        return;
      }
      else if (res.success == true) {
        let newLoginDetails = { 'userid': res.data.userInfo.email, 'sToken': sToken, 'userInfo': res.data };
        if (newLoginDetails.userInfo.userInfo
          && (newLoginDetails.userInfo.userInfo.employeeInfo
            && newLoginDetails.userInfo.userInfo.employeeInfo.agentRole
            && newLoginDetails.userInfo.userInfo.employeeInfo.agentRole === 'BOOK_FOR_OTHERS')) {
          this.showRadio = true;
          //this.typeOfBooking='BOOK_FOR_OTHERS'
        } else {
          this.showRadio = false;
          this.typeOfBooking = 'BOOK_FOR_SELF'
        }
        if (newLoginDetails
          && newLoginDetails.userInfo.userInfo
          && newLoginDetails.userInfo.userInfo.employeeInfo
          && (newLoginDetails.userInfo.userInfo.employeeInfo.role as Role === Role.APPROVER
            || newLoginDetails.userInfo.userInfo.employeeInfo.role as Role === Role.ADMIN
            || newLoginDetails.userInfo.userInfo.employeeInfo.role as Role === Role.DEPT_ADMIN)
        ) {
          this.userIsAdmin = true;
        } else {
          this.userIsAdmin = false;
        }
        if (newLoginDetails
          && newLoginDetails.userInfo.userInfo
          && newLoginDetails.userInfo.userInfo.employeeInfo
          && (newLoginDetails.userInfo.userInfo.employeeInfo.role as Role === Role.DEPT_ADMIN
            || newLoginDetails.userInfo.userInfo.employeeInfo.role as Role === Role.ADMIN)
        ) {
          this.userIsDeptAdmin = true;
        } else {
          this.userIsDeptAdmin = false;
        }
        this.userSessionChangePrompt$.next(newLoginDetails);
        setCDValues(res.data.userInfo.email, res.data.userInfo.companyId);
        CommonUtils.initializeRollBar(res.data.userInfo.email, environment.appVersion, environment.appStoreVersionName,
          environment.platform, environment.otaBuildNumber);
      } else if (res.status === 'error') {
        this.isLoginInProgress = false;
        this.updateUserSession(this.userEmail, this.sToken, undefined);
        if (redirect403) {
          this.router.navigate(['/errors'], { queryParams: { errorCode: '403' } });
        } else {
          this.router.navigate(['/search'], { queryParams: {} });
        }
      }
    }, error => {
      this.isLoginInProgress = false;
      console.log(JSON.stringify(error));
      this.updateUserSession(this.userEmail, this.sToken, undefined);
      if (redirect403) {
        this.router.navigate(['/errors'], { queryParams: { errorCode: '403' } });
      } else {
        this.router.navigate(['/search'], { queryParams: {} });
      }
    });

  }

  public fetchUserPreferences() {
    if (!this.userEmail || !this.sToken) return;

    this.fetchPreferenceSubscription = this.requestUserPreferences(this.userEmail, this.sToken).subscribe(res => {
      if (!res && res == null) {
        return;
      }
      else if (res.status === 'success' && res.data) {
        let userPref: UserPreferences = deserialize(res.data, UserPreferences);
        this.setUserPreferences(userPref);
      }
    }, error => {
      console.log(JSON.stringify(error));
    });

  }

  setAccountInfo(userAccountInfoObj: UserAccountInfo) {
    if (userAccountInfoObj) {
      this.gallopLocalStorage.setItem("userAccountData", JSON.stringify(userAccountInfoObj));
      this.userAccountInfoObj = userAccountInfoObj;
      this.broadcastUserAcountInfo(this.userAccountInfoObj);
    }
  }

  setUserPreferences(userPref: UserPreferences) {
    if (userPref) {
      this.userPreferencesObj = userPref;
      this.broadcastUserPreferences(this.userPreferencesObj);
    }
  }
  private broadcastUserPreferences(userPref: UserPreferences) {
    this.userPreferencesObjObserver$.next(userPref);
  }

  private broadcastUserAcountInfo(userAccountInfoObj: UserAccountInfo) {
    this.userAccountInfoObjObserver$.next(userAccountInfoObj);
  }
  public getLatlng(address): Observable<any> {
    return this.http1.get('https://maps.googleapis.com/maps/api/geocode/json?address='+ address + '&key=' + environment.googleMapsAPIKey)

  }
public getTimeZone(item): Observable<any> {
let timestamp =(new Date().getTime()/1000);
return this.http1.get(environment.googleMapTimeZone+'?location='+ item.lat + ',' + item.lon +'&timestamp=' +timestamp+ '&key=' + environment.googleMapsAPIKey)

}
  public redirectFromSlack(code, state): Observable<any> {
    return this.http.get(environment.apiForSlackOAuth + '?code=' + code + '&state=' + state);
  }
  public getSubscriptionPlan() {

    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo.userEntityData) {
      return this.userAccountInfoObj.userInfo.userEntityData.subscription.plan;
    } else {
      return null;
    }
  }



  public getGallopCashBack(total: number) {
    let plan = this.getSubscriptionPlan();
    if (plan && plan != null && plan.cashback) {
      if (plan.cashback.cashbackType.toString() === 'absolute') {
        return plan.cashback.amount;
      } else {
        return total * plan.cashback.amount / 100;
      }
    }
  }


  public isCorporateUser() {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo) {
      return this.userAccountInfoObj.userInfo.is_corporate_user;
    } else {
      return false;
    }
  }

  public restrictOutsidePolicyBooking(): boolean {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo.employeeInfo
      && this.userAccountInfoObj.userInfo.employeeInfo.policyViolationFlag) {
      return this.userAccountInfoObj.userInfo.employeeInfo.policyViolationFlag === 'RESTRICT_OOP';
    } else {
      return true;
    }
  }
  public hasBillingIdConfigured(carVendor): boolean {
    if (carVendor && this.userAccountInfoObj && this.userAccountInfoObj.companySettings && this.userAccountInfoObj.companySettings.discount_code) {
      for (let index = 0; index < this.userAccountInfoObj.companySettings.discount_code.length; index++) {
        if (this.userAccountInfoObj.companySettings.discount_code[index].partnerCode === carVendor
          && this.userAccountInfoObj.companySettings.discount_code[index].billingNumber
          && this.userAccountInfoObj.companySettings.discount_code[index].billingNumber.trim().length > 0) {
          return true;
        }
      }
    }
    return false;
  }
  public canAccessCompanyCard() {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo.userEntityData) {
      return this.userAccountInfoObj.userInfo.is_corporate_user && this.userAccountInfoObj.userInfo.userEntityData.companyCardAccess === 'true';
    } else {
      return false;
    }
  }

  public getUserPaymentMethod(carVendor) {
    if (carVendor) {
      if (this.hasBillingIdConfigured(carVendor) && this.canAccessCompanyCard()) {
        return 'BILL_TO_COMPANY';
      } else {
        return 'PERSONAL_CARD';
      }
    }
    if (this.userAccountInfoObj && this.userAccountInfoObj.cardList && this.userAccountInfoObj.cardList.paymentMethod) {
      if (this.userAccountInfoObj.cardList.paymentMethod === 'PERSONAL_CARD' && this.canAccessCompanyCard()) {
        return 'BILL_TO_COMPANY';
      } else if (this.userAccountInfoObj.cardList.paymentMethod === 'PERSONAL_CARD' && !this.canAccessCompanyCard())
        return this.userAccountInfoObj.cardList.paymentMethod;
    } else if (this.userAccountInfoObj && this.userAccountInfoObj.cardList
      && this.userAccountInfoObj.cardList.card_list && this.userAccountInfoObj.cardList.card_list.length > 0) {
      return 'PERSONAL_CARD';
    } else if (this.canAccessCompanyCard()) {
      return 'BILL_TO_COMPANY';
    } else {
      return 'PERSONAL_CARD';
    }
  }

  public getUserCompany() {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo.is_corporate_user) {
      return this.userAccountInfoObj.userInfo.companyName;
    } else {
      return "";
    }
  }
  public getUserCompanyId() {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo.is_corporate_user) {
      return this.userAccountInfoObj.userInfo.companyId;
    } else {
      return -1;
    }
  }

  public isLoggedIn(): boolean {
    return this.userAccountInfoObj && this.userAccountInfoObj.userInfo ? true : false;
  }

  public getUserEmail(): string {
    if (this.isLoggedIn()) {
      return this.userAccountInfoObj.userInfo.email;
    } else {
      return this.userEmail;
    }
  }

  public getGallopCash(): number {
    if (this.userAccountInfoObj && this.userAccountInfoObj.gallopCash) {
      return this.userAccountInfoObj.gallopCash.amount;
    } else {
      return 0.00;
    }
  }


  public getUserName(): string {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo) {
      return this.userAccountInfoObj.userInfo.fullName;
    } else {
      return 'You';
    }
  }

  public getUserFirstName(): string {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo) {
      if (this.userAccountInfoObj.userInfo.firstName && this.userAccountInfoObj.userInfo.lastName) {
        return (this.userAccountInfoObj.userInfo.firstName.charAt(0) + this.userAccountInfoObj.userInfo.lastName.charAt(0));
      } else {
        return this.userAccountInfoObj.userInfo.firstName.charAt(0);
      }
    } else {
      return 'You';
    }
  }


  public getUserPhoneNumber() {
    if (this.userAccountInfoObj
      && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.phoneNumber
      && this.userAccountInfoObj.userInfo.phoneNumber.number
      && this.userAccountInfoObj.userInfo.phoneNumber.number.trim().length > 0)
      return this.userAccountInfoObj.userInfo.phoneNumber.countryCode
        + this.userAccountInfoObj.userInfo.phoneNumber.number;
  }
 isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
  public getUserImage(): string {
    if (this.userAccountInfoObj && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.userEntityData
      && this.userAccountInfoObj.userInfo.userEntityData.google_info
      && this.userAccountInfoObj.userInfo.userEntityData.google_info.trim().length > 0
    ) {
      let jsonVerofied = this.isJsonString(this.userAccountInfoObj.userInfo.userEntityData.google_info);
      if(jsonVerofied){
      let google_info_obj = JSON.parse(this.userAccountInfoObj.userInfo.userEntityData.google_info);
      if (typeof google_info_obj == 'string') {
        google_info_obj = JSON.parse(google_info_obj);
      }
      if (google_info_obj.image && google_info_obj.image.url && google_info_obj.image.url.trim().length > 0) {
        return google_info_obj.image.url;
      }
    }else{
      return Constants.DEFAULT_PROFILE_IMAGE;
    }
    }
    return Constants.DEFAULT_PROFILE_IMAGE;

  }


  public clearLoggedInData() {
    this.gallopLocalStorage.clear();
    this.userAccountInfoObj = undefined;
    this.userEmail = undefined;
    this.feedbackSubmitSuccessfully = false;
    this.sToken = undefined;
    localStorage.setItem("loginSession", null);
    this.gallopLocalStorage.removeItem('userid');
    this.gallopLocalStorage.removeItem('sToken');
    this.userAccountInfoObjObserver$.next(null);
    this.userAccountInitObjObserver$.next(null);
    this.userSessionChangePrompt$.next(null);
    this.userCredentials.setUserId(null);
    this.userCredentials.setSToken(null);
    this.userIsAdmin = false;
    this.userIsDeptAdmin = false;
  }
  public signOut() {
   
    this.clearLoggedInData();
    this.router.navigate(['/login']);
  }

  updateAvailableGallopCash(gallopCash: number) {
    if (this.userAccountInfoObj && this.userAccountInfoObj.gallopCash) {
      this.userAccountInfoObj.gallopCash.amount = gallopCash;
      this.broadcastUserAcountInfo(this.userAccountInfoObj);
    }

  }

  public getSToken() {
    return this.sToken;
  }
public isUserIsTravelManager(){
  if (this.userAccountInfoObj
    && this.userAccountInfoObj.userInfo
    && this.userAccountInfoObj.userInfo.employeeInfo
  ) {
    return this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.TRAVEL_MANAGER
  } else {
    return false;
  }
}
  public isUserCorporateAdmin() {
    if (this.userAccountInfoObj
      && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.employeeInfo
    ) {
      return this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.APPROVER
        || this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.ADMIN
        || this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.DEPT_ADMIN;
    } else {
      return false;
    }
  }

  public isUserCompanyAdmin() {
    if (this.userAccountInfoObj
      && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.employeeInfo
    ) {
      return this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.ADMIN;
    } else {
      return false;
    }
  }
  public showSettingsTab() {
    return this.isUserCompanyAdmin();
  }

  public showPoliciesTab() {
    if (this.userAccountInfoObj
      && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.employeeInfo
    ) {
      return this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.DEPT_ADMIN
        || this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.ADMIN;
    } else {
      return false;
    }
  }

  public canUserAddEditPolicies() {
    if (this.userAccountInfoObj
      && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.employeeInfo
    ) {
      return this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.ADMIN;
    } else {
      return false;
    }
  }

  public showEmployeesTab() {
    if (this.userAccountInfoObj
      && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.employeeInfo
    ) {
      return this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.DEPT_ADMIN
        || this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.ADMIN
        || this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.MANAGER;
    } else {
      return false;
    }
  }
  public showReportsTab() {
    if (this.userAccountInfoObj
      && this.userAccountInfoObj.userInfo
      && this.userAccountInfoObj.userInfo.employeeInfo
    ) {
      return this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.DEPT_ADMIN
        || this.userAccountInfoObj.userInfo.employeeInfo.role as Role === Role.ADMIN;
    } else {
      return false;
    }
  }
  public convertLocalizationFile() {
    this.http.get("/assets/i18n/de.json")
      .subscribe(
        data => {
          this.http.get("/assets/i18n/en.json")
            .subscribe(
              enData => {
                var newObj = {};
                this.mergeRecursive(data, enData);
              }
            );
        }
      );
  }

  convertEachRecursive(obj, newObj) {
    for (var k in obj) {
      if (typeof obj[k] == "object" && obj[k] !== null) {
        var newTempObj = {};
        newObj['LNGKEY_' + k.toUpperCase()] = newTempObj;
        this.convertEachRecursive(obj[k], newTempObj);
      } else {
        newObj['LNGKEY_' + k.toUpperCase()] = obj[k];
      }
    }
  }
  convertBackEachRecursive(obj, origKeyObj, newObj) {
    for (var k in origKeyObj) {
      if (typeof origKeyObj[k] == "object" && origKeyObj[k] !== null) {
        var newTempObj = {};
        newObj[k] = newTempObj;
        if (('LNGKEY_' + k.toUpperCase()) in obj) {
          this.convertBackEachRecursive(obj['LNGKEY_' + k.toUpperCase()], origKeyObj[k], newTempObj);
        }
      } else {
        if (('LNGKEY_' + k.toUpperCase()) in obj) {
          newObj[k] = obj['LNGKEY_' + k.toUpperCase()];
        }
      }
    }
  }

  mergeRecursive(obj, origKeyObj) {
    for (var k in origKeyObj) {
      if (typeof origKeyObj[k] == "object" && origKeyObj[k] !== null) {
        if (k in obj) {
          this.mergeRecursive(obj[k], origKeyObj[k]);
        } else {
          obj[k] = origKeyObj[k];
        }
      } else {
        if (!(k in obj)) {
          obj[k] = origKeyObj[k];
        }
      }
    }
  }
}
