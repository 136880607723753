import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BookingService } from '../booking.service';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { DeviceDetailsService } from '../device-details.service';
import { Subscription } from 'rxjs';
import { SeatSelect } from '../entity/seatSelected';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PlatformLocation } from '@angular/common';
import { FlightSearchRequest } from '../entity/flight-search-request';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { CommonUtils } from '../util/common-utils';
import { SearchService } from '../search.service';
@Component({
  selector: 'app-seat',
  templateUrl: './seat.component.html',
  styleUrls: ['./seat.component.scss']
})
export class SeatComponent implements OnInit {
  @Input() noOfPassengers: number;
  @Input() seatIndex: number;
  @Output() goBackEmitterSeat = new EventEmitter();
  @Output() proceedTopay = new EventEmitter();
  results: any;
  bsModalRef: BsModalRef;
  isMobile = false;
  index = 0;
  max = [];
  currMaxSeats = 0;
  hopIndex = 0;
  showSeats = true;
  indexSelect = 0;
  source: Array<any> = [];
  dest = [];
  totalFreeSeat: Array<any> = [];
  seatData: Array<any>[];
  responseData: any = [];
  responseSeatData: any = [];
  selectedSeat = false;
  seatSelectArray: SeatSelect[]
  selectedSeatArray: Array<any>[];
  selectedSeatArray1 = [];
  deviceSubscription: Subscription;
  constructor(private bookingService: BookingService,
    private toastr: ToastrService,
    private bsModelLoginRef: BsModalRef,
    private searchService1: SearchService,
    private deviceDetailsService: DeviceDetailsService,
    private gallopLocalStorage: GallopLocalStorageService,
    location2: PlatformLocation) {
    location2.onPopState(() => {
      if (this.bsModelLoginRef) {
        this.bsModelLoginRef.hide();
      }
    });
  }

  ngOnInit() {
    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    if (this.gallopLocalStorage.getItem('flightSearchRequestForBooking') && this.gallopLocalStorage.getItem('flightSearchRequestForBooking') !== null) {
      let flightSearchReq: FlightSearchRequest = deserialize(JSON.parse(this.gallopLocalStorage.getItem('flightSearchRequestForBooking')));
      if (flightSearchReq.travellers.infants) {
        this.bookingService.travellers = (flightSearchReq.travellers.adults + flightSearchReq.travellers.children);
      }
    } this.selectedSeatArray = [];
    this.getSeatData();
  }
  onModelCancel() {
    this.searchService1.selectedSeatArrayForPaymentPage = [...this.selectedSeatArray];
    if (!this.searchService1.paymentPage) {
      history.go(-1);
    }
    this.bsModelLoginRef.hide();
  }
  getCurrencySymbol(currencyCode: string): string {
    return CommonUtils.getCurrencySymbol(currencyCode);
  }
  getSeatData() {
    this.responseData = this.bookingService.forMultipleBooking[this.seatIndex];
    this.selectedSeatArray = new Array(this.responseData.length).fill(null).map(_ => [])
    if(this.searchService1.multiTripBooking){
    let seatArray = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
    
    if(seatArray && seatArray.length > 0){
      let counter=0;
      if(seatArray[this.seatIndex] && seatArray[this.seatIndex].length > 0){
        
        for(let item of seatArray[this.seatIndex]){
          let seatTempArray=[];
          seatTempArray.push(item.seatNumber);
          this.bookingService.selectedSeatArray[counter] = seatTempArray;
            counter++;
          
        }
       // this.selectedSeatArray[this.seatIndex] = seatArray[this.seatIndex]
      }else{
        this.bookingService.selectedSeatArray=[];
      }

    }else{
      this.bookingService.selectedSeatArray=[];
    }
  }
    this.searchService1.selectedSeatArrayForPaymentPage = new Array(this.responseData.length).fill(null).map(_ => []);
    if (this.bookingService.selectedSeatArray && this.bookingService.selectedSeatArray.length > 0) {
      for (let counter = 0; counter < this.bookingService.selectedSeatArray.length; counter++) {
        this.selectedSeatArray[counter] = this.bookingService.selectedSeatArray[counter];
      }
    }
    this.seatData = new Array(this.responseData.length).fill(null).map(_ => []);
    for (let item of this.responseData) {
      //for(let  item1 of item){
      // for(let  item2 of item1.flight_hops){
      //  flightObject.flightNumber = item2.flightNumber;
      let flightObject = { flightNumber: '', source: '', dest: '', group: '' }
      flightObject.source = item.origin;
      flightObject.dest = item.destination;
      flightObject.group = item.group;
      this.source.push(flightObject);
      //}
      //}
    }
    this.mapSeatWithHop();

  }
  mapSeatWithHop() {
    let j = 0
    for (let i = 0; i < this.source.length; i++) {
      var getIndex;
      let source = this.source[i].source;
      let destination = this.source[i].dest;
      let datetime = this.source[i].group;
      getIndex = this.responseData.find(item =>
        (item.origin === source && item.destination === destination && item.group === datetime));
      if (getIndex && getIndex.seat) {
        this.responseSeatData[j] = getIndex;
        j = j + 1;
      }
    }
    //  
    if (this.responseSeatData && this.responseSeatData.length > 0) {
      for (let counter = 0; counter < this.responseSeatData.length; counter++) {
        this.seatData[counter] = this.responseSeatData[counter].seat;
      }
      this.getMaximumSeat(this.seatData);
    }

    this.getTotalFreeSeat();

    this.isHopHaveSeats()
    //
  }
  getMaximumSeat(data) {
    var maxNum = data[0][0].row.length;
    for (let i = 0; i < data.length; i++) {
      for (let item of data[i]) {
        if (item.length > maxNum)
          maxNum = item.length;

      }
      this.max.push(maxNum);
    }
  }
  isHopHaveSeats() {
    let source = this.source[this.hopIndex].source;
    let destination = this.source[this.hopIndex].dest;
    var getIndex = this.responseData.find(item =>
      (item.origin === source && item.destination === destination)
    )
    // 
    if (!getIndex) {
      this.showSeats = false;
      // this.hopIndex = this.hopIndex+1
    } else {
      this.showSeats = true;
    }
  }

  getTotalFreeSeat() {
    for (let counter = 0; counter < this.responseData.length; counter++) {
      let i = 0;
      for (let item of this.seatData[counter]) {
        for (let seat of item.row) {
          if ((seat.type === 'Seat' && seat.available === 'AVAILABLE') || (seat.type === 'Seat' && seat.available === 'Premium')) {
            i = i + 1
          }
        }
      }
      this.totalFreeSeat[counter] = i;
    }
    //
  }
  selectIndexForSeat() {
    this.hopIndex = (this.hopIndex + 1);
    this.isHopHaveSeats();
  }
  selectIndexDecrease() {
    if (this.index > 0) {
      this.index = (this.index - 1);
    }
    this.hopIndex = (this.hopIndex - 1);
    this.isHopHaveSeats();
  }
  selectIndex() {
    if (this.index !== this.seatData.length - 1) {
      // this.searchService1.selectedSeatArrayForPaymentPage[this.index].push(this.selectedSeatArray[this.index]);
      this.index = (this.index + 1);
    }

    this.hopIndex = (this.hopIndex + 1);
    this.isHopHaveSeats();
  }
  selectSeat(code) {
    console.log("travellers",this.bookingService.travellers);
    if (this.getSeatSelected(code) !== undefined) {
      var index1 = this.selectedSeatArray[this.index].indexOf(code);
      this.selectedSeatArray[this.index].splice(index1, 1);
      //this.searchService1.selectedSeatArrayForPaymentPage[this.index].splice(index1,1);
    } else if (this.selectedSeatArray[this.index].length === this.bookingService.travellers) {
      this.getSeat(code)
    } else {
      this.selectedSeatArray[this.index].push(code);
      //this.searchService1.selectedSeatArrayForPaymentPage[this.index].push(code);
    }
  }
  haveWings(str) {
    let string = 'wing'
    if (str && str.length !== 0) {
      str = JSON.stringify(str);
      if (str.toLowerCase().search(string) !== -1) {
        return true;
      } else {
        return false;
      }
    }

  }
  haveExitRow(str) {
    let string = 'exit'
    if (str && str.length !== 0) {
      str = JSON.stringify(str);
      if (str.toLowerCase().search(string) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  getSeat(code) {
    if (this.indexSelect === this.bookingService.travellers) {
      this.indexSelect = 0;
      this.selectedSeatArray[this.index][this.indexSelect] = code;
      // this.searchService1.selectedSeatArrayForPaymentPage[this.index][this.indexSelect] =code;
      this.indexSelect = this.indexSelect + 1;
    } else {
      this.selectedSeatArray[this.index][this.indexSelect] = code;
      // this.searchService1.selectedSeatArrayForPaymentPage[this.index][this.indexSelect] =code;
      this.indexSelect = this.indexSelect + 1;
    }
  }
  getSeatSelected(code): string {
    let temp;
    if (this.selectedSeatArray[this.index].length !== undefined) {
      temp = this.selectedSeatArray[this.index].find(value =>
        value === code);
    }
    return temp;
  }
  selectSkip() {
    if (this.selectedSeatArray[this.index].length !== this.bookingService.travellers) {
      this.selectedSeatArray[this.index] = [];
      this.searchService1.selectedSeatArrayForPaymentPage[this.index] = [];
      this.searchService1.seatForMultipleBooking[this.seatIndex] = []
    }
    //this.searchService1.selectedSeatArrayForPaymentPage[this.index].push('empty');
    if (this.index === this.seatData.length - 1) {
      this.seatSelectArray = [];
      let travellersArray = this.bookingService.getTravelerDetails('WebSearch');
      this.gallopLocalStorage.removeItem("selectedSeat");
      for (let counter = 0; counter < this.selectedSeatArray.length; counter++) {
        this.bookingService.selectedSeatArray[counter] = this.selectedSeatArray[counter];
      }
      for (let i = 0; i < this.responseSeatData.length; i++) {
        // var passenger = parseInt(this.responseData[i].passengerName.match(/\d+/),10);
        for (let j = 0; j < this.selectedSeatArray[i].length; j++) {
          let seatObject: SeatSelect = { origin: '', destination: '', airlineCode: '', airlineNumber: '', passengerName: '', group: '', seatNumber: [] };
          seatObject.origin = this.responseSeatData[i].origin;
          seatObject.destination = this.responseSeatData[i].destination;
          seatObject.airlineCode = this.responseSeatData[i].airlineCode;
          seatObject.airlineNumber = this.responseSeatData[i].airlineNumber;
          seatObject.passengerName = travellersArray.travellers[j].firstName;
          seatObject.group = this.responseSeatData[i].group;
          seatObject.seatNumber = this.selectedSeatArray[i][j];
          this.seatSelectArray.push(seatObject);
        }
      }

      this.gallopLocalStorage.setItem("selectedSeat", JSON.stringify(this.seatSelectArray));
      this.bookingService.proceedButton = true;
      this.bsModelLoginRef.hide();
    } else {
      this.index = (this.index + 1);
      this.hopIndex = (this.hopIndex + 1);
      this.isHopHaveSeats();
    }
  }
  proceedForPayment() {
    this.seatSelectArray = [];
    this.searchService1.seatSelectArray1 = [];
    if (this.selectedSeatArray[this.index].length !== this.bookingService.travellers) {
      this.selectedSeatArray[this.index] = [];
      // this.searchService1.selectedSeatArrayForPaymentPage[this.index]=[];
    }
    let travellersArray = this.bookingService.getTravelerDetails('WebSearch');
    if(!this.searchService1.multiTripBooking){
    this.gallopLocalStorage.removeItem("selectedSeat");
    }
    for (let counter = 0; counter < this.selectedSeatArray.length; counter++) {
      this.bookingService.selectedSeatArray[counter] = this.selectedSeatArray[counter];
    }
    this.searchService1.selectedSeatArrayForPaymentPage[this.seatIndex] = [...this.selectedSeatArray];
    for (let i = 0; i < this.responseSeatData.length; i++) {
      // var passenger = parseInt(this.responseData[i].passengerName.match(/\d+/),10);
      for (let j = 0; j < this.selectedSeatArray[i].length; j++) {
        let seatObject: SeatSelect = { origin: '', destination: '', airlineCode: '', airlineNumber: '', passengerName: '', group: '', seatNumber: [] };
        seatObject.origin = this.responseSeatData[i].origin;
        seatObject.destination = this.responseSeatData[i].destination;
        seatObject.airlineCode = this.responseSeatData[i].airlineCode;
        seatObject.airlineNumber = this.responseSeatData[i].airlineNumber;
        seatObject.passengerName = travellersArray.travellers[j].firstName;
        seatObject.group = this.responseSeatData[i].group;
        seatObject.seatNumber = this.selectedSeatArray[i][j];
        this.seatSelectArray.push(seatObject);
      }
    }
    this.searchService1.paymentPage = true;
    this.searchService1.seatSelectArray1 = [...this.seatSelectArray];
    this.searchService1.seatForMultipleBooking[this.seatIndex] = [...this.searchService1.seatSelectArray1]
    let selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"));
    let arrayOfSeat = new Array(selectedFlight.length).fill(null).map(_ => []);;
    let seatArray = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
    
    if(seatArray){
      arrayOfSeat=[...seatArray];
      
        arrayOfSeat[this.seatIndex]=this.searchService1.seatForMultipleBooking[this.seatIndex];
      
    }else{
      arrayOfSeat[this.seatIndex]=this.searchService1.seatForMultipleBooking[this.seatIndex];
    }

    this.gallopLocalStorage.setItem("selectedSeat", JSON.stringify(arrayOfSeat));
    this.bookingService.proceedButton = true;
    this.bsModelLoginRef.hide();
  }
  getSourceName(): string {
    if (this.hopIndex !== this.seatData.length - 1) {
      return this.source[this.hopIndex + 1].source;
    }
  }
  getDestName(): string {
    if (this.hopIndex !== this.seatData.length - 1) {
      return this.source[this.hopIndex + 1].dest;
    }
  }
  isSkipShow() {
    if (this.hopIndex === this.seatData.length - 1) {
      var seatsSelected = this.isArrayEmpty();
      return ((this.selectedSeatArray[this.index].length !== this.bookingService.travellers && this.selectedSeatArray[this.index].length < this.totalFreeSeat[this.index]) && seatsSelected);
    } else {
      return (this.selectedSeatArray[this.index].length !== this.bookingService.travellers && this.selectedSeatArray[this.index].length < this.totalFreeSeat[this.index]);
    }
  }
  getSeatCodeToDisplay(input: string) {
    return input.replace('-', '');
  }
  isArrayEmpty() {
    for (let i = 0; i < this.responseSeatData.length; i++) {
      if (this.selectedSeatArray[i].length > 0) {
        return true;
      }
      break;
    }
  }
}
