import { PhoneNumberDTO } from '../phonenumber-dto'

export class CarEmailDTO {
    airConditioning: boolean;
               automaticTransmission: boolean;
               basePrice: 0;
               cardRequired: boolean;
               partnerName: '';
               postPay: boolean;
               rateKey: '';
               status: '';
               traflaPartnerCode: null;
               traflaVehicleCode: null;
               optionSelectionReason: '';
               images: {};
               description: '';
               pickUpLocation: '';
               pickUpLocationType: '';
               dropOffLocation: '';
               destinationCity:string;
               carMake: '';
               dropLocationType:'';
               revalidation:boolean;
               optionId:Number;
               eventId:any;
               paymentRequired:boolean;
               zipCodeRequired :boolean;
               milage: null;
               distance: '';
               passengers: 0;
               bags: 0;
               phoneNumber: {number: string;
                areaCode: string;
                countryCode: string;
                type: string;};
               doors: 0;
               price: 0;
               displayPrice:Number;
               displayBasePrice:Number;
               displayCurrency:String;
               currency: '';
               policy: null;
               handlerType: '';
               partnerLogo: '';
               carClass: '';
               carType: '';
               priceUpdated: null;
               isAvailable: null;
               locationInformation: '';
               
               pickUpLat: null;
               pickUpLng: null;
               dropOffLat: null;
               dropOffLng: null;
               pickUpTime: '';
               dropOffTime: '';
               pickUpDate: '';
               dropOffDate: '';
               
               source: ''
               numberOfDay: 0;
               airportCity: '';
               freeCancellation: null;
               outOfPolicyReason: null;
}