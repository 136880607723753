declare var getApiBaseUrl: any;
export const environment = {
  maxTravellersForFlight: 9,
  maxNumRoomsForHotels: 9, // All API put a limit on 10 rooms
  maxKTNFieldLength: 12,
  hj_site_id_booking: '3229781',
  hj_site_id_dashboard: '3229784',
  locationSupportedInCarSearch: true,
   appVersion : 'dev_27aug24_qa_rsrel_4',
   otaBuildNumber : 2143,
  appStoreVersionName: '1.0.0',
  appStoreBuildNumber: 0,
  plaidEnvironment: 'sandbox',
  platform: 'web',
  production: false,
  multipleLeg: 4,
   amnetSpecificRelease: false,
  presentAlternateInPolicyOptions: false,
  userAirportOnlySearch: true,
  calendarPrefix: getApiBaseUrl('calendarPrefix') + '/subscribe/redir?url=webcal://',
  googleAnalytics: { domain: 'auto', trackingId: 'G-H1T00GNSKD' },
  apiSearchFlightUrl: getApiBaseUrl('apiSearchFlightUrl') + '/api/searchMultiClass',
  apiSearchHotelUrl: getApiBaseUrl('apiSearchHotelUrl') + '/api/searchHotels',
  flightIconUrl: 'https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir',
  hotelImageAPI: getApiBaseUrl('hotelImageAPI') + '/api/allHotelImages',
  apiBookFlightURL: getApiBaseUrl('apiBookFlightURL') + '/api/bookFlightsAsync',
  apiBookTripSessionURL: getApiBaseUrl('apiBookTripSessionURL') + '/api/bookTripSessionAsync',
  apiCheckBookingResponseURL: getApiBaseUrl('apiCheckBookingResponseURL') + '/api/checkBookingResponse',
  apiApproveBookingURL: getApiBaseUrl('apiApproveBookingURL') + '/api/attemptBookingWithApprovalAsync',
  apiApproveTripSessionBookingURL: getApiBaseUrl('apiApproveTripSessionBookingURL') + '/api/attemptTripSessionBookingWithApprovalAsync',
  apiBookHotelURL: getApiBaseUrl('apiBookHotelURL') + '/api/bookHotelsAsync',
  apiRevalidateFlightURL: getApiBaseUrl('apiRevalidateFlightURL') + '/api/revalidate',
  apiRevalidateHotelURL: getApiBaseUrl('apiRevalidateHotelURL') + '/api/reCheckHotelPrice',
  apiroomRulesAndRateDescURL: getApiBaseUrl('apiroomRulesAndRateDescURL') + '/api/roomRulesAndRateDesc',
  apiEmailFlowBookURL: getApiBaseUrl('apiEmailFlowBookURL') + '/api/confirmBookRequest',
  apiUserAccountUrlForFetch: getApiBaseUrl('apiUserAccountUrl') + '/api/userAccount/fetch',
  apiUserAccountUrlforSave: getApiBaseUrl('apiUserAccountUrl') + '/api/userAccount/save',
  apiUserAccountUrlforSaveCard: getApiBaseUrl('apiUserAccountUrlforSaveCard') + '/api/userAccount/addCard',
  apiUserAccountUrlforDeleteCard: getApiBaseUrl('apiUserAccountUrl') + '/api/userAccount/deleteCard',
  apiUserAccountUrlForOtherTraveller: getApiBaseUrl('apiUserAccountUrl') + '/api/userAccount/saveTraveller',
  apiUserPreferenceUrlForOtherTraveller: getApiBaseUrl('apiUserPreferenceUrl') + '/api/userPreferences/saveTraveller',
  apiUserPreferenceUrlForFetch: getApiBaseUrl('apiUserPreferenceUrl') + '/api/userPreferences/fetch',
  apiUserPreferenceUrlForSave: getApiBaseUrl('apiUserPreferenceUrl') + '/api/userPreferences/save',
  apiSavedBookingDetailsUrl: getApiBaseUrl('apiSavedBookingDetailsUrl') + '/api/savedBookingDetails',
  apiEmailQuoteOptionsUrl: getApiBaseUrl('apiEmailQuoteOptionsUrl') + '/api/emailQuoteOptions/fetch',
  apiLogin: getApiBaseUrl('apiLogin') + '/WebUserRequestHandler/websignin',
  apiSignUp: getApiBaseUrl('apiSignUp') + '/WebUserRequestHandler/websignup',
  apiPasswordResetlink: getApiBaseUrl('apiPasswordResetlink') + '/WebUserRequestHandler/requestPasswordResetlink',
  apiResetPassword: getApiBaseUrl('apiResetPassword') + '/WebUserRequestHandler/doResetPasswordURL',
  apiVerifyEmail: getApiBaseUrl('apiVerifyEmail') + '/WebUserRequestHandler/verifyEmail',
  apiVerifyCompanyEmail: getApiBaseUrl('apiVerifyCompanyEmail') + '/WebUserRequestHandler/verifyCorporateEmail',
  apiLogout: getApiBaseUrl('apiLogout') + '/api/logout',
  agentNotifyURL: getApiBaseUrl('agentNotifyURL') + '/AgentServlet/notifyConfirmBook',
  adminNotifyURL: getApiBaseUrl('adminNotifyURL') + '/AgentServlet/notifyAdminApprovalRequest',
  agentGenTransactionURL: getApiBaseUrl('agentGenTransactionURL') + '/AgentServlet/agentConfirmBookTransaction',
  uiAddCardUrl: getApiBaseUrl('uiAddCardUrl') + 'https://beta.gallop.ai/biztravel-ui-dev/user-card/index.html',
  stripeAPIKey: 'pk_test_XRQK86fo6wOs9bslgvktAQsc',
  USER_API: getApiBaseUrl('USER_API') + '/api/user/get',
  apiLocationFromIP: getApiBaseUrl('apiLocationFromIP') + '/api/findLocationFromIP',
  webBookingUrl: '/search',
  googleMapsAPIKey: 'AIzaSyA3lywR0fqq2uX6zwfiHpoRxah_zETrSPc',
  googleMapTimeZone:'https://maps.googleapis.com/maps/api/timezone/json',
  googleMapsGeoCodeZipCodeUrl: 'https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:',
  googleMapsGeoCodeLocationeUrl: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
  apiForAirportsOnSearchForm: getApiBaseUrl('apiForAirportsOnSearchForm') + '/autocomplete/airport',
  apiForBookingHistory: getApiBaseUrl('apiForBookingHistory') + '/api/bookingHistory/list',
  apiForBookingHistoryDetail: getApiBaseUrl('apiForBookingHistoryDetail') + '/api/bookingHistory/detail',
  apiForTripSessionBookingHistoryDetail: getApiBaseUrl('apiForBookingHistoryDetail') + '/api/tripSessionbookingHistory/detail',
  apiForBookingHistoryCancel: getApiBaseUrl('apiForBookingHistoryCancel') + '/api/bookingHistory/cancel',
  apiForBookingHistoryCancelApproval:getApiBaseUrl('apiForBookingHistoryCancel') +'/api/tripSessionBookingApproval/cancel',
  apiForBookingHistoryChanges: getApiBaseUrl('apiForBookingHistoryChanges') + '/api/bookingHistory/requestchanges',
  apiForBookingHistoryExpensify: getApiBaseUrl('apiForBookingHistoryExpensify') + '/api/bookingHistory/expensify',
  apiForAdminPolicyForUpdate: getApiBaseUrl('apiForAdminPolicy') + '/api/company/policy/update',
  apiForAdminPolicyForAdd: getApiBaseUrl('apiForAdminPolicy') + '/api/company/policy/add',
  apiForAdminPolicyForDelete: getApiBaseUrl('apiForAdminPolicy') + '/api/company/policy/delete',
  apiForAdminPolicyForGet: getApiBaseUrl('apiForAdminPolicy') + '/api/company/policy/get',
  apiForAdminPolicyForList: getApiBaseUrl('apiForAdminPolicy') + '/api/company/policy/list',
  apiCompanySettings: getApiBaseUrl('apiCompanySettings') + '/api/company/settings/get',
  apiSaveCompanySettings: getApiBaseUrl('apiSaveCompanySettings') + '/api/company/settings/save',
  apiSaveAdminSettings: getApiBaseUrl('apiSaveAdminSettings') + '/api/company/admin/settings/save',
  apiRemoveCompanyDepartment: getApiBaseUrl('apiRemoveCompanyDepartment') + '/api/company/department/remove',
  apiUploadCompanyImage: getApiBaseUrl('apiUploadCompanyImage') + '/image/company/profile/upload',
  apiUploadProfileImage: getApiBaseUrl('apiUploadProfileImage') + '/image/user/profile/upload',
  apiCompanyEmployeeCheckDetail: getApiBaseUrl('apiCompanyEmployeeCheckDetail') + '/api/company/employee/checkDetail',
  apiCompanyAddEmployee: getApiBaseUrl('apiCompanyAddEmployee') + '/api/company/employee/add',
  apiCompanyInviteEmployee: getApiBaseUrl('apiCompanyInviteEmployee') + '/api/company/employee/invite',
  apiCompanyDeleteEmployee: getApiBaseUrl('apiCompanyDeleteEmployee') + '/api/company/employee/delete',
  apiCompanyEmployeeList: getApiBaseUrl('apiCompanyEmployeeList') + '/api/company/employee/list',
  apiCompanyReports: getApiBaseUrl('apiCompanyReports') + '/api/company/reports/get',
  apiCompanyApprovalsList: getApiBaseUrl('apiCompanyApprovalsList') + '/api/company/approvals/list',
  apiChartsData: getApiBaseUrl('apiChartsData') + '/Charts/data',
  apiCompanyApprovalDetail: getApiBaseUrl('apiCompanyApprovalDetail') + '/api/company/approvals/detail',
  apiCompanyTripSessionApprovalDetail: getApiBaseUrl('apiCompanyTripSessionApprovalDetail') + '/api/company/tripSessionApprovals/detail',
  apiCompanyApprovalReviewAction: getApiBaseUrl('apiCompanyApprovalReviewAction') + '/api/company/approvals/reviewActionAsync',
  apiCompanyTripSessionApprovalReviewAction: getApiBaseUrl('apiCompanyApprovalReviewAction') + '/api/company/approvals/reviewTripSessionActionAsync',
  apiDownloadCompanyReport: getApiBaseUrl('apiDownloadCompanyReport') + '/api/company/reports/download',
  apiCompanyReportDetail: getApiBaseUrl('apiCompanyReportDetail') + '/api/company/reports/get/detail',
  apiForGoogleAutoCompletePredictions: getApiBaseUrl('apiForGoogleAutoCompletePredictions') + '/api/getGoogleAutocompletePredictions',
  apiInitializeClient: getApiBaseUrl('apiInitializeClient') + '/api/initClient',
  apiForCarSearch: getApiBaseUrl('apiForCarSearch') + '/api/searchCars',
  apiForCarRevalidation: getApiBaseUrl('apiForCarRevalidation') + '/api/revalidateCar',
  apiForGraphData: getApiBaseUrl('apiForGraphData') + '/Charts/dashboardData?',
  apiForCarBooking: getApiBaseUrl('apiForCarBooking') + '/api/bookCar',
  apiAddCompanyDepartment: getApiBaseUrl('apiAddCompanyDepartment') + '/api/company/department/add',
  apiUpdateCompanyDepartment: getApiBaseUrl('apiUpdateCompanyDepartment') + '/api/company/department/edit',
  apiDeleteCompanyDepartment: getApiBaseUrl('apiDeleteCompanyDepartment') + '/api/company/department/remove',
  apiFaq: getApiBaseUrl('apiFaq') + '/api/faqs',
  apiForSeat: getApiBaseUrl('apiForSeat') + '/api/getFlightSeat',
  apiForTrack: getApiBaseUrl('apiForTrack') + '/useractivity/activity',
  apiForActiveTravellers: getApiBaseUrl('apiForActiveTravellers') + '/api/company/activeTravellers/get',
  apiForGetMessage: getApiBaseUrl('apiForGetMessage') + '/api/company/messages/get',
  apiForSentMessage: getApiBaseUrl('apiForSentMessage') + '/api/company/messages/send',
  apiForFeedBack: getApiBaseUrl('apiForFeedBack') + '/api/hotel/feedback',
  apiForTravelCredit: getApiBaseUrl('apiForTravelCredit') + '/api/company/credits',
  apiUploadReconcileFile: getApiBaseUrl('apiUploadReconcileFile') + '/api/recon',
  apiForSlackOAuth: getApiBaseUrl('apiForSlackOAuth') + '/slack/redir',
  apiForSlackDisconnect: getApiBaseUrl('apiForSlackDisconnect') + '/slack/disconnect',
  apiForSlackStatusCheck: getApiBaseUrl('apiForSlackStatusCheck') + '/slack/check',
  urlForSlackConnect: getApiBaseUrl('urlForSlackConnect') + '/slack/init',
  apiForLcc: getApiBaseUrl('apiForLcc') + '/api/lccList',
  apiForresetApprovers: getApiBaseUrl('apiForresetApprovers') + '/api/company/employee/resetApprovers',
  apiForHotelRecommendation: getApiBaseUrl('apiForHotelRecommendation') + '/admin/prioritize',
  apiForRemoveHotelRecommendation: getApiBaseUrl('apiForRemoveHotelRecommendation') + '/admin/dePrioritize',
  apiForNormalizeHotelRecommendation: getApiBaseUrl('apiForRemoveHotelRecommendation') + '/admin/normalize',
  apiForCardDetails: getApiBaseUrl('apiForCardDetails') + '/cards/getCards',
  apiForCardTransactions: getApiBaseUrl('apiForCardTransactions') + '/cards/getCardTransactions',
  apiForCardStatus: getApiBaseUrl('apiForCardStatus') + '/issuing/fluidpay/getCards',
  apiForCardReportTransaction: getApiBaseUrl('apiForCardReportTransaction') + '/issuing/fluidpay/transactions',
  apiForCardTransaction: getApiBaseUrl('apiForCardTransaction') + '/issuing/fluidpay/issuingTransactions',
  apiForUpdateTransactionExpense: getApiBaseUrl('apiForUpdateTransactionExpense') + '/issuing/fluidpay/updateTransactionExpenseCategory',
  apiForUpdateTransactionMemo: getApiBaseUrl('apiForUpdateTransactionMemo') + '/issuing/fluidpay/updateTransactionMemo',
  apiForUpdateTransactionReceipt: getApiBaseUrl('apiForUpdateTransactionReceipt') + '/issuing/fluidpay/uploadTransactionReceipt',
  apiForDeleteTransactionReceipt: getApiBaseUrl('apiForDeleteTransactionReceipt') + '/issuing/fluidpay/removeReciept',
  apiForTransactionSync: getApiBaseUrl('apiForTransactionSync') + '/issuing/fluidpay/syncTransactions',
  apiForTransactionStatusSync: getApiBaseUrl('apiForTransactionStatusSync') + '/issuing/fluidpay/checkSyncStatus',
  apiForUserTransactionSave: getApiBaseUrl('apiForUserTransactionSave') + '/issuing/fluidpay/issuingTransactionsOfUser',
  apiForAddCard: getApiBaseUrl('apiForAddCard') + '/issuing/fluidpay/createCard',
  apiForUpdateCard: getApiBaseUrl('apiForUpdateCard') + '/issuing/fluidpay/updateCardStatus',
  apiForUpdateCardDetails: getApiBaseUrl('apiForUpdateCardDetails') + '/issuing/fluidpay/updateCardDetails',
  apiForTransactionCardDetails: getApiBaseUrl('apiForTransactionCardDetails') + '/issuing/fluidpay/issuingTransactrion',
  apiForLiveBalance: getApiBaseUrl('apiForLiveBalance') + '/issuing/fluidpay/issuingBalance',
  apiForRevealCard: getApiBaseUrl('apiForRevealCard') + '/issuing/fluidpay/revealCardDetails',
  apiForRequestCard: getApiBaseUrl('apiForRequestCard') + '/issuing/fluidpay/requestAccess',
  urlForADSignIn: getApiBaseUrl('urlForADSignIn') + '/msalHelper/initiateSignin',
  apiForADSignIn: getApiBaseUrl('apiForADSignIn') + '/msalHelper/handleResponseFromMSAL',
  apiForADSSOstatus: getApiBaseUrl('apiForADSSOstatus') + '/msalHelper/getSSOStatus',
  apiForGetAllTags: getApiBaseUrl('apiForGetAllTags') + '/tagsv2/tags/get',
  apiForUpdateTags: getApiBaseUrl('apiForUpdateTags') + '/tagsv2/tags/update',
  apiForUpdateAllTags: getApiBaseUrl('apiForUpdateTags') + '/tagsv2/tags/updateAll',
  apiForSaveTags: getApiBaseUrl('apiForSaveTags') + '/api/company/tags/create',
  apiForSaveTagsAtTrip: getApiBaseUrl('apiForSaveTagsAtTrip') + '/api/company/tags/updateProjectTagForTripBooking',
  apiForQuickBooks: getApiBaseUrl('apiForQuickBooks') + '/qbo/checkQBOConnectStatus',
  apiForDisconnectQuickBooks: getApiBaseUrl('apiForDisconnectQuickBooks') + '/qbo/disconnect',
  apiForQuickBooksInitiate: getApiBaseUrl('apiForQuickBooksInitiate') + '/qbo/initiate',
  plaidInitUrl: getApiBaseUrl('plaidInitUrl') + '/issuing/initiatePlaid',
  plaidIssuingUrl: getApiBaseUrl('plaidIssuingUrl') + '/issuing/createStripeBankAccountToken',
  getUseraAsignedCards: getApiBaseUrl('getUseraAsignedCards') + '/issuing/fluidpay/getAllUserCards',
  apiForFundVerficationCodeCard: getApiBaseUrl('apiForFundVerficationCodeCard') + '/issuing/fluidpay/sendVerificationCode',
  apiForTransferFundCard: getApiBaseUrl('apiForTransferFundCard') + '/issuing/fluidpay/confirmTransfer',
  apiForAllzFares: getApiBaseUrl('apiForAllzFares') + '/api/fetchAllFares',
  apiForOnboardingList: getApiBaseUrl('apiForOnboardingList') + '/api//onboarding/list',
  apiForUpdateOnboarding: getApiBaseUrl('apiForUpdateOnboarding') + '/api//onboarding/updateItem',
  apiForUpdateRoutespring: getApiBaseUrl('apiForUpdateRoutespring') + '/api/updates/list',
  apiForUpdateItemRoutespring: getApiBaseUrl('apiForUpdateItemRoutespring') + '/api/updates/updateItemsViewed',
  apiForUpdateItemClickedRoutespring: getApiBaseUrl('apiForUpdateItemClickedRoutespring') + '/api/updates/updateItemClicked',
  apiForWdrApproval: getApiBaseUrl('apiForWdrApproval') + '/api/company/reports/approvals/get',
  apiForPaymentOptions: getApiBaseUrl('apiForPaymentOptions') + '/company/payments/payment_config_details',
  apiForUploadDocumentsForPayLater: getApiBaseUrl('apiForUploadDocumentsForPayLater') + '/company/payments/upload_docs',
  apiForGetUploadDocumentsForPayLater: getApiBaseUrl('apiForGetUploadDocumentsForPayLater') + '/company/payments/company_docs',
  apiForUpdateCreditCard: getApiBaseUrl('apiForUpdateCreditCard') + '/company/payments/update_credit_card_details',
  apiForUpdateCreditCardDropdown: getApiBaseUrl('apiForUpdateCreditCardDropdown') + '/company/payments/update_payment_details',
  apiForGetTnplDetails: getApiBaseUrl('apiForUpdateCreditCard') + '/company/payments/get_tnpl_application_status',
  apiDeleteCompanyCard: getApiBaseUrl('apiDeleteCompanyCard') + '/company/payments/deleteCard',
  apiForUpdateTnplDetails: getApiBaseUrl('apiForUpdateCreditCardDropdown') + '/company/payments/update_tnpl_application_status',
  apiForUpdateQbocardDetails: getApiBaseUrl('apiForUpdateCreditCardDropdown') + '/company/payments/map_qbo_account_to_corp_card',
  apiForUpdateQbocardExpenseSave: getApiBaseUrl('apiForUpdateQbocardExpenseSave') + '/company/payments/qbo_exp_category_for_travel',
  apiForTagsets: getApiBaseUrl('apiForUpdateCreditCardDropdown') + '/tagsv2/tags/get',
  apiForTagsetsSave: getApiBaseUrl('apiForUpdateCreditCardDropdown') + '/tagsv2/tags/create',
  apiForTagsetsUpdate: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/tagsv2/tagset/edit',
  apiForTagsetsList: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/tagsv2/tags/activeTags',
  apiForAcceptTerms: getApiBaseUrl('apiForUpdateRoutespring') + '/issuing/fluidpay/acceptUserTerms',
  apiForGoogleSignGetSSOStatus: getApiBaseUrl('apiForGoogleSignGetSSOStatus') + '/gOAuth/getSSOStatus',
  googleSignInUrl: getApiBaseUrl('googleSignInUrl') + '/gOAuth/initiateSignin',
  apiForUatpList: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/company/payments/list_uatp_cards',
  apiForAddUatpCard: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/company/payments/add_uatp_card',
  apiForEditUatpCard: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/company/payments/edit_uatp_card',
  apiForDeleteUatpCard: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/company/payments/delete_uatp_card',
  apiForAirlineTourList: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/settings/company/tour_code/list',
  apiForAddAirlineTourCode: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/settings/company/tour_code/save',
  apiForEditAirlineTourCode: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/settings/company/tour_code/edit',
  apiForDeleteAirlineTourCode: getApiBaseUrl('apiForUpdateCreditCardDropdown')+ '/settings/company/tour_code/delete',
  apiForTravelManagerManagement: getApiBaseUrl('apiForTravelManagerManagement')+ '/api/bookingHistory/listAllBookings',
  apiForMappingStar: getApiBaseUrl('apiForMappingStar')+ '/api/ngs/star_mappings',
  apiForTravellersList: getApiBaseUrl('apiForTravellersList')+ '/api/company/travellers',
  apiForCheckDataConstraint: getApiBaseUrl('apiForCheckDataConstraint')+ '/tandc/checkDataConsentRequirement',
  apiForOktaDataRendering:getApiBaseUrl('apiForOktaDataRendering')+ '/settings/company/getSAMLConfigs',
  apiForOktaDataSaving:getApiBaseUrl('apiForOktaDataSaving')+ '/settings/company/saveSAMLConfig',
  apiForSAMLDataSaving:getApiBaseUrl('apiForOktaDataSaving')+ '/settings/company/requestSAMLActivation',
  apiForSAMLMetaDataSaving:getApiBaseUrl('apiForOktaDataSaving')+ '/settings/company/uploadSSOMetaDataFile',
  apiForSAMLDataDeactivate:getApiBaseUrl('apiForOktaDataSaving')+ '/settings/company/deactivateSAML',
  apiForSSoLogin:getApiBaseUrl('apiForSSoLogin')+ '/saml_sso/sso_policy',
  apiForFindingDuplicateBooking:getApiBaseUrl('apiForFindingDuplicateBooking')+ '/api/activeBookings/get',
  apiForFindingMoreHotel:getApiBaseUrl('apiForFindingDuplicateBooking')+ '/autocomplete/hotelByName',
  apiForFindingMoreHotelRevalidation:getApiBaseUrl('apiForFindingDuplicateBooking')+ '/api/searchSpecificHotel',
  apiForlogout: getApiBaseUrl('apiForTravellersList')+ '/api/logout',
};

