import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event, Params, NavigationStart } from '@angular/router';
import * as $ from 'jquery';
import { UserAccountService } from './user-account.service';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UserAccountInfo } from './entity/user-account-info';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeleteCardModelComponent } from './email-booking-flow/delete-card-model/delete-card-model.component';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtils } from './util/common-utils';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SearchService } from './search.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DeviceDetailsService } from './device-details.service';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { ConnectionService } from 'ng-connection-service';
import { InitClientComponent } from './cancel-success/init-client-component';
import { BookingService } from './booking.service';
import { LoginService } from './login.service';
import { ClientConfiguration } from './client-config.service';
import { FeedbackComponent } from './feedback/feedback.component';
import { filter } from 'rxjs/operators';
import { AdminPanelService } from './admin-panel.service';
import { UserCredentialsService } from './user-credentials.service';
import { Constants } from './util/constants';
import { PopupComponent } from './popup/popup.component';
import { isJsxSelfClosingElement } from 'typescript';
export let browserRefresh = false;


declare var getCurrentlyOpenNgxSmartModalIds: any;
declare var setNgxSmartModalOpenStateClosed: any;
declare var gtag: any;
declare var stopEventsBehindCal: any;
declare var startEventsBehindCal: any;
declare var isNJoySpecificParamInUrl: any;
declare var getStoreAppVersionName: any;
declare var getAppBuildNumber: any;
declare var getAppPlatform: any;
declare var setUPRollBar: any;
declare var setAppLoaded: any;
declare var initializeHotJar: any;
declare var addingHeader:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private fetchAccountInfoSubscription: Subscription;
  bsModalRef: BsModalRef;
  bsModalHotelreview: BsModalRef;
  njoySpecificBuild: boolean
  isMobile: boolean = false;
  isMobile1: boolean = false;
  connectionListener: Subscription;
  userInitListner: Subscription;
  userDetailsSubscription: Subscription;
  private currentUrl: string = '';
  private currentParams: any;
  public currDatePicker;
  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private adminPanelService: AdminPanelService,
    public userAccountService: UserAccountService,
    private deviceDetailsService: DeviceDetailsService,
    private modalService: BsModalService,
    public connectionService: ConnectionService,
    private gallopLocalStorage: GallopLocalStorageService,
    private route: ActivatedRoute, private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    public searchService: SearchService,
    private titleService: Title,
    public bookingService: BookingService,
    private userCredentials: UserCredentialsService,
    public el: ElementRef,
    public clientConfig: ClientConfiguration,
    public renderer: Renderer2) {
    renderer.listen('document', 'click', (event) => {
      let action = event.target.getAttribute('attr.data-track');
      let params1 = event.target.getAttribute('attr.data-params') ? ('&' + 'ua_' + event.target.getAttribute('attr.data-params')) : '';
      let params2 = event.target.getAttribute('data-params') ? ('&' + 'ua_' + event.target.getAttribute('data-params')) : '';
      if (params2) {
        let params = '?' + 'ua_action=' + action + params2;
        this.searchService.letsTrack(params);
      }
      else if (action) {
        let params = '?' + 'ua_action=' + action + params1;
        this.searchService.letsTrack(params);
      }
  
    //  this.connectionListener = this.connectionService.monitor().subscribe(isConnected => {
       
    //  });
     
    });
    localStorage.getItem('loginSession')
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    if (isNJoySpecificParamInUrl()) {
      this.userAccountService.njoySpecificBuild = true;
      this.njoySpecificBuild = true;
    }
    environment.appStoreVersionName = getStoreAppVersionName();
    environment.appStoreBuildNumber = getAppBuildNumber();
    environment.platform = getAppPlatform();
    const navEndEvent$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
  }
  
  
  
  setUpAppMode() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    environment.appStoreVersionName = getStoreAppVersionName();
    environment.appStoreBuildNumber = getAppBuildNumber();
    environment.platform = getAppPlatform();
    const appMode = localStorage.getItem('is_app_mode');
    if (appMode){
      this.userAccountService.njoySpecificBuild = true;
      this.njoySpecificBuild = true;
      return;
    }
    if (isNJoySpecificParamInUrl()) {
      this.userAccountService.njoySpecificBuild = true;
      this.njoySpecificBuild = true;
      localStorage.setItem('is_app_mode','true');
    }
  }

  ngOnInit() {
    this.setUpAppMode();
    setAppLoaded();
    addingHeader();
    this.searchService.calendarOpenEventListsner.asObservable().subscribe(datePicker => {
      this.currDatePicker = datePicker;
      this.searchService.hoverCounter = 0;
      if (this.currDatePicker) {
        stopEventsBehindCal();
      } else {
        setTimeout(() => {
          startEventsBehindCal();
        }, 500);
      }
    })
    let timeNow = Date.now();
    this.connectionListener = this.connectionService.monitor().subscribe(isConnected => {
      let timeNowNoW = Date.now();
      timeNow = timeNowNoW;
    
    });
    let self = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === 'visible') {
        self.userAccountService.appVisibleToUser = true;
       
         
        
      } else {
        self.userAccountService.appVisibleToUser = false;
      }
    });
    if (this.userAccountService.isLoggedIn()) {
      this.inItClientRequest();
    }

    this.gallopLocalStorage.initAppSessionKey();
    this.userAccountService.userSessionChangePrompt$.subscribe((loginDetails: any) => {
      if (loginDetails && loginDetails['userid'] && loginDetails['sToken'] && loginDetails['userInfo']) {
        let userInfoObj: UserAccountInfo = deserialize(loginDetails['userInfo'], UserAccountInfo);
        if (!this.isLogoutPage()) {
          this.promptContinueLogin(loginDetails['userid'], loginDetails['sToken'], userInfoObj);
        }
      } else {
        // this.attemptLoginSessionUpdate(undefined,undefined,this.currentUrl);
      }
    });
    this.userAccountService.userAccountInitObjObserver$.subscribe(resp => {
      if (resp && resp.status === 'success') {
        this.loginService.initResponse =resp;
        if ((resp && resp.status === 'success')
          && (
            resp.responseType === 'AllowedToProceed' ||
            resp.responseType === 'NotAllowedToProceed' ||
            resp.responseType === 'AllowedToProceedWithCTA' ||
            resp.responseType === 'NotAllowedToProceedWithCTA'
          )
        ) {
          let ctaTextString = undefined;
          let ctaString = undefined;
          //if(resp && resp.confermaStatus){

          // }
          if (resp.responseType !== 'AllowedToProceedWithCTA' ||
            resp.responseType !== 'NotAllowedToProceedWithCTA'
          ) {
            ctaTextString = resp.ctaText;
            ctaString = resp.cta;
          }

          this.bsModalRef = this.modalService.show(InitClientComponent, {
            initialState: {
              message: resp.errorMessage,
              responseType: resp.responseType,
              cta: ctaString,
              ctaText: ctaTextString
            }, backdrop: true, keyboard: false, ignoreBackdropClick: true
          });
        }
        if(resp.defaultConfig){
          this.searchService.mapSupprted = resp.defaultConfig.mapsSupported;
          this.searchService.showFlightTab = resp.defaultConfig.flightsEnabled;
          this.searchService.showCarTab = resp.defaultConfig.carsEnabled;
          this.searchService.showHotelTab = resp.defaultConfig.hotelsEnabled;
          this.searchService.logoIcon = resp.defaultConfig.logoIcon;
         // this.searchService.tripFeatureEnabled = resp.defaultConfig.tripFeatureEnabled;
         // this.searchService.infantBookingAllowed = resp.defaultConfig.minorBookingSupported;
        }
        this.searchService.allFaresPopupShow = resp.upSellFareSupported;
        this.userAccountService.showLanguageOptions = resp.showLanguageOptions;
        this.userAccountService.showAllBookings = resp.showAllBookings;
        this.userAccountService.showUpSellOnAllLegs = resp.showUpSellOnAllLegs;
        this.userAccountService.initResponseReceived = true;
        this.userAccountService.confermaStatus = resp.confermaStatus;

        if (resp.onBoarded) {
          this.userAccountService.onBoardingTask = resp.onBoarded;
        }
        if (resp.cardSupportEnabled) {
          this.userAccountService.userhascard = resp.cardSupportEnabled;
        }
        if (resp.hotelDetailForFeedback) {
          if (this.loginService.userid && this.loginService.sToken && !this.bsModalHotelreview) {
            this.bsModalHotelreview = this.modalService.show(FeedbackComponent, {
              initialState: {
                hotelName: resp.hotelDetailForFeedback.hotelName,
                checkin: resp.hotelDetailForFeedback.checkIn,
                checkout: resp.hotelDetailForFeedback.checkout,
                tripId: resp.hotelDetailForFeedback.tripId,
                transactionId: resp.hotelDetailForFeedback.transactionId,
                eventId: resp.hotelDetailForFeedback.eventId,
                ticketId: resp.hotelDetailForFeedback.ticketId,
              }, backdrop: true, keyboard: false, ignoreBackdropClick: true
            });
          }
        }
        this.userAccountService.isCarsEnabled = resp.carsEnabled;
        environment.locationSupportedInCarSearch = resp.locationSupportedInCarSearch;
        environment.presentAlternateInPolicyOptions = resp.presentAlternateInPolicyOptions;
        this.userAccountService.isDutyOfCareEnabled = resp.dutyOfCareEnabled;
        // Going forward we will use config service for configuration related things
        this.clientConfig.carsEnabled = resp.carsEnabled;
        this.clientConfig.locationSupportedInCarSearch = resp.locationSupportedInCarSearch;
        this.clientConfig.presentAlternateInPolicyOptions = resp.presentAlternateInPolicyOptions;
        this.clientConfig.dutyOfCareEnabled = resp.dutyOfCareEnabled;
        this.clientConfig.classicReports = resp.classicReports;
        this.clientConfig.bookingAppBaseUrl = resp.bookingAppBaseUrl;
        this.clientConfig.dashboardAppBaseUrl = resp.dashboardAppBaseUrl;
        this.clientConfig.enableReconcile = resp.enableReconcile;
        this.clientConfig.agentEmailId = resp.agentEmailId;
        this.clientConfig.brandName = resp.brandName;
        if (this.clientConfig.bookingAppBaseUrl && this.clientConfig.bookingAppBaseUrl.endsWith('/')) {
          this.clientConfig.bookingAppBaseUrl
            = this.clientConfig.bookingAppBaseUrl.substring(0, this.clientConfig.bookingAppBaseUrl.length - 1);
        }
        if (this.clientConfig.dashboardAppBaseUrl && this.clientConfig.dashboardAppBaseUrl.endsWith('/')) {
          this.clientConfig.dashboardAppBaseUrl
            = this.clientConfig.dashboardAppBaseUrl.substring(0, this.clientConfig.dashboardAppBaseUrl.length - 1);
        }
        const selectedLang = localStorage.getItem('selectedLanguage');
        if (selectedLang && this.userAccountService.showLanguageOptions) {
          this.translate.use(selectedLang);
          this.userCredentials.setLang(selectedLang);
        }
      }
    });
  
    //let emailid = this.gallopLocalStorage.getItem("userid");
    this.searchService.getMappingStars().subscribe(res =>{
      if(res && res.data){
        console.log("mapping star response",res);
        this.searchService.NGS_UI_STAR_MAPPING = res.data;
      }
    })
    //let stoken = this.gallopLocalStorage.getItem("sToken");
    this.router.events.subscribe((event: Event) => {
      // if(this.userAccountService.isLoggedIn()) return;
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        let str = '/search'
        if (this.currentUrl.toLowerCase().search(str) !== -1) {
          this.searchService.isSearchPage = true;
        } else {
          this.searchService.isSearchPage = false;
        }
        this.userAccountService.updateCurrentRouterPath(event.url);
        this.currentParams = this.route.snapshot.queryParams;
        if (this.userAccountService.isLoggedIn()) return;
        let userid = this.currentParams['userid'] ? this.currentParams['userid'] : this.loginService.userid;
        let sToken = this.currentParams['sToken'] ? this.currentParams['sToken'] : this.loginService.sToken;
        if (!userid) userid = this.currentParams['emailId'];
        if (!this.isLogoutPage()) {
          this.attemptLoginSessionUpdate(userid, sToken, this.currentUrl);
        }
        if(this.isLoginOrLogoutPage()){
          this.userAccountService.showLanguageOptions =true;
        }
      }
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
        console.log("browserRefresh",browserRefresh,event.url);
        if((event.url==='/hotelSelection' || event.url==='/emailflow?pageMode=WebSearch') && browserRefresh){
             this.goToHomePage();
        }
        if(event.url==='/emailflow?pageMode=WebSearch'){
          this.searchService.comingFromSelectionPAge = true;
        }
        if (event.navigationTrigger === 'popstate' && event.restoredState) {
          this.searchService.browserBackOrForwardClickListener.next(null);
        }
      }
    });
    this.userAccountService.userAccountInfoObjObserver$.subscribe((userProfile: UserAccountInfo) => {

      if (userProfile) {
        if (this.userAccountService.showRadio) {
          if(this.userAccountService.isConnected &&  this.currentUrl!=='/emailflow?pageMode=WebSearch'){
            this.searchService.fetchEmployeesList(this.userAccountService.getUserCompanyId());
          this.adminPanelService.fetchEmployeesList(this.userAccountService.getUserCompanyId());
          if ((this.userAccountService.isUserIsTravelManager() || this.userAccountService.isUserCorporateAdmin()) && !this.bookingService.previousTransactionId) {
            this.searchService.employeeEmail = [];
            if(this.route.snapshot.queryParams['query']){
              this.searchService.bookingAndApprovalDone =true;
            }
          }
          }
        }
      }


    });
    window.addEventListener('storage', this.storageEventListener.bind(this));
    this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.deviceDetailsService.isMobile2().subscribe(isMobile => {
      this.isMobile1 = isMobile;
    });

    if (environment.production) {
      setUPRollBar('b96fe0a6424046608f6d9df2a95ad498', 'production');
    } else {
      setUPRollBar('0f2131c4446f4a81b9b2deb71a8a783c', 'dev');
    }
    if (!CommonUtils.doesPathContain(location.href, '/login')
        &&
        !CommonUtils.doesPathContain(location.href, '/admin')
      ) {
        initializeHotJar(environment.hj_site_id_booking);
    }
  }
  goToHomePage() {
    if((this.userAccountService.isUserCorporateAdmin() || this.userAccountService.isUserIsTravelManager())){
      this.searchService.employeeEmail=[];
      this.searchService.bookingAndApprovalDone =true;
    }
    this.router.navigate(["search"], { queryParams: { userid: this.userAccountService.getUserEmail(), sToken: this.userAccountService.getSToken() }, replaceUrl: true });
  }
  changeStyle() {
    if (this.airportModalOpen()) {
      return { 'min-height': '0px', 'padding-bottom': '0px' };
    } else {
      return { 'min-height': '100vh', 'padding-bottom': '100px' };
    }
  }
  changeStyle1() {
    if (this.airportModalOpen()) {
      return { 'min-height': '0px' };
    } else {
      return { 'min-height': '100vh' };
    }
  }
  isLoginPage() {
    return this.userAccountService.getCurrentRouterPath().indexOf('login') === 1;
  }
  isSlackconnectRequested() {
    console.log("router path", this.userAccountService.getCurrentRouterPath());
    return this.userAccountService.getCurrentRouterPath().indexOf('addToSlack') === 1;
  }
  isLoginOrLogoutPage() {
    return this.userAccountService.getCurrentRouterPath().indexOf('login') === 1
      || this.userAccountService.getCurrentRouterPath().indexOf('logout') === 1;
  }
  isLogoutPage() {
    return this.userAccountService.getCurrentRouterPath().indexOf('logout') === 1;
  }
  isDashboardPage() {
    return this.userAccountService.getCurrentRouterPath().indexOf('admin') === 1;
  }

  private promptContinueLogin(newUserId: string, newSToken: string, userInfoObj: UserAccountInfo) {
    let currentSessionUser: string = undefined;
    if (localStorage.getItem('loginSession') !== 'null' && localStorage.getItem('loginSession')) {
      let prevLoginSession = JSON.parse(localStorage.getItem('loginSession'));
      currentSessionUser = prevLoginSession['userid'];
    }
    if (currentSessionUser && currentSessionUser !== newUserId) {
      if (this.bsModalRef
        && this.bsModalRef.content
        && this.bsModalRef.content.title
        && this.bsModalRef.content.title ===  this.translate.instant('search.Areyousureyouwanttoproceed?')) {
        return;
      }

      this.bsModalRef = this.modalService.show(DeleteCardModelComponent, {
        initialState: {
          title: this.translate.instant('search.Areyousureyouwanttoproceed?'),
          message:  this.translate.instant('search.Youwillbesignedoutof')+ currentSessionUser +  this.translate.instant('search.andsignedinto') + newUserId +  this.translate.instant('search.account'),
          yesButtonSubText:  this.translate.instant('search.Yes'),
          noButtonSubText:  this.translate.instant('search.No'),
          color: 'black',
          login:true,
          buttonColor: '007bff'
        }, backdrop: true, keyboard: false, ignoreBackdropClick: true
      });
      this.bsModalRef.content.onClose.subscribe(result => {
        this.bsModalRef = null;
        if (result) {
          this.userAccountService.updateUserSession(newUserId, newSToken, userInfoObj);
          window.location.reload()
        } else {
          //this.titleService.setTitle('Flight Search');
          this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
          this.router.navigate(['/search'], { queryParams: {} });
        }
      });
    } else {
      this.userAccountService.updateUserSession(newUserId, newSToken, userInfoObj);
      if (this.isLoginOrLogoutPage()) {
        if (this.loginService.currentUrl && this.loginService.currentUrl.trim().length > 0) {
          this.router.navigateByUrl(this.loginService.currentUrl);
        } else {
          //this.titleService.setTitle('Flight Search');
          this.titleService.setTitle(this.translate.instant('search.FlightSearch'));
          this.router.navigate(['/search'], { queryParams: {} });
        }
      }
    }
  }

  isuserHascard(): boolean {
    return this.userAccountService.userhascard;
  }

  private attemptLoginSessionUpdate(userid: string, sToken: string, currentUrl: string) {
    let currentSessionUser: string = undefined;
    let currentSessionSToken: string = undefined;
    if (!CommonUtils.doesPathContain(currentUrl, 'emailAction') && !CommonUtils.doesPathContain(currentUrl, 'signup')) {
      if (localStorage.getItem('loginSession') !== 'null' && localStorage.getItem('loginSession')) {
        let prevLoginSession = JSON.parse(localStorage.getItem('loginSession'));
        currentSessionUser = prevLoginSession['userid'];
        currentSessionSToken = prevLoginSession['sToken'];
        this.loginService.userid = prevLoginSession['userid'];
        this.loginService.sToken = prevLoginSession['sToken'];
      }
      if (userid && sToken) {
        const dataConsentRequired = localStorage.getItem('dataConsentRequired');
      if (dataConsentRequired === 'true') {
        localStorage.setItem('dataConsentAlreadyAccepted', 'true');
      }
        if (this.isSlackconnectRequested()) {
          window.location.href = (environment.urlForSlackConnect + '?userid=' + userid);
        }
        this.userAccountService.initUserAccountService(userid, sToken, CommonUtils.doesPathContain(currentUrl, 'emailflow'));
        this.inItClientRequest();
      } else if (!this.userAccountService.isLoggedIn() && currentSessionUser && currentSessionSToken) {
        if (this.isSlackconnectRequested()) {
          window.location.href = (environment.urlForSlackConnect + '?userid=' + currentSessionUser);
        }
        this.userAccountService.initUserAccountService(currentSessionUser, currentSessionSToken, CommonUtils.doesPathContain(currentUrl, 'emailflow'));
        this.inItClientRequest();

      } else if (!this.userAccountService.isLoggedIn()
        && !this.isLoginOrLogoutPage() && !CommonUtils.doesPathContain(currentUrl, 'emailAction')
        && !CommonUtils.doesPathContain(currentUrl, 'signup')) {
        if (!this.userAccountService.isLoginInProgress) {
          this.loginService.currentUrl = currentUrl;
          this.loginService.userid = undefined;
          this.loginService.sToken = undefined;
          this.router.navigateByUrl('/login');
          this.titleService.setTitle(this.translate.instant('login.Login'));
        }
      }
    } else if (CommonUtils.doesPathContain(currentUrl, 'emailAction')) {
      //this.titleService.setTitle('Reset Password');
      this.titleService.setTitle(this.translate.instant('search.ResetPassword'));
    }
  }
  private storageEventListener(storeData: any) {
    if (storeData && storeData.key === 'loginSession') {
      let newLoginSession = undefined;
      let prevLoginSession = undefined;
      if (storeData.newValue && storeData.newValue != null) {
        newLoginSession = JSON.parse(storeData.newValue);
      }
      if (storeData.oldValue && storeData.oldValue != null) {
        prevLoginSession = JSON.parse(storeData.oldValue);
      }

      if (prevLoginSession && (!newLoginSession || newLoginSession['userid'] !== prevLoginSession['userid'])) {
        if (this.bsModalRef
          && this.bsModalRef.content
          && this.bsModalRef.content.title
          && this.bsModalRef.content.title ===  this.translate.instant('search.SignedOut')) {
          return;
        }
        this.bsModalRef = this.modalService.show(DeleteCardModelComponent, {
          initialState: {
            title:this.translate.instant('search.SignedOut'),
            message: this.translate.instant('search.Youhavebeensignedoutof') + this.userAccountService.getUserEmail()
            + ' ' + this.translate.instant('search.account.'),
            yesButtonSubText: this.translate.instant('search.OK'),
            color: 'black',
            buttonColor: '007bff',
            disableCancel: 'true',

          }, backdrop: true, keyboard: false, ignoreBackdropClick: true
        });
        this.bsModalRef.content.onClose.subscribe(result => {
          this.bsModalRef = null;
          if (result) {
            this.userAccountService.clearLoggedInData();
            this.attemptLoginSessionUpdate(undefined, undefined, this.currentUrl);
            //this.titleService.setTitle('Login');
            this.titleService.setTitle(this.translate.instant('login.Login'));
            window.location.href = (this.clientConfig.bookingAppBaseUrl + '/logout');
          }
        });
      } else if (newLoginSession && newLoginSession['userid']) {
        this.attemptLoginSessionUpdate(undefined, undefined, this.currentUrl);
      }
    }
  }
  private inItClientRequest() {
    let userid = this.userAccountService.getUserEmail();
    let sToken = this.userAccountService.getSToken();
    //if(this.userAccountService.isLoggedIn()){
    this.userAccountService.initializeApiClient();

    //}
  }

  initLogin() {

  }
  ngOnDestroy() {
    this.gallopLocalStorage.clear();
  }
  airportModalOpen() {
    if (this.ngxSmartModalService.getOpenedModals() &&
      (this.ngxSmartModalService.getOpenedModals().length > 0 && this.isMobile)
    ) {
      let modals = this.ngxSmartModalService.getOpenedModals();
      if (modals[0].id.search('search') !== -1 || modals[0].id.search('dest') !== -1 || modals[0].id.search('mobile') !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  public handleClickEventForImplementingModalHack() {
    if (this.ngxSmartModalService.getOpenedModals() &&
      this.ngxSmartModalService.getOpenedModals().length > 0
    ) {
      let modals = this.ngxSmartModalService.getOpenedModals();
      for (let index = 0; index < modals.length; index++) {
        var currModalTimeStamps = getCurrentlyOpenNgxSmartModalIds();
        let helpModalAlreadyOpen = false;
        if (currModalTimeStamps[modals[index].id] &&
          new Date().getTime() - currModalTimeStamps[modals[index].id] > 500
        ) {
          if (modals[index].id !== 'helpModal') {
            this.ngxSmartModalService.getModal(modals[index].id).close();
            this.searchService.inputId = '';
            setNgxSmartModalOpenStateClosed(modals[index].id);
          } else {
            if ($('.modalAirportFilterInfo').css('display') !== 'none') {
              $('.modalAirportFilterInfo').slideToggle();
              setNgxSmartModalOpenStateClosed(modals[index].id);
            }
          }
        }
      }
    }
    return true;
  }
  public getWindowHeight() {
    return window.innerHeight + 'px';
  }
  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }
  public closeCalendar() {
    if (this.currDatePicker) {
      setTimeout(() => {
        startEventsBehindCal();
      }, 500);
      this.currDatePicker.hide();
      this.currDatePicker = null;
      setTimeout(() => {
        startEventsBehindCal();
      }, 500);
    }
    return;
  }
}
