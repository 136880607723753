import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserAccountService } from '../user-account.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  constructor( private userAccountService: UserAccountService,public translateService: TranslateService,) { }
@Input()tagset=[];
@Input()selectedTag=[];
@Input()dropDownopen=[];
@Input() tagShow=[];
@Input() luggageDropDown =false
@Input() reportComponet=false;
@Input() isTripDetails = false;
@Input() changing: Subject<any>;
@Input() newTagSetsArray = [];
@Output() goBackDropDownOptions = new EventEmitter();
selectLuggagePromt = '<Select Luggage Option>';
savingTag =false;
selectLuggage = this.selectLuggagePromt;
showSelectTags = [true,true,true];

  ngOnInit(): void {
    if(this.isTripDetails){
      let newTagsSet = [];
      let newTagsShow =[];
      let newSelectedTags = [];

      this.tagset.forEach((e,i)=>{
        if(this.selectedTag[i]){
          newTagsSet.push(e);
          newTagsShow.push(this.tagShow[i]);
          newSelectedTags.push(this.selectedTag[i]);
        };
      });
      
      this.tagset = newTagsSet;
      this.tagShow = newTagsShow;
      this.selectedTag = newSelectedTags;
    };

    
    if(this.isTripDetails == false){
      this.changing.subscribe(resp => { 
        if(resp){
        this.savingTag = resp.value1;
        }
      });
    }else{
      this.savingTag = true;
    }
    if (this.luggageDropDown) {
      this.tagShow = JSON.parse(JSON.stringify(this.tagShow));
      this.tagShow.unshift({'originalText': this.selectLuggagePromt, 'optionValue':'-1'});
    }
  }
  showLuggageChanged(event){
    this.selectLuggage = this.selectLuggagePromt;
    for(let looper = 0; looper < this.tagShow.length; looper++) {
      if (this.tagShow[looper].optionValue === event.optionValue && event.optionValue !== -1) {
        this.tagShow[looper].selected =true;
        this.selectLuggage= this.tagShow[looper].originalText;
      } else {
        this.tagShow[looper].selected =false;
      }
    }
    this.goBackDropDownOptions.emit(this.tagShow);
  }
  removeSeelctedTag(index) {
    let selected =[];
   for(let item of this.selectedTag){
   if(item!==''){
     selected.push(item);
   }
   }
   selected =   selected.filter(item => item !== this.selectedTag[index]);
   this.selectedTag[index] = null;
   this.goBackDropDownOptions.emit(selected)
   this.showSelectTags[index] = true;
  }
  closeDropdown(i) {
    this.dropDownopen[i] = false;
  }
  isdropDownOpen(i) {

    this.dropDownopen[i] = true;
  }
  getChangeClass(){
    if(this.reportComponet){
      return 'col-12 tagsBox';
    }else{
 return 'col-sm-12 col-lg-4 tagsBox';
    }
  }
  getTagSetNameClass(){
    if(this.reportComponet){
      return 'tagSetName-report';
    }else{
 return 'tagSetName';
    }
  }
  getSelectedData(item,index) {
    // console.log("tag saved",this.selectedTag)
     if (!item) {
       if (this.selectedTag[index] && this.selectedTag[index] !=='' ){
        this.showSelectTags[index] = false;
         let findname = this.tagShow[index].findIndex(item => item.tag_id === this.selectedTag[index])
         if (findname !== -1) {
           return this.tagShow[index][findname].tag_name;
         } else {
           return this.translateService.instant('paymentDetails.SelectProjectTagoptional');
         }
       } else {
         return  this.translateService.instant('paymentDetails.SelectProjectTagoptional');
       }
     } else {
       if (this.selectedTag[index] && this.selectedTag[index] !=='') {
         let findname = this.tagShow[index].findIndex(item => item.tag_id === this.selectedTag[index]);
         if (findname !== -1) {
           return '';
         } else {
           return  this.translateService.instant('paymentDetails.SelectProjectTagoptional');
         }
 
       } else {
         return this.translateService.instant('paymentDetails.SelectProjectTagoptional')
       }
     }
   }
   showTagChanged(event,index) {
    //if(event!==''){
    
    this.selectedTag[index] = event;
   
  this.goBackDropDownOptions.emit(this.selectedTag);
  }

  

  checkTagIsMendotaryOrNot(i:number) {
    if(this.tagShow[i].length > 0 && this.isTripDetails == false) {
      const selectedSetId = this.newTagSetsArray.find(e => e.tagsetId == this.tagShow[i][0].tagsetId );
      if (selectedSetId){
        return selectedSetId.mandatory;
      }
    }
    return false;
  }

  changeViewForRepots(){
    if(this.reportComponet  == true){
      return 'tripDiv';
    }else{
      return '';
    }
  }
}
