import { Injectable } from '@angular/core';
import { SearchResultService } from './search-result.service';
import { FlightResult } from './entity/flight-result';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { BookingRequest } from './entity/booking-request';
import { TravelerDetails } from './entity/traveler-details';
import { PaymentInstrumentDetails } from './entity/payment-instrument-details';
import { TravelersInfo } from './entity/travelers-info';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { DateUtils } from './util/date-utils';
import { PassportDetails } from './entity/passport-details';
import { environment } from '../environments/environment';
import { BookingResponse } from './entity/booking-response';
import { BookingResponseErrorType } from './enum/booking-response-error.type';
import { SearchService } from './search.service';
import { Constants } from './util/constants';
import { FlightUtils } from './util/flight-utils';
import { FrequentFlyerInfo } from './entity/frequent-flyer-info';
import { EmailFlowBookRequest } from './entity/email-flow/email-book-request';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { RevalidateRequest } from './entity/revalidate-request';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { UserAccountService } from './user-account.service';
import { HotelBookingRequest } from './entity/hotel-booking-request';
import { HotelDetailResult } from './entity/hotel-detail-result';
import { HotelBookingResponse } from './entity/hotel-booking-response';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { TranslateService } from "@ngx-translate/core";
import { BookingApprovalRequest } from './entity/booking-approval-request';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { HttpParams } from '@angular/common/http';
import { SeatSelect } from './entity/seatSelected';
import { PhoneNumberDTO } from './entity/phonenumber-dto';
import { ClientConfiguration } from './client-config.service';
import { TripSessionBookingData } from './entity/trip-session-booking-data';
import { TripSessionBookingRequest } from './entity/trip-session-booking-request';
import { HotelSearchService } from './hotel-search.service';
//
// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class BookingService {

  constructor(private http: GallopHttpClient,
    private searchResultService: SearchResultService,
    private gallopLocalStorage: GallopLocalStorageService,
    private searchService: SearchService,
    private hotelSearchService: HotelSearchService,
    private datePipe: DatePipe,
    private userAccountService: UserAccountService,
    public translateService: TranslateService,
    private clientConfig: ClientConfiguration
  ) {
    this.subscribeEvents();
  }
  cardAllowed: any=[];
  totalPayble: number;
  rebookingTravllername='';
  rebookingTravlleremail=[];
  previousTransaction:any;
  oldTripSessionId:any;
  rebookingConfirm = false;
  total = 0;
  airports:any;
  noteToadmin:any;
  airlines:any;
  bookingData:any;
  isZipCodeRequired = false;
  selectedPaymentEventIndex=0;
  bookingButtonClicked=false;
  nextButtonClicked = false;
  beforeRevalidation = false;
  selectedTag = [];
  previousBooking:any;
  previousTransactionId:string;
  previousTripSessionId:string;
  bookRequestProgress = false;
  public currencyCode: string = 'USD';
  proceedButton = false;
  priceChange = false;
  forMultipleBooking=[];
  selectedSeatArray: Array<any>[];
  priceChangeData: Array<any> = []
  priceChanngeFlight: any;
  responseData: any;
  public flightSelected = [];
  public travellers: number;
  pageMode = '';
  bookingRequest: any;
  selectedFlightSubscription: Subscription;
  selectedFlight: FlightResult;
  selectedFlight1: FlightResult;
  selectedHotelSubscription: Subscription;
  selectedHotel: HotelDetailResult;
  passengersFormValue: any;
  bookingResponse: BookingResponse;
  hotelBookingResponse: HotelBookingResponse;

  flightFareChangeSubject = new BehaviorSubject<any>(null);
  flightFareChange$ = this.flightFareChangeSubject.asObservable();


  subscribeEvents() {

    this.selectedFlightSubscription = this.searchResultService.selectedFlight$
      .subscribe((flight: FlightResult) => {
        this.selectedFlight = flight;
      });

    this.selectedHotelSubscription = this.searchResultService.selectedHotel$
      .subscribe((hotel: HotelDetailResult) => {
        this.selectedHotel = hotel;
      });

  }


   public equals(obj1: any, obj2: any): boolean {
      // Check if the objects are of the same type
      if (typeof obj1 !== typeof obj2) {
        return false;
      }
    
      // Check if both objects are null or undefined
      if (!obj1 && !obj2) {
        return true;
      }
    
      // Check if only one of the objects is null or undefined
      if (!obj1 || !obj2) {
        return false;
      }
    
      // Get the keys of the objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
    
      // Check if the objects have the same number of keys
      if (keys1.length !== keys2.length) {
        return false;
      }
    
      // Check if the objects have the same keys
      if (!keys1.every((key) => keys2.includes(key))) {
        return false;
      }
    
      // Check if the values of the keys are equal
      for (const key of keys1) {
        const value1 = obj1[key];
        const value2 = obj2[key];
    
        // Recursive call if the value is an object
        if (typeof value1 === 'object' && typeof value2 === 'object') {
          if (!this.equals(value1, value2)) {
            return false;
          }
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          if (value1.trim().toLowerCase() !== value2.trim().toLowerCase()) {
            return false;
          }
        } 
        else if (value1 !== value2) {
          return false;
        }
      }
    
      // If all checks pass, the objects are considered equal
      return true;
    }
    
  
  broadCastFlightFareChange(fareAmount: any) {
    this.flightFareChangeSubject.next(fareAmount);
    this.flightFareChangeSubject.next(null);
  }

  unSubscribeEvents() {
    this.selectedFlightSubscription.unsubscribe();
    this.selectedHotelSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unSubscribeEvents();
  }


  getseatRequest(travellerCount, ticketId, tripId, eventIdAndOptionMap,index) {
    let bookingRequest: any = {};
    let travellerArray = [];
    let travelerDetails = new TravelerDetails();
    for (let i = 0; i < travellerCount; i++) {
      let traveller = { title: "", firstName: "", lastName: "", dateOfBirth: "" }
      traveller.title = "Mr";
      traveller.firstName = "dummy" + i;
      traveller.lastName = "dummylast" + i;
      traveller.dateOfBirth = "1987-08-20";
      if(this.searchService.infantBookingAllowed && this.searchService.ageGroupArray[i].id!=='INF'){
      travellerArray.push(traveller);
      }else if(!this.searchService.infantBookingAllowed){
        travellerArray.push(traveller);
      }
    }
    travelerDetails.travellers = travellerArray;
    bookingRequest.travellerDetails = travelerDetails;
    let flightsList: FlightResult[] = [];
    let selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"))
    let inPolicyFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlightInPolicy"));
    if(this.searchService.nonCombo){
      for(let flight of this.searchService.nonComboSelectedFlight){
        flightsList.push(flight);
      }
    }else if (inPolicyFlight) {
      flightsList.push(inPolicyFlight);
    } else {
      if(!this.searchService.multiTripBooking){
      flightsList.push(this.selectedFlight);
      }else{
        flightsList.push(selectedFlight[index]);
      }
    }
    let inP

    let flights = { 'flights': flightsList };

    bookingRequest.flightsToBook = flights;
    bookingRequest.ticketId = ticketId;
    bookingRequest.tripId = tripId;
    if (bookingRequest.ticketId) {
      bookingRequest.eventIdAndOptionMap = eventIdAndOptionMap;
    }

    return bookingRequest;
  }
  getSeatDeatils(travellerCount, ticketId, tripId, eventIdAndOptionMap,index?,ffnMap?): Observable<any> {
    this.travellers = travellerCount;
    let seatRequest = this.getseatRequest(travellerCount, ticketId, tripId, eventIdAndOptionMap,index);
    if(ffnMap){
    seatRequest['ffnDetails']=ffnMap;
    }
    return this.http.post(environment.apiForSeat, seatRequest);
  }
  getBookingRequestWithTripSessionIndex(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag,tripSessionItemIndex): BookingRequest {
  
    let bookingRequest = new BookingRequest();

    bookingRequest.travellerDetails = this.getTravelerDetails('WebSearch');
    bookingRequest.paymentDetails = this.getPaymentDetails(details, cardType, gallopCash);
    // let seatSelected:SeatSelect[]=[]
    if (selectedTag && selectedTag.length > 0) {
      bookingRequest.projectTagId = selectedTag.toString();;
    }
    // let seatSelected = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
    let seatSelected = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
    this.selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"))[tripSessionItemIndex];
    if(this.selectedFlight && this.selectedFlight['allUpsellFares']){
      delete this.selectedFlight['allUpsellFares'];
    }
    if(this.selectedFlight && this.selectedFlight.otherFarePriceAttributes){
      delete this.selectedFlight.otherFarePriceAttributes;
    }
    let flightsList: FlightResult[] = [];
    let inPolicyFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlightInPolicy"));
    if(this.selectedFlight && this.selectedFlight.displayPrice){
      bookingRequest.displayPrice = this.selectedFlight.displayPrice;
      bookingRequest.displayCurrency = this.selectedFlight.displayCurrency;
    }
    if (this.userAccountService.typeOfBooking === 'BOOK_FOR_OTHERS') {
      if (inPolicyFlight && inPolicyFlight.travelCreditsInfo) {
        delete inPolicyFlight.travelCreditsInfo;
      } else if (this.selectedFlight && this.selectedFlight.travelCreditsInfo) {
        delete this.selectedFlight.travelCreditsInfo;
      }
    }
    if(this.searchService.nonCombo){
      for(let flight of this.searchService.nonComboSelectedFlight){
        flightsList.push(flight);
      }
    }else if (inPolicyFlight) {
      flightsList.push(inPolicyFlight);
    } else {
      flightsList.push(this.selectedFlight);
    }

    let flights = { 'flights': flightsList };
      
    bookingRequest.flightsToBook = flights;
    bookingRequest.tripId = tripId;
    //bookingRequest.selectedSeat = seatSelected;
    // bookingRequest.selectedSeat = seatSelected;
    bookingRequest.ticketId = ticketId;
    bookingRequest.eventIdAndOptionMap = eventIdAndOptions;
    // bookingRequest.searchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequestForBooking")));let flightSearchQueryParam: FlightSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequest")));
    let flightSearchQueryParam =  this.searchService.multiflightQuery[tripSessionItemIndex];
    bookingRequest.searchQuery = this.searchService.getSearchRequest(flightSearchQueryParam);
    if (this.gallopLocalStorage.getItem("bookingResponse") && this.gallopLocalStorage.getItem("bookingResponse") != null) {
      let bookingRespose: BookingResponse = deserialize(JSON.parse(this.gallopLocalStorage.getItem("bookingResponse")));
      bookingRequest.lastTransactionId = bookingRespose.transactionId;
      bookingRequest.ticketId = bookingRespose.ticketId;
      bookingRequest.tripId = bookingRespose.tripId;
      bookingRequest.eventIdAndOptionMap = bookingRespose.eventIdAndOptions;
    }
    if (inPolicyFlight || this.selectedFlight) {
      this.priceChangeData.push(flights);
    }
    bookingRequest.previousBookingCodes = this.priceChangeData;
    if(bookingRequest.previousBookingCodes && bookingRequest.previousBookingCodes.length > 0 && bookingRequest.previousBookingCodes[0].otherFarePriceAttributes){
      delete bookingRequest.previousBookingCodes[0].otherFarePriceAttributes;
    }
    if(bookingRequest.previousBookingCodes[0] && bookingRequest.previousBookingCodes.length > 0 && bookingRequest.previousBookingCodes[0]['allUpsellFares']){
      delete bookingRequest.previousBookingCodes[0]['allUpsellFares'];
    }
    return bookingRequest;
  }
  getBookingRequest(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag): BookingRequest {

    let bookingRequest = new BookingRequest();

    bookingRequest.travellerDetails = this.getTravelerDetails('WebSearch');
    bookingRequest.paymentDetails = this.getPaymentDetails(details, cardType, gallopCash);
    // let seatSelected:SeatSelect[]=[]
    if (selectedTag && selectedTag.length > 0) {
      bookingRequest.projectTagId = selectedTag.toString();;
    }
    let seatSelected = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
    this.selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"));
    let flightsList: FlightResult[] = [];
    let inPolicyFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlightInPolicy"));
    if (this.userAccountService.typeOfBooking === 'BOOK_FOR_OTHERS') {
      if (inPolicyFlight && inPolicyFlight.travelCreditsInfo) {
        delete inPolicyFlight.travelCreditsInfo;
      } else if (this.selectedFlight && this.selectedFlight.travelCreditsInfo) {
        delete this.selectedFlight.travelCreditsInfo;
      }
    }
    if(this.searchService.nonCombo){
      for(let flight of this.searchService.nonComboSelectedFlight){
        flightsList.push(flight);
      }
    }else if (inPolicyFlight) {
      flightsList.push(inPolicyFlight);
    } else {
      flightsList.push(this.selectedFlight);
    }

    let flights = { 'flights': flightsList };
      
    bookingRequest.flightsToBook = flights;
    bookingRequest.tripId = tripId;
    bookingRequest.selectedSeat = seatSelected;
    bookingRequest.ticketId = ticketId;
    bookingRequest.eventIdAndOptionMap = eventIdAndOptions;
    bookingRequest.searchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequestForBooking")));
    if (this.gallopLocalStorage.getItem("bookingResponse") && this.gallopLocalStorage.getItem("bookingResponse") != null) {
      let bookingRespose: BookingResponse = deserialize(JSON.parse(this.gallopLocalStorage.getItem("bookingResponse")));
      bookingRequest.lastTransactionId = bookingRespose.transactionId;
      bookingRequest.ticketId = bookingRespose.ticketId;
      bookingRequest.tripId = bookingRespose.tripId;
      bookingRequest.eventIdAndOptionMap = bookingRespose.eventIdAndOptions;
    }
    if (inPolicyFlight || this.selectedFlight) {
      this.priceChangeData.push(flights);
    }
    bookingRequest.previousBookingCodes = this.priceChangeData;
    return bookingRequest;
  }
  public getJSON(file): Observable<any> {
    return this.http.get(file);
  }

  getApprovalBookingRequest(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, approvalFlowType, bookingStatusId, selectedTag?): BookingApprovalRequest {

    let bookingRequest = new BookingApprovalRequest();
    if(this.searchService.displayPrice){
      bookingRequest.displayPrice = this.searchService.displayPrice;
      bookingRequest.displayCurrency = this.searchService.displayCurrency;
    }
    bookingRequest.travellerDetails = this.getTravelerDetails('WebSearch');
    let seatSelected = JSON.parse(this.gallopLocalStorage.getItem("selectedSeat"));
    bookingRequest.paymentDetails = this.getPaymentDetails(details, cardType, gallopCash);
    if (selectedTag !== '') {
      bookingRequest.projectTagId = selectedTag.toString();
    }
    if (approvalFlowType === 'flight') {
      let flightsList: FlightResult[] = [];
      let inPolicyFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlightInPolicy"));
      if (this.userAccountService.typeOfBooking === 'BOOK_FOR_OTHERS') {
        if (inPolicyFlight && inPolicyFlight.travelCreditsInfo) {
          delete inPolicyFlight.travelCreditsInfo
        } else {
          if (this.selectedFlight.travelCreditsInfo) {
            delete this.selectedFlight.travelCreditsInfo
          }
        }
      }
      if(this.searchService.nonCombo){
        for(let flight of this.searchService.nonComboSelectedFlight){
          flightsList.push(flight);
        }
      }else if (inPolicyFlight) {
        flightsList.push(inPolicyFlight);
      } else {
        flightsList.push(this.selectedFlight);
      }

      // flightsList.push(this.selectedFlight);
      let flights = { 'flights': flightsList };
      bookingRequest.flightsToBook = flights;
      bookingRequest.selectedSeat = seatSelected;
      bookingRequest.flightSearchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequestForBooking")));
      bookingRequest.bookingType = 'flight';
      if (this.selectedFlight) {
        this.priceChangeData.push(flights);
      }
      bookingRequest.previousBookingCodes = this.priceChangeData;
    } else if (approvalFlowType === 'hotel') {
      let hotelsList: any[] = [];
      let selectedHotelDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
      hotelsList.push(selectedHotelDetailsObj);
      bookingRequest.hotelsToBook = hotelsList;
      bookingRequest.hotelSearchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequestForBooking")));
      bookingRequest.bookingType = 'hotel';
    } else if (approvalFlowType === 'cars') {
      let carsList: any[] = [];
      let selectedCarDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedCarForBooking"));
      for (let car of selectedCarDetailsObj) {
        carsList.push(car);
      }
      bookingRequest.carsToBook = carsList;
      bookingRequest.carSearchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("carSearchRequestForBooking")));
      bookingRequest.bookingType = 'cars';
    }
    else {
      bookingRequest.bookingType = 'emailquote';
    }
    if (this.proceedButton) {
      bookingRequest.selectedSeat = seatSelected;
    }
    bookingRequest.tripId = tripId;
    bookingRequest.bookingStatusId = bookingStatusId;
    bookingRequest.ticketId = ticketId;
    bookingRequest.eventIdAndOptionMap = eventIdAndOptions;
    if (this.gallopLocalStorage.getItem("bookingResponse") && this.gallopLocalStorage.getItem("bookingResponse") != null) {
      let bookingRespose: BookingResponse = deserialize(JSON.parse(this.gallopLocalStorage.getItem("bookingResponse")));
      bookingRequest.lastTransactionId = bookingRespose.transactionId;
      bookingRequest.ticketId = bookingRespose.ticketId;
      bookingRequest.tripId = bookingRespose.tripId;
      bookingRequest.eventIdAndOptionMap = bookingRespose.eventIdAndOptions;
    }
    return bookingRequest;
  }

  getHotelBookingRequest(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag): HotelBookingRequest {

    this.bookingRequest = new HotelBookingRequest();

    this.bookingRequest.travellerDetails = this.getTravelerDetails('WebSearch');
    this.bookingRequest.paymentDetails = this.getPaymentDetails(details, cardType, gallopCash);

    let hotelsList: any[] = [];
    let selectedHotelDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
    hotelsList.push(selectedHotelDetailsObj);

    // let flights = {'flights': flightsList};

    this.bookingRequest.hotelsToBook = hotelsList;
    this.bookingRequest.tripId = tripId;
    if (selectedTag !== '') {
      this.bookingRequest.projectTagId = selectedTag.toString();
    }
    this.bookingRequest.ticketId = ticketId;
    this.bookingRequest.eventIdAndOptionMap = eventIdAndOptions;
    this.bookingRequest.hotelSearchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequestForBooking")));
    if (this.gallopLocalStorage.getItem("hotelBookingResponse") && this.gallopLocalStorage.getItem("hotelBookingResponse") != null) {
      let bookingRespose: HotelBookingResponse = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelBookingResponse")));
      this.bookingRequest.lastTransactionId = bookingRespose.transactionId;
      this.bookingRequest.ticketId = bookingRespose.ticketId;
      this.bookingRequest.tripId = bookingRespose.tripId;
      this.bookingRequest.eventIdAndOptionMap = bookingRespose.eventIdAndOptions;
    }
    return this.bookingRequest;
  }
  buildHotelTripItemBookingRequest(paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag,index): HotelBookingRequest {

    let bookingRequest = new HotelBookingRequest();

    // bookingRequest.travellerDetails = this.getTravelerDetails('WebSearch');
    bookingRequest.paymentDetails = this.getPaymentDetails(details, cardType, gallopCash);

    let hotelsList: any[] = [];
    let selectedHotelDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"))[index];
    hotelsList.push(selectedHotelDetailsObj);
    if(selectedHotelDetailsObj && selectedHotelDetailsObj.displayPrice){
      bookingRequest.displayPrice = selectedHotelDetailsObj.displayPrice;
      bookingRequest.displayCurrency = selectedHotelDetailsObj.displayCurrency;
    }
    // let flights = {'flights': flightsList};

    bookingRequest.hotelsToBook = hotelsList;
    bookingRequest.tripId = tripId;
    // if (selectedTag !== '') {
    //   bookingRequest.projectTagId = selectedTag.toString();
    // }
    bookingRequest.ticketId = ticketId;
    bookingRequest.eventIdAndOptionMap = eventIdAndOptions;
    bookingRequest.hotelSearchQuery = this.hotelSearchService.getSearchRequest(this.searchService.multihotelQuery[index]);
    // this.bookingRequest.hotelSearchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequestForBooking")));
    // if (this.gallopLocalStorage.getItem("hotelBookingResponse") && this.gallopLocalStorage.getItem("hotelBookingResponse") != null) {
    //   let bookingRespose: HotelBookingResponse = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelBookingResponse")));
    //   this.bookingRequest.lastTransactionId = bookingRespose.transactionId;
    //   this.bookingRequest.ticketId = bookingRespose.ticketId;
    //   this.bookingRequest.tripId = bookingRespose.tripId;
    //   this.bookingRequest.eventIdAndOptionMap = bookingRespose.eventIdAndOptions;
    // }
    bookingRequest.paymentDetails.paymentMethod = paymentMethod;
    return bookingRequest;
  }
  getTemporarilyHotelBookingRequest(): HotelBookingRequest {
    return this.bookingRequest;
  }
  getRevalidateRequest(ticketId, tripId, eventId, optionId, threadId,index): RevalidateRequest {

    let revalidateRequest = new RevalidateRequest();
    let flightsList: FlightResult[] = [];
    let selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"))
    if(this.searchService.nonCombo){
      for(let flight of this.searchService.nonComboSelectedFlight){
        flightsList.push(flight);
      }
    }else if (this.searchService.outsidePolicyselected && this.searchService.inPolicyFlight.length > 0) {
      let eventId1 = +eventId;
      let optionId1 = +optionId;
      if (eventId1 === 1) {
        let inPolicyFlight = this.searchService.inPolicyFlight[(optionId1 - 1)];
        flightsList.push(inPolicyFlight);
      } else {
        flightsList.push(this.selectedFlight);
      }
    } else {
      if(!this.searchService.multiTripBooking){
      flightsList.push(this.selectedFlight);
      }else{
        flightsList.push(selectedFlight[index]);
      }
    }
    let flights = { 'flights': flightsList };
    revalidateRequest.flightsToBook = flights;
    revalidateRequest.tripId = tripId;
    revalidateRequest.ticketId = ticketId;
    revalidateRequest.eventId = eventId;
    revalidateRequest.optionId = optionId;
    revalidateRequest.threadId = threadId;
    revalidateRequest.searchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequestForBooking")));
    return revalidateRequest;
  }

  getTravelerDetails(type: string): TravelerDetails {
    let travelerDetails = new TravelerDetails();

    let travelersInfoList: TravelersInfo[] = [];

    let passengersDetails: any;
    passengersDetails = JSON.parse(this.gallopLocalStorage.getItem('passengers'));

    passengersDetails['passengers'].forEach((p) => {
      let travelersInfo: TravelersInfo;
      //TODO: to disallow unknown fields
      travelersInfo = deserialize(p, TravelersInfo);
      travelersInfo.phoneNumber = p.phoneNumber ? p.dialCode + p.phoneNumber : "";
      travelersInfo.middleName = p.middleName && p.middleName.trim().length > 0 ? p.middleName.trim() : " ";
      travelersInfo.firstName = p.firstName && p.firstName.trim().length > 0 ? p.firstName.trim() : " ";
      travelersInfo.lastName = p.lastName && p.lastName.trim().length > 0 ? p.lastName.trim() : " ";
      travelersInfo.title = p.title ? p.title : " ";
      travelersInfo.knownTravellerNumber = p.knownTravellerNumber && p.knownTravellerNumber.trim().length > 0 ? p.knownTravellerNumber.trim() : " ";
      travelersInfo.address = p.address && p.address.trim().length > 0 ? p.address.trim() : " ";
      travelersInfo = this.deserialiseAndFormatTravelersInfoKnownFields(travelersInfo, type);

      travelersInfoList.push(travelersInfo);
    });
    travelerDetails.travellers = travelersInfoList;
    let emergencyContact: any = JSON.parse(this.gallopLocalStorage.getItem("emergencyContactDetails"));
    if (travelerDetails.emergencyContact) {
      if (emergencyContact) {
        travelerDetails.emergencyContact.name = emergencyContact.name.trim();
        travelerDetails.emergencyContact.relationship = emergencyContact.relationship.trim();
        if (emergencyContact.contactNumber) {
          if (travelerDetails.emergencyContact.contactNumber == null) {
            travelerDetails.emergencyContact.contactNumber = new PhoneNumberDTO();
          }
          travelerDetails.emergencyContact.contactNumber.countryCode = emergencyContact.dialcode1 ? emergencyContact.dialcode1 : "+1";
          travelerDetails.emergencyContact.contactNumber.number = emergencyContact.contactNumber ? (emergencyContact.dialcode1+emergencyContact.contactNumber) : "";
        }
        travelerDetails.emergencyContact.contactNumber.number = (emergencyContact.dialcode1+emergencyContact.contactNumber);
        travelerDetails.emergencyContact.contactNumber.countryCode = emergencyContact.dialcode1;
      }
    } else {
      travelerDetails.emergencyContact = { name: '', relationship: '', contactNumber: new PhoneNumberDTO() };
      if (emergencyContact) {
        travelerDetails.emergencyContact.name = emergencyContact.name.trim();
        travelerDetails.emergencyContact.relationship = emergencyContact.relationship.trim();
        if (emergencyContact.contactNumber) {
          if (travelerDetails.emergencyContact.contactNumber == null) {
            travelerDetails.emergencyContact.contactNumber = new PhoneNumberDTO();
          }
          travelerDetails.emergencyContact.contactNumber.countryCode = emergencyContact.dialcode1 ? emergencyContact.dialcode1 : "+1";
          travelerDetails.emergencyContact.contactNumber.number = emergencyContact.contactNumber ? (emergencyContact.dialcode1+emergencyContact.contactNumber) : "";
        }
        travelerDetails.emergencyContact.contactNumber.number = (emergencyContact.dialcode1+emergencyContact.contactNumber);
        travelerDetails.emergencyContact.contactNumber.countryCode = emergencyContact.dialcode1;
      }
    }
    travelerDetails.phoneNumber = travelersInfoList[0].phoneNumber;
    travelerDetails.email = travelersInfoList[0].email;
    return travelerDetails;
  }

  getPaymentDetails(details, type, gallopCash): PaymentInstrumentDetails {
    let paymentInstrumentDetails = new PaymentInstrumentDetails();

    // let cardDetails = new BookingCardDetails();
    // cardDetails.id = details.id;
    // cardDetails.brand = details.brand;
    // cardDetails.exp_month = details.exp_month;
    // cardDetails.exp_year = details.exp_year;
    // cardDetails.last4 = details.last4;
    // cardDetails.name = details.name;
    // if(type && type === 'token'){
    //   paymentInstrumentDetails.cardToken = details;
    // }else{
    paymentInstrumentDetails.card = details;
    // }
    paymentInstrumentDetails.appliedGallopCash = gallopCash;
    return paymentInstrumentDetails;
  }

  deserialiseAndFormatTravelersInfoKnownFields(travelersInfo: TravelersInfo, type: string): TravelersInfo {
   let  passengersDetails = JSON.parse(this.gallopLocalStorage.getItem('passengers'));

   let totaltraveller =  passengersDetails['passengers'].length;
    travelersInfo.title = travelersInfo.title.toUpperCase();
    if (type && type === 'WebSearch') {
      travelersInfo.dateOfBirth = DateUtils.getDateStringAsYYYMMDD(travelersInfo.dateOfBirth);
    } else {
      travelersInfo.dateOfBirth = this.datePipe.transform(new Date(travelersInfo.dateOfBirth), 'MM/dd/yyyy');
    }
    if (travelersInfo['passportNationality']) {
      travelersInfo.nationality = travelersInfo['passportNationality']['code'];
    }

    let passportDetails = undefined;

    if (travelersInfo['passportNumber']) {
      passportDetails = new PassportDetails();
      passportDetails.passportNumber = travelersInfo['passportNumber'];
    }

    if (travelersInfo['passportExpiryDate'] && travelersInfo['passportNumber']) {
      if (type && type === 'WebSearch') {
        passportDetails.passportExpiryDate = DateUtils.getDateStringAsYYYMMDD(travelersInfo['passportExpiryDate']);
      } else {
        passportDetails.passportExpiryDate = this.datePipe.transform(new Date(travelersInfo['passportExpiryDate']), 'MM/dd/yyyy');
      }
    }

    if (travelersInfo['passportNumber'] && travelersInfo['passportCountry'] && travelersInfo['passportCountry']['code']) {
      passportDetails.passportCountryCode = travelersInfo['passportCountry']['code'];
    }

    travelersInfo.passportDetails = passportDetails;

    let ffnList: FrequentFlyerInfo[] = [];

    travelersInfo['frequentFlyerInfo'].forEach((ffn) => {

      if (ffn['ffnAvailable']) {
        delete ffn['ffnAvailable'];
       if(totaltraveller >1 && ffn['frequent_flyer_number'] && ffn['frequent_flyer_number']!==''){
        ffnList.push(ffn);
       }else if(totaltraveller===1){
        ffnList.push(ffn);
        }
        
      }
     
    });

    if (ffnList.length > 0) {
      travelersInfo.frequentFlyerInfo = ffnList;
    } else {
      delete travelersInfo['frequentFlyerInfo'];
    }
    let clnList: any = [];
    travelersInfo['carLoyalityInfo'].forEach((cln) => {

      if (cln['clnAvailable']) {
        delete cln['clnAvailable'];
        if(totaltraveller >1 && cln['rentalCarLoyaltyNumber'] && cln['rentalCarLoyaltyNumber']!==''){
          clnList.push(cln);
         }else if(totaltraveller===1){
        clnList.push(cln);
        }
     
      }
    });

    if (clnList.length > 0) {
      travelersInfo.carLoyalityInfo = clnList;
    } else {
      delete travelersInfo['carLoyalityInfo'];
    }
    let hlnList: any = [];
    if(travelersInfo['hotelLoyalityInfo']){
    travelersInfo['hotelLoyalityInfo'].forEach((hln) => {

      if (hln['hlnAvailable']) {
        delete hln['hlnAvailable'];
        if(totaltraveller >1 && hln['hotel_loyality_number'] && hln['hotel_loyality_number']!==''){
          hlnList.push(hln);
         }else if(totaltraveller===1){
        hlnList.push(hln);
        }
      
      }
    });
  }

    if (hlnList.length > 0) {
      travelersInfo.hotelLoyalityInfo = hlnList;
    } else {
      delete travelersInfo['hotelLoyalityInfo'];
    }
    if (!travelersInfo['ktnAvailable']) {
      travelersInfo['knownTravellerNumber'] = ' ';
    }
    if (!this.isZipCodeRequired) {
      delete travelersInfo['zipCode'];
    }
    delete travelersInfo['ffnAvailable'];
    delete travelersInfo['clnAvailable'];
    delete travelersInfo['ktnAvailable'];
    delete travelersInfo['hlnAvailable'];
    delete travelersInfo['openFormOnUI'];
    delete travelersInfo['passportNumber'];
    delete travelersInfo['passportExpiryDate'];
    delete travelersInfo['passportCountry'];
    delete travelersInfo['passportNationality'];
    delete travelersInfo['dialCode'];

    return travelersInfo;
  }

  buildBookFlightRequestData(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingStatusId, selectedTag,tripSessionItemIndex:number = 0): any {
    let bookingRequest = this.getBookingRequestWithTripSessionIndex(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag,tripSessionItemIndex);
    if (includeExpensifyDetails) {
      let expensifyDetails = JSON.parse(this.gallopLocalStorage.getItem("expensifyDetails"));
      bookingRequest.expensifyId = expensifyDetails && expensifyDetails.expensifyEmail && expensifyDetails.expensifyEmail.trim().length > 0 ?
        expensifyDetails.expensifyEmail : undefined;
      bookingRequest.addToExpensify = expensifyDetails && expensifyDetails.addToExpensify ? expensifyDetails.addToExpensify : false;
      let expenseDetails = this.gallopLocalStorage.getItem("expenseDetails");
      if (bookingRequest.expensifyId) {
        bookingRequest.expenseProvider = expenseDetails;
      }
    } else {
      bookingRequest.addToExpensify = false;
    }
   
    if(this.previousTransactionId){
      bookingRequest.rebookingInfo = {transactionId : this.previousTransactionId,tripSessionId:this.previousTripSessionId}
     }
    bookingRequest.paymentDetails.paymentMethod = paymentMethod;
    return bookingRequest;
  }
  bookFlight(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingStatusId, selectedTag): Observable<any> {
    let bookingRequest = this.buildBookFlightRequestData(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingStatusId, selectedTag);
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      .set('bookingStatusId', bookingStatusId);
    ;
    return this.http.post(environment.apiBookFlightURL, bookingRequest, { params });
  }


  buildTripSessionBookRequest(bookingRequest,forApproval = false){
    let tripSessionBookingRequest : TripSessionBookingRequest = new TripSessionBookingRequest();
    let tripSessionBookingItems : TripSessionBookingData [] =  new Array();
   
    tripSessionBookingRequest.travellerDetails = this.getTravelerDetails('WebSearch');
    for(let i = 0; i < bookingRequest.length; i++){
      
      let bookingData : TripSessionBookingData = new TripSessionBookingData();
      bookingData.bookingType = bookingRequest[i]["type"];
      if(bookingRequest[i]["type"] == "flight"){
        let bookingDetails: BookingRequest = bookingRequest[i]["bookingDetails"];
        bookingData.displayCurrency = bookingDetails.displayCurrency;
        bookingData.displayPrice = bookingDetails.displayPrice;
        bookingData.eventIdAndOptionMap = bookingDetails.eventIdAndOptionMap;
        bookingData.paymentDetails = bookingDetails.paymentDetails;

        bookingData.tripId = bookingDetails.tripId;
        bookingData.ticketId = bookingDetails.ticketId;

        bookingData.searchQuery = bookingDetails.searchQuery;
        bookingData.selectedSeat = bookingDetails.selectedSeat;
        bookingData.flights = bookingDetails.flightsToBook.flights;
        bookingData.previousBookingCodes = bookingDetails.previousBookingCodes;
        bookingData.rebookingInfo = bookingDetails.rebookingInfo;
        // if(!travelerDetails){
        //   travelerDetails = bookingDetails.travellerDetails;
        // }
       

      } else if(bookingRequest[i]["type"] == "hotel"){
        let bookingDetails: HotelBookingRequest = bookingRequest[i]["bookingDetails"];
        bookingData.displayCurrency = bookingDetails.displayCurrency;
        bookingData.displayPrice = bookingDetails.displayPrice;
        bookingData.eventIdAndOptionMap = bookingDetails.eventIdAndOptionMap;
        bookingData.paymentDetails = bookingDetails.paymentDetails;
        bookingData.tripId = bookingDetails.tripId;
        bookingData.ticketId = bookingDetails.ticketId;

        bookingData.hotelSearchQuery = bookingDetails.hotelSearchQuery;
        bookingData.hotels = bookingDetails.hotelsToBook;
        // if(!travelerDetails){
        //   travelerDetails = bookingDetails.travellerDetails;
        // }
       // travelerDetails.travellers[0].hotelLoyalityInfo = bookingDetails.travellerDetails.travellers[0].hotelLoyalityInfo;

      }else if(bookingRequest[i]["type"] == "cars"){
        let bookingDetails: any = bookingRequest[i]["bookingDetails"];
        bookingData.displayCurrency = bookingDetails.displayCurrency;
        bookingData.displayPrice = bookingDetails.displayPrice;
        bookingData.eventIdAndOptionMap = bookingDetails.eventIdAndOptionMap;
        bookingData.paymentDetails = bookingDetails.paymentDetails;
        bookingData.tripId = bookingDetails.tripId;
        bookingData.ticketId = bookingDetails.ticketId;

        bookingData.carSearchQuery = bookingDetails.searchQuery;
        bookingData.cars = bookingDetails.carsToBook;
        // if(!travelerDetails){
        //   travelerDetails = bookingDetails.travellerDetails;
        // }
       // travelerDetails.travellers[0].hotelLoyalityInfo = bookingDetails.travellerDetails.travellers[0].hotelLoyalityInfo;

      }
      // let tripSessionItem : TripSessionBookingData = new TripSessionBookingData();
      // tripSessionItem.type = bookingRequest[i]["type"];
      // tripSessionItem.bookingData = bookingData;
      tripSessionBookingItems.push(bookingData);
  }
  if(forApproval){
    tripSessionBookingRequest.approvalItems = tripSessionBookingItems;
  }else{
    tripSessionBookingRequest.bookingItems = tripSessionBookingItems;
  }
  
  return tripSessionBookingRequest;
  }
  bookTripSession( bookingRequest ,tripSessionLoyalityNumbers,groupTagName,includeExpensifyDetails,projectTagId,noteToAdmin){
    console.log(groupTagName)
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken);
      // .set('bookingStatusId', bookingStatusId);
    ;
    // let bookingRequest : [] = JSON.parse(this.gallopLocalStorage.getItem("tripSessionBookings"));
    let tripSessionBookingRequest = this.buildTripSessionBookRequest(bookingRequest);
    if(tripSessionBookingRequest.travellerDetails.travellers && tripSessionBookingRequest.travellerDetails.travellers.length===1){
    if(tripSessionLoyalityNumbers.hlnno && tripSessionLoyalityNumbers.hlnno.length > 0 &&  tripSessionLoyalityNumbers.hlnno[0]['hotel_loyality_number'] && tripSessionLoyalityNumbers.hlnno[0]['hotel_loyality_number']!==''){
      tripSessionBookingRequest.travellerDetails.travellers[0].hotelLoyalityInfo = 
      tripSessionLoyalityNumbers.hlnno;
    }else{
      delete tripSessionBookingRequest.travellerDetails.travellers[0].hotelLoyalityInfo;
    }
    if(tripSessionLoyalityNumbers.clnno  && tripSessionLoyalityNumbers.clnno.length > 0 && tripSessionLoyalityNumbers.clnno[0]['rentalCarLoyaltyNumber'] &&  tripSessionLoyalityNumbers.clnno[0]['rentalCarLoyaltyNumber']!==''){
      tripSessionBookingRequest.travellerDetails.travellers[0].carLoyalityInfo =
      tripSessionLoyalityNumbers.clnno;
    }else{
      delete tripSessionBookingRequest.travellerDetails.travellers[0].carLoyalityInfo;
    }
    if(tripSessionLoyalityNumbers.ffn  && tripSessionLoyalityNumbers.ffn.length > 0 && tripSessionLoyalityNumbers.ffn[0]['frequent_flyer_number'] && tripSessionLoyalityNumbers.ffn[0]['frequent_flyer_number']!=='' ){
      tripSessionBookingRequest.travellerDetails.travellers[0].frequentFlyerInfo =
      tripSessionLoyalityNumbers.ffn;
    }else{
      delete tripSessionBookingRequest.travellerDetails.travellers[0].frequentFlyerInfo;
    }
    }
    if(groupTagName == undefined){
      tripSessionBookingRequest.groupTagName = "TripSession Name - " + new Date().toISOString();
    }else{
      tripSessionBookingRequest.groupTagName = groupTagName;

    }
    if(this.oldTripSessionId != undefined){
      tripSessionBookingRequest.existingGroupId = this.oldTripSessionId;
    }
    tripSessionBookingRequest.noteToAdmin = (noteToAdmin && noteToAdmin.length > 0) ? noteToAdmin.trim():noteToAdmin;
    if (includeExpensifyDetails) {
      let expensifyDetails = JSON.parse(this.gallopLocalStorage.getItem("expensifyDetails"));
      tripSessionBookingRequest.expensifyId = expensifyDetails && expensifyDetails.expensifyEmail && expensifyDetails.expensifyEmail.trim().length > 0 ?
        expensifyDetails.expensifyEmail : undefined;
        tripSessionBookingRequest.addToExpensify = expensifyDetails && expensifyDetails.addToExpensify ? expensifyDetails.addToExpensify : false;
      let expenseDetails = this.gallopLocalStorage.getItem("expenseDetails");
      if (tripSessionBookingRequest.expensifyId) {
        tripSessionBookingRequest.expenseProvider = expenseDetails;
      }
    } else {
      tripSessionBookingRequest.addToExpensify = false;
    }
    tripSessionBookingRequest.projectTagId = (projectTagId) ? projectTagId.toString(): "";
    // tripSessionBookingRequest.groupTagName = 
    
  // tripSessionBookingRequest.travellerDetails = travelerDetails;


    return this.http.post(environment.apiBookTripSessionURL, tripSessionBookingRequest, { params });
  }

  
  sendTripSessionBookingApproval( bookingRequest,tripSessionLoyalityNumbers,noteToAdmin,tripName,
    includeExpensifyDetails,projectTagId ){
   
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken);
      // .set('bookingStatusId', bookingStatusId);
    ;
    // let bookingRequest : [] = JSON.parse(this.gallopLocalStorage.getItem("tripSessionBookings"));
    let tripSessionBookingRequest = this.buildTripSessionBookRequest(bookingRequest, true);
    if(tripSessionBookingRequest.travellerDetails.travellers && tripSessionBookingRequest.travellerDetails.travellers.length===1){
    if(tripSessionLoyalityNumbers.hlnno && tripSessionLoyalityNumbers.hlnno.length > 0 &&  tripSessionLoyalityNumbers.hlnno[0]['hotel_loyality_number'] && tripSessionLoyalityNumbers.hlnno[0]['hotel_loyality_number']!==''){
      tripSessionBookingRequest.travellerDetails.travellers[0].hotelLoyalityInfo = 
      tripSessionLoyalityNumbers.hlnno;
    }else{
      delete tripSessionBookingRequest.travellerDetails.travellers[0].hotelLoyalityInfo;
    }
    if(tripSessionLoyalityNumbers.clnno  && tripSessionLoyalityNumbers.clnno.length > 0 && tripSessionLoyalityNumbers.clnno[0]['rentalCarLoyaltyNumber'] &&  tripSessionLoyalityNumbers.clnno[0]['rentalCarLoyaltyNumber']!==''){
      tripSessionBookingRequest.travellerDetails.travellers[0].carLoyalityInfo =
      tripSessionLoyalityNumbers.clnno;
    }else{
      delete tripSessionBookingRequest.travellerDetails.travellers[0].carLoyalityInfo;
    }
    if(tripSessionLoyalityNumbers.ffn  && tripSessionLoyalityNumbers.ffn.length > 0 && tripSessionLoyalityNumbers.ffn[0]['frequent_flyer_number'] && tripSessionLoyalityNumbers.ffn[0]['frequent_flyer_number']!=='' ){
      tripSessionBookingRequest.travellerDetails.travellers[0].frequentFlyerInfo =
      tripSessionLoyalityNumbers.ffn;
    }else{
      delete tripSessionBookingRequest.travellerDetails.travellers[0].frequentFlyerInfo;
    }
  }
    if (includeExpensifyDetails) {
      let expensifyDetails = JSON.parse(this.gallopLocalStorage.getItem("expensifyDetails"));
      tripSessionBookingRequest.expensifyId = expensifyDetails && expensifyDetails.expensifyEmail && expensifyDetails.expensifyEmail.trim().length > 0 ?
        expensifyDetails.expensifyEmail : undefined;
        tripSessionBookingRequest.addToExpensify = expensifyDetails && expensifyDetails.addToExpensify ? expensifyDetails.addToExpensify : false;
      let expenseDetails = this.gallopLocalStorage.getItem("expenseDetails");
      if (tripSessionBookingRequest.expensifyId) {
        tripSessionBookingRequest.expenseProvider = expenseDetails;
      }
    } else {
      tripSessionBookingRequest.addToExpensify = false;
    }
    if(tripName == undefined){
      tripSessionBookingRequest.groupTagName = "TripSession Name - " + new Date().toISOString();
    }else{
      tripSessionBookingRequest.groupTagName = tripName;
    }
    if(this.oldTripSessionId != undefined){
      tripSessionBookingRequest.existingGroupId = this.oldTripSessionId;
    }
    tripSessionBookingRequest.noteToAdmin = (noteToAdmin && noteToAdmin.length > 0) ? noteToAdmin.trim():noteToAdmin;
    tripSessionBookingRequest.projectTagId = (projectTagId) ? projectTagId.toString(): "";
  // tripSessionBookingRequest.travellerDetails = travelerDetails;


    return this.http.post(environment.apiApproveTripSessionBookingURL, tripSessionBookingRequest, { params });
  }

  pollingForBookingResponse(bookingStatusId): Observable<any> {

    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      .set('bookingStatusId', bookingStatusId);
    return this.http.get(environment.apiCheckBookingResponseURL, { params });
  }

  requestBookingApproval(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingFlowType, bookingStatusId,messageForAdmin,selectedTag?): Observable<any> {
    let bookingRequest = this.getApprovalBookingRequest(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingFlowType, bookingStatusId, selectedTag);
if(messageForAdmin){
  bookingRequest.noteToAdmin = (messageForAdmin && messageForAdmin.length > 0) ? messageForAdmin.trim():messageForAdmin;
}
    if (includeExpensifyDetails) {
      let expensifyDetails = JSON.parse(this.gallopLocalStorage.getItem("expensifyDetails"));
      bookingRequest.expensifyId = expensifyDetails && expensifyDetails.expensifyEmail && expensifyDetails.expensifyEmail.trim().length > 0 ?
        expensifyDetails.expensifyEmail : undefined;
      bookingRequest.addToExpensify = expensifyDetails && expensifyDetails.addToExpensify ? expensifyDetails.addToExpensify : false;
      let expenseDetails = this.gallopLocalStorage.getItem("expenseDetails");
      if (bookingRequest.expensifyId) {
        bookingRequest.expenseProvider = expenseDetails;
      }
    } else {
      bookingRequest.addToExpensify = false;
    }
    if(this.previousTransactionId){
      bookingRequest.rebookingInfo = {transactionId : this.previousTransactionId,tripSessionId:this.previousTripSessionId}
     }
    if (paymentMethod) {
      bookingRequest.paymentDetails.paymentMethod = paymentMethod;
    } else {
      bookingRequest.paymentDetails = null;
    }
    // bookingRequest.paymentDetails.paymentMethod = paymentMethod;
    var revalidateTestCase = "";
    var bookingTestCase = "";
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      .set('revalidateTestCase', revalidateTestCase)
      .set('bookingTestCase', bookingTestCase)
      .set('bookingStatusId', bookingStatusId)
      ;
    return this.http.post(environment.apiApproveBookingURL, bookingRequest, { params });
  }
  buildBookHotelRequestData(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingStatusId, selectedTag?): any {
    let bookingRequest = this.getHotelBookingRequest(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag);

    if (includeExpensifyDetails) {
      let expensifyDetails = JSON.parse(this.gallopLocalStorage.getItem("expensifyDetails"));
      bookingRequest.expensifyId = expensifyDetails && expensifyDetails.expensifyEmail && expensifyDetails.expensifyEmail.trim().length > 0 ?
        expensifyDetails.expensifyEmail : undefined;
      bookingRequest.addToExpensify = expensifyDetails && expensifyDetails.addToExpensify ? expensifyDetails.addToExpensify : false;
      let expenseDetails = this.gallopLocalStorage.getItem("expenseDetails");
      if (bookingRequest.expensifyId) {
        bookingRequest.expenseProvider = expenseDetails;
      }
    } else {
      bookingRequest.addToExpensify = false;
    }
    if(this.searchService.displayPrice){
      bookingRequest.displayPrice = this.searchService.displayPrice;
      bookingRequest.displayCurrency = this.searchService.displayCurrency;
    }
    bookingRequest.paymentDetails.paymentMethod = paymentMethod;
    return bookingRequest;
  }

  
  bookHotel(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingStatusId, selectedTag?): Observable<any> {
    let bookingRequest = this.buildBookHotelRequestData(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingStatusId, selectedTag);
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      .set('bookingStatusId', bookingStatusId)
      ;
    // if (bookingRequest.flightsToBook.flights[0].currency !== 'USD') {
    //   params = params.set('revalidateTestCase','HANDOVER_TO_HUMAN');
    // }
    return this.http.post(environment.apiBookHotelURL, bookingRequest, { params });
  }
getAllfare(flight,flightsearchquery): Observable<any>{
  let revalidateRequest = new RevalidateRequest();
  let flightsList: FlightResult[] = [];
    if(this.searchService.nonCombo){
      
        flightsList= flight;
    
    } else {
      flightsList =flight;
    }
    let flights = { 'flights': flightsList };
  revalidateRequest.flightsToBook = flights;
  revalidateRequest.tripId = '';
  revalidateRequest.ticketId = '';
  revalidateRequest.eventId = '';
  revalidateRequest.optionId = '';
  revalidateRequest.threadId = '';
  revalidateRequest.searchQuery = (flightsearchquery);
  revalidateRequest.searchQuery.minPrices = this.searchService.minPrices;
  const userId = this.userAccountService.getUserEmail();
  const sToken = this.userAccountService.getSToken();
  const params = new HttpParams()
   
    // .set('revalidateTestCase',"PRICE_CHANGED")
    ;
  return this.http.post(environment.apiForAllzFares,revalidateRequest, { params });
}
  revalidateFlight(ticketId, tripId, eventId, OptionId, threadId,index): Observable<any> {
    let revaldiateRequest = this.getRevalidateRequest(ticketId, tripId, eventId, OptionId, threadId,index);
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      // .set('revalidateTestCase',"PRICE_CHANGED")
      ;
    return this.http.post(environment.apiRevalidateFlightURL, revaldiateRequest, { params });
  }

  addRecommendHottel(hotelid, hotelHandler, gallopid): Observable<any> {
    if (gallopid) {
      const params = new HttpParams()
        .set('hotelId', hotelid)
        .set('hotelHandler', hotelHandler)
        .set('gallopId', gallopid);
      return this.http.get(environment.apiForHotelRecommendation, { params });
    } else {
      const params = new HttpParams()
        .set('hotelId', hotelid)
        .set('hotelHandler', hotelHandler);
      return this.http.get(environment.apiForHotelRecommendation, { params });
    }

  }
  cardDetails(): Observable<any> {
    return this.http.get(environment.apiForCardDetails);
  }
  cardTransaction(): Observable<any> {
    return this.http.get(environment.apiForCardTransactions);
  }
  removeRecommendHottel(hotelid, hotelHandler, gallopid): Observable<any> {
    if (gallopid) {
      const params = new HttpParams()
        .set('hotelId', hotelid)
        .set('hotelHandler', hotelHandler)
        .set('gallopId', gallopid);
      return this.http.get(environment.apiForRemoveHotelRecommendation, { params });
    } else {
      const params = new HttpParams()
        .set('hotelId', hotelid)
        .set('hotelHandler', hotelHandler);
      return this.http.get(environment.apiForRemoveHotelRecommendation, { params });
    }

  }
  normalizeRecommendHottel(hotelid, hotelHandler, gallopid): Observable<any> {
    if (gallopid) {
      const params = new HttpParams()
        .set('hotelId', hotelid)
        .set('hotelHandler', hotelHandler)
        .set('gallopId', gallopid);
      return this.http.get(environment.apiForNormalizeHotelRecommendation, { params });
    } else {
      const params = new HttpParams()
        .set('hotelId', hotelid)
        .set('hotelHandler', hotelHandler);
      return this.http.get(environment.apiForNormalizeHotelRecommendation, { params });
    }

  }

  confirmBookFlight(includeExpensifyDetails: boolean, emailbookReq: EmailFlowBookRequest, userId: string, sToken: string,noteToAdmin,tripSessionLoyalityNumbers?): Observable<any> {
    emailbookReq.travellerDetails = this.getTravelerDetails('emailFlow');
    if(emailbookReq.travellerDetails.travellers && emailbookReq.travellerDetails.travellers.length===1){
    if(tripSessionLoyalityNumbers.hlnno && tripSessionLoyalityNumbers.hlnno.length > 0 &&  tripSessionLoyalityNumbers.hlnno[0]['hotel_loyality_number'] && tripSessionLoyalityNumbers.hlnno[0]['hotel_loyality_number']!==''){
      emailbookReq.travellerDetails.travellers[0].hotelLoyalityInfo = 
      tripSessionLoyalityNumbers.hlnno;
    }else{
      delete emailbookReq.travellerDetails.travellers[0].hotelLoyalityInfo;
    }
    if(tripSessionLoyalityNumbers.clnno  && tripSessionLoyalityNumbers.clnno.length > 0 && tripSessionLoyalityNumbers.clnno[0]['rentalCarLoyaltyNumber'] &&  tripSessionLoyalityNumbers.clnno[0]['rentalCarLoyaltyNumber']!==''){
      emailbookReq.travellerDetails.travellers[0].carLoyalityInfo =
      tripSessionLoyalityNumbers.clnno;
    }else{
      delete emailbookReq.travellerDetails.travellers[0].carLoyalityInfo;
    }
    if(tripSessionLoyalityNumbers.ffn  && tripSessionLoyalityNumbers.ffn.length > 0 && tripSessionLoyalityNumbers.ffn[0]['frequent_flyer_number'] && tripSessionLoyalityNumbers.ffn[0]['frequent_flyer_number']!=='' ){
      emailbookReq.travellerDetails.travellers[0].frequentFlyerInfo =
      tripSessionLoyalityNumbers.ffn;
    }else{
      delete emailbookReq.travellerDetails.travellers[0].frequentFlyerInfo;
    }
  }
 // emailbookReq.noteToAdmin = noteToAdmin;
    let eventIds = JSON.parse(this.gallopLocalStorage.getItem('eventIds'));
    let expensifyDetails = JSON.parse(this.gallopLocalStorage.getItem("expensifyDetails"));
    let expenseProvider = this.gallopLocalStorage.getItem("expenseDetails");
    emailbookReq.eventIds = eventIds;
    if (includeExpensifyDetails) {
      emailbookReq.expensifyEmail = expensifyDetails && expensifyDetails.expensifyEmail && expensifyDetails.expensifyEmail.trim().length > 0 ?
        expensifyDetails.expensifyEmail : undefined;
      emailbookReq.addToExpensify = expensifyDetails && expensifyDetails.addToExpensify ? expensifyDetails.addToExpensify : false;
      emailbookReq.expenseProvider = expenseProvider;
    }
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken);
    return this.http.post(environment.apiEmailFlowBookURL, emailbookReq, { params });
  }

  getBookingModalHotelResponse(bookingResponse: HotelBookingResponse): any {
    // this.bookingResponse = bookingResponse;
    let bookingModalRes = {};
    let responseType = bookingResponse.status.toUpperCase();

    if (responseType === Constants.ERROR) {
      responseType = bookingResponse.errors[0].errorType;
    }
    switch (responseType) {

      case 'SUCCESS': {
        bookingModalRes = {
          title: this.translateService.instant('bookingService.BookingSuccessful'),
          description: this.translateService.instant('bookingService.Youwillreceiveanemailshortlywithyouritineraryandinvoice'),
          nextStep: this.translateService.instant('bookingService.BookTravel'),
          iconSCSS: 'checkmark',
          errorCode: 'SUCCESS',
          bookingType: 'hotel',
          bookingResponse:bookingResponse
        };
      } break;


      case BookingResponseErrorType.ERROR_PAYMENT: {
        let errorMessage = bookingResponse.errors[0].errorMessage;
        bookingModalRes = {
          title: errorMessage,
          nextStep: this.translateService.instant('bookingService.TRYAGAIN'),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_PAYMENT
        };
      } break;


      case BookingResponseErrorType.ERROR_PRICE_CHANGE: {

        let newPrice = bookingResponse.changedHotels[0].price;
        // this.selectedHotel = bookingResponse.changedHotels[0];
        this.broadCastFlightFareChange(newPrice);
        let currencyPipe: CurrencyPipe = new CurrencyPipe('en_US');
        let priceWithCurrency: string = currencyPipe.transform(newPrice, this.selectedHotel.currency, 'code', '1.2-2');
        bookingModalRes = {
          // description: 'The total fare for the selected itinerary has changed to <span class="text-fare">$'+newPrice+'</span>'+'\n'+
          // 'Please click "Accept" to proceed with the new fare or click "Find alternative" to search best alternative options.',
          description: this.translateService.instant('bookingService.flightFareChangedDescriptionPart1').toString() + '<span class="text-fare"> ' + priceWithCurrency + ' </span>' + this.translateService.instant('bookingService.flightFareChangedDescriptionPart2').toString(),
          nextStep: this.translateService.instant('bookingService.FINDALTERNATIVE'),
          buttonName: this.translateService.instant('bookingService.ACCEPT'),
          iconSCSS: 'fare-changed',
          errorCode: BookingResponseErrorType.ERROR_PRICE_CHANGE
        };
      } break;

      case BookingResponseErrorType.ERROR_ROOM_UNAVAILABLE:
      case BookingResponseErrorType.ERROR_FAILED_BY_VENDOR: {
        let selectedHotelDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
        let failedRatesStr: any = this.gallopLocalStorage.getItem('failedRates');
        let failedRates = {};
        if (failedRatesStr) {
          failedRates = JSON.parse(failedRatesStr);
        }

       if (selectedHotelDetailsObj[0].hotelRateDetail.ratePlanTypePPN != null) {
          failedRates[selectedHotelDetailsObj[0].hotelRateDetail.ratePlanTypePPN] = true;
        } else {
         failedRates[selectedHotelDetailsObj[0].hotelRateDetail.ratePlanType] = true;
        }
        this.gallopLocalStorage.setItem('failedRates', JSON.stringify(failedRates));

        bookingModalRes = {
          // description: 'The total fare for the selected itinerary has changed to <span class="text-fare">$'+newPrice+'</span>'+'\n'+
          // 'Please click "Accept" to proceed with the new fare or click "Find alternative" to search best alternative options.',
          // this.translate.instant('bookingService.flightFareChangedDescriptionPart1');
          // | currency : getCurrencySymbol(currencyCode) : "code" : '1.2-2'
          description: this.translateService.instant('bookingService.hotelRoomNotAvailable').toString(),
          nextStep: this.translateService.instant('bookingService.FINDALTERNATIVE_ROOM'),
          iconSCSS: 'cross-bold',
          errorCode: responseType
        };
      } break;
      case BookingResponseErrorType.ERROR_AVAILABILITY: {

        let dynamicText = 'you selected';

        // if(this.selectedFlight) {

        //   let flightLegInfoList: [{source:string,destination:string}] = FlightUtils.getLegWiseSourceDestination(this.selectedFlight);

        //   let source= flightLegInfoList[0].source;
        //   let destination = flightLegInfoList[flightLegInfoList.length-1].destination;

        //   if(flightLegInfoList.length==1){
        //     dynamicText= 'from ' + source + ' to '+ destination ;
        //   }else{

        //     dynamicText= 'from ' + source + ' to ' ;

        //     for(let i=0;i<flightLegInfoList.length-1;i++){

        //       let d = flightLegInfoList[i].destination;
        //       let s_next = flightLegInfoList[i+1].source;

        //       if(d==s_next){
        //         dynamicText+= d + ' to ';
        //       }else{
        //         dynamicText+= d + ' / ' + s_next + ' to ';
        //       }
        //     }

        //     dynamicText+= destination;
        //   }

        // }

        bookingModalRes = {
          // description: 'Oops!! The hotel <strong>' + dynamicText +'</strong> is no longer available. ' +
          // 'Please click on "Search again" to find the best alternative option.',
          description: this.translateService.instant('bookingService.hotelUnavailableDescription'),
          nextStep: this.translateService.instant('bookingService.SEARCHAGAIN'),
          iconSCSS: 'no-flight',
          errorCode: BookingResponseErrorType.ERROR_AVAILABILITY
        };
      } break;

      case BookingResponseErrorType.ERROR_INTERNAL: {

        bookingModalRes = {
          title: this.translateService.instant('bookingService.Aww…Snap'),
          description: this.translateService.instant('bookingService.internalErrorDescription', { "GALLOP_HELP_CONTACT_NO": Constants.GALLOP_HELP_CONTACT_NO, "GALLOP_HELP_EMAIL": this.clientConfig.agentEmailId }),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_INTERNAL
        };
      } break;

      case BookingResponseErrorType.ERROR_API: {

        bookingModalRes = {
          title: this.translateService.instant('bookingService.Aww…Snap'),
          description: this.translateService.instant('bookingService.apiErrorDescription', { "GALLOP_HELP_CONTACT_NO": Constants.GALLOP_HELP_CONTACT_NO, "GALLOP_HELP_EMAIL": this.clientConfig.agentEmailId }),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_API
        };
      } break;

      case BookingResponseErrorType.ERROR_TICKETING: {

        bookingModalRes = {
          title: this.translateService.instant('bookingService.Bookingcompletebutnotconfirmed'),
          description: this.translateService.instant('bookingService.ticketingErrorDescription', { "GALLOP_HELP_CONTACT_NO": Constants.GALLOP_HELP_CONTACT_NO, "GALLOP_HELP_EMAIL": this.clientConfig.agentEmailId }),
          errorCode: BookingResponseErrorType.ERROR_TICKETING,
          nextStep: this.translateService.instant('bookingService.BookTravel'),
          bookingType: 'hotel'
        };
      } break;

      case BookingResponseErrorType.ERROR_INVALID_INPUT: {

        let errorMessage = bookingResponse.errors[0].errorMessage;

        bookingModalRes = {
          title: errorMessage,
          nextStep: this.translateService.instant('bookingService.TRYAGAIN'),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_INVALID_INPUT
        };

      } break;
    }
    return bookingModalRes;
  }
  getCarBookingModalResponse(bookingResponse: BookingResponse, showNextStep: boolean): any {
    this.bookingResponse = bookingResponse;
    let bookingModalRes = {};
    let responseType = bookingResponse.status.toUpperCase();
    let message = this.translateService.instant('bookingService.Selectdifferentoptions');
    if (this.pageMode === 'emailTicketMode') {
      message = this.translateService.instant('bookingService.Searchagain');
    }

    if (responseType === Constants.ERROR) {
      responseType = bookingResponse.errors[0].errorType;
    }

    switch (responseType) {
      case 'SUCCESS': 
      case BookingResponseErrorType.ERROR_BOOKING_PENDING: {
        bookingModalRes = {
          title: this.translateService.instant('bookingService.BookingSuccessful'),
          // description: 'You will receive an email shortly with your itinerary and invoice.',
          description: this.translateService.instant('bookingService.Youwillreceiveanemailshortlywithitineraryandinvoice'),
          nextStep: this.translateService.instant('bookingService.BookTravel'),
          iconSCSS: 'checkmark',
          errorCode: 'SUCCESS',
          bookingType: 'car',
          bookingResponse:bookingResponse
        };
      } break;
    }
    return bookingModalRes;
  }

  getBookingModalResponse(bookingResponse: BookingResponse, showNextStep: boolean,numberofoption?): any {
    this.bookingResponse = bookingResponse;
    let bookingModalRes = {};
    let description='';
    let responseType = bookingResponse.status.toUpperCase();
    let message = this.translateService.instant('bookingService.Selectdifferentoptions');
    if (this.pageMode === 'emailTicketMode') {
      message =  this.translateService.instant('bookingService.Searchagain');
    }

    if (responseType === Constants.ERROR) {
      responseType = bookingResponse.errors[0].errorType;
    }

    switch (responseType) {

      case 'SUCCESS': {
        bookingModalRes = {
          title: this.translateService.instant('bookingService.BookingSuccessful'),
          // description: 'You will receive an email shortly with your itinerary and invoice.',
          description: this.translateService.instant('bookingService.bookingSuccessDescription'),
          nextStep: this.translateService.instant('bookingService.BookTravel'),
          iconSCSS: 'checkmark',
          errorCode: 'SUCCESS',
         // bookingType: 'flight',
          bookingResponse:bookingResponse
        };
      } break;


      case BookingResponseErrorType.ERROR_PAYMENT: {
        let errorMessage = this.bookingResponse.errors[0].errorMessage;
        bookingModalRes = {
          title: errorMessage,
          nextStep: this.translateService.instant('bookingService.TRYAGAIN'),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_PAYMENT
        };
      } break;


      case BookingResponseErrorType.ERROR_PRICE_CHANGE: {
        let selectedFlight =[]
          

        this.selectedFlight = this.bookingResponse.changedFlights.flights[0];
        selectedFlight.push(this.selectedFlight)
        this.gallopLocalStorage.setItem("selectedFlight", JSON.stringify(selectedFlight));
        let newPriceObject = {
          price: this.bookingResponse.changedFlights.flights[0].fareBreakup.totalPrice,
          discountedPrice: this.bookingResponse.changedFlights.flights[0].fareBreakup.discountedPrice
        };
        if(this.bookingResponse.changedFlights.flights[0].displayPrice){
          newPriceObject = {
            price: this.bookingResponse.changedFlights.flights[0].displayPrice.toFixed(2),
            discountedPrice: this.bookingResponse.changedFlights.flights[0].fareBreakup.displayPrice.toFixed(2)
          };
        }
        let newPrice = newPriceObject.price;
        if (newPriceObject.discountedPrice && newPriceObject.discountedPrice !== newPriceObject.price) {
          newPrice = newPriceObject.discountedPrice;
        }
        this.broadCastFlightFareChange(newPriceObject);
        let currencyPipe: CurrencyPipe = new CurrencyPipe('en_US');
        let priceWithCurrency: string ;
        if(this.bookingResponse.changedFlights.flights[0].displayPrice){
          priceWithCurrency = currencyPipe.transform(newPrice, this.selectedFlight.displayCurrency, 'symbol', '1.2-2');
        }else{
        priceWithCurrency = currencyPipe.transform(newPrice, this.selectedFlight.currency, 'symbol', '1.2-2');
        }
        bookingModalRes = {
          // description: 'The total fare for the selected itinerary has changed to <span class="text-fare">$'+newPrice+'</span>'+'\n'+
          // 'Please click "Accept" to proceed with the new fare or click "Find alternative" to search best alternative options.',
          // this.translate.instant('bookingService.flightFareChangedDescriptionPart1');
          // | currency : getCurrencySymbol(currencyCode) : "code" : '1.2-2'
          description: this.translateService.instant('bookingService.flightFareChangedDescriptionPart1').toString()
            + '<span class="text-fare"> ' + priceWithCurrency
            + ' </span>' + this.translateService.instant('bookingService.flightFareChangedDescriptionPart2').toString(),
          nextStep: showNextStep ? this.translateService.instant('bookingService.FINDALTERNATIVE') : '',
          buttonName: this.translateService.instant('bookingService.ACCEPT'),
          iconSCSS: 'fare-changed',
          errorCode: BookingResponseErrorType.ERROR_PRICE_CHANGE
        };
      } break;
      case BookingResponseErrorType.ERROR_ROOM_UNAVAILABLE:{
        if(this.searchService.multiTripBooking && bookingResponse.multiBookingResponse && bookingResponse.multiBookingResponse.length > 0){
          message =  this.translateService.instant('bookingService.Searchagain');
          this.pageMode = 'emailTicketMode';
        }
        if(numberofoption && numberofoption==='singleflight'){
            description = this.translateService.instant('bookingService.Flightnotavailable');
        }else{
          let selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"));
          let selectedCar = JSON.parse(this.gallopLocalStorage.getItem("selectedCar"))
          let selectedHotelDetailsObj = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
          if(selectedHotelDetailsObj && selectedHotelDetailsObj.length===1 && !selectedFlight && !selectedCar){
            let selectedHotelDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
            let failedRatesStr: any = this.gallopLocalStorage.getItem('failedRates');
            let failedRates = {};
            if (failedRatesStr) {
              failedRates = JSON.parse(failedRatesStr);
            }
          
           if (selectedHotelDetailsObj[0].hotelRateDetail.ratePlanTypePPN != null) {
              failedRates[selectedHotelDetailsObj[0].hotelRateDetail.ratePlanTypePPN] = true;
            } else {
             failedRates[selectedHotelDetailsObj[0].hotelRateDetail.ratePlanType] = true;
            }
            this.gallopLocalStorage.setItem('failedRates', JSON.stringify(failedRates));
          }
          if(selectedFlight && selectedFlight.length > 0){
            description = this.translateService.instant('bookingService.Flightnotavailable');
            if((selectedCar && selectedCar.length > 0) || (selectedHotelDetailsObj && selectedHotelDetailsObj.length > 0)){
              description = this.translateService.instant('bookingService.Selectedoptionsnotavailable');
            }else if(selectedFlight && selectedFlight.length > 1){
              description = this.translateService.instant('bookingService.Flighstnotavailable');
            }
          }
          if(selectedCar && selectedCar.length > 0){
            description = this.translateService.instant('bookingService.Carnotavailable');
            if((selectedFlight && selectedFlight.length > 0) || (selectedHotelDetailsObj && selectedHotelDetailsObj.length > 0)){
              description = this.translateService.instant('bookingService.Selectedoptionsnotavailable');
            }else if(selectedCar && selectedCar.length > 1){
              description = this.translateService.instant('bookingService.Carsnotavailable');
              
            }
          }
          if(selectedHotelDetailsObj && selectedHotelDetailsObj.length > 0){
            description = this.translateService.instant('bookingService.hotelRoomNotAvailable');
            if((selectedFlight && selectedFlight.length > 0) || (selectedCar && selectedCar.length > 0)){
              description = this.translateService.instant('bookingService.Selectedoptionsnotavailable');
            }else if(selectedHotelDetailsObj && selectedHotelDetailsObj.length > 1){
              description = this.translateService.instant('bookingService.hotelRoomsNotAvailable');
            }
          }
        }
        bookingModalRes = {
          // description: 'The total fare for the selected itinerary has changed to <span class="text-fare">$'+newPrice+'</span>'+'\n'+
          // 'Please click "Accept" to proceed with the new fare or click "Find alternative" to search best alternative options.',
          // this.translate.instant('bookingService.flightFareChangedDescriptionPart1');
          // | currency : getCurrencySymbol(currencyCode) : "code" : '1.2-2'
          // description:  this.translateService.instant('bookingService.Flightnotavailable'),
          description : description,
          nextStep: message,
          iconSCSS: 'cross-bold',
          errorCode: responseType
        };
      } break;
      case BookingResponseErrorType.ERROR_AVAILABILITY: {

        let dynamicText = this.translateService.instant('bookingService.flightyouselected').toString();

        //  if(this.selectedFlight) {

        //  let flightLegInfoList: [{source:string,destination:string}] = FlightUtils.getLegWiseSourceDestination(this.selectedFlight);

        //let source= flightLegInfoList[0].source;
        //let destination = flightLegInfoList[flightLegInfoList.length-1].destination;

        //if(flightLegInfoList.length==1){
        //dynamicText= this.translateService.instant('bookingService.fromspace').toString() + source +  this.translateService.instant('bookingService.spacetospace').toString()+ destination ;
        //}else{

        //dynamicText= this.translateService.instant('bookingService.fromspace').toString() + source +  this.translateService.instant('bookingService.spacetospace').toString();

        //for(let i=0;i<flightLegInfoList.length-1;i++){

        //let d = flightLegInfoList[i].destination;
        //let s_next = flightLegInfoList[i+1].source;

        //if(d==s_next){
        //dynamicText+= d + this.translateService.instant('bookingService.spacetospace').toString();
        //}else{
        //dynamicText+= d + ' / ' + s_next + this.translateService.instant('bookingService.spacetospace').toString();
        //}
        //}

        //dynamicText+= destination;
        //}
        //}
        if(this.searchService.multiTripBooking && bookingResponse.multiBookingResponse && bookingResponse.multiBookingResponse.length > 0){
          message =  this.translateService.instant('bookingService.Searchagain');
          this.pageMode = 'emailTicketMode';
        }
        if(numberofoption && numberofoption==='singleflight'){
            description = this.translateService.instant('bookingService.Flightnotavailable');
        }else{
          let selectedFlight = JSON.parse(this.gallopLocalStorage.getItem("selectedFlight"));
          let selectedCar = JSON.parse(this.gallopLocalStorage.getItem("selectedCar"))
          let selectedHotelDetailsObj = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
          
          if(selectedFlight && selectedFlight.length > 0){
            description = this.translateService.instant('bookingService.Flightnotavailable');
            if((selectedCar && selectedCar.length > 0) || (selectedHotelDetailsObj && selectedHotelDetailsObj.length > 0)){
              description = this.translateService.instant('bookingService.Selectedoptionsnotavailable');
            }else if(selectedFlight && selectedFlight.length > 1){
              description = this.translateService.instant('bookingService.Flighstnotavailable');
            }
          }
          if(selectedCar && selectedCar.length > 0){
            description = this.translateService.instant('bookingService.Carnotavailable');
            if((selectedFlight && selectedFlight.length > 0) || (selectedHotelDetailsObj && selectedHotelDetailsObj.length > 0)){
              description = this.translateService.instant('bookingService.Selectedoptionsnotavailable');
            }else if(selectedCar && selectedCar.length > 1){
              description = this.translateService.instant('bookingService.Carsnotavailable');
              
            }
          }
          if(selectedHotelDetailsObj && selectedHotelDetailsObj.length > 0){
            description = this.translateService.instant('bookingService.hotelRoomNotAvailable');
            if((selectedFlight && selectedFlight.length > 0) || (selectedCar && selectedCar.length > 0)){
              description = this.translateService.instant('bookingService.Selectedoptionsnotavailable');
            }else if(selectedHotelDetailsObj && selectedHotelDetailsObj.length > 1){
              description = this.translateService.instant('bookingService.hotelRoomsNotAvailable');
            }
          }
        }
        bookingModalRes = {
          // description: 'The total fare for the selected itinerary has changed to <span class="text-fare">$'+newPrice+'</span>'+'\n'+
          // 'Please click "Accept" to proceed with the new fare or click "Find alternative" to search best alternative options.',
          // this.translate.instant('bookingService.flightFareChangedDescriptionPart1');
          // | currency : getCurrencySymbol(currencyCode) : "code" : '1.2-2'
          // description:  this.translateService.instant('bookingService.Flightnotavailable'),
          description : description,
          nextStep: message,
          iconSCSS: 'cross-bold',
          errorCode: responseType
        };
      } break;

      case BookingResponseErrorType.ERROR_AVAILABILITY_WITHIN_TWOHRS: {

        let textInitialPart = this.translateService.instant('bookingService.flightyouselected').toString();
        let dynamicText = "";

        if (this.selectedFlight) {

          let flightLegInfoList: [{ source: string, destination: string }] = FlightUtils.getLegWiseSourceDestination(this.selectedFlight);

          let source = flightLegInfoList[0].source;
          let destination = flightLegInfoList[flightLegInfoList.length - 1].destination;

          if (flightLegInfoList.length == 1) {
            dynamicText = dynamicText + this.translateService.instant('bookingService.fromspace').toString() + source + this.translateService.instant('bookingService.spacetospace').toString() + destination;
          } else {

            dynamicText = dynamicText + this.translateService.instant('bookingService.fromspace').toString() + source + this.translateService.instant('bookingService.spacetospace').toString();

            for (let i = 0; i < flightLegInfoList.length - 1; i++) {

              let d = flightLegInfoList[i].destination;
              let s_next = flightLegInfoList[i + 1].source;

              if (d == s_next) {
                dynamicText += d + this.translateService.instant('bookingService.spacetospace').toString();
              } else {
                dynamicText += d + ' / ' + s_next + this.translateService.instant('bookingService.spacetospace').toString();
              }
            }

            dynamicText += destination;
          }

        }

        bookingModalRes = {
          // description: '<strong>' + dynamicText +'</strong> is no longer available. ' +
          // 'Please click on "Search again" to find the best alternative option.',
          description: textInitialPart + ' <strong>' + dynamicText + '</strong> ' + this.translateService.instant('bookingService.flightUnavailableDescription'),
          nextStep: this.translateService.instant('bookingService.SEARCHAGAIN'),
          iconSCSS: 'no-flight',
          errorCode: BookingResponseErrorType.ERROR_AVAILABILITY
        };
      } break;

      case BookingResponseErrorType.ERROR_INTERNAL: {

        bookingModalRes = {
          title: this.translateService.instant('bookingService.Aww…Snap'),
          description: this.translateService.instant('bookingService.internalErrorDescription', { "GALLOP_HELP_CONTACT_NO": Constants.GALLOP_HELP_CONTACT_NO, "GALLOP_HELP_EMAIL": this.clientConfig.agentEmailId }),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_INTERNAL
        };
      } break;

      case BookingResponseErrorType.ERROR_API: {

        bookingModalRes = {
          title: this.translateService.instant('bookingService.Aww…Snap'),
          description: this.translateService.instant('bookingService.apiErrorDescription', { "GALLOP_HELP_CONTACT_NO": Constants.GALLOP_HELP_CONTACT_NO, "GALLOP_HELP_EMAIL": this.clientConfig.agentEmailId }),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_API
        };
      } break;

      case BookingResponseErrorType.ERROR_TICKETING: {

        bookingModalRes = {
          title: this.translateService.instant('bookingService.Bookingcompletebutnotconfirmed'),
          description: this.translateService.instant('bookingService.ticketingErrorDescription', { "GALLOP_HELP_CONTACT_NO": Constants.GALLOP_HELP_CONTACT_NO, "GALLOP_HELP_EMAIL": this.clientConfig.agentEmailId }),
          errorCode: BookingResponseErrorType.ERROR_TICKETING,
          nextStep: this.translateService.instant('bookingService.BookTravel'),
          bookingType: 'flight',
          bookingResponse:bookingResponse
        };
      } break;

      case BookingResponseErrorType.ERROR_INVALID_INPUT: {

        let errorMessage = this.bookingResponse.errors[0].errorMessage;

        bookingModalRes = {
          title: errorMessage,
          nextStep: this.translateService.instant('bookingService.TRYAGAIN'),
          iconSCSS: 'cross-bold',
          errorCode: BookingResponseErrorType.ERROR_INVALID_INPUT
        };

      } break;
    }

    return bookingModalRes;

  }

  getFlightIconURL(flightCode: string): string {
    flightCode += '.gif';
    return environment.flightIconUrl + flightCode;
  }

  getAirportName(code: string): string {

    let airports;

    if (this.gallopLocalStorage.getItem('airports')) {
      airports = JSON.parse(this.gallopLocalStorage.getItem('airports'));
    }
    return airports ? (airports[code] ? airports[code].name : code) : code;
  }


  updateFlightObjectWithNewFare(transactionAmount: any) {

    let flightObject: FlightResult = this.selectedFlight;
    flightObject.price = transactionAmount.price;
    flightObject.discountedPrice = transactionAmount.discountedPrice;

    flightObject.fareBreakup['price'] = transactionAmount.price;
    flightObject.fareBreakup['totalPrice'] = transactionAmount.price;
    flightObject.fareBreakup['discountedPrice'] = transactionAmount.discountedPrice;

  }

  getPaymentErrorMessageModal(result: any): any {
    let paymentModalRes = {
      title: result.error.message,
      iconSCSS: 'cross-bold',
      errorCode: Constants.STRIPE
    };
    return paymentModalRes;
  }

  getTransactionAmountFromLocalStorage() {
    return JSON.stringify(this.gallopLocalStorage.getItem('transactionAmountBackupFromSubscibe'));
  }
}
